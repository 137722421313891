import React, { useState } from "react";
import { Button, Div, Text } from "../index";
import { Modal } from "react-bootstrap";

// import { BsCheck, IoClose, RiError } from "./styles";
import { AiFillExclamationCircle } from "react-icons/ai";
import {
  globalColor,
  globalFont,
} from "../../components/utils/RepeatFunctions/Schedule";

const PaymentError = (props) => {
  const title = props.billingsetting
    ? "Are you Sure ?"
    : props.errorType === "customer"
    ? "Check Account Details"
    : "Payment Error";

  const paras = props.billingsetting
    ? [
        `Changing the stripe key details may result in payment error as the connection between patient card and stripe key will get cut off.`,
        `If you change the key details you need to remove all the patients card and add it again to proceed payment.`,
        `Further you can't refund the amount to the patients card, so refund the  amount before you change the stripe key details.`,
      ]
    : props.errorType === "customer"
    ? [
        `You can't refund the amount to the patient account as the link between the stripe and patient card is invalid.`,
        `Check your stripe key details with your server details to make sure the details are same to proceed refund.`,
      ]
    : [
        `Can't proceed the payment as the patients card is not connected to
    the providers stripe key to make payment as there is change in key
    details.`,
        `To proceed with payment remove the patients card and add it again to
    secure a connection with stripe key`,
      ];

  return (
    <>
      <Modal.Body
        className="p-20"
        show="flex"
        justifyContent="center"
        onClick={props.handleModalClick}
      >
        <Div
          width="fit-content"
          height="fit-content"
          display="block"
          backgroundColor={"#fffff"}
          borderColor={"#fffff"}
          borderRadius="8px"
        >
          <Div display="flex" alignItems="center" justifyContent="flex-start">
            <AiFillExclamationCircle
              style={{ fontSize: "25px", color: "#F24822" }}
            />

            <Text
              fontFamily={globalFont}
              fontWeight="600"
              fontSize="16px"
              lineHeight="normal"
              color={"#2E2E2E"}
              padding="0px 6px 2px 5px"
              // MaxWidth="373px"
              align="left"
            >
              {title}
            </Text>
          </Div>

          {paras.map((data) => (
            <Text
              fontFamily={globalFont}
              fontWeight="400"
              fontSize="14px"
              lineHeight="normal"
              color={"#2E2E2E"}
              padding="8px 6px 6px 0px"
              // MaxWidth="373px"
              align="left"
              marginBottom="0px"
            >
              {data}
            </Text>
          ))}
          {props.billingsetting && (
            <Div margin="16px auto 0" display="flex" justifyContent="center">
              <Button
                background={globalColor}
                padding="8px 16px"
                marginRight="24px"
                className="save_active"
                onClick={props.confirm}
              >
                Yes, I wish to change
              </Button>
              <Button
                background={globalColor}
                className="save_active"
                padding="8px 16px"
                onClick={props.close}
              >
                No
              </Button>
            </Div>
          )}
          {/* {     <Text
            fontFamily={globalFont}
            fontWeight="400"
            fontSize="14px"
            lineHeight="normal"
            color={"#2E2E2E"}
            padding="6px 6px 0px 0px"
            // MaxWidth="373px"
            marginBottom="0px"
            align="left"
          >
            To proceed with payment remove the patients card and add it again to
            secure a connection with stripe key
          </Text>} */}
        </Div>
      </Modal.Body>
    </>
  );
};

export default PaymentError;
