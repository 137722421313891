import React, { useState, useEffect } from "react";
import AgingTable from "./AgingTable";
import SideNav from "../Sidenav";
import { useSelector } from "react-redux";

const Aging = () => {
  const [navData1, setNavData1] = useState("iconopen");

  var NavData1 = useSelector((state) => state.Schedule?.NavData);

  useEffect(() => {
    if (NavData1 !== "") {
      setNavData1(NavData1);
    }
  }, [NavData1]);
  return (
    <>
      <>
        <SideNav />
      </>
      <div
        className={
          (navData1 === "iconopen" && "right-block nav_right w-100") ||
          (navData1 === "iconclose" && "right-block nav_right1 w-100")
        }
      >
        <div
          id="demographics  prod-proc-main"
          className="appt-block-main-bill p-b-6 m-r-0 p-r-13"
        >
          <div className="appt-block-main schedule-comp1 vozo_margin p-b-20 prod-proc-main-cls">
            <AgingTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default Aging;
