import React, { useState, memo, useEffect } from "react";
import { Modal } from "react-bootstrap";
import edit_appointment_delete from "../../assets/images/edit_appointment_delete.png";
import edit_appointment from "../../assets/images/edit_appointment.png";
import edit_appointment_cancel from "../../assets/images/edit_appointment_cancel.png";
import axiosInstance from "../../axios/axios";
import axios from "axios";
import { Div, Span, Image, SpinnerDiv } from "../../StyledComponents";
import { Select } from "antd";
import { Button, Spinner } from "react-bootstrap";
import { Decrypt_Value, Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { useSelector, useDispatch } from "react-redux";
import AppointmentDetail from "./AppointmentDetail";
import { BsExclamationOctagon } from "react-icons/bs";
import moment from "moment";

const { Option } = Select;

function EditSchedule({
  closepopupedit,
  dataObj,
  repeatpopupenable,
  getAppointmentData,
  setEditAppointmentPop,
  setshow,
  setBookAgain,
  setappshow,
  setMsgPopup,
  facarray,
  provarray,
  pat_share,
  appFromDate,
  FetchAppointments,
  apptoDate,
  examroom,
  openAppointment,
}) {
  const [appointmentInfo, setAppointmentInfo] = useState({
    patientName: "",
    appointmentType: "",
    date: "",
    time: "",
    facility: "",
    provider: "",
    status: "",
    statusDropDown: [],
  });

  const [statusVal, setStatusVal] = useState("");
  const [SpinnerVal, setSpinnerVal] = useState(true);

  const repeats_checked = useSelector(
    (state) => state.Schedule.repeats_checked
  );
  const fetchStatusDropdown = async () => {
    try {
      const response = await axiosInstance.get("/vozo/appstatus/category");
      const decryptedData = Decrypt_Value(response.data, "vozo");
      setAppointmentInfo((prev) => ({
        ...prev,
        statusDropDown: [...decryptedData[0], ...decryptedData[1]],
      }));
    } catch (error) {
      console.error("Error fetching status dropdown:", error);
    }
  };

  const fetchAppointmentData = async () => {
    try {
      const popUpData = await getAppointmentData();

      const formattedTime = `${
        moment(popUpData?.pc_startTime, "HH:mm:ss").format("hh:mm a") || ""
      } - ${moment(popUpData?.pc_endTime, "HH:mm:ss").format("hh:mm a") || ""}`;
      setAppointmentInfo((prev) => ({
        ...prev,
        appointmentType: dataObj.title || "",
        patientName: dataObj.pname || "",
        provider: popUpData?.fname + " " + popUpData.lname || "",
        facility: popUpData?.name || "",
        date: popUpData?.pc_eventDate || "",
        time: formattedTime,
        status: popUpData?.pc_apptstatus || "",
        date: popUpData?.pc_eventDate || "",
        pcEid: popUpData?.pc_eid || "",
      }));

      setStatusVal(popUpData?.pc_apptstatus || "");
      setSpinnerVal(false);
    } catch (error) {
      setSpinnerVal(false);

      console.error("Error fetching appointment data:", error);
    }
  };

  useEffect(() => {
    fetchStatusDropdown();
    fetchAppointmentData();
  }, [dataObj, getAppointmentData]);

  const handleStatusChange = (value) => {
    setStatusVal(value);
  };

  const handleEdit = () => {
    setshow(false);
    setEditAppointmentPop(true);
  };
  const handleBookAgain = () => {
    setshow(false);
    setBookAgain(true);
    setappshow(true);
  };
  const handleUpdate = () => {
    const article = {
      apptstatus: statusVal,
      pcid: appointmentInfo.pcEid,
      time: appointmentInfo.date + " " + appointmentInfo.time,
    };
    let encryptedData = Encrypt_Value(article, "vozo");
    axiosInstance
      .put("/vozo/getappdashboardupdate", encryptedData)
      .then((res) => {
        setMsgPopup(true);
        FetchAppointments(
          provarray,
          facarray,
          appFromDate,
          apptoDate,
          pat_share,
          examroom
        );
        if (statusVal == "@" || statusVal == "doc_completed") {
          openAppointment("Yes");
        }
        setTimeout(() => setMsgPopup(false), 2000);

        setshow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = () => {
    repeatpopupenable(repeats_checked, dataObj);
  };
  const handleAdd = () => {
    openAppointment("Yes");
    setshow(false);
  };

  return (
    <>
      {SpinnerVal ? (
        <SpinnerDiv height="380px">
          <Spinner animation="border" variant="primary" />
        </SpinnerDiv>
      ) : (
        <>
          <Div borderRadius="10px">
            <Div
              display="flex"
              justifyContent="space-between"
              padding="16px"
              paddingBottom="8px"
              borderRadius="10px"
            >
              <Div>
                <Span marginBottom="4px" fontWeight="600" fontSize="16px">
                  {`${appointmentInfo.patientName} - ${appointmentInfo.appointmentType}`}
                </Span>
                <Span fontWeight="400" fontSize="12px">
                  {appointmentInfo.date
                    ? moment(appointmentInfo.date).format("MM-DD-YYYY")
                    : appointmentInfo.date}
                  &nbsp;&nbsp;{appointmentInfo.time}
                </Span>
              </Div>
              <Div display="flex" gap="16px" cursor="pointer">
                <img
                  onClick={handleEdit}
                  src={edit_appointment}
                  width={"20px"}
                  height={"20px"}
                  alt="Edit"
                />
                <img
                  onClick={handleDelete}
                  src={edit_appointment_delete}
                  width={"20px"}
                  height={"20px"}
                  alt="Delete"
                />
                <img
                  onClick={closepopupedit}
                  src={edit_appointment_cancel}
                  width={"20px"}
                  height={"20px"}
                  alt="Cancel"
                  style={{ cursor: "pointer" }}
                />
              </Div>
            </Div>
            <Div
              padding="0px 16px"
              borderTop="1px solid rgba(46, 46, 46, 0.25)"
              borderBottom="1px solid rgba(46, 46, 46, 0.25)"
            >
              <Div padding="16px 0px">
                <Span marginBottom="4px" fontWeight="400" fontSize="12px">
                  Provider
                </Span>
                <Span fontWeight="600" fontSize="14px">
                  {appointmentInfo.provider}
                </Span>
              </Div>
              <Div padding="0px">
                <Span marginBottom="4px" fontWeight="400" fontSize="12px">
                  Facility
                </Span>
                <Span fontWeight="600" fontSize="14px">
                  {appointmentInfo.facility}
                </Span>
              </Div>
              <Div padding="16px 0px">
                <Select
                  value={statusVal}
                  onChange={handleStatusChange}
                  className="edit_appointment_dropDown"
                >
                  {appointmentInfo.statusDropDown
                    .filter((x) => {
                      const isPresentNormal = appointmentInfo.statusDropDown
                        .filter((item) => item.value === statusVal)
                        .map((x) => x.check_flag);
                      return x.check_flag === isPresentNormal[0];
                    })
                    .map((item) => (
                      <Option key={item.value} value={item.value}>
                        {item.text}
                      </Option>
                    ))}
                </Select>
              </Div>
            </Div>
            <Div
              display="flex"
              justifyContent="space-between"
              padding="16px"
              borderRadius="10px"
            >
              <Div display="flex" gap="16px" alignItems="center">
                <Span
                  cursor="pointer"
                  color="rgba(44, 123, 229, 1)"
                  fontWeight="600"
                  marginBottom="0px !important"
                  fontSize="14px"
                  onClick={handleAdd}
                >
                  Add Services
                </Span>
                <Span
                  cursor="pointer"
                  color="rgba(44, 123, 229, 1)"
                  fontWeight="600"
                  fontSize="14px"
                  onClick={handleAdd}
                >
                  Add Note
                </Span>
              </Div>
              <Div display="flex" gap="16px">
                <Button className="remove_bg_blue" onClick={handleBookAgain}>
                  Book again
                </Button>
                <Button onClick={handleUpdate}>Update</Button>
              </Div>
            </Div>
          </Div>
        </>
      )}
    </>
  );
}

export default memo(EditSchedule);
