import React, { useState, useEffect, useRef, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Modal, Form, Col, Button, Spinner } from "react-bootstrap";
import BillingStepOne from "./BillingStepOne";
import InsuranceStep from "./InsuranceStep";
import SuperBill from "./SuperBill";
import { BsDownload } from "react-icons/bs";
import axiosInstance from "../../axios/axios";
import PrintSuperBill from "../popups/PrintSuperBill";
import PlayerIcon from "../../assets/images/Group_2552.png";
import Making_Payment_Vdo from "../../assets/videos/Making Payments.m4v";
import { MdClose } from "react-icons/md";
import { FiTrash2 } from "react-icons/all";
import { IoCloseSharp } from "react-icons/io5";

import { Decrypt_Value, Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { SpinnerDiv } from "../../StyledComponents";
import { changeAppointmentValues } from "../../StateManagement/Reducers/ScheduleState";
import { DatePicker, Select } from "antd";
import SuffixIcon from "../popups/CustomSuffixIcon";
import moment from "moment";
import UpgradeRequired from "../popups/UpgradeRequired";
import {ProfileFileURL } from "../../axios/url.js";

// import BillingDetail from "./BillingDetail";

const AppointmentBillingDetail = (
  {
    rerender,
    eventid,
    appID,
    pid,
    deleteapp,
    closepopupedit,
    savealertmsg,
    popuphide,
    setspinnerfornewapp,
    FetchAppointments,
    provarray,
    facarray,
    pat_share,
    examroom,
    appFromDate,
    apptoDate,
    renderTab,
    start,
    tabstatus1,
    tabstatus2,
    ServiceRefresh,
  },
  ref
) => {
  // ---------------------------------------- State and Variables Start--------------------------------------------------- //
  const { Option } = Select;
  var encounter = eventid;
  const dateFormat = "YYYY-MM-DD";
  const dtFormat = localStorage.getItem("dateFormat");

  // var provider = Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  const focusRef = useRef();
  const dispatch = useDispatch();
  const [showResults, setShowResults] = useState(true);
  const [showInsurance, setshowInsurance] = useState(false);
  const [ApptPaymentProfiles, setApptPaymentProfiles] = useState([]);
  const [superLogo, setSuperLogo] = useState("");
  const [displayLogo, setDisplayLogo] = useState("");
  const [superFooter, setSuperFooter] = useState("");
  const [signLine, setSignLine] = useState("");
  const [codeText, setCodeText] = useState("");
  const [PaymentStatus, setPaymentStatus] = useState("");
  const [Billstatus, setBillstatus] = useState("");
  const [HCF_FormID, setHCF_FormID] = useState("");
  const [OnsetDtTyp, setOnsetDtTyp] = useState("");
  const [OnsetDt, setOnsetDt] = useState(null);
  const [OtherDtTyp, setOtherDtTyp] = useState("");
  const [OtherDt, setOtherDt] = useState(null);
  const [Emp, setEmp] = useState("");
  const [AutoAccdnt, setAutoAccdnt] = useState("");
  const [OtherAccdnt, setOtherAccdnt] = useState("");
  const [patAppt, setPatAppt] = useState([]);
  const [patData, setPatData] = useState([]);
  const [billed, setBilled] = useState("");
  const [adjmt, setAdjmt] = useState("");
  const [patAmt, setPatAmt] = useState("");
  const [insAmt, setinsAmt] = useState("");
  const [totalBal, setTotBal] = useState("");
  const [icdCode, setIcdCode] = useState([]);
  const [insData, setInsData] = useState([]);
  const [show, setShow] = useState(false);
  const [logoext, setLogoext] = useState("");
  const [base64, setBase64] = useState("");
  const [billstatError, setbillstatError] = useState(false);
  const [payProDisable, setpayProDisable] = useState(false);
  const [SpinnerVal, setSpinnerVal] = useState(true);
  const [emailSuperBill, setEmailSuperBill] = useState("");
  const [profileErr, setProfileErr] = useState(false);
  const [SecprofileErr, setSecProfileErr] = useState(false);
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [AlertsMsg, setAlertMsg] = useState("");

  const [EmpErr, setEmpErr] = useState(false);
  const [AutoAccErr, setAutoAccErr] = useState(false);
  const [OtherAccErr, setOtherAccErr] = useState(false);
  const [upgrade, setUpgrade] = useState(false);
  const [invStatus, setInvStatus] = useState(0);
  // console.log(invStatus);
  const [state, setState] = useState({
    showHelp: false,
  });

  const BillStepOne = {
    eid: eventid,
    pid: pid,
    HCF_FormID: HCF_FormID,
    PaymentStatus: PaymentStatus,
    Billstatus: Billstatus,
    OnsetDtTyp: OnsetDtTyp,
    OnsetDt: OnsetDt,
    Employment: Emp,
    OtherDtType: OtherDtTyp,
    AutoAccdnt: AutoAccdnt,
    OtherDt: OtherDt,
    OtherAccdnt: OtherAccdnt,
  };
 
  const saveButton = useSelector((state) => state.Schedule.saveButton);

  const CompRef = useRef();

  const currency = useSelector((state) => state.Report.Currency);
  const callFunction = useSelector((state) => state.Schedule.callFunction);
  let accountType = Decrypt_Value(localStorage.getItem("account_type"), "vozo");
  let verifiedEncounter = start;
  // ---------------------------------------- State and Variables End--------------------------------------------------- //

  // ---------------------------------------- Functions Start----------------------------------------------------------- //

  const billingstatuserror = () => {
    focusRef.current.focus();
    setbillstatError(true);
  };

  const HandleClose = () => setShow(false);

  const HandleOpen = async () => {
    if (verifiedEncounter === "0" || verifiedEncounter === 0) {
      return false;
    }

    if (tabstatus1 && tabstatus2) {
      return false;
    }
    await axiosInstance
      .get(
        "/vozo/patientstatement/appointmentdetail?eid=" + eventid + "&id=" + pid
      )
      .then((response) => {
        let temp_role_pat_stat_app = Decrypt_Value(response.data, "vozo");
        if (pid != null) {
          setPatData(temp_role_pat_stat_app[2]);
          setInsData(temp_role_pat_stat_app[3]);
          setIcdCode(temp_role_pat_stat_app[4]);
          setPatAppt(temp_role_pat_stat_app[0]);
          setBilled(temp_role_pat_stat_app[1][4].totbilled);
          setAdjmt(temp_role_pat_stat_app[1][3].totadj);
          setinsAmt(temp_role_pat_stat_app[1][1].totins);
          setPatAmt(temp_role_pat_stat_app[1][2].totpat);
          setTotBal(temp_role_pat_stat_app[1][0].total_due);
          setShow(true);
        }
      })
      .catch((err) => {
        setPatAppt([]);
        console.error(err);
      });

    if (emailSuperBill === "1") {
      axiosInstance
        .post("/vozo/patsuperbillmail?pid=" + pid + "&eid=" + eventid)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const buttonDetailClickActions = async (e) => {
    e !== "avoid" && e.preventDefault();
    var payment_type;
    if (PaymentStatus == null || PaymentStatus === "") {
      payment_type = "1";
    } else {
      payment_type = PaymentStatus;
    }
    var error = 0;
    if (Billstatus === "" || Billstatus === null) {
      focusRef.current.focus();
      setbillstatError(true);
      error = 2;
    }
    if (payment_type === "1") {
      setEmpErr(false);
      setAutoAccErr(false);
      setOtherAccErr(false);
      if (error > 0) {
        dispatch(changeAppointmentValues({ name: "reset call", value: "" }));
        return false;
      }
    }
    if (payment_type === "2") {
      if (Emp === "") {
        setEmpErr(true);
        error = 1;
      }
      if (AutoAccdnt === "") {
        setAutoAccErr(true);
        error = 1;
      }
      if (OtherAccdnt === "") {
        setOtherAccErr(true);
        error = 1;
      }
      if (error > 0) {
        dispatch(changeAppointmentValues({ name: "reset call", value: "" }));
        return false;
      }

      if (profileErr) {
        setModalAlerShow(true);
        setAlertMsg("Please Add Insurance provider Details and Continue");
        setTimeout(() => setModalAlerShow(false), 2000);
        dispatch(changeAppointmentValues({ name: "reset call", value: "" }));
        return false;
      }
    }
    if (Billstatus === "Bill Secondary Insurance" && payment_type === "2") {
      if (SecprofileErr) {
        setModalAlerShow(true);
        setAlertMsg("Please Add Secondary Insurance Details and Continue");
        setTimeout(() => setModalAlerShow(false), 2000);
        dispatch(changeAppointmentValues({ name: "reset call", value: "" }));
        return false;
      }
    }

    var encounternew = eventid;
    if (Billstatus !== null) {
      var insurancearticle = {
        billSt: Billstatus,
        PaySt: payment_type,
      };
      let encryptedData_ins_art = Encrypt_Value(insurancearticle, "vozo");

      if (payment_type === "1") {
        axiosInstance
          .put(
            "/vozo/updatebillinghdrstatus?num=1&eid=" + encounternew,
            encryptedData_ins_art
          )
          .then(() => {
            setspinnerfornewapp();
            savealertmsg("Billing Details Updated", true);
            setTimeout(() => popuphide(), 2000);
            FetchAppointments(
              provarray,
              facarray,
              appFromDate,
              apptoDate,
              pat_share,
              examroom
            );
          })
          .catch((err) => console.error(err));
      } else {
        let HCF_FormIDnew = HCF_FormID;
        let OnsetDtTypnew = OnsetDtTyp;
        let OnsetDtnew = OnsetDt;
        let Employmentnew = Emp;
        let OtherDtTypenew = OtherDtTyp;
        let AutoAccdntnew = AutoAccdnt;
        let OtherDtnew = OtherDt;
        let OtherAccdntnew = OtherAccdnt;

        if (HCF_FormIDnew === "") {
          const article = {
            eid: encounternew,
            pid: pid,
            OnsetDtTyp: OnsetDtTypnew,
            OnsetDt: OnsetDtnew,
            Employment: Employmentnew,
            OtherDtType: OtherDtTypenew,
            AutoAccdnt: AutoAccdntnew,
            OtherDt: OtherDtnew,
            OtherAccdnt: OtherAccdntnew,
          };

          let encryptedData_hfca_insert = Encrypt_Value(article, "vozo");
          axiosInstance
            .post("/vozo/hcfaform/insert", encryptedData_hfca_insert)
            .then(() => {})
            .catch((err) => console.log(err));
        } else {
          const article = {
            eid: encounternew,
            pid: pid,
            OnsetDtTyp: OnsetDtTypnew,
            OnsetDt: OnsetDtnew,
            Employment: Employmentnew,
            OtherDtType: OtherDtTypenew,
            AutoAccdnt: AutoAccdntnew,
            OtherDt: OtherDtnew,
            OtherAccdnt: OtherAccdntnew,
          };
          let encryptedData_hfca_update = Encrypt_Value(article, "vozo");
          axiosInstance
            .put(
              "/vozo/hcfaform/update?hcfid=" + HCF_FormIDnew,
              encryptedData_hfca_update
            )
            .then(() => {})
            .catch((err) => console.log(err));
        }

        axiosInstance
          .put(
            "/vozo/updatebillinghdrstatus?num=2&eid=" + encounternew,
            encryptedData_ins_art
          )
          .then(() => {
            setspinnerfornewapp();
            savealertmsg("Billing Details Updated", true);
            setTimeout(() => popuphide(), 2000);
            FetchAppointments(
              provarray,
              facarray,
              appFromDate,
              apptoDate,
              pat_share,
              examroom
            );
          })
          .catch((err) => console.error(err));
      }
    } else {
      closepopupedit();
    }
  };

  const payment_profile_disable = (e) => {
    setpayProDisable(e);
  };
  const PaymentProfileChange = (e) => {
    // console.log(e, "e");

    // console.log(typeof e, "type-e");
    setShowResults(true);

    if (invStatus > 0) {
      return;
    }
    if (accountType === "1" && e === "2") {
      setUpgrade(true);
      return false;
    }
    setPaymentStatus(e);
    if (e === "1") {
      setshowInsurance(false);
      setEmpErr(false);
      setAutoAccErr(false);
      setOtherAccErr(false);
    }

    if (e === "2") {
      setshowInsurance(true);
      setShowResults(false);
    }
    dispatch(
      changeAppointmentValues({ name: "save button", value: "billing" })
    );
  };

  const PrintSupberBill = () => {
    axiosInstance
      .get(
        "/vozo/patientstatement/appointmentdetail?eid=" + eventid + "&id=" + pid
      )
      .then((response) => {
        let temp_role_pat_stat_app = Decrypt_Value(response.data, "vozo");
        if (pid != null) {
          setPatData(temp_role_pat_stat_app[2]);
          setPatAppt(temp_role_pat_stat_app[0]);
          setBilled(temp_role_pat_stat_app[1][4].totbilled);
          setAdjmt(temp_role_pat_stat_app[1][3].totadj);
          setinsAmt(temp_role_pat_stat_app[1][1].totins);
          setPatAmt(temp_role_pat_stat_app[1][2].totpat);
          setTotBal(temp_role_pat_stat_app[1][0].total_due);
        }

        PrintSuperBill(
          patAppt,
          patData,
          temp_role_pat_stat_app[3]
            ? temp_role_pat_stat_app[3]
            : {
                insurer: "",
                subscriber_name: "",
                group_number: "",
                member_id: "",
              },
          temp_role_pat_stat_app[4],
          billed,
          insAmt,
          patAmt,
          totalBal,
          adjmt,
          currency,
          superLogo,
          superFooter,
          signLine,
          codeText,
          logoext,
          base64
        );
      })
      .catch((err) => {
        setPatAppt([]);
        console.error(err);
      });
  };

  const changeBillStatus = (val) => {
    setBillstatus(val);
    setbillstatError(false);
    dispatch(
      changeAppointmentValues({ name: "save button", value: "billing" })
    );
  };
  const handleOnsetTyp = (val) => {
    setOnsetDtTyp(val);
    dispatch(
      changeAppointmentValues({ name: "save button", value: "billing" })
    );
  };

  const handleOnsetDate = (val) => {
    setOnsetDt(val ? moment(val, dtFormat).format("YYYY-MM-DD") : null);
    dispatch(
      changeAppointmentValues({ name: "save button", value: "billing" })
    );
  };

  const handleEmploy = (val) => {
    setEmp(val);
    setEmpErr(false);
    dispatch(
      changeAppointmentValues({ name: "save button", value: "billing" })
    );
  };

  const handleOtherDateTyp = (val) => {
    setOtherDtTyp(val);
    dispatch(
      changeAppointmentValues({ name: "save button", value: "billing" })
    );
  };
  const handleAutoAcd = (val) => {
    setAutoAccdnt(val);
    setAutoAccErr(false);
    dispatch(
      changeAppointmentValues({ name: "save button", value: "billing" })
    );
  };

  const handleOtherDate = (val) => {
    setOtherDt(val ? moment(val, dtFormat).format("YYYY-MM-DD") : null);
    dispatch(
      changeAppointmentValues({ name: "save button", value: "billing" })
    );
  };
  const handleOtherAcd = (val) => {
    setOtherAccdnt(val);
    setOtherAccErr(false);
    dispatch(
      changeAppointmentValues({ name: "save button", value: "billing" })
    );
  };

  const billingDatas = async () => {
    let test_paymentstatus;
    let type;
    await axiosInstance
      .get(
        "/vozo/billing/payment_hdr/disable_payment/disable_bill/hfca_form?eid=" +
          eventid
      )
      .then((response) => {
        let data = Decrypt_Value(response.data, "vozo");

        if (data[2].payment_type) {
          setpayProDisable(true);
          setPaymentStatus(data[2].payment_type);
        }
        if (Billstatus !== "" || Billstatus !== null) {
          if (data[2].status_secondary) {
            setBillstatus(data[2].status_secondary);
          }
        } else {
          setBillstatus("");
        }

        test_paymentstatus = data[2].payment_type;

        if (data[2].payment_type === "1" || data[2].payment_type === 1) {
          setShowResults(true);
          setshowInsurance(false);
        } else if (data[2].payment_type === "2" || data[2].payment_type === 2) {
          setshowInsurance(true);
          setShowResults(false);
        }

        if (
          data[0] !== "0" ||
          data[1] !== "0" ||
          data[0] !== null ||
          data[1] !== null
        ) {
          // setpayProDisable(true);
        }
        if (data[3] !== null) {
          type = data[3];
        }
        if (data[4] !== null) {
          // setpayProDisable(true);
          setshowInsurance(true);
          setHCF_FormID(data[4][0].id);

          if (data[4][0].onset_dt_typ) {
            setOnsetDtTyp(data[4][0].onset_dt_typ);
          }
          setOnsetDt(data[4][0].onset_date);

          if (data[4][0].other_dt_typ) {
            setOtherDtTyp(data[4][0].other_dt_typ);
          }
          setOtherDt(data[4][0].other_date);

          setEmp(data[4][0].emp);

          setAutoAccdnt(data[4][0].auto_accident);
          setOtherAccdnt(data[4][0].other_accident);

          if (data[4][0].id !== "" || data[4][0].id === undefined) {
            setShowResults(true);
          }
        }
      })
      .catch((err) => {
        setPaymentStatus("");
        console.error(err);
      });

    await axiosInstance
      .get("/vozo/checkboxdefault?eid=" + eventid)
      .then((response) => {
        let temp_role_check_box_def = Decrypt_Value(response.data, "vozo");
        if (temp_role_check_box_def === "zero") {
          axiosInstance
            .get("/vozo/billingdet/getcatdet?cat_id=" + appID)
            .then((response) => {
              let temp_role_get_catdata = Decrypt_Value(response.data, "vozo");

              if (test_paymentstatus === "" || test_paymentstatus === null) {
                if (
                  temp_role_get_catdata[0].PayProf === 1 ||
                  temp_role_get_catdata[0].PayProf === "1"
                ) {
                  setPaymentStatus(temp_role_get_catdata[0].PayProf);

                  setShowResults(true);
                  setshowInsurance(false);
                } else if (
                  temp_role_get_catdata[0].PayProf === 2 ||
                  temp_role_get_catdata[0].PayProf === "2"
                ) {
                  setPaymentStatus(temp_role_get_catdata[0].PayProf);
                  setShowResults(false);
                  setshowInsurance(true);
                } else {
                  setPaymentStatus("1");

                  setShowResults(true);
                  setshowInsurance(false);
                }
              }
            })
            .catch((err) => console.error(err));
        }

        if (temp_role_check_box_def === "one") {
          setshowInsurance(true);
          setShowResults(false);
          // document.getElementById("defaultselect").selectedIndex = "1";
        }
      })
      .catch((err) => console.error(err));
    await axiosInstance
      .get("/vozo/appprofiles/payement_profile")
      .then((response) => {
        let temp_role_pay_prof = Decrypt_Value(response.data, "vozo");
        setApptPaymentProfiles(temp_role_pay_prof);
      })
      .catch((err) => {
        setApptPaymentProfiles([]);
        console.error(err);
      });

    await axiosInstance
      .get("/vozo/settingdocumentdata")
      .then((response) => {
        let temp_role_setng_doc_data = Decrypt_Value(response.data, "vozo");
        var text = temp_role_setng_doc_data[4]["gl_value"];
        var ext = text.substr(text.length - 3);

        setLogoext(text.substr(text.length - 3));

        setDisplayLogo(temp_role_setng_doc_data[4]["gl_value"]);
        setSuperLogo(temp_role_setng_doc_data[9]["gl_value"]);
        setSuperFooter(temp_role_setng_doc_data[8]["gl_value"]);
        setSignLine(temp_role_setng_doc_data[10]["gl_value"]);
        setCodeText(temp_role_setng_doc_data[7]["gl_value"]);

        let imgfile = {
          url: ProfileFileURL + temp_role_setng_doc_data[4]["gl_value"],
          ext: ext,
        };

        axiosInstance
          .post("/vozo/document/img/base64", imgfile)
          .then((res) => {
            setBase64(res.data);
          })
          .catch(() => {
            setBase64("");
          });
      })
      .catch((err) => console.error(err));

    await axiosInstance
      .get("/vozo/billing/notification/preferences?pid=" + pid)
      .then((response) => {
        let res = Decrypt_Value(response.data, "vozo");
        setEmailSuperBill(res.emailSuperBill);
        setSpinnerVal(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinnerVal(false);
      });

    await axiosInstance
      .get("/vozo/insurancedatacheck?pid=" + pid)
      .then((response) => {
        let decode = Decrypt_Value(response.data, "vozo");
        if (decode.primary === "exist") setProfileErr(false);
        else setProfileErr(true);
        if (decode.secondary === "exist") setSecProfileErr(false);
        else setSecProfileErr(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlecloseUpgrade = () => {
    setUpgrade(false);
  };
  // ------------------------------ Functions End--------------------------------------------------- //

  // ------------------------------ useEffect Start--------------------------------------------------- //

  useEffect(() => {
    axiosInstance
      .get("/vozo/showinvoicename?eid=" + encounter + "&type=0")
      .then((response) => {
        const temp_role_show_invoicename = Decrypt_Value(response.data, "vozo");
        // console.log(temp_role_show_invoicename, "temp_role_show_invoicename");
        setInvStatus(temp_role_show_invoicename.length);
      })
      .catch((error) => setInvStatus(0));
  }, []);

  useEffect(() => {
    setSpinnerVal(true);

    billingDatas();
  }, [rerender, renderTab]);

  useEffect(() => {
    if (callFunction["billing"] > 1) {
      buttonDetailClickActions("avoid");
    }
  }, [callFunction["billing"]]);

  // --------------------------=---- useEffect End--------------------------------------------------- //
  return (
    <>
      {SpinnerVal ? (
        <SpinnerDiv height="320px">
          <Spinner animation="border" variant="primary" />
        </SpinnerDiv>
      ) : (
        <>
          <Modal.Body className="bill_appt bill-appt-pdb apt-det-bill-body">
            <input
              type="hidden"
              className="we-none12"
              id="new-app-encounter"
              value={encounter}
            />
            <>
              <input type="hidden" id="hcfformID" value={HCF_FormID} />
            </>

            <div className="vid-play-box mystyle_video">
              <div className="vid-line1"></div>
              <div className="vid-line2"></div>

              <div className="vid-play-icon">
                <img
                  src={PlayerIcon}
                  height="28px"
                  width="31px"
                  onClick={() => setState({ showHelp: true })}
                  alt="Play icon"
                />
              </div>
              <div className="vid-line3"></div>
              <div
                className="vid-play-text"
                onClick={() => setState({ showHelp: true })}
              >
                Watch a quick tutorial about Payment
              </div>
            </div>

            <Form.Row>
              <Form.Group as={Col} className="bill_superbill">
                {/* <div className="for-pat-sup-bill-top superbill_btn_marg"> */}
                <Form.Label className="w-100">Super Bill</Form.Label>
                <Button
                  variant="outline-secondary grey-out-btn m-r-10 superbill_btn bill-super-btn"
                  onClick={() => HandleOpen()}
                  disabled={
                    (tabstatus1 && tabstatus2) ||
                    verifiedEncounter === "0" ||
                    verifiedEncounter === 0
                  }
                  id="appt_superbill_btn"
                >
                  Patient Super Bill
                </Button>
                {/* </div> */}
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className="w-100">Employment</Form.Label>
                <div>
                  <Select
                    suffixIcon={<SuffixIcon />}
                    placeholder="Select"
                    className={EmpErr ? "w-200 err_mess_border " : "w-200"}
                    value={Emp.toString() || undefined}
                    onChange={(e) => {
                      handleEmploy(e);
                    }}
                    disabled={PaymentStatus === "1"}
                  >
                    <Option value="1">Yes</Option>
                    <Option value="0">No</Option>
                  </Select>
                  {EmpErr && (
                    <p className="errormessage ">Please choose anyone</p>
                  )}
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row className="for-billing-row-hgt">
              <Form.Group as={Col} className="margin-form bill_billstatus">
                <Form.Label className="w-100">Billing Status</Form.Label>
                <div>
                  <Select
                    suffixIcon={<SuffixIcon />}
                    className={
                      billstatError ? "w-200 err_mess_border " : "w-200"
                    }
                    placeholder="Select"
                    value={Billstatus || undefined}
                    id="getbillingstatus"
                    ref={focusRef}
                    onChange={(e) => {
                      changeBillStatus(e);
                    }}
                    disabled={
                      payProDisable &&
                      (verifiedEncounter === "0" || verifiedEncounter === 0)
                    }
                    // notFoundContent={FullScreen || payProDisable}
                    // disabled={FullScreen || payProDisable}
                    // allowClear={!FullScreen && !payProDisable}
                  >
                    <Option value="Paid In Full">Paid In Full</Option>

                    <Option value="Balance Due">Balance Due</Option>

                    <Option value="Settled" id="appt_billing_settled">
                      Settled
                    </Option>

                    <Option value="Internal Review">Internal Review</Option>

                    <Option value="Bill Insurance">Bill Insurance</Option>

                    <Option value="Bill Secondary Insurance">
                      Bill Secondary Insurance
                    </Option>

                    <Option value="Worker's Comp Claim">
                      Worker's Comp Claim
                    </Option>

                    <Option value="Auto Accident Claim">
                      Auto Accident Claim
                    </Option>

                    <Option value="Durable Medical Equipment Claim">
                      Durable Medical Equipment Claim
                    </Option>
                  </Select>
                  {billstatError && (
                    <p className="errormessage ">
                      Please select Billing Status
                    </p>
                  )}
                </div>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className="w-100">Onset Date Type</Form.Label>

                <Select
                  suffixIcon={<SuffixIcon />}
                  className="w-200"
                  placeholder="Select"
                  id="cashselect"
                  value={OnsetDtTyp || undefined}
                  onChange={(e) => handleOnsetTyp(e)}
                  disabled={PaymentStatus === "1"}
                >
                  <Option value="431">
                    Onset of Current Symptoms or Illness
                  </Option>

                  <Option value="439">Date of Accident</Option>

                  <Option value="484">Last Menstrual Period</Option>
                </Select>
              </Form.Group>
            </Form.Row>
            <Form.Row className="for-billing-row-hgt">
              <Form.Group as={Col}>
                <Form.Label className="w-100">Payment Profile</Form.Label>

                <Select
                  suffixIcon={<SuffixIcon />}
                  placeholder="Select"
                  className="w-200"
                  value={PaymentStatus || undefined}
                  id="defaultselect"
                  onChange={PaymentProfileChange}
                  disabled={
                    (payProDisable &&
                      (verifiedEncounter === "0" || verifiedEncounter === 0)) ||
                    invStatus > 0
                  }
                >
                  {ApptPaymentProfiles.map((row) =>
                    accountType === "-1" && row.value === "2" ? (
                      <Option key={row.value} disabled value={row.value}>
                        {row.name}
                      </Option>
                    ) : (
                      <Option key={row.value} value={row.value}>
                        {row.name}
                      </Option>
                    )
                  )}
                </Select>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="w-100">Onset Date</Form.Label>

                <DatePicker
                  className="w-200"
                  format="MM-DD-YYYY"
                  placeholder="mm-dd-YYYY"
                  value={OnsetDt ? moment(OnsetDt, dateFormat) : null}
                  disabled={PaymentStatus === "1"}
                  onChange={(e) => handleOnsetDate(e)}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row className="for-billing-row-hgt">
              <Form.Group as={Col}>
                <Form.Label className="w-100">Auto Accident</Form.Label>
                <div>
                  <Select
                    suffixIcon={<SuffixIcon />}
                    placeholder="Select"
                    className={AutoAccErr ? "w-200 err_mess_border " : "w-200"}
                    value={AutoAccdnt || undefined}
                    onChange={(e) => {
                      handleAutoAcd(e);
                    }}
                    disabled={PaymentStatus === "1"}
                  >
                    <Option value="1">Yes</Option>

                    <Option value="0">No</Option>
                  </Select>
                  {AutoAccErr && (
                    <p className="errormessage ">Please choose anyone</p>
                  )}
                </div>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="w-100">Other Date Type</Form.Label>
                <Select
                  suffixIcon={<SuffixIcon />}
                  placeholder="Select"
                  className="w-200"
                  value={OtherDtTyp || undefined}
                  onChange={(e) => handleOtherDateTyp(e)}
                  disabled={PaymentStatus === "1"}
                >
                  <Option value="444">Initial Visit Date</Option>

                  <Option value="454">Initial Treatment Date</Option>

                  <Option value="304">Last Related Visit</Option>
                </Select>
              </Form.Group>
            </Form.Row>
            <Form.Row className="for-billing-row-hgt">
              <Form.Group as={Col} className="for-bill-last-box">
                <Form.Label className="w-100">Other Accident</Form.Label>
                <div>
                  <Select
                    suffixIcon={<SuffixIcon />}
                    placeholder="Select"
                    className={OtherAccErr ? "w-200 err_mess_border " : "w-200"}
                    value={OtherAccdnt || undefined}
                    onChange={(e) => {
                      handleOtherAcd(e);
                    }}
                    disabled={PaymentStatus === "1"}
                  >
                    <Option value="1">Yes</Option>

                    <Option value="0">No</Option>
                  </Select>
                  {OtherAccErr && (
                    <p className="errormessage ">Please choose anyone</p>
                  )}
                </div>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="w-100">Other Date</Form.Label>

                <DatePicker
                  placeholder="mm-dd-YYYY"
                  className="w-200"
                  format="MM-DD-YYYY"
                  value={OtherDt ? moment(OtherDt, dateFormat) : null}
                  onChange={(e) => handleOtherDate(e)}
                  disabled={PaymentStatus === "1"}
                />
              </Form.Group>
            </Form.Row>

            {showResults && (
              <BillingStepOne
                encounter={start}
                eid={eventid}
                pid={pid}
                row={BillStepOne}
                rerender={rerender}
                renderTab={renderTab}
                billingstatuserror={billingstatuserror}
                payment_profile_disable={payment_profile_disable}
                ServiceRefresh={ServiceRefresh}
                setInvStatus={setInvStatus}
              />
            )}
            {showInsurance && (
              <InsuranceStep
                eid={eventid}
                proid={pid}
                row={BillStepOne}
                rerender={rerender}
                renderTab={renderTab}
                billingstatuserror={billingstatuserror}
                payment_profile_disable={payment_profile_disable}
                setEmpErr={setEmpErr}
                setAutoAccErr={setAutoAccErr}
                setOtherAccErr={setOtherAccErr}
                // FullScreen={FullScreen}
              />
            )}
          </Modal.Body>
        </>
      )}

      <Modal
        show={show}
        id="fullscreen-popup"
        className="super_bill_main sup-bill-bck-chnge invoice-full bil_invoice"
        onHide={HandleClose}
      >
        <Modal.Header
          closeButton
          className="collapse-footer backgr-chng1"
          id="appt_superbill_close"
        >
          <div className="superbill_print_icon">
            <BsDownload className="print_sbill" onClick={PrintSupberBill} />
          </div>
        </Modal.Header>

        <div className="super_bill_body">
          <SuperBill
            ref={CompRef}
            currency={currency}
            text={codeText}
            logoname={displayLogo}
            icdCode={icdCode}
            logo={superLogo}
            sign={signLine}
            footer={superFooter}
            insData={insData}
            patAppt={patAppt}
            patData={patData}
            billed={billed}
            insAmt={insAmt}
            patAmt={patAmt}
            totalBal={totalBal}
            adjmt={adjmt}
          />
        </div>
      </Modal>
      <Modal className="alert-popup-message" show={ModalAlerShow}>
        <div className="alert msg alert-danger" role="alert">
          <IoCloseSharp className="icons-bs" />
          {AlertsMsg}
        </div>
      </Modal>
      {/* video */}
      <Modal
        show={state.showHelp}
        onHide={() => setState({ showHelp: false })}
        className="help-modal-video"
        centered
      >
        <div
          className="closeButtonModal"
          onClick={() => setState({ showHelp: false })}
        >
          <MdClose />
        </div>

        <Modal.Body>
          <video controls autoPlay className="vdo_alt">
            <source src={Making_Payment_Vdo} type="video/mp4"></source>
          </video>
        </Modal.Body>
      </Modal>
      {upgrade && (
        <UpgradeRequired
          title="Patient Insurance Information"
          description="Add and View list of insurance data, which will be used for payment."
          boxContent={
            <span>
              Available in
              <span className="upgradFont"> Premium</span> plan
            </span>
          }
          handlecloseUpgrade={handlecloseUpgrade}
          bgChange={true}
        />
      )}
      {/* video */}
    </>
  );
};
export default memo(React.forwardRef(AppointmentBillingDetail));
