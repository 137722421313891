import React, { useState, useEffect, useRef, memo } from "react";
import { useSelector } from "react-redux";

import axiosInstance from "../../axios/axios";
import { Modal, Spinner } from "react-bootstrap";
import Example from "../patient/Example";
import { BsDownload, BsExclamationOctagon } from "react-icons/bs";
import { AiOutlinePrinter } from "react-icons/ai";
import { BiTrashAlt } from "react-icons/bi";
import { BsCheckCircle } from "react-icons/bs";
import AddPayment from "./AddPayment";
import { SpinnerDiv, BillingBlock } from "../../StyledComponents";

var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Decrypt_Value = (data, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(data, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};

const Encrypt_Value = (Val, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(Val), key, {
    format: Encode_Decode_JSON,
  }).toString();
};

const BillingStepOne = ({
  eid,
  pid,
  row,
  rerender,
  renderTab,
  billingstatuserror,
  payment_profile_disable,
  FullScreen,
  encounter,
  ServiceRefresh,
  setInvStatus,
}) => {
  // ---------------------------------------- State and Variables Start--------------------------------------------------- //

  const fref = useRef();
  const [showfull, showfullpop] = useState(false);
  const [invoicesetval, setinvoicesetval] = useState("");
  const [show, setShow] = useState(false);
  const [Showtotalbill, setShowtotalbill] = useState([]);
  const [showinvoname, setshowinvoname] = useState([]);
  const [invoname, setinvoname] = useState("");
  const [invototal, setinvototal] = useState("");
  const [invobalance, setinvobalance] = useState("");
  const [pat_name, setpat_name] = useState("");

  const [invStat, setInvStat] = useState("");
  const [total_bal, settotal_bal] = useState(0);
  const [total_cre, settotal_cre] = useState(false);
  const [reload_inv, setreload_inv] = useState(false);
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [ModalAlerShow1, setModalAlerShow1] = useState(false);
  const [AlertsMsg, setAlertMsg] = useState("");
  const [AlertsMsg1, setAlertMsg1] = useState("");
  const [feeLoad1, setFeeLoader1] = useState(true);
  const [clicked, setClicked] = useState(false);
  const currency = useSelector((state) => state.Report.Currency);

  var provider = Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  var storedNames = Decrypt_Value(localStorage.getItem("setroles"), "vozo");

  // ---------------------------------------- State and Variables End--------------------------------------------------- //

  // ---------------------------------------- Functions Start----------------------------------------------------------- //

  const settotal_balance = (val) => {
    settotal_bal(val);
  };

  const handleClose = () => {
    setShow(false);
  };

  const printpage = () => {
    fref.current.setFromOutside();
  };

  const downloadpdf = () => {
    fref.current.generatePDF();
  };

  const invshowfullpop = () => {
    setreload_inv(!reload_inv);
    showfullpop(false);
  };

  const deleteinv = (invid) => {
    var temp = { inv: invid };
    var delete_inv_encryptedData = Encrypt_Value(temp, "vozo");
    if (window.confirm("Are you sure ?")) {
      axiosInstance
        .put("/vozo/delete_inc", delete_inv_encryptedData)
        .then((response) => {
          if (response.data === "pass") {
            setModalAlerShow(true);
            setAlertMsg("invoice deleted successfully");
            setTimeout(modalhide, 1500);
          } else if (response.data === "fail") {
            setModalAlerShow1(true);
            setAlertMsg1("Invoice already paid");
            setTimeout(modalhide, 1500);
          }
          setreload_inv(!reload_inv);
        })
        .catch((err) => console.log(err));
      showfullpop(false);
    }
  };

  const saveinvoice = async (eid) => {
    var error = 0;
    setInvStat("");
    if (row.Billstatus === "" || row.Billstatus === null) {
      billingstatuserror();
      error = 2;
    }
    if (error > 0) {
      return false;
    }
    if (!clicked) {
      setClicked(true);
      if (row.PaymentStatus === "1") {
        payDatas();
      }

      // var chginvoice = localStorage.getItem("invoname");
      // var subinvoval = chginvoice.substr(8);
      // var invoname = "vozoinv#" + (parseInt(subinvoval) + 1);
      // localStorage.setItem("invoname", invoname);
      var article = { amount: Showtotalbill[1], num: 0 };
      var insert_invpoice_encryptedData = Encrypt_Value(article, "vozo");
      await axiosInstance
        .put(
          "/vozo/insertinvoice?eid=" + eid + "&status=insertdata",
          insert_invpoice_encryptedData
        )
        .then((response) => {
          let data = Decrypt_Value(response.data, "vozo");
          setinvoicesetval(data["invoice_id"]);
          localStorage.setItem("invoname", data["invoice_id"]);
          // axiosInstance
          //   .post(
          //     "/vozo/insertinvoicedata?eid=" + eid + "&num=0",
          //     insert_invpoice_encryptedData
          //   )
          //   .then(() => {})
          //   .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
      if (ServiceRefresh) {
        ServiceRefresh();
      }
      // setinvoicesetval(invoname);
      showfullpop(true);
      setClicked(false);
    }
  };

  const saveinvoid = (id) => {
    if (invStat === "Paid") {
      return;
    }

    // if (!FullScreen) {
    setinvoname(id);

    var article = { inid: id };
    var total_invoice_id_encryptedData = Encrypt_Value(article, "vozo");

    axiosInstance
      .put("/vozo/totalinvoiceid?eid=" + eid, total_invoice_id_encryptedData)
      .then((response) => {
        var temp_role_total_invoice = Decrypt_Value(response.data, "vozo");

        setinvototal(temp_role_total_invoice[0]);
        setinvobalance(temp_role_total_invoice[1]);

        if (total_cre === false) {
          setShow(true);
        } else if (total_cre < temp_role_total_invoice[1]) {
          let data = {
            invoice_id: id,
            amount: total_cre,
            userid: provider,
          };
          let pat_cre_pay_encryptedData = Encrypt_Value(data, "vozo");
          axiosInstance
            .put("/vozo/pat_cre_pay?eid=" + eid, pat_cre_pay_encryptedData)
            .then((response) => {
              // var temp_role_pat_cre_pay = Decrypt_Value(response.data, "vozo");
              axiosInstance
                .put(
                  "/vozo/totalinvoiceid?eid=" + eid,
                  total_invoice_id_encryptedData
                )
                .then((response) => {
                  var temp_role_total_invoice_2 = Decrypt_Value(
                    response.data,
                    "vozo"
                  );
                  setinvototal(temp_role_total_invoice_2[0]);
                  setinvobalance(temp_role_total_invoice_2[1]);
                  setShow(true);
                  if (!showfull) {
                    setreload_inv(!reload_inv);
                  }
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        } else if (total_cre >= temp_role_total_invoice[1]) {
          if (temp_role_total_invoice[1] !== "0.00") {
            let data = {
              invoice_id: id,
              amount: temp_role_total_invoice[1],
              userid: provider,
            };

            let pat_cre_pay_encryptedData = Encrypt_Value(data, "vozo");

            axiosInstance
              .put("/vozo/pat_cre_pay?eid=" + eid, pat_cre_pay_encryptedData)
              .then((response) => {
                // var temp_role_pat_cre_pay = Decrypt_Value(response.data, "vozo");
                setModalAlerShow(true);
                setAlertMsg("Credit  amount paid successfully");
                showfullpop(false);
                setreload_inv(!reload_inv);
                setTimeout(modalhide, 1500);
              })
              .catch((err) => console.log(err));
          } else {
            setShow(true);
          }
        }
      })
      .catch((err) => console.log(err));
    // }
  };

  const showstatus = () => {
    axiosInstance
      .get("/vozo/showinvoicename?eid=" + eid + "&type=0")
      .then((response) => {
        var temp_role_show_invoicename = Decrypt_Value(response.data, "vozo");
        // console.log(temp_role_show_invoicename, 'temp_role_show_invoicename')
        setInvStatus(
          temp_role_show_invoicename?.length > 0
            ? temp_role_show_invoicename.length
            : 0
        );
        setshowinvoname(temp_role_show_invoicename);
      })
      .catch((error) => setshowinvoname([]));
  };

  const getstat = (status) => {
    return status === "Paid" ? "lightgreen" : "#ff4d4f";
  };

  const modalhide = () => {
    setModalAlerShow(false);
    setModalAlerShow1(false);
  };

  const eidsetval = (geteid, inid, status) => {
    eid = geteid;
    var st = status;
    setInvStat(st);
    setinvoicesetval(inid);
    showfullpop(true);
  };

  const handleSpinneForNewApp = () => {
    console.log("Don't remove this function");
  };

  const cashpaymentDatas = async () => {
    await axiosInstance
      .get("/vozo/showtotbill_Patname?eid=" + eid)
      .then((response) => {
        var data = Decrypt_Value(response.data, "vozo");
        setShowtotalbill(data);
        if (data[2] !== null) setpat_name(data[2].pat_name);
      })
      .catch((err) => console.log(err));

    // await axiosInstance
    //   .get("/vozo/showtotalbill?eid=" + eid) //this one
    //   .then((response) => {
    //     var temp_role_show_bill = Decrypt_Value(response.data, "vozo");
    //     console.log(temp_role_show_bill, "data");
    //     setShowtotalbill(temp_role_show_bill);
    //   })
    //   .catch((err) => console.log(err));

    await axiosInstance
      .get("/vozo/showinvoicename?eid=" + eid + "&type=0")
      .then((response) => {
        var temp_role_show_invoicename = Decrypt_Value(response.data, "vozo");
        if (temp_role_show_invoicename.length > 0) {
          payment_profile_disable(true);
        } else {
          payment_profile_disable(false);
        }

        setshowinvoname(temp_role_show_invoicename);
      })
      .catch(() => setshowinvoname([]));

    //   await axiosInstance
    //     .get("/vozo/patientname?eid=" + eid) // this one
    //     .then((response) => {
    //       var temp_role_patient_name = Decrypt_Value(response.data, "vozo");
    //       console.log(temp_role_patient_name);
    //       setpat_name(temp_role_patient_name.pat_name);
    //     })
    //     .catch((err) => console.log(err));
  };

  const payDatas = async () => {
    var insurancearticle = {
      billSt: row.Billstatus,
      PaySt: row.PaymentStatus,
    };
    var update_billing_hdr_status_encryptedData = Encrypt_Value(
      insurancearticle,
      "vozo"
    );
    await axiosInstance
      .put(
        "/vozo/updatebillinghdrstatus?num=1&eid=" + eid,
        update_billing_hdr_status_encryptedData
      )
      .then((response) => {
        payment_profile_disable(true);

        showstatus();

        axiosInstance
          .get("/vozo/pat_total?eid=" + eid)
          .then((response) => {
            var temp_role_patient_total = Decrypt_Value(response.data, "vozo");
            settotal_bal(temp_role_patient_total.total_balance);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        payment_profile_disable(true);
        console.log(err);
      });
  };
  // ------------------------------ Functions End--------------------------------------------------- //

  // ------------------------------ useEffect Start--------------------------------------------------- //

  useEffect(() => {
    setFeeLoader1(true);
    cashpaymentDatas();
    setTimeout(
      () =>
        axiosInstance
          .get("/vozo/pat_total?eid=" + eid)
          .then((response) => {
            var temp_role_patient_total = Decrypt_Value(response.data, "vozo");
            settotal_bal(temp_role_patient_total.total_balance);
            settotal_cre(temp_role_patient_total.credit);
          })
          .catch((err) => console.log(err)),
      1500
    );
    setTimeout(() => setFeeLoader1(false), 1000);
  }, [reload_inv, rerender, renderTab]);

  // ------------------------------ useEffect End--------------------------------------------------- //

  return (
    <div id="BillingStepOne" className="mb-neg-10px">
      <hr className="bill-new2"></hr>
      {feeLoad1 ? (
        <SpinnerDiv height="120px">
          <Spinner animation="border" variant="primary" />
        </SpinnerDiv>
      ) : (
        <>
          <>
            <>
              <div className="appoint-font">Appointment Total</div>
              <div className="Un-invoiced mgtb-app-mod">
                UnInvoiced Amount{" "}
                {Showtotalbill[1] === null || Showtotalbill[1] === undefined
                  ? `${currency} 0.00`
                  : `${currency} ${Showtotalbill[1]}`}
                {Showtotalbill[1] === null || Showtotalbill[1] === undefined ? (
                  ""
                ) : (
                  <BillingBlock
                    onClick={() => saveinvoice(eid)}
                    className={`bill-crt-inv-btn ${
                      FullScreen &&
                      (encounter === "0" || encounter === 0) &&
                      "disabled"
                    }`}
                    id="appt_billing_invoice"
                  >
                    Create Invoice
                  </BillingBlock>
                )}
              </div>
              <div className="pat-balance">
                Patient&nbsp;Balance&nbsp;
                {`${currency} ${total_bal === null ? 0 : total_bal}`}
              </div>
              {total_cre ? (
                <div className="pat-balance">
                  Patient&nbsp;Credit&nbsp;{`${currency} ${total_cre}`}
                </div>
              ) : (
                <></>
              )}
            </>
            <div className="container_billstep">
              {showinvoname.map((row, index) => (
                <div
                  key={`bill${index}`}
                  className="grey-color m-t-4 item_billstep"
                >
                  <span
                    className="blue-color-inv curs"
                    key={`${row.invoice_id}invoice`}
                    onClick={() =>
                      eidsetval(row.encounter, row.invoice_id, row.status)
                    }
                  >
                    {row.invoice_id}
                  </span>{" "}
                  <span
                    className="pay-status-appt"
                    // key={`${index}sts`}
                    style={{
                      background: getstat(row.status),

                      color: "white",
                    }}
                  >
                    {row.status}
                  </span>
                  {storedNames.coding ? (
                    <>
                      {row.status === "Paid" ? null : (
                        <BillingBlock
                          // key={`${index}paid`}
                          className={`m-l-5p bill-add-pay-btn ${
                            (encounter === "0" || encounter === 0) && "disabled"
                          }`}
                          onClick={() => saveinvoid(row.invoice_id)}
                        >
                          Add Payment
                        </BillingBlock>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </div>
          </>

          <Modal
            className="client-popup pat-pay-tab w-100 topcreate_popup_centre"
            centered
            show={show}
            onHide={handleClose}
          >
            <AddPayment
              handleClose={handleClose}
              settotal_balance={settotal_balance}
              invoname={invoname}
              invototal={invototal}
              invobalance={invobalance}
              pid={pid}
              eid={eid}
              reload_inv={reload_inv}
              setModalAlerShow={setModalAlerShow}
              setAlertMsg={setAlertMsg}
              setModalAlerShow1={setModalAlerShow1}
              setAlertMsg1={setAlertMsg1}
              showfullpop={showfullpop}
              setreload_inv={setreload_inv}
              showstatus={showstatus}
              modalhide={modalhide}
              payInvAppt={false}
              checkamount_pay={handleSpinneForNewApp}
            />
          </Modal>

          <Modal
            show={showfull}
            id="fullscreen-popup"
            className="modal-bg modal_top_0 invoice-full bil_invoice"
            onHide={invshowfullpop}
          >
            <Modal.Header
              closeButton
              className="modal-header1 Invoice_Modal_Header"
            >
              <div className="invoice-name ">Invoice for {pat_name}</div>

              {storedNames.coding && (
                <>
                  <BillingBlock
                    className={`btn btn-success float-right mr-3 ml-1  icon-siz1 bill-add-pay-btn prac-sht-add-pay 
                    }`}
                    disabled={invStat === "Paid" && true}
                    onClick={() => saveinvoid(invoicesetval)}
                    id="invoice_addpayment"
                  >
                    Add Payment
                  </BillingBlock>
                </>
              )}

              <div className="btn  float-right mr-2 ml-1 icon-siz ">
                <BiTrashAlt
                  onClick={() => deleteinv(invoicesetval)}
                  className="h-icon"
                />
              </div>

              <div className="hr-line float-right mr-4 ml-1"></div>
              <div className="btn  float-right mr-3 ml-1 icon-siz ">
                <BsDownload onClick={downloadpdf} className="h-icon" />
              </div>
              <div className="btn  float-right mr-3 ml-1 icon-siz ">
                <AiOutlinePrinter onClick={printpage} className="h-icon" />
              </div>
            </Modal.Header>

            <Modal.Body className="invoice-comp w-800">
              <Example
                ref={fref}
                invs={invStat}
                eid={eid}
                invoiceid={invoicesetval}
                copay="0"
              />
            </Modal.Body>
          </Modal>

          <Modal className="alert-popup-message" show={ModalAlerShow}>
            <div className="alert msg alert-success" role="alert">
              <BsCheckCircle className="icons-bs" /> {AlertsMsg}
            </div>
          </Modal>
          <Modal
            className="alert-popup-message eramsg"
            show={ModalAlerShow1}
            onHide={() => setModalAlerShow1(false)}
          >
            <div role="alert" className="alert-danger erapad">
              <BsExclamationOctagon
                className="icons-bs"
                style={{ marginBottom: 4, marginRight: 5 }}
              />
              <span style={{ fontSize: 20 }}> {AlertsMsg1}</span>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};
export default memo(BillingStepOne);
