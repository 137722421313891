import { Decrypt_Value } from "../MiddleWare/EncryptDecrypt";

let DBHost =
  localStorage.getItem("DBHost") &&
  Decrypt_Value(localStorage.getItem("DBHost"), "vozo");
let subdomain = window.location.hostname.split(".")[0];
const fileurl =
  subdomain !== "localhost"
    ? process.env.REACT_APP_BACKEND_FILE_ACCESS.replace("oemr", subdomain)
    : process.env.REACT_APP_BACKEND_FILE_ACCESS;
const defaultSubdomains = ["localhost", "mumbai", "production"];
DBHost = defaultSubdomains.includes(subdomain) ? "default" : subdomain;

let AccessMailUrl;
if (window.location.href.includes("localhost")) {
  AccessMailUrl = "http://localhost:8000/InstantSignIn/Old";
} else {
  let firstDot = window.location.hostname.split(".");
  AccessMailUrl = `https://${firstDot[0]}.clientx.me/InstantSignIn/Old`;
}
const apiURL =
  subdomain !== "localhost"
    ? process.env.REACT_APP_BACKEND_URL.replace("oemr", subdomain)
    : process.env.REACT_APP_BACKEND_URL;
const Checkurl =
  subdomain !== "localhost"
    ? process.env.REACT_APP_BACKEND_URL.replace("oemr", subdomain)
    : process.env.REACT_APP_BACKEND_URL;
const ProfileFileURL = `${fileurl}/sites/${DBHost}/documentimg/`;
const EducationFileURL = `${fileurl}/sites/${DBHost}/Education_Files/`;
const InsuranceFileURL = `${fileurl}/sites/${DBHost}/insimage/`;
const LabFileURL = `${fileurl}/sites/${DBHost}/LabDocuments/`;
const MessageImagesURL = `${fileurl}/sites/${DBHost}/MessageImages/`;
const AudioURL = `${fileurl}/sites/${DBHost}/Voice_recordings/`;
const IncomingURL = `${fileurl}/sites/${DBHost}/Faxdocuments/Incoming/`;

const OutgoingURL = `${fileurl}/sites/${DBHost}/Faxdocuments/Outgoing/`;

const ServDocURL = `${fileurl}/src/Services/documentimg/`;

export {
  fileurl,
  ProfileFileURL,
  EducationFileURL,
  InsuranceFileURL,
  LabFileURL,
  ServDocURL,
  MessageImagesURL,
  AudioURL,
  IncomingURL,
  OutgoingURL,
  DBHost,
  apiURL,
  AccessMailUrl,
  Checkurl,
};
