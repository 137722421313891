import React, { useState } from "react";
import {
  At,
  Attach,
  Avatar,
  AvatarLetter,
  Button,
  ChatBox,
  ChatBoxMsg,
  Close,
  Data,
  Div,
  FileInput,
  Image,
  Row,
  SearchInputText,
  Send,
  Text,
  DowloadBtn,
  UserContent2,
} from "./styles";
import moment from "moment";
import {
  Encrypt_Value,
  Decrypt_Value,
} from "../../../MiddleWare/EncryptDecrypt";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  SendMsgData,
  getMessagerList,
  getPreviewDownload,
  setPreviewDownload,
} from "../../../StateManagement/Reducers/MessageState";
import DocType from "../../../assets/images/docType.png";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { Modal, ModalDiv } from "../../../StyledComponents";
import { BsInfoCircle } from "react-icons/bs";
import { AvatarImage } from "../StyledComponents";
import { memo } from "react";
import imageCompression from "browser-image-compression";
import { globalColor, globalFont } from "../../utils/RepeatFunctions/Schedule";
import { ProfileFileURL, MessageImagesURL } from "../../../axios/url.js";

const ChatIndex = (props) => {
  // ----------------------------State and Variables ---------------------------------------------------///

  const hiddenFileInput = React.useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [viewType, setViewType] = useState("");
  const [ImgUrl, setImgUrl] = useState("");
  // const [value, setValue] = useState("");
  const [fileName, setFileName] = useState(null);
  const [preview, setPreview] = useState();
  const dispatch = useDispatch();
  const messageEl = useRef(null);
  const inputEl = useRef(null);
  const textInputRef = useRef(null);
  const [showAlert, setShowAlert] = useState(false);
  const [drop, setDrop] = useState(false);
  const [usersDrop, SetUsersDrop] = useState(false);
  const [typedUser, setTypedUser] = useState("");
  const [selectedUsernames, setSelectedUsernames] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [text, setText] = useState("");

  // const imageUrl =
  //   process.env.REACT_APP_BACKEND_FILE_ACCESS + "/sites/default/MessageImages/";
  // const Test_URL =
  //   process.env.REACT_APP_BACKEND_FILE_ACCESS + "/sites/default/documentimg/";

  const data = useSelector((state) => state.Message.MessageList);
  const PreviewDownload = useSelector((state) => state.Message.previewdownload);
  const dtFormat = localStorage.getItem("dateFormat");

  // ----------------------------State and Variables ---------------------------------------------------///

  // -------------------------- Functions Start-------------------------------------------------------------//

  const filtInputAttach = () => {
    hiddenFileInput.current.value = "";
    hiddenFileInput.current.click();
  };

  const valSetfunc = (e) => {
    const newValue = e.target.value;
    props.setValue(newValue);

    if (props.session === "General") {
      const cursorPosition = e.target.selectionStart;

      let atIndex = -1;
      for (let i = cursorPosition - 1; i >= 0; i--) {
        if (newValue[i] === "@") {
          atIndex = i;
          break;
        }
      }

      if (atIndex !== -1) {
        const typedUser = newValue.slice(atIndex + 1, cursorPosition);

        const filteredList = props.list.filter((data) =>
          data.ProviderName.toLowerCase().includes(typedUser.toLowerCase())
        );

        if (filteredList.length > 0) {
          SetUsersDrop(true);
          setTypedUser(typedUser);
        } else {
          SetUsersDrop(false);
          setTypedUser("");
        }
      } else {
        SetUsersDrop(false);
        setTypedUser("");
      }
    }
  };

  const imgPreFls = () => props.setImgPre(false);
  const handlePreview = (item) => {
    props.setImgPre(true);
    setImgUrl(item);
    let getName = item.split("/");
    setFileName(getName[getName.length - 1]);
  };
  const handleDownload = () => {
    dispatch(getPreviewDownload({ file: ImgUrl }));
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDrop(true);
    } else if (e.type === "dragleave") {
      setDrop(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      const ext = file.type.split("/")[1];

      switch (ext) {
        case "jpg":
        case "jpeg":
        case "bmp":
        case "png":
        case "pdf":
          props.setSelectedFile(file);
          setDrop(false);
          break;
        default:
          props.setSelectedFile();
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 1500);
          setDrop(false);
      }
    }
  };

  const handleChange = (event) => {
    const ext = event.target.files[0].type.split("/")[1];
    switch (ext) {
      case "jpg":
      case "jpeg":
      case "bmp":
      case "png":
      case "pdf":
        props.setSelectedFile(event.target.files[0]);
        break;
      default:
        props.setSelectedFile();
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 1500);
    }
  };

  const handleOnSend = async () => {
    const filedata = new FormData();
    let article = {
      message: props.value,
      ipAddress: "127.0.0.1",
      sender_id: props.userId,
      recip_id: props.ProviderName,
      receive_id: props.receive_id,
      status: 1,
      message_type: "text",
      common_msg: props.common_msg,
      receive_id2: props.receive_id2,
    };

    let enc = Encrypt_Value(article, "vozo");
    filedata.append("message", enc);

    if (props.selectedFile) {
      if (props.selectedFile.type.startsWith("image/")) {
        let compressedFile = props.selectedFile;
        const options = {
          maxSizeMB: 1, // Max file size in MB
          maxWidthOrHeight: 1600, // Max width or height in pixels
          useWebWorker: true, // Use web worker for faster compression
        };
        try {
          compressedFile = await imageCompression(props.selectedFile, options);
        } catch (error) {
          console.error("Error compressing image:", error);
        }

        const objectUrl = URL.createObjectURL(compressedFile);
        filedata.append("fileupload", compressedFile, compressedFile.name);
      } else {
        filedata.append("fileupload", props.selectedFile);
      }
    } else if (props.value.trim() === "") {
      return false;
    }

    dispatch(SendMsgData(filedata));
    props.setValue("");
    props.setSelectedFile(null);
  };

  const selFileNull = () => props.setSelectedFile(null);

  const handleUserClick = (selectedUser) => {
    const currentValue = props.value;

    const cursorPosition = textInputRef.current.selectionStart;

    let atIndex = -1;
    for (let i = cursorPosition - 1; i >= 0; i--) {
      if (currentValue[i] === "@") {
        atIndex = i;
        break;
      }
    }

    if (atIndex !== -1) {
      const beforeCursor = currentValue.slice(0, atIndex);
      const afterCursor = currentValue.slice(cursorPosition);

      const newValue = beforeCursor + "@" + selectedUser + afterCursor;

      props.setValue(newValue);

      SetUsersDrop(false);

      const usernames = [...selectedUsernames, selectedUser];
      setSelectedUsernames(usernames);
    }
  };

  // const filteredUsers = props.list.filter((item) =>
  //   item.ProviderName.toLowerCase().includes(typedUser.toLowerCase())
  // );

  // const handleInput = (e) => {
  //   const newValue = e.target.textContent;
  //   console.log(newValue, "new value");

  //   if (props.session === "General") {
  //     const cursorPosition = window.getSelection().focusOffset;
  //     console.log(cursorPosition, "cursor");

  //     let atIndex = -1;
  //     for (let i = cursorPosition - 1; i >= 0; i--) {
  //       if (newValue[i] === "@") {
  //         atIndex = i;
  //         break;
  //       }
  //     }

  //     console.log(atIndex, "at index");

  //     if (atIndex !== -1) {
  //       const typedUser = newValue.slice(atIndex + 1, cursorPosition);

  //       const filteredList = props.list.filter((data) =>
  //         data.ProviderName.toLowerCase().includes(typedUser.toLowerCase())
  //       );

  //       if (filteredList.length > 0) {
  //         SetUsersDrop(true);
  //         setTypedUser(typedUser);
  //       } else {
  //         SetUsersDrop(false);
  //         setTypedUser("");
  //       }
  //     } else {
  //       SetUsersDrop(false);
  //       setTypedUser("");
  //     }
  //   }
  // };

  // const handleUserClick = (selectedUser) => {
  //   const contentDiv = textInputRef.current;
  //   const cursorPosition = window.getSelection().focusOffset;

  //   let atIndex = -1;
  //   for (let i = cursorPosition - 1; i >= 0; i--) {
  //     if (contentDiv.textContent[i] === "@") {
  //       atIndex = i;
  //       break;
  //     }
  //   }

  //   if (atIndex !== -1) {
  //     const beforeCursor = contentDiv.textContent.slice(0, atIndex);
  //     const afterCursor = contentDiv.textContent.slice(cursorPosition);
  //     const newValue =
  //       beforeCursor +
  //       `<span style="color: blue">@${selectedUser} </span>` +
  //       afterCursor;
  //     console.log(beforeCursor, "before");
  //     console.log(afterCursor, "after");
  //     console.log(newValue, "new");
  //     // Set the innerHTML of the content-div with the newValue
  //     contentDiv.innerHTML = newValue;

  //     SetUsersDrop(false);

  //     const usernames = [...selectedUsernames, selectedUser];
  //     setSelectedUsernames(usernames);

  //     // To keep the "@" symbol and username selection independent, clear the typedUser
  //     setTypedUser("");

  //     // Move the cursor to the end of the content-div
  //     const range = document.createRange();
  //     range.selectNodeContents(contentDiv);
  //     range.collapse(false); // Collapse the range to the end
  //     const selection = window.getSelection();
  //     selection.removeAllRanges();
  //     selection.addRange(range);
  //   }
  // };

  // const handleKeyDown = (e) => {
  //   if (e.key === "Enter") {
  //     // Handle Enter key, e.g., send message
  //     e.preventDefault();
  //     // Do something with the text
  //     console.log("Sending message:", text);
  //     setText("");
  //   }
  // };
  // -------------------------- Functions End-------------------------------------------------------------//

  // -------------------------- useEffect Start-------------------------------------------------------------//

  useEffect(() => {
    if (PreviewDownload !== "") {
      var tag = document.createElement("a");
      tag.href = PreviewDownload.file;
      tag.download = PreviewDownload.filename;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
      dispatch(setPreviewDownload(""));
    }
  }, [PreviewDownload]);

  useEffect(() => {
    if (!props.loading && messageEl.current) {
      messageEl.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.loading, data]);

  useEffect(() => {
    if (!props.selectedFile) {
      setPreview(undefined);
      return;
    }
    setFileName(props.selectedFile.name);
    var checkType = props.selectedFile.type.split("/");

    if (checkType[0] === "image") {
      setViewType("image");
    } else {
      setViewType("doc");
    }

    const objectUrl = URL.createObjectURL(props.selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [props.selectedFile]);

  useEffect(() => {
    if (props.receive_id !== "") {
      const credential = { receiveId: props.receive_id2, userId: props.userId };
      dispatch(getMessagerList(credential));
    }
  }, [props.receive_id2]);

  useEffect(() => {
    const filteredList = props.list.filter((data) =>
      data.ProviderName.toLowerCase().includes(typedUser.toLowerCase())
    );

    if (filteredList.length === 0) {
      SetUsersDrop(false);
    }

    setFilteredUsers(filteredList);
  }, [typedUser]);

  // --------------------------useEffect End-------------------------------------------------------------//

  return (
    <>
      <Data
        height="390px"
        width="100%"
        padding="16px 29px 16px 26px"
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        drop={drop}
      >
        <FileInput
          ref={inputEl}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
        {!props.loading ? (
          data.length !== 0 ? (
            data.map((item, ind) => {
              return (
                <ChatBox
                  user={item.sender_id === props.userId ? true : false}
                  type={item.type}
                  key={ind}
                  marginLeft={
                    (item.type === "image" || item.type === "doc") &&
                    item.sender_id === props.userId
                      ? "auto"
                      : ""
                  }
                >
                  {item.type !== "date" && item.sender_id !== props.userId && (
                    <>
                      {item.rowImage === null ? (
                        <Avatar width="32px" height="32px">
                          <AvatarLetter top="7px" left="9px">
                            {item.rowName.charAt(0).toUpperCase()}
                          </AvatarLetter>
                        </Avatar>
                      ) : (
                        <Image
                          src={ProfileFileURL + item.rowImage}
                          alt="image-failed"
                          loading="lazy"
                          width="32px"
                          height="32px"
                          mr="8px"
                          borderRadius="40px"
                        />
                      )}
                    </>
                  )}
                  <ChatBoxMsg
                    user={item.sender_id === props.userId ? true : false}
                    type={item.type}
                    padding=" 8px 10px 7px 10px"
                    day={
                      item.type === "date" &&
                      moment(item.date).calendar(null, {
                        sameDay: "[Today]",
                        nextDay: "[Tomorrow]",
                        nextWeek: "dddd",
                        lastDay: "[Yesterday]",
                        lastWeek: dtFormat,
                        sameElse: dtFormat,
                      })
                    }
                  >
                    {item.type === "date" && (
                      <Row>
                        <Text
                          font-family={globalFont}
                          font-style=" normal"
                          font-weight=" 600"
                          font-size="14px"
                          line-height="22px"
                        >
                          {moment(item.date).calendar(null, {
                            sameDay: "[Today]",
                            nextDay: "[Tomorrow]",
                            nextWeek: "dddd",
                            lastDay: "[Yesterday]",
                            lastWeek: dtFormat,
                            sameElse: dtFormat,
                          })}
                        </Text>
                      </Row>
                    )}

                    {item.type === "text" && (
                      <>
                        <Text
                          width="100%"
                          fontFamily={globalFont}
                          fontWeight="500"
                          fontSize="14px"
                          lineHeight="19px"
                          textAlign="left"
                          color={
                            item.sender_id === props.userId
                              ? "#EDEDED"
                              : "#2D3748"
                          }
                          display="inline"
                          wordBreak="break-word"
                        >
                          {item.message}
                        </Text>
                        <Text
                          width="unset"
                          margin="auto 0 0 10px"
                          fontWeight="400"
                          fontSize="12px"
                          lineHeight="12px"
                          color={
                            item.sender_id === props.userId
                              ? "#EDEDED"
                              : "#2D3748"
                          }
                          display="inline"
                          textAlign="right"
                          verticalAlign="sub"
                        >
                          {item.date && moment(item.date).format("h:mm")}
                        </Text>
                      </>
                    )}
                    {item.type === "image" && (
                      <>
                        <Row dis="flex" flexDirection="column" width="100%">
                          <Image
                            cursor="pointer"
                            width="214px"
                            height="129px"
                            objectFit="contain"
                            display="block"
                            src={
                              item.imageUrl && MessageImagesURL + item.imageUrl
                            }
                            alt=" "
                            onClick={() => {
                              handlePreview(MessageImagesURL + item.imageUrl);
                              setViewType("image");
                            }}
                          />

                          <Text
                            fontFamily={globalFont}
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="17px"
                            textAlign="left"
                            // paddingRight="40px"
                            color={
                              item.sender_id === props.userId
                                ? "#EDEDED"
                                : "#2D3748"
                            }
                            display="inline"
                            width="100%"
                            wordBreak="break-word"
                          >
                            {item.message}
                          </Text>
                        </Row>
                        <Text
                          width="unset"
                          margin="auto 0 0 10px"
                          fontWeight="400"
                          fontSize="12px"
                          lineHeight="12px"
                          color={
                            item.sender_id === props.userId
                              ? "#EDEDED"
                              : "#2D3748"
                          }
                          display="inline"
                          textAlign="right"
                          verticalAlign="sub"
                        >
                          {item.date && moment(item.date).format("h:mm ")}
                        </Text>
                      </>
                    )}
                    {item.type === "doc" && (
                      <>
                        <Row dis="flex" flexDirection="column">
                          <Image
                            cursor="pointer"
                            MaxWidth="221px"
                            MaxHeight="137px"
                            objectFit="contain"
                            display="block"
                            src={DocType}
                            alt=" "
                            onClick={() => {
                              handlePreview(MessageImagesURL + item.imageUrl);
                              setViewType("doc");
                            }}
                          />
                          <Text
                            fontFamily={globalFont}
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="17px"
                            color={
                              item.sender_id === props.userId
                                ? "#EDEDED"
                                : "#2D3748"
                            }
                            display="inline"
                            width="100%"
                            textAlign="left"
                            wordBreak="break-word"
                          >
                            {item.message}
                          </Text>
                        </Row>
                        <Text
                          width="unset"
                          fontWeight="400"
                          fontSize="12px"
                          lineHeight="12px"
                          color={
                            item.sender_id === props.userId
                              ? "#EDEDED"
                              : "#2D3748"
                          }
                          display="inline"
                          textAlign="right"
                          verticalAlign="sub"
                          margin="auto 0 0 10px"
                        >
                          {item.date && moment(item.date).format("h:mm ")}
                        </Text>
                      </>
                    )}
                  </ChatBoxMsg>
                  {item.type !== "date" && item.sender_id === props.userId && (
                    <>
                      {item.sender_image === null ||
                      item.sender_image === "" ? (
                        <Avatar width="32px" height="32px" minWidth="32px">
                          <AvatarLetter top="7px" left="9px">
                            {item.sender_name.charAt(0).toUpperCase()}
                          </AvatarLetter>
                        </Avatar>
                      ) : (
                        <Image
                          src={ProfileFileURL + item.sender_image}
                          alt="image-failed"
                          loading="lazy"
                          borderRadius="40px"
                          height="32px"
                          width="32px"
                          mr="8px"
                        />
                      )}
                    </>
                  )}
                </ChatBox>
              );
            })
          ) : (
            <Div
              display="flex"
              justifyContent="center"
              fontWeight="500"
              fontSize="14px"
              lineHeight="17px"
              color="#8A969F"
              backColor="unset"
            >
              No Messages....
            </Div>
          )
        ) : (
          <Div
            display="flex"
            justifyContent="center"
            fontWeight="500"
            fontSize="14px"
            lineHeight="17px"
            color="#8A969F"
            backColor="unset"
          >
            loading Messages....
          </Div>
        )}

        <Row ref={messageEl}></Row>
      </Data>
      {props.selectedFile && (
        <Row
          position="absolute"
          top="57px"
          width="100%"
          height="100%"
          backgroundColor="rgba(244, 244, 244, 0.9)"
          display="flex"
          flexDirection="column"
          padding="10px 20px 20px 20px"
          radius="4px"
          MaxWidth="788px"
          maxHeight="390px"
        >
          <Row dis="flex" justifyContent="space-between" alignItems="center">
            <Text fontSize="13px" lineHeight="18px" color="#91929e">
              {fileName}
            </Text>
            <Close onClick={selFileNull} />
          </Row>
          {viewType === "image" && (
            <Row
              position="relative"
              dis="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%"
              overFlow="hidden"
              padding="8px 0 0 0 "
            >
              <Image
                src={preview}
                width="max-content"
                height="max-content"
                MaxHeight="100%"
                MaxWidth="100%"
                margin="auto"
                objectFit="contain"
              />
            </Row>
          )}
          {viewType === "doc" && (
            <Row
              position="relative"
              dis="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%"
              overFlow="hidden"
              padding="8px 0 0 0 "
            >
              <Image
                src={DocType}
                width="max-content"
                height="max-content"
                MaxHeight="350px"
                MaxWidth="350px"
                margin="auto"
              />
            </Row>
          )}
        </Row>
      )}
      {props.imgPre && (
        <Row
          position="absolute"
          top="57px"
          width="100%"
          height="100%"
          backgroundColor="rgba(244, 244, 244, 0.9)"
          display="flex"
          flexDirection="column"
          padding="10px 20px 20px 20px"
          borderRadius="4px"
          overFlow="hidden"
          maxWidth="788px"
          maxHeight="463px"
        >
          <Row dis="flex" justifyContent="space-between" alignItems="center">
            <Row dis="flex">
              <Text fontSize="13px" lineHeight="18px" color="#91929e">
                {fileName}
              </Text>
              <DowloadBtn onClick={handleDownload} />
            </Row>
            <Row dis="flex">
              <Close onClick={imgPreFls} />{" "}
            </Row>
          </Row>
          {viewType === "image" && (
            <Row
              position="relative"
              dis="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%"
              overFlow="hidden"
              padding="8px 0 0 0 "
            >
              <Image
                src={ImgUrl}
                objectFit="contain"
                width="max-content"
                height="max-content"
                MaxHeight="100%"
                MaxWidth="100%"
              />
            </Row>
          )}
          {viewType === "doc" && (
            <iframe
              className="practice"
              title="img"
              src={ImgUrl}
              width="100%"
              height="97%"
              frameBorder="0"
              style={{ display: "block" }}
            />
          )}
        </Row>
      )}

      {!props.imgPre && (
        <Div
          backgroundColor={
            usersDrop && props.session === "General" ? "#F1F5F8" : "#ffff"
          }
          height="72px"
        >
          <Row
            dis="flex"
            position="relative"
            margin="0px 16px 16px 16px"
            backgroundColor="#ffffff"
            border="1px solid #EAEAEA"
            boxShadow="0px 1px 2px rgba(184, 200, 224, 0.222055)"
            radius="16px"
            marginLeft="20px"
            display="flex"
            maxHeight="fit-content"
            padding="8px 12px 8px 17px"
            height="56px"
          >
            <SearchInputText
              type="file"
              accept=".pdf,.png,.jpg,.bmp,.jpeg"
              ref={hiddenFileInput}
              onChange={(e) => handleChange(e)}
              display="none"
            />
            <Attach color="#2E2E2E" onClick={filtInputAttach} />
            <At
              onClick={() => {
                const newValue = props.value + "@";
                props.setValue(newValue);
                if (props.session === "General") {
                  setTypedUser("");
                  SetUsersDrop(true);

                  const filteredList = props.list;
                  if (filteredList.length === 0) {
                    SetUsersDrop(false);
                  }
                }
              }}
            />

            <SearchInputText
              placeholder="Type your message here…"
              ref={textInputRef}
              value={props.value}
              paddingBottom="2px"
              width="100%"
              onChange={(e) => valSetfunc(e)}
              onKeyPress={(e) => e.key === "Enter" && handleOnSend()}
            />

            {/* <p
              contentEditable
              ref={textInputRef}
              style={{
                minHeight: "50px",
                padding: "13px 8px 8px",
                outline: "none",
                width: "100%",
                textAlign: "left",
                marginBottom: "0px",
              }}
              onInput={handleInput}
              onKeyDown={handleKeyDown}
              dangerouslySetInnerHTML={{ __html: text }}
            ></p> */}

            <Button
              width="48px"
              height="36px"
              borderRadius="12px"
              backgroundColor={globalColor}
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="none"
              cursor="pointer"
              onClick={handleOnSend}
            >
              <Send />
            </Button>
          </Row>
        </Div>
      )}

      {/* --------------- user list dropdown ---------------- */}
      {usersDrop && props.session === "General" && (
        <Div
          dis="block"
          position="absolute"
          backgroundColor="#F1F5F8"
          display="flex"
          maxheight="123px"
          top={
            filteredUsers.length === 3
              ? "327px"
              : filteredUsers.length === 2
              ? "367px"
              : filteredUsers.length === 1
              ? "407px "
              : "325px"
          }
          width="100%"
          overflow="auto"
        >
          {filteredUsers.length > 0 ? (
            filteredUsers.map((item, index) => (
              <UserContent2
                onClick={() => handleUserClick(item.ProviderName)}
                key={item.id}
              >
                {item.img_url === null ? (
                  <Avatar width="24px" height="24px" marginTop="-5px">
                    <AvatarLetter fontSize="14px">
                      {item.ProviderName.charAt(0).toUpperCase()}
                    </AvatarLetter>
                  </Avatar>
                ) : (
                  <AvatarImage
                    src={ProfileFileURL + item.img_url}
                    alt="image-failed"
                    loading="lazy"
                    width="24px"
                    height="24px"
                    objectFit="cover"
                  />
                )}

                <Div width="60%" marginTop="-2px" background="none">
                  <Text
                    color="#000"
                    mb="4px"
                    fontSize="14px"
                    fontWeight="400"
                    textAlign="left"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    fontFamily={globalFont}
                  >
                    {item.ProviderName}
                  </Text>
                </Div>
              </UserContent2>
            ))
          ) : (
            <Div paddingTop="170px">
              <Text
                fontWeight="400"
                fontSize="16px"
                lineHeight="16px"
                color="#000"
              >
                No Providers to Chat
              </Text>
            </Div>
          )}
        </Div>
      )}

      <Modal show={showAlert} className="alert-popup-message">
        <ModalDiv className="alert msg alert-danger" role="alert">
          <BsInfoCircle className="icons-bs" />
          File Not allowed
        </ModalDiv>
      </Modal>
    </>
  );
};
export default memo(ChatIndex);
