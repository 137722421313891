import axios from "axios";
import { apiURL,  DBHost } from "./url.js";

// localStorage.setItem("DBHoast", Encrypt_Value("default", "vozo"));

var headers = {};

if (localStorage.token) {
 

  headers.Authorization = `${localStorage.token}`;

  var baseURL = apiURL + DBHost + "/api";
} else {
 

  baseURL = apiURL + DBHost;
  headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  };
}

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers,
});
axiosInstance.interceptors.request.use(
  (config) => {
    if (localStorage.token) {
      config.headers.Authorization = localStorage.token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axiosInstance;
