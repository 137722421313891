import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

const invoiceGeneratePdf = (
  data,
  document,
  patprov,
  base64,

  symbol,
  status,
  copay
) => {
  const doc = new jsPDF();
  const dtFormat = localStorage.getItem("dateFormat");
  let i = 0;

  var prov_st;
  if (
    patprov[1].billingaddress === null ||
    patprov[1].billingaddress === undefined
  ) {
    prov_st = "";
  } else {
    prov_st = patprov[1].billingaddress;
  }
  var prov_cty;
  if (
    patprov[1].billingstate === null ||
    patprov[1].billingstate === undefined
  ) {
    prov_cty = "";
  } else {
    prov_cty = patprov[1].billingstate;
  }
  var prov_country;
  if (
    patprov[1].billingcountry === null ||
    patprov[1].billingcountry === undefined
  ) {
    prov_country = "";
  } else {
    prov_country = patprov[1].billingcountry;
  }
  var prov_zip;
  if (patprov[1].zip === null || patprov[1].zip === undefined) {
    prov_zip = "";
  } else {
    prov_zip = patprov[1].zip;
  }
  var pat_st;
  if (
    patprov[0].street === null ||
    patprov[0].street === undefined ||
    patprov[0].street === ""
  ) {
    pat_st = "";
  } else {
    pat_st = patprov[0].street;
  }
  var pat_cty;
  if (
    patprov[0].city === null ||
    patprov[0].city === undefined ||
    patprov[0].city === ""
  ) {
    pat_cty = "";
  } else {
    pat_cty = patprov[0].city;
  }
  var pat_state;
  if (
    patprov[0].state === null ||
    patprov[0].state === undefined ||
    patprov[0].state === ""
  ) {
    pat_state = "";
  } else {
    pat_state = patprov[0].state + " - ";
  }

  var pat_zip;

  if (
    patprov[0].postal_code === null ||
    patprov[0].postal_code === undefined ||
    patprov[0].postal_code === ""
  ) {
    pat_zip = "";
  } else {
    pat_zip = patprov[0].postal_code;
  }

  var rows = [];

  const cols =
    copay === "0"
      ? ["Date ", "Description", "Units", "Amount"]
      : ["Description", "Amount"];
  let l = data.length - 1;
  if (copay === "0") {
    for (i = 0; i < data.length - 1; i++) {
      if (data[i]["units"] !== undefined) {
        const formattedDate = moment(data[i]["date"]).format(dtFormat);
        rows.push([
          formattedDate,
          data[i]["code_text"],
          data[i]["units"],
          `${symbol}${data[i]["fee"]}`,
        ]);
      }
    }
  } else {
    rows.push([data[0]["code_text"], `${symbol}${data[0]["fee"]}`]);
  }

  rows.push(
    copay === "0"
      ? ["", "", "Total", `${symbol} ${data[l].totalfee}`]
      : ["Total", `${symbol} ${data[0].fee}`]
  );
  rows.push(["", "", "Make Payments to12 ", `${patprov[1].facilityname}`]);

  var len = rows.length;
  var y;

  y = len - 1;

  doc.setFontSize(13);
  doc.setFillColor(247, 247, 247);
  doc.rect(0, 0, 220, 35, "F");
  if (document[3]["gl_value"] === "1") {

    const validImageBase64 = base64;
  
    if (validImageBase64.startsWith('data:image/')) {
      const mimeType = validImageBase64.split(';')[0].split('/')[1];
  
      if (mimeType === 'jpg' || mimeType === 'jpeg') {
        doc.addImage(validImageBase64, 'JPEG', 8, 8, 24, 21);
      } else if (mimeType === 'png') {
        doc.addImage(validImageBase64, 'PNG', 8, 8, 24, 21);
      } else if (mimeType === 'bmp') {
        doc.addImage(validImageBase64, 'BMP', 8, 8, 24, 21);
      } else if (mimeType === 'jfif') {
        doc.addImage(validImageBase64, 'JPEG', 8, 8, 24, 21);
      } else {
        console.error('Unsupported image format.');
      }
    } else {
      console.error('Error: Unknown image format.');
    }

    // doc.addImage(base64, document[4]["gl_value"], 8, 8, 24, 21);
  }

  if (status === "Paid") {
    doc.setFontSize(15);
    doc.setDrawColor(0, 128, 0);
    doc.roundedRect(60, 8, 30, 18, 2, 2, "FD");
    doc.setTextColor(0, 128, 0);
    doc.text(70, 19, "PAID");
  }
  doc.setDrawColor(0, 0, 0);
  doc.setTextColor(0, 0, 0);
  doc.text(130, 10, "Invoice");
  doc.text(130, 20, "Invoice id");
  doc.text(130, 30, "issue date");
  doc.setLineWidth(0.5);
  doc.line(155, 17, 155, 30);
  doc.text(160, 20, `${data[0].invoice_id}`);
  doc.text(160, 30, `${moment(data[0].date).format(dtFormat)}`);

  doc.setFontSize(13);
  doc.setTextColor(0, 0, 0);
  doc.setFont("helvetica", "normal");
  doc.text(8, 50, "Bill from");
  doc.setTextColor(113, 128, 150);
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  doc.text(8, 57, `${patprov[1].facilityname}`);
  doc.text(8, 65, `${prov_st}`);
  doc.text(8, 73, `${prov_cty}` + `${prov_zip}` + `${prov_country}`);

  doc.setFontSize(13);
  doc.setTextColor(0, 0, 0);
  doc.setFont("helvetica", "normal");
  doc.text(130, 50, "Bill to");
  doc.setTextColor(113, 128, 150);
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  doc.text(130, 57, `${patprov[0].fname}` + "  " + `${patprov[0].lname}`);
  doc.text(130, 65, `${pat_st}`);
  doc.text(130, 73, `${pat_cty}` + `${pat_state}` + `${pat_zip}`);

  doc.setFontSize(13);
  doc.setTextColor(0, 0, 0);
  doc.setFont("helvetica", "normal");
  doc.text(8, 85, "Provider");
  doc.setFontSize(12);
  doc.setTextColor(113, 128, 150);
  doc.setFont("helvetica", "normal");
  doc.text(8, 92, `${patprov[1].fname}` + "  " + `${patprov[1].lname}`);
  doc.text(8, 99, "Tax ID : ");
  if (patprov[1].federaltaxid !== "null") {
    doc.text(25, 99, `${patprov[1].federaltaxid}`);
  }

  doc.text(8, 106, `${patprov[1].email}`);

  doc.setFontSize(13);
  doc.setTextColor(0, 0, 0);
  doc.setFont("helvetica", "normal");
  doc.text(130, 85, "Client");
  doc.setFontSize(12);
  doc.setTextColor(113, 128, 150);
  doc.setFont("helvetica", "normal");
  doc.text(130, 92, `${patprov[0].fname}` + "  " + `${patprov[0].lname}`);
  doc.text(130, 99, `${patprov[0].phone_cell}`);
  doc.text(130, 106, `${patprov[0].email}`);

  doc.setDrawColor(247, 247, 247);
  doc.autoTable(cols, rows, {
    startY: 120,
    theme: "plain",
    margin: { left: 0, right: 0 },
    styles: {
      cellPadding: { top: 4, right: 5, bottom: 4, left: 8 },
      fontSize: 10,
    },

    headStyles: {
      fillColor: (247, 247, 247),
      textColor: (0, 0, 0),
      fontStyle: "normal",
      halign: "left",
      fontSize: 12,
    },
    bodyStyles: {
      fillColor: (255, 255, 255),
      fontStyle: "normal",
      halign: "left",
      fontSize: 12,
    },
    willDrawCell: (data) => {
      if (data.row.section === "body") {
        if (data.row.index !== 0 && data.row.index < y) {
          doc.setDrawColor(216, 224, 240);
          doc.setLineWidth(1);
          doc.line(
            data.cell.x + data.column.width,
            data.cell.y,
            data.cell.x,
            data.cell.y
          );
        }
      }
    },
  });

  if (document[2]["gl_value"] !== "") {
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();

      const footer = `${document[2]["gl_value"]}`;

      // Footer
      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 15,
        { baseline: "bottom" }
      );
    }
  }
  doc.save(
    `invoice of ${patprov[0].fname} + "  " +${patprov[0].lname}_${data[0].invoice_id}.pdf`
  );
};

export default invoiceGeneratePdf;
