import React, { useState, useEffect } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "semantic-ui-css/semantic.min.css";
import App from "./App";
import store from "./StateManagement/store";
import { Provider } from "react-redux";
import axiosInstance from "./axios/axios"; // Your custom axios instance

const root = document.getElementById("root");

const AppContainer = () => {
  const [apiResponse, setApiResponse] = useState(null);
  const token = localStorage.getItem("token");
  useEffect(() => {
    const fetchTransferData = async () => {
      const checkPath = !token ? "/api/vozo/transfer" : "/vozo/transfer";
      try {
        const response = await axiosInstance.get(checkPath);
        setApiResponse(response.data);
        window.apiResponse = 1;
      } catch (error) {
        console.error("API call failed:", error);
        setApiResponse(0);
        window.apiResponse = 0;
      }
    };

    fetchTransferData();
  }, []);

  if (apiResponse === 0) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h1>403 Forbidden</h1>
        <p>You do not have permission to access this application.</p>
      </div>
    );
  }

  // If API response is valid (not 0), render the main app
  return (
    apiResponse === 1 && (
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    )
  );
};

// Rendering the AppContainer instead of App directly
render(<AppContainer />, root);
