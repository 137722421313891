import React, { useState, useEffect, useRef, memo } from "react";
import { useSelector } from "react-redux";

import axiosInstance from "../../axios/axios";
import { Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Example from "../patient/Example";
import { BsDownload, BsExclamationOctagon } from "react-icons/bs";
import { AiOutlinePrinter } from "react-icons/ai";
import { BiTrashAlt } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import { BsCheckCircle } from "react-icons/bs";
import AddPayment from "./AddPayment";
import { SpinnerDiv } from "../../StyledComponents";

var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Decrypt_Value = (data, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(data, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};

const Encrypt_Value = (Val, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(Val), key, {
    format: Encode_Decode_JSON,
  }).toString();
};

const InsuranceStep = ({
  eid,
  proid,
  row,
  rerender,
  renderTab,
  billingstatuserror,
  payment_profile_disable,
  setEmpErr,
  setAutoAccErr,
  setOtherAccErr,
  FullScreen,
}) => {
  const fref = useRef();
  const [invoicesetval, setinvoicesetval] = useState("");
  const [showfull, showfullpop] = useState(false);
  const [total_cre, settotal_cre] = useState(false);
  const [show, setShow] = useState(false);
  const [showtotal, setshowtotal] = useState("");
  const [showinvoname, setshowinvoname] = useState([]);
  const [invoname, setinvoname] = useState("");
  const [copaybal, setcopaybal] = useState("");
  const [insurebal, setinsurebal] = useState("");
  const [patbal, setpatbal] = useState("");
  const [invototal, setinvototal] = useState("");
  const [invStat, setInvStat] = useState("");
  const [invobalance, setinvobalance] = useState("");
  const [patInvName, setPatInvName] = useState("");
  const [reload_inv, setreload_inv] = useState(false);

  const [copay_main, setcopay_main] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [ModalAlerShow1, setModalAlerShow1] = useState(false);
  const [AlertsMsg, setAlertMsg] = useState("");
  const [AlertsMsg1, setAlertMsg1] = useState("");
  const [feeLoad1, setFeeLoader1] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [paidAmt, setPaidAmt] = useState("");
  const [showCopay, setShowCopay] = useState(false);

  const currency = useSelector((state) => state.Report.Currency);

  var provider = Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  var storedNames = Decrypt_Value(localStorage.getItem("setroles"), "vozo");

  useEffect(() => {
    let amountPaid;
    const getpaidAmt = () => {
      axiosInstance
        .get("/vozo/getpaidAmt?eid=" + eid)
        .then((res) => {
          if (res.data.length === 0) {
            amountPaid = 0;
          } else {
            amountPaid = res.data.total_pay_amount;
          }
          setPaidAmt(res.data);

          const calcOverpaid = Number(copay_main) + Number(amountPaid);
          if (Number(insurebal) >= Number(calcOverpaid)) {
            setShowCopay(true);
          } else {
            setShowCopay(false);
          }
        })
        .catch((err) => {
          console.log(err.msg);
        });
    };
    getpaidAmt();
  }, [insurebal, copaybal]);

  // ---------------------------------------- State and Variables End--------------------------------------------------- //

  // ---------------------------------------- Functions Start----------------------------------------------------------- //

  const handleClose = () => setShow(false);

  const checkamount_pay = () => {
    setTimeout(() => {
      chceckamountpay();
    }, 700);
  };

  const getstat = (status) => {
    return status === "Paid" ? "lightgreen" : "#ff4d4f";
  };

  const printpage = () => {
    fref.current.setFromOutside();
  };
  const downloadpdf = () => {
    fref.current.generatePDF();
  };

  const showstatus = () => {
    axiosInstance
      .get("/vozo/showinvoicename?eid=" + eid + "&type=1")
      .then((response) => {
        var temp_role_show_invoicename = Decrypt_Value(response.data, "vozo");
        setshowinvoname(temp_role_show_invoicename);
      })
      .catch(() => setshowinvoname([]));
  };

  const refreshData = () => {
    axiosInstance
      .get("/vozo/checkboxdefault?eid=" + eid)
      .then((response) => {
        let temp_role_check_box_def = Decrypt_Value(response.data, "vozo");
        if (temp_role_check_box_def === "zero") {
          document.getElementById("defaultselect").disabled = true;
          document.getElementById("defaultselect").selectedIndex = "0";
        }
        if (temp_role_check_box_def === "one") {
          document.getElementById("defaultselect").disabled = true;
          document.getElementById("defaultselect").selectedIndex = "1";
        }
      })
      .catch((err) => console.log(err));
  };
  const chceckamountpay = () => {
    axiosInstance
      .get("/vozo/showinsurancebalance?eid=" + eid)
      .then((response) => {
        var temp_role_show_ins_bal = Decrypt_Value(response.data, "vozo");
        setpatbal(temp_role_show_ins_bal[0]);
        setshowtotal(temp_role_show_ins_bal[1]);
        setcopaybal(temp_role_show_ins_bal[2]);
        setinsurebal(temp_role_show_ins_bal[3]);
      })
      .catch((err) => console.log(err));
  };

  const onClick = async () => {
    var error = 0;
    setInvStat("");
    if (row.Billstatus === "" || row.Billstatus === null) {
      billingstatuserror();
      error = 2;
    }

    if (row.Employment === "") {
      setEmpErr(true);
      error = 1;
    }
    if (row.AutoAccdnt === "") {
      setAutoAccErr(true);
      error = 1;
    }
    if (row.OtherAccdnt === "") {
      setOtherAccErr(true);
      error = 1;
    }
    if (error > 0) {
      return false;
    }
    if (!clicked) {
      setClicked(true);
      if (row.PaymentStatus === "2") {
        var insurancearticle = {
          billSt: row.Billstatus,
          PaySt: row.PaymentStatus,
        };

        var update_billing_hdr_status_encryptedData = Encrypt_Value(
          insurancearticle,
          "vozo"
        );
        await axiosInstance
          .put(
            "/vozo/updatebillinghdrstatus?num=2&eid=" + row.eid,
            update_billing_hdr_status_encryptedData
          )
          .then((response) => {})
          .catch((err) => console.log(err));

        if (row.HCF_FormID === "") {
          const article = {
            eid: row.eid,
            pid: proid,
            OnsetDtTyp: row.OnsetDtTyp,
            OnsetDt: row.OnsetDt,
            Employment: row.Employment,
            OtherDtType: row.OtherDtType,
            AutoAccdnt: row.AutoAccdnt,
            OtherDt: row.OtherDt,
            OtherAccdnt: row.OtherAccdnt,
          };

          var hcfa_form_ins_encryptedData = Encrypt_Value(article, "vozo");

          await axiosInstance
            .post("/vozo/hcfaform/insert", hcfa_form_ins_encryptedData)
            .then((response) => {})
            .catch((err) => console.log(err));
        } else {
          const article = {
            eid: row.eid,
            pid: proid,
            OnsetDtTyp: row.OnsetDtTyp,
            OnsetDt: row.OnsetDt,
            Employment: row.Employment,
            OtherDtType: row.OtherDtType,
            AutoAccdnt: row.AutoAccdnt,
            OtherDt: row.OtherDt,
            OtherAccdnt: row.OtherAccdnt,
          };

          var hcfa_form_update_encryptedData = Encrypt_Value(article, "vozo");

          await axiosInstance
            .put(
              "/vozo/hcfaform/update?hcfid=" + row.HCF_FormID,
              hcfa_form_update_encryptedData
            )
            .then((response) => {})
            .catch((err) => console.log(err));
        }
      }

      // var chginvoice = localStorage.getItem("invoname");
      // var subinvoval = chginvoice.substr(8);
      // var invoname = "vozoinv#" + (parseInt(subinvoval) + 1);
      // localStorage.setItem("invoname", invoname);
      var article = {
        // invoicename: invoname,
        amount: copay_main,
        num: 1,
      };
      var insert_invpoice_encryptedData = Encrypt_Value(article, "vozo");
      await axiosInstance
        .put(
          "/vozo/insertinvoice?eid=" + eid + "&status=insertdata",
          insert_invpoice_encryptedData
        )
        .then(async (response) => {
          let data = Decrypt_Value(response.data, "vozo");

          setinvoicesetval(data["invoice_id"]);
          localStorage.setItem("invoname", data["invoice_id"]);

          // await axiosInstance
          //   .post(
          //     "/vozo/insertinvoicedata?eid=" + eid + "&num=1",
          //     insert_invpoice_encryptedData
          //   )
          //   .then((response) => {
          if (
            data["invoice_result"] === "insert" ||
            data["invoice_result"] === 'Email Sent Successfully"insert"'
          ) {
            // setinvoicesetval(localStorage.getItem("invoname"));
            showfullpop(true);
            stpaydis();
          }
          //     })
          //     .catch((err) => console.log(err));
        })
        .catch((e) => console.log(e));
      setTimeout(() => {
        showstatus();
        refreshData();
        chceckamountpay();
        setClicked(false);
      }, 700);
    }
  };

  const saveinvoid = async (id) => {
    if (invStat === "Paid") {
      return;
    }
    setinvoname(id);
    var article = { inid: id };
    var total_invoice_id_encryptedData = Encrypt_Value(article, "vozo");
    await axiosInstance
      .put("/vozo/totalinvoiceid?eid=" + eid, total_invoice_id_encryptedData)
      .then((response) => {
        var temp_role_total_invoice = Decrypt_Value(response.data, "vozo");
        setinvototal(temp_role_total_invoice[0]);
        setinvobalance(temp_role_total_invoice[1]);
        if (total_cre === false) {
          setShow(true);
        } else if (total_cre < temp_role_total_invoice[1]) {
          let data = {
            invoice_id: id,
            amount: total_cre,
            userid: provider,
          };
          var pat_cre_pay_encryptedData = Encrypt_Value(data, "vozo");
          axiosInstance
            .put("/vozo/pat_cre_pay?eid=" + eid, pat_cre_pay_encryptedData)
            .then((response) => {
              axiosInstance
                .put(
                  "/vozo/totalinvoiceid?eid=" + eid,
                  total_invoice_id_encryptedData
                )
                .then((response) => {
                  var temp_role_total_invoice_2 = Decrypt_Value(
                    response.data,
                    "vozo"
                  );
                  setinvototal(temp_role_total_invoice_2[0]);
                  setinvobalance(temp_role_total_invoice_2[1]);
                  setShow(true);
                  if (!showfull) {
                    setreload_inv(!reload_inv);
                  }
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        } else if (total_cre >= temp_role_total_invoice[1]) {
          if (temp_role_total_invoice[1] !== "0.00") {
            let data = {
              invoice_id: id,
              amount: temp_role_total_invoice[1],
              userid: provider,
            };
            let pat_cre_pay_encryptedData = Encrypt_Value(data, "vozo");

            axiosInstance
              .put("/vozo/pat_cre_pay?eid=" + eid, pat_cre_pay_encryptedData)
              .then((response) => {
                setModalAlerShow(true);
                setAlertMsg("Credit amount paid successfully");
                showfullpop(false);
                setTimeout(modalhide, 1500);
                setreload_inv(!reload_inv);
              })
              .catch((err) => console.log(err));
          } else {
            setShow(true);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const invshowfullpop = () => {
    setreload_inv(!reload_inv);
    showfullpop(false);
  };

  const showinvoicepage = (id, status) => {
    setinvoicesetval(id);
    var st = status;
    setInvStat(st);
    setinvoicesetval(id);
    showfullpop(true);
  };
  const deleteinv = (invid) => {
    var temp = { inv: invid };
    var delete_inv_encryptedData = Encrypt_Value(temp, "vozo");
    if (window.confirm("Are you sure ?")) {
      axiosInstance
        .put("/vozo/delete_inc", delete_inv_encryptedData)
        .then((response) => {
          if (response.data === "pass") {
            setModalAlerShow(true);
            setAlertMsg("Invoice deleted successfully");
            setTimeout(modalhide, 1500);
            // setreload_inv(!reload_inv);
          } else if (response.data === "fail") {
            setModalAlerShow1(true);
            setAlertMsg1("Invoice already paid");
            setTimeout(modalhide, 1500);
          }
          setreload_inv(!reload_inv);
        })
        .catch((err) => console.log(err));

      showfullpop(false);
    }
  };

  const modalhide = () => {
    setModalAlerShow(false);
    setModalAlerShow1(false);
  };

  const stpaydis = () => {
    let instype = "primary";
    if (row.Billstatus === "Bill Secondary Insurance") instype = "secondary";

    axiosInstance
      .get("/vozo/insurancepaytotal?eid=" + eid + "&instype=" + instype)
      .then((response) => {
        var temp_role_ins_pay_total = Decrypt_Value(response.data, "vozo");
        if (temp_role_ins_pay_total[0] !== "zero") {
          setcopay_main(temp_role_ins_pay_total[0]);
        } else {
          setcopay_main(0);
        }
      })
      .catch((err) => console.log(err));
  };

  const insurancePayDatas = async () => {
    await axiosInstance
      .get("/vozo/showinsurancebalance?eid=" + eid)
      .then((response) => {
        var temp_role_show_ins_bal = Decrypt_Value(response.data, "vozo");
        setpatbal(temp_role_show_ins_bal[0]);
        setshowtotal(temp_role_show_ins_bal[1]);
        setcopaybal(temp_role_show_ins_bal[2]);
        setinsurebal(temp_role_show_ins_bal[3]);
        setFeeLoader1(false);
      })
      .catch(() => {
        setpatbal("");
        setshowtotal("");
        setcopaybal("");
        setinsurebal("");
        setFeeLoader1(false);
      });

    await axiosInstance
      .get("/vozo/showinvoicename?eid=" + eid + "&type=1")
      .then((response) => {
        var temp_role_show_invoicename = Decrypt_Value(response.data, "vozo");
        if (temp_role_show_invoicename.length > 0) {
          payment_profile_disable(true);
        } else {
          payment_profile_disable(false);
        }

        setshowinvoname(temp_role_show_invoicename);
      })
      .catch(() => setshowinvoname([]));
    await axiosInstance
      .get("/vozo/patient/name/invoice?eid=" + eid)
      .then((res) => {
        var temp_role_show_invoicename = Decrypt_Value(res.data, "vozo");
        setPatInvName(temp_role_show_invoicename.name);
      })
      .catch((err) => {
        setPatInvName("");
        console.error("There was an error", err);
      });

    await axiosInstance
      .get("/vozo/pat_total?eid=" + eid)
      .then((response) => {
        var temp_role_patient_total = Decrypt_Value(response.data, "vozo");
        console.log(temp_role_patient_total);
        setpatbal(temp_role_patient_total.total_balance);
        settotal_cre(temp_role_patient_total.credit);
      })
      .catch((err) => console.log(err));
  };

  // ------------------------------ Functions End--------------------------------------------------- //

  // ------------------------------ useEffect Start--------------------------------------------------- //

  useEffect(() => {
    if (row.PaymentStatus === "2") {
      stpaydis();
    }
  }, [row.Billstatus, reload_inv]);

  useEffect(() => {
    setFeeLoader1(true);
    setClicked(false);
    insurancePayDatas();
  }, [reload_inv, rerender, renderTab]);

  // ------------------------------ useEffect End--------------------------------------------------- //

  return (
    <div id="InsuranceStep" className="mb-neg-10px">
      <hr className="bill-new2"></hr>
      {feeLoad1 ? (
        <SpinnerDiv height="120px">
          <Spinner animation="border" variant="primary" />
        </SpinnerDiv>
      ) : (
        <>
          <div className="d-flex">
            <div className="count-bo">
              <div className="dollor-value">
                {showtotal === null
                  ? `${currency} 0.00`
                  : `${currency} ${showtotal}`}
              </div>
              <div className="dollor-name">Appointment Total</div>
            </div>
            <div className="count-bo">
              <div className="dollor-value">
                {!showCopay || Number(showtotal) < Number(copay_main)
                  ? `${currency} 0.00`
                  : `${currency} ${copay_main}.00`}
              </div>
              <div className="dollor-name">Copay Amount</div>
            </div>
            <div className="count-bo">
              <div className="dollor-value">
                {showCopay && Number(showtotal) > Number(copay_main)
                  ? `${currency} ${insurebal - copay_main}.00`
                  : Number(showtotal) < Number(copay_main)
                  ? `${currency} ${insurebal}.00`
                  : `${currency} 0.00`}
              </div>
              <div className="dollor-name">Insurance Amount</div>
            </div>
          </div>
          <hr className="bill-new3"></hr>
          {parseInt(copay_main) <= parseInt(showtotal) && showCopay ? (
            <>
              <div className="pat-balance"> Copay Amount </div>
              <div className="d-flex">
                <div className="iconwith w-100">
                  <span>{currency}</span>
                  <span id="insuranceamt">
                    {copay_main}
                    {parseInt(copay_main) > 0 ? (
                      <span className="creat-link curs" onClick={onClick}>
                        Create Invoice
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
              <div className="pat-balance">
                Patient&nbsp;Balance&nbsp;
                {patbal === null ? `${currency} 0.00` : `${currency} ${patbal}`}
              </div>
              {total_cre ? (
                <div className="pat-balance">
                  Patient&nbsp;Credit&nbsp;{`${currency} ${total_cre} `}
                  CR
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {showinvoname.map((row, index) => (
            <React.Fragment key={index}>
              <div
                className={`grey-color d-flex bill-pay-bottom ${
                  FullScreen && "disabled"
                }`}
                key={`${index}ins`}
              >
                <span
                  key={`${index}id`}
                  className={`invoice-pointer ${FullScreen && "disabled"}`}
                  onClick={() => showinvoicepage(row.invoice_id, row.status)}
                >
                  {row.invoice_id}
                </span>{" "}
                <span
                  className="pay-status-appt"
                  key={`${index}status`}
                  style={{
                    background: getstat(row.status),

                    color: "black",
                  }}
                >
                  {row.status}
                </span>
                {storedNames.coding && (
                  <>
                    {row.status === "Paid" ? null : (
                      <span
                        className={`blue-color-inv curs  m-l-5 ${
                          FullScreen && "disabled"
                        }`}
                        key={`${index}pay`}
                        onClick={() => saveinvoid(row.invoice_id)}
                      >
                        Add Payment
                      </span>
                    )}
                  </>
                )}
              </div>
            </React.Fragment>
          ))}

          <Modal
            className="client-popup pat-pay-tab w-100 topcreate_popup_centre"
            centered
            show={show}
            onHide={handleClose}
          >
            <AddPayment
              pid={proid}
              handleClose={handleClose}
              invoname={invoname}
              invototal={invototal}
              invobalance={invobalance}
              eid={eid}
              reload_inv={reload_inv}
              showstatus={showstatus}
              modalhide={modalhide}
              showfullpop={showfullpop}
              checkamount_pay={checkamount_pay}
              setModalAlerShow={setModalAlerShow}
              setAlertMsg={setAlertMsg}
              setModalAlerShow1={setModalAlerShow1}
              setAlertMsg1={setAlertMsg1}
              chceckamountpay={chceckamountpay}
              setreload_inv={setreload_inv}
              insurance="insurance"
              payInvAppt={false}
            />
          </Modal>
          <Modal
            show={showfull}
            id="fullscreen-popup"
            className="modal-bg invoice-full bil_invoice"
            onHide={invshowfullpop}
          >
            <Modal.Header className="modal-header1">
              <div className="inv-ins-close">
                <IoCloseSharp
                  className="font-vdo-call-stp "
                  // style={{ height: "18px", width: "18px" }}
                  onClick={invshowfullpop}
                />
              </div>
              <div className="invoice-name ">Invoice for {patInvName}</div>

              {storedNames.coding ? (
                <>
                  <div
                    className={`btn btn-success float-right mr-3 ml-1  icon-siz1 bill-add-pay-btn prac-sht-add-pay  ${
                      FullScreen && "disabled "
                    } ${invStat === "Paid" && "curs-not-insurance"}`}
                    onClick={() => saveinvoid(invoicesetval)}
                  >
                    Add Payment
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="btn  float-right mr-2 ml-1 icon-siz ">
                <BiTrashAlt
                  onClick={() => deleteinv(invoicesetval)}
                  className="h-icon"
                />
              </div>

              <div className="hr-line float-right mr-4 ml-1"></div>
              <div className="btn  float-right mr-3 ml-1 icon-siz ">
                <BsDownload onClick={downloadpdf} className="h-icon" />
              </div>
              <div className="btn  float-right mr-3 ml-1 icon-siz ">
                <AiOutlinePrinter onClick={printpage} className="h-icon" />
              </div>
            </Modal.Header>

            <Modal.Body className="invoice-comp w-800">
              <Example
                ref={fref}
                invs={invStat}
                eid={eid}
                invoiceid={invoicesetval}
                copay="1"
              />
            </Modal.Body>
          </Modal>

          <Modal className="alert-popup-message" show={ModalAlerShow}>
            <div className="alert msg alert-success" role="alert">
              <BsCheckCircle className="icons-bs" /> {AlertsMsg}
            </div>
          </Modal>

          <Modal
            className="alert-popup-message eramsg"
            show={ModalAlerShow1}
            onHide={() => setModalAlerShow1(false)}
          >
            <div role="alert" className="alert-danger erapad">
              <BsExclamationOctagon
                className="icons-bs"
                style={{ marginBottom: 4, marginRight: 5 }}
              />
              <span style={{ fontSize: 20 }}> {AlertsMsg1}</span>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};
export default memo(InsuranceStep);
