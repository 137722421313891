/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from "react";
import { useSelector } from "react-redux";
import { Button, Image } from "react-bootstrap";
import { Select } from "antd";
import "./report.css";
import axiosInstance from "../../axios/axios";
import Loader from "../popups/Loading";
import Emp_data_pic from "../../assets/images/Emp_data_pic.png";
import {
  Table,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  TableHead,
} from "../setting/PatientPortalSettings/StyledComponents";
import { Tablerow } from "../patient/StyledComponent";
import { globalColor, globalFont } from "../utils/RepeatFunctions/Schedule";
import CustomSuffixIcon from "../popups/CustomSuffixIcon";
import { Encrypt_Value, Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";

function AgingTable() {
  //------------------------------------------ State and Variables Start ------------------------------------------------------- //

  const [agingdata, setagingdata] = useState([]);
  const [mainrows, setmainfutureId] = useState([]);
  const [filtervar, setfiltervar] = useState([]);

  const [spinner, setspinner] = useState(true);

  const [all, setAll] = useState([]);

  const [showLoading, setShowLoading] = useState("reportDivblock");
  const [load_flag, setload_flag] = useState(0);

  const [office, setoffice] = useState("");
  const [getdata, setgetdata] = useState("patient");
  const [providerdata, setproviderdata] = useState(false);

  const [alloffice, getalloffice] = useState([]);

  var storedNames = Decrypt_Value(localStorage.getItem("setroles"), "vozo");
  var user_ID = Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  var authorized = Decrypt_Value(localStorage.getItem("authorized"), "vozo");
  var main_pro = Decrypt_Value(localStorage.getItem("main_pro"), "vozo");
  var owner_id = Decrypt_Value(localStorage.getItem("owner_id"), "vozo");
  var group_id = Decrypt_Value(localStorage.getItem("group_id"), "vozo");

  const currency = useSelector((state) => state.Report.Currency);
  const { Option } = Select;

  //------------------------------------------ State and Variables End -------------------------------------------------------- //

  // ----------------------------------------- Functions Start ---------------------------------------------------------------- //

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const firstloadTenPokemon = () => {
    const tenPokemon1 = [];
    let Data_frsh =
      "limit=40&offset=" +
      mainrows.length +
      "&authorized=" +
      authorized +
      "&main_pro=" +
      main_pro +
      "&owner_id=" +
      owner_id +
      "&userID=" +
      user_ID +
      "&pat_share=" +
      storedNames.pat_share;
    let Data_enc = Encrypt_Value(Data_frsh, "vozo");
    axiosInstance
      .get("/vozo/agingget?search=" + Data_enc)
      .then((response) => {
        let DecodeVal = Decrypt_Value(response.data, "vozo");
        DecodeVal[0].forEach((p) => tenPokemon1.push(p));
        setmainfutureId((pokemon) => [...pokemon, ...tenPokemon1]);
        setload_flag(0);
        setspinner(false);
        let items = DecodeVal[0].slice(agingdata.length, 15);
        setagingdata((mitems) => [...mitems, ...items]);
        if (DecodeVal[0].length > 15) {
          setShowLoading("reportDivblock");
        } else {
          setShowLoading("reportDivnone");
        }
        setAll(DecodeVal[1][0]);
      })
      .catch((error) => {
        let tenPokemon1 = [];
        setmainfutureId((pokemon) => [...pokemon, ...tenPokemon1]);
        setAll([]);
      });
  };

  const change = (x) => {
    if (x === null || x === undefined) {
      return `${currency} 0`;
    } else {
      return `${currency} ${x}`;
    }
  };
  const agingdata1 = [];
  const cptfilter = () => {
    const article = {
      limit: 40,
      offset: 0,
      authorized: authorized,
      main_pro: main_pro,
      owner_id: owner_id,
      userID: user_ID,
      pat_share: storedNames.pat_share,
      office: office,
      getdata: getdata,
    };

    setfiltervar(article);
    let Data_enc = Encrypt_Value(article, "vozo");
    if (storedNames.pat_share) {
      axiosInstance
        .post("/vozo/agingget", Data_enc)
        .then((response) => {
          let DecodeVal = Decrypt_Value(response.data, "vozo");
          let tenPokemon = [];
          DecodeVal[0].forEach((p) => tenPokemon.push(p));
          setmainfutureId(tenPokemon);
          if (DecodeVal[0].length > 15) {
            setShowLoading("reportDivblock");
          } else {
            setShowLoading("reportDivnone");
          }
          setAll(DecodeVal[1][0]);

          var items = [];
          var i = 0;
          for (i = 0; i < 10; i++) {
            if (tenPokemon[i] !== undefined) {
              items.push(tenPokemon[i]);
            }
          }
          setagingdata(items);
        })
        .catch((err) => {
          setmainfutureId([]);
          setAll([]);
          setShowLoading("reportDivnone");
        });
    }
  };

  const loadTenPokemon = (e) => {
    setShowLoading("reportDivblock");

    var data = mainrows.length;
    var final = mainrows;
    var rowscount = agingdata.length;

    var res = [];

    for (var i in final) {
      res.push(final[i]);
    }

    if (rowscount >= data - 30 && load_flag === 0) {
      setload_flag(1);

      const article = {
        cptcode: filtervar.cptcode,
        cpttype: filtervar.cpttype,
        fromdate: filtervar.fromdate,
        todate: filtervar.todate,
        limit: 40,
        offset: data,
        authorized: authorized,
        main_pro: main_pro,
        owner_id: owner_id,
        userID: user_ID,
        pat_share: storedNames.pat_share,
        office: office,
        getdata: getdata,
      };
      let Data_enc = Encrypt_Value(article, "vozo");
      axiosInstance
        .post("/vozo/agingget?uid=" + user_ID, Data_enc)
        .then((res) => {
          let DecodeVal = Decrypt_Value(res.data, "vozo");
          let tenPokemon = [];
          DecodeVal.forEach((p) => tenPokemon.push(p));
          setmainfutureId((pokemon) => [...pokemon, ...tenPokemon]);
          setload_flag(0);
        })
        .catch((err) => {
          let tenPokemon1 = [];
          setmainfutureId((pokemon) => [...pokemon, ...tenPokemon1]);
        });
    }
    if (agingdata.length <= mainrows.length) {
      var items = [];
      let s = parseInt(rowscount) + 10;
      for (i = rowscount; i < s; i++) {
        if (res[i] !== undefined) {
          items.push(res[i]);
        }
      }

      setagingdata((mitems) => [...mitems, ...items]);
    }
  };

  // ----------------------------------------- Functions End ------------------------------------------------------------------ //

  // ----------------------------------------- UseEffect Start --------------------------------------------------------------- //

  useEffect(() => {
    getFacilityDatas();

    firstloadTenPokemon();
    setTimeout(() => setspinner(false), 3000);
  }, []);

  useEffect(() => {
    if (agingdata.length === mainrows.length) {
      setShowLoading("reportDivnone");
    }
  }, [agingdata]);
  const getFacilityDatas = async () => {
    let input =
      "authorized=" +
      authorized +
      "&main_pro=" +
      main_pro +
      "&group_id=" +
      group_id;
    let dta = Encrypt_Value(input, "vozo");
    await axiosInstance
      .get("/vozonew/facility/all?search=" + dta)
      .then((res) => {
        var res3 = Decrypt_Value(res.data, "vozo");
        getalloffice(res3);
      })
      .catch((err) => {
        getalloffice([]);
      });
  };
  // ----------------------------------------- UseEffect End ---------------------------------------------------------------- //

  return (
    <div>
      {spinner ? (
        <Loader />
      ) : (
        <>
          <div className="tab_aging_main">
            <div className="tab_title_name">Aging Report</div>
          </div>
          <div className="bg-back_report backgr-chng for-aging-top">
            <div>
              <div className="d-flex ">
                <div className="tele-select-btn m-l-25">
                  <Select
                    placeholder="Facility"
                    className="w-210 t-a-l antd-dd-tele_repo ant-sel-hei34 "
                    suffixIcon={<CustomSuffixIcon />}
                    onChange={(e, data) => setoffice(data.value)}
                    value={office ? office : undefined}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.title ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {alloffice.map((row, ind) => (
                      <Option
                        key={row.officeid + ind}
                        value={row.officeid}
                        title={row.Office}
                      >
                        {row.Office}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="aging-search"> Search by</div>
                <div className="tele-select-btn">
                  <Select
                    // placeholder="Facility"
                    className="w-210 t-a-l antd-dd-tele_repo ant-sel-hei34 "
                    suffixIcon={<CustomSuffixIcon />}
                    onChange={(e, data) => {
                      setgetdata(data.value);
                      if (data.value === "provider") {
                        setproviderdata(true);
                      } else {
                        setproviderdata(false);
                      }
                    }}
                    value={getdata ? getdata : undefined}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.title ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Option key={1} value="patient">
                      Patient
                    </Option>
                    <Option key={2} value="payer">
                      Payer
                    </Option>
                    <Option key={3} value="provider">
                      Provider
                    </Option>
                  </Select>
                </div>
                <Button
                  variant="primary"
                  onClick={cptfilter}
                  className="m-l-16 create-appt edit-note input-size btn-fnt-wgt button_Click_event_alt"
                  id="rpt_proc-btn_upt"
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
          <>
            <div className="table-boder-pad_down2">
              <div className=" table-for-template1">
                <Table minWidth="650px">
                  <TableHead
                    className="Tab_content_Table_Header appt-head"
                    display="table-header-group"
                    height="40px"
                  >
                    <Tablerow
                      className="schedule-head-row tr-no aging-head-row"
                      fontWeight="400"
                      color={globalColor}
                    >
                      <TableHeadCell width={providerdata ? "17%" : "20%"}>
                        <div>Responsible party</div>
                      </TableHeadCell>
                      <TableHeadCell
                        align="left"
                        width={providerdata ? "10%" : "14%"}
                      >
                        0-30 days
                      </TableHeadCell>
                      <TableHeadCell
                        align="left"
                        width={providerdata ? "10%" : "14%"}
                      >
                        31-60 days
                      </TableHeadCell>
                      <TableHeadCell
                        align="left"
                        width={providerdata ? "10%" : "14%"}
                      >
                        61-90 days
                      </TableHeadCell>
                      <TableHeadCell
                        align="left"
                        width={providerdata ? "10%" : "14%"}
                      >
                        91-120 days
                      </TableHeadCell>
                      <TableHeadCell
                        align="left"
                        width={providerdata ? "13%" : "14%"}
                      >
                        Above 120 days
                      </TableHeadCell>
                      {providerdata ? (
                        <>
                          <TableHeadCell align="left" width="10%">
                            Patient
                          </TableHeadCell>
                          <TableHeadCell align="left" width="10%">
                            Insurance
                          </TableHeadCell>
                        </>
                      ) : (
                        ""
                      )}
                      <TableHeadCell
                        align="left"
                        paddingRight="16px"
                        width={providerdata ? "10%" : "14%"}
                      >
                        Total
                      </TableHeadCell>
                    </Tablerow>
                  </TableHead>
                  {agingdata1.length !== 0 ? (
                    <>
                      <TableBody
                        display="table-row-group"
                        className="Boreder_none"
                      >
                        {agingdata1.map((row, ind) => (
                          <Tablerow
                            // key={row.Code + ind}
                            className="table-data-row schedule-body-row"
                            color="#636d73"
                            lineHeight="20.01px"
                            fontFamily={globalFont}
                            borderBottom="1px solid
                            #cbd5e0"
                            padding="6px 0px 6px 18px"
                            height="40px"
                          >
                            <TableBodyCell
                              scope="row"
                              width={providerdata ? "17%" : "20%"}
                            >
                              {row.name}
                            </TableBodyCell>
                            <TableBodyCell
                              align="left"
                              width={providerdata ? "10%" : "14%"}
                            >
                              {row.till30days}
                            </TableBodyCell>
                            <TableBodyCell
                              align="left"
                              width={providerdata ? "10%" : "14%"}
                            >
                              {row.till60days}
                            </TableBodyCell>
                            <TableBodyCell
                              align="left"
                              width={providerdata ? "10%" : "14%"}
                            >
                              {row.till90days}
                            </TableBodyCell>
                            <TableBodyCell
                              align="left"
                              width={providerdata ? "10%" : "14%"}
                            >
                              {row.till120days}
                            </TableBodyCell>

                            <TableBodyCell
                              align="left"
                              width={providerdata ? "13%" : "14%"}
                            >
                              {row.above120days}
                            </TableBodyCell>
                            {providerdata ? (
                              <>
                                <TableBodyCell align="left" width="10%">
                                  {change(row.patientpaid)}
                                </TableBodyCell>
                                <TableBodyCell align="left" width="10%">
                                  {change(row.patientpaid)}
                                </TableBodyCell>
                              </>
                            ) : (
                              ""
                            )}
                            <TableBodyCell
                              align="left"
                              paddingRight="16px"
                              width={providerdata ? "10%" : "14%"}
                            >
                              {/* {change(Number(row.total ?? 0).toFixed(2))} */}
                              {change(row.total)}
                            </TableBodyCell>
                          </Tablerow>
                        ))}
                      </TableBody>
                    </>
                  ) : (
                    <>
                      <TableBody>
                        <Tablerow className="bot_border">
                          <TableBodyCell
                            className="CreditRowAlign"
                            colSpan={providerdata ? 9 : 7}
                          >
                            <div className="img_top">
                              <Image
                                height="133px"
                                width="133px"
                                src={Emp_data_pic}
                              ></Image>
                            </div>
                          </TableBodyCell>
                        </Tablerow>
                      </TableBody>
                    </>
                  )}
                </Table>
              </div>
            </div>
          </>
          {agingdata.length !== 0 && (
            <div
              className={
                "loading-more-pro-proc recharts-legend-wrapper " + showLoading
              }
            >
              <Button
                id="load-data"
                variant="primary"
                className={
                  "loading-more-div recharts-legend-wrapper " + showLoading
                }
                onClick={loadTenPokemon}
              >
                Load more...
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default memo(AgingTable);
