import React from "react";
import { Div, Text } from "../../../StyledComponents";
import { Avatar, AvatarLetter, AvatarImage } from "../StyledComponents";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import { ProfileFileURL } from "../../../axios/url.js";

const HeadNav = ({ data }) => {
  // ------------------------------ State And Variables Start------------------------ //
  // const File_URL =
  // process.env.REACT_APP_BACKEND_FILE_ACCESS + "/sites/default/documentimg/";
  

  // ------------------------------State And Variables End------------------------ //

  // ------------------------------Functions Start------------------------------- //

  // ------------------------------Functions End-------------------------- //

  return (
    <Div
      padding="10px 0px 10px 11.5px "
      display="flex"
      alignItems="center"
      borderBottom="1px rgba(0, 0, 0, 0.25) solid"
    >
      {data.img_url === null ? (
        <Avatar width="36px" height="36px" border="1px solid #FFFFFF">
          <AvatarLetter top="8px">
            {data.ProviderName.charAt(0).toUpperCase()}
          </AvatarLetter>
        </Avatar>
      ) : (
        <AvatarImage
          src={ProfileFileURL + data.img_url}
          alt="image-failed"
          loading="lazy"
          width="36px"
          height="36px"
          border="1px solid #FFFFFF"
          objectFit="cover"
          marginTop="0 !important"
        />
      )}
      <Div marginLeft="6px">
        <Text
          color="#0a1629"
          lineHeight="22px"
          fontSize="16px"
          fontWeight="600"
        >
          {data.ProviderName}
        </Text>
      </Div>
    </Div>
  );
};

export default HeadNav;
