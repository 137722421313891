import React, { useState, useEffect, memo, useRef } from "react";
import { Modal, Form } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import PeopleOutline from "@material-ui/icons/PeopleOutline";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import RoomOutlined from "@material-ui/icons/RoomOutlined";
import LocalHospitalOutlined from "@material-ui/icons/LocalHospitalOutlined";
import PersonOutline from "@material-ui/icons/PersonOutline";
import { Select } from "antd";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Dropdown as Dropdownant } from "react-bootstrap";
import doctorLogo from "../../assets/images/fontisto_doctor.png";
import BillingFacilityIcon from "../../assets/images/BillingFacilityIcon.png";
import {
  setBookingData,
  SetPatientBool,
} from "./../../StateManagement/Reducers/ScheduleState";
import EditSchedule from "./EditSchedule";
import {
  Spinner,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import "./Popups.css";
import moment from "moment";
import axiosInstance from "../../axios/axios";
import axios from "axios";

import { DatePicker, Radio } from "antd";
import { IoFlagOutline } from "react-icons/io5";
import { Switch } from "antd";

import { Popup } from "semantic-ui-react";
import {
  Image,
  SpinnerDiv,
  StyledSvg,
  StyledButton,
  StyledDiv,
} from "../../StyledComponents";
import { Div, Span } from "../billing/CardTransactions/styles";
import { BsFillCaretDownFill } from "react-icons/bs";
import { BsExclamationOctagon } from "react-icons/bs";
import UpgradeRequired from "../popups/UpgradeRequired";
import CreateClient from "./CreateClient";
import { useLocation } from "react-router-dom";
import { BsCheckCircle } from "react-icons/bs";
import { Tabs } from "antd";
import AddAppTemplate from "./AddAppTemplate";
import { useSelector, useDispatch } from "react-redux";
import { Decrypt_Value, Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { fileurl } from "../../axios/url.js";

import { ProviderName } from "../schedule/styles";
import AppointmentDetail from "./AppointmentDetail";
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const NewAppointment = (props) => {
  // ---------------------------------------- State and Variables Start--------------------------------------------------- //
  const { Option } = Select;
  const dispatch = useDispatch();

  var start = "";
  var end = "";
  const classes = useStyles();

  var currentDate = new Date();
  props.start ? (start = props.start.start) : (start = currentDate);
  props.start
    ? (end = props.start.end)
    : (end = new Date(currentDate.getTime() + 30 * 60000).toString());

  const [swict_check, setswict_check] = useState(false);
  const [swict_check_mnth, setswict_check_mnth] = useState(false);
  const [Week_and_every, setWeek_and_every] = useState(false);
  const [Month_and_every, setMonth_and_every] = useState(false);
  const [Month_and_every_Interval, setMonth_and_every_Interval] = useState("");
  const [Week_Day_Month_Year, setWeek_Day_Month_Year] = useState("");
  const [swict_drop, setswict_drop] = useState(false);
  const [source, setSource] = useState([]);
  const [patientid, setpatientid] = useState("");
  const [search1, setsearch1] = useState("");
  const [sourceroom, setSourceRoom] = useState([]);
  const [roomval, setRoomVal] = useState("");
  const [sourceprofile, setSourceprofile] = useState([]);
  const [billingprofile, setBillingProfile] = useState([]);
  const [profileid, setprofileid] = useState("");
  const [billingid, setbillingid] = useState("");
  const [sourceprovider, setSourceprovider] = useState([]);
  const [providerid, setproviderid] = useState("");
  const [sourceoffice, setSourceoffice] = useState([]);
  const [officeid, setofficeid] = useState("");
  const [sourcestatus, setSourcestatus] = useState([]);
  const [sourcestatustele, setSourcestatustele] = useState([]);
  const [statusid, setstatusid] = useState("");
  const dtFormat = localStorage.getItem("dateFormat");
  var dtFormatsec = `${dtFormat} hh:mm a`;
  const [aptdate, setaptdate] = useState("");
  const [dayswise, setdayswise] = useState(false);
  const [apttime, setapttime] = useState("");
  const [aptfromdate, setappfromdate] = useState("");
  const [apttodate, setapptodate] = useState("");
  const [SpinnerVal, setSpinnerVal] = useState(true);
  const [checking1, setchecking1] = useState(false);
  const [telehealth, settelehealth] = useState([]);
  const [teleprofile, setteleprofile] = useState([]);
  const [Appttype, setAppttype] = useState(1);
  const [forteleornor, setforteleornor] = useState(1);
  const [videochck, setvideochck] = useState(false);
  const [patsrchvalue, setpatsrchvalue] = useState("");
  const [normalappointment, setnormalappointment] = useState(
    localStorage.getItem("normalapppopup") ? true : false
  );
  const [teleappointment, setteleappointment] = useState(false);
  const [weekday, setweekday] = useState("");

  const [repeatselectnew, setrepeatselectnew] = useState("");
  const [repeatselect2new, setrepeatselect2new] = useState("0");
  const [dateselect2new, setdateselect2new] = useState("");
  const [timelimiterrorbox, settimelimiterrorbox] = useState(false);
  const [repeatOptionErr, setRepeatOptioErr] = useState(false);
  const [timelimiterror, settimelimiterror] = useState("none");
  const [patError, setpatError] = useState(false);
  const [profileError, setprofileError] = useState(false);
  const [examroomError, setexamroomError] = useState(false);
  const [billingError, setBillingError] = useState(false);
  const [provError, setprovError] = useState(false);
  const [officeError, setofficeError] = useState(false);
  const [statusError, setstatusError] = useState(false);
  const [untildateError, setuntildateError] = useState(false);
  const [everydayError, seteverydayError] = useState(false);
  const [selectdayError, setselectdayError] = useState(false);
  const [notDisabledSpec, setNotDisabledSpec] = useState("");
  // console.log(notDisabledSpec, "notDisabledSpec");
  const [ModalAlerShow1, setModalAlerShow1] = useState(false);
  const [ModalMsg1, setModalMsg1] = useState("");

  const [walkincheck, setwalkincheck] = useState(false);
  const [patFlag, setPatFlag] = useState([]);
  const [fetchingOptions, setFetchingOptions] = useState(false);
  var accountType = Decrypt_Value(localStorage.getItem("account_type"), "vozo");
  var trial = Decrypt_Value(localStorage.getItem("trial_period"), "vozo");
  const bill_facility_id = useSelector(
    (state) => state.Schedule.BillingFacility
  );
  const bookingData = useSelector((state) => state.Schedule.bookingData);
  var teleFlag = Decrypt_Value(localStorage.getItem("telehealth_flag"), "vozo");

  var authorized = Decrypt_Value(localStorage.getItem("authorized"), "vozo");
  var userID = Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  var main_pro = Decrypt_Value(localStorage.getItem("main_pro"), "vozo");
  var owner_id = Decrypt_Value(localStorage.getItem("owner_id"), "vozo");
  var storedNames = Decrypt_Value(localStorage.getItem("setroles"), "vozo");
  var group_id = Decrypt_Value(localStorage.getItem("group_id"), "vozo");
  var startformat = moment(start, "YYYY-MM-DD HH:mm:ss").format(
    "YYYY-MM-DD HH:mm:ss"
  );
  var endformat = moment(end, "YYYY-MM-DD HH:mm:ss").format(
    "YYYY-MM-DD HH:mm:ss"
  );
  var duration = moment(endformat, "YYYY-MM-DD HH:mm:ss").diff(
    moment(startformat, "YYYY-MM-DD HH:mm:ss"),
    "minutes"
  );
  const [billFacId, setBillFacId] = useState("");

  const [editing, setEditing] = useState(duration);
  const [btnDis, setBtnDis] = useState(false);
  const [workDays, setworkDays] = useState(0);
  const [startTime, setStartTime] = useState(9);
  const [endTime, setEndTime] = useState(17);

  const initialRender = useRef(true);

  const [showrepApp, setShowrepApp] = useState(false);
  const [repvalue, setrepValue] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [date, setDate] = useState("");
  const [dyOfMont, setDyOfMont] = useState("");
  const [wkOfMont, setWkOfMont] = useState("");
  const [aftDate, setAftDate] = useState("");
  const [onDate, setOnDate] = useState("");
  const [onDatefetch, setOnDateFetch] = useState("");
  const [occurence, setOccurence] = useState("1");
  const [custChk, setcustChk] = useState("");
  const [tempOpen, setTempOpen] = useState(false);
  const [inTimeErr, setInTimeErr] = useState(false);
  const [wkErr, setWkErr] = useState(false);
  const [repeatselectmonth, setRepeatSelectMonth] = useState("1");
  const [custchkerr, setcustchkerr] = useState(false);

  const [endErrTime, setEndErrTime] = useState("");
  const [startErrTime, setStartErrTime] = useState("");
  const [frtimeSlots, setFromTimeSlots] = useState([]);
  const [totimeSlots, setToTimeSlots] = useState([]);
  const currentTime = moment().format("h:mm a").split(" ");
  const chekDate = moment().format("YYYY-MM-DD");
  const [upgrade, setUpgrade] = useState(false);
  const [userAvail, setUserAvail] = useState([]);

  const [Telehealthplatform, setTelehealthplatform] = useState("zoom");

  const [speciality, setSpeciality] = useState("");
  const [specialError, setSpecialError] = useState(false);
  const [allowAppt, setAllowAppt] = useState(0);
  const [occurenceday, setOccurenceday] = useState();
  const [occurencedayErr, setOccurencedayErr] = useState(false);
  const [lastdate, setLastdate] = useState(false);
  const [lastdateApp, setLastdateApp] = useState("");
  const [sortSpeciality, setSortSpecility] = useState("");
  const [dateErr, setDateErr] = useState(false);
  const specialityList = [
    "Plastic Surgery",
    "Dermatology",
    "Sleep Medicine",
    "Allergy & Immunology",
    "Concierge Medicine",
    "Oncology",
    "Palliative Care",
    "Endocrinology",
    "Sports Medicine",
    "Hospice",
    "Podiatry",
    "Pediatrics",
    "Pathology",
    "Obstetrics and Gynecology",
    "Hand Surgery",
    "Hematology",
    "Emergency Medicine",
    "Surgery",
    "Cardiology",
    "Vascular Surgery",
    "Pulmonology",
    "Austism",
    "Radiology",
    "ECG Management",
    "Physical Medicine & Rehabilitation",
    "Opthalmology",
    "Geriatric Medicine",
    "Neurology",
    "Infectious Disease",
    "Nephrology",
    "Ambulatory Care & Surgery",
    "Otolaryngology (ENT)",
    "Rheumatology",
    "Dental Health",
    "Chiropractic",
    "Psychiatry",
    "Urology",
    "Wound Care",
    "Orthopedic Surgery",
    "Behaviour Health",
    "Gastroenterology",
    "Anesthesiology",
    "Family Medicine",
    "Behavioral health therapy",
    "Acupuncture",
    "Lactation consulting",
    "Massage therapy",
    "Counseling",
    "Marriage and family therapy",
    "Psychology",
    "Social work",
    "Dietetics or nutrition counseling",
    "Applied behavior analysis",
    "Occupational therapy",
    "Physical therapy",
    "Speech-language pathology",
    "Substance use counseling",
    "Other",
  ];

  const [billFacility, setBillFacility] = useState([]);
  const [billFacilityID, setBillFacilityID] = useState("");
  const [billFacilityError, setBillFacilityError] = useState(false);
  // changes by sridar
  const { state } = useLocation();
  const [eid, setEid] = useState("");
  const [temShow, setTemShow] = useState(true);
  const [clientAddIs, setClientAddIs] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setid] = useState(0);
  const [IdforDelete, setIdforDelete] = useState(0);
  const [singleRow, setSingleRow] = useState([]);
  const [showAvl, setShowAvl] = useState(true);
  const [switchvalue, setswitchvalue] = useState(false);
  const [WeeklyDisable, setWeeklyDisable] = useState("");
  const [changeTab, setChangeTag] = useState(
    props.ApptTab ? props.ApptTab : "1"
  );

  const [inTime, setInTime] = useState("");
  const [outTime, setOutTime] = useState("");
  const [fromShow, setFromShow] = useState(false);
  const [toShow, setToShow] = useState(false);
  const [fromId, setFromId] = useState("");
  //......................

  // ---------------------------------------- State and Variables End--------------------------------------------------- //

  // ---------------------------------------- Functions Start----------------------------------------------------------- //

  const handleRefresh = () => {
    props.closepopup();
    props.FetchAppointments(
      props.provarray,
      props.facarray,
      props.appFromDate,
      props.apptoDate,
      props.pat_share,
      props.examroom
    );
  };

  // changes by sridar
  const ClientAdd = (result) => {
    setClientAddIs(result);
  };
  const enableSwitch = (checked) => {
    setswitchvalue(checked);
    const article = [
      {
        glname: "enable_availability_switch",
        glindex: "0",
        glvalue: checked === true ? "1" : "0",
      },
    ];
    let put_data_enc = Encrypt_Value(article, "vozo");
    axiosInstance
      .put("/vozo/settingdocument", put_data_enc)
      .then((response) => {});
  };

  //.....................

  const AddTemplate = () => {
    setShowAvl(!showAvl);
    setid(0);
    setEid("");
    setIdforDelete(0);
  };

  const handleShowuntildate = () => {
    setLastdate(true);
  };

  const handlerepSelect = (e) => {
    if (e === "Does not repeat") {
      setchecking1(false);
      setdayswise(false);
      setMonth_and_every(false);
      setrepeatselectnew("");
      setrepeatselect2new("");
      setRepeatSelectMonth("1");
      setdateselect2new("");
      setweekday("");
      setcustChk("");
      setselectdayError(false);
    } else if (e === "Daily") {
      setstatusid("");
      setchecking1(true);
      setrepeatselectnew("1");
      setrepeatselect2new("0");
      setdayswise(false);
      setMonth_and_every(false);
      setselectdayError(false);
    } else if (e.includes("Week")) {
      setstatusid("");
      setchecking1(true);
      setdayswise(true);
      setMonth_and_every(false);
    }
    setrepValue(e);
  };

  const convertHMS = (value) => {
    return Math.floor(value * 60);
  };

  const disabledFromTimes = (time, meridiem, type) => {
    const now = moment();
    const currentTime = moment(`${time} ${meridiem}`, "h:mm A");
    const appointmentDate = moment(aptdate);
    if (appointmentDate.isSame(now, "day")) {
      if (type === 2 && currentTime.isBefore(now)) {
        return true;
      }
    }
    return false;
  };

  const disabledToTime = (time, meridiem, id, type) => {
    if (!inTime) return false;

    const fromTime = moment(inTime, "h:mm A");
    const toTime = moment(`${time} ${meridiem}`, "h:mm A");
    if (toTime.format("h:mm A") === "12:00 AM") {
      return false;
    }
    const appointmentDate = moment(aptdate);
    if (fromTime.isAfter(toTime) || fromTime.isSame(toTime)) {
      return true;
    }

    return false;
  };

  const buttonClickActions = async (e) => {
    let MonthOccurences;

    e.preventDefault();
    if (billFacId === undefined) {
      props.redirect();
      return;
    }
    var statusid_temp;
    if (checking1 === true) {
      statusid_temp = "";
    } else {
      statusid_temp = statusid;
    }

    if (inTime === "") {
      setInTimeErr(true);
      return;
    }

    let availcheckfilt = userAvail.filter((x) => {
      return x.pc_eventDate === aptdate;
    });

    if (availcheckfilt.length !== 0) {
      for (let appointment of availcheckfilt) {
        let intimeString = appointment.pc_eventDate + " " + inTime;
        let outtimeString = appointment.pc_eventDate + " " + outTime;

        let intimeDate = new Date(intimeString);
        let outtimeDate = new Date(outtimeString);
        let startTimeString =
          appointment.pc_eventDate + " " + appointment.pc_startTime;
        let endTimeString =
          appointment.pc_eventDate + " " + appointment.pc_endTime;
        let startTimeDate = new Date(startTimeString);
        let endTimeDate = new Date(endTimeString);

        // Check if outtime is the same as pc_startTime
        if (outtimeDate.getTime() === startTimeDate.getTime()) {
          // Skip the if condition and continue to the next appointment
          continue;
        }

        // Check if intime is the same as pc_endTime
        if (intimeDate.getTime() === endTimeDate.getTime()) {
          // Skip the if condition and continue to the next appointment
          continue;
        }

        // Check for overlap only if the conditions above are not met

        if (
          (startTimeDate <= outtimeDate && endTimeDate >= intimeDate) ||
          (endTimeDate >= intimeDate && startTimeDate <= outtimeDate) ||
          (startTimeDate <= intimeDate && endTimeDate >= outtimeDate)
        ) {
          setInTimeErr(true);
          return;
          // You might add a break statement here if you want to exit the loop after finding an overlap
        } else {
          setInTimeErr(false);
        }
      }
    }

    const timenew_alert = moment(outTime, ["h:mm A"]).format("HH:mm");
    const time = timenew_alert.split(":");
    const timeHour = time[0];

    const a = moment(timenew_alert, "hh:mm A").format("hh:mm A");

    const maintime = moment(a, "h:mma");

    var b;
    if (endTime !== "12") {
      b = moment(`0${endTime - 12}:00pm`, "h:mma");
    } else {
      b = moment(`${endTime - 12}:00pm`, "h:mma");
    }

    if (inTimeErr || timelimiterrorbox) {
      return false;
    }
    if (
      Number(startTime) <= Number(timeHour) &&
      Number(timeHour) <= Number(endTime)
    ) {
      settimelimiterrorbox(false);
      settimelimiterror("none");
    } else {
      settimelimiterrorbox(true);
      settimelimiterror("block");
      return false;
    }

    let splitTime = apttime.split(":");
    let splitMin = splitTime[1].split(" ");

    if (splitMin[1] === "PM" && Number(splitTime[0]) !== Number(12)) {
      let totTime = +splitTime[0] + +12;
      if (totTime >= outTime) {
        settimelimiterrorbox(true);
        return false;
      } else if (totTime < startTime) {
        settimelimiterrorbox(true);
        return false;
      }
    }

    let outSplitTime = outTime.split(":");
    let outSplitMin = outSplitTime[1].split("");

    if (outSplitMin[1] === "PM" && Number(outSplitTime[0]) !== Number(12)) {
      let totTime = +outSplitTime[0] + +12;
      if (totTime >= outTime) {
        settimelimiterrorbox(true);
        return false;
      }
    }

    var timenew = aptdate + " " + apttime;
    var dtFormatWithTime1 = `${dtFormat} hh:mm a`;

    var timedetnew = moment(timenew, dtFormatWithTime1).format(
      "YYYY-MM-DD HH:mm a"
    );
    var durationnew = editing;
    var appttypenew = Appttype;
    // var alldaynew = alldaydisable;
    var walkinnew = walkincheck;
    var select1, select2, untildate, fromdate, todate, days_wise, repeats;
    if (weekday != null) {
      if (weekday || swict_check) {
        if (swict_check && (weekday === "" || weekday.length == 1)) {
          setWkErr(true);
          return false;
        } else {
          setWkErr(false);
          repeats = weekday;
        }
      } else {
        repeats = "";
      }
    } else {
      repeats = "";
    }

    if (repeatselectnew) {
      select1 = repeatselectnew;
    } else {
      select1 = 0;
    }

    if (repeatselect2new) {
      select2 = repeatselect2new;
    } else {
      select2 = "";
    }
    var repeat_type;

    if (checking1) {
      untildate = "";
      repeat_type = "notCustom";
    } else {
      untildate = "";
      repeat_type = "norepeat";
    }

    // untildate = "";
    // repeat_type = "norepeat";

    if (aptfromdate) {
      fromdate = aptfromdate;
    } else {
      fromdate = "";
    }

    if (apttodate) {
      todate = apttodate;
    } else {
      todate = "";
    }

    if (dayswise != null) {
      if (dayswise) {
        days_wise = true;
      } else {
        days_wise = false;
      }
    } else {
      days_wise = false;
    }

    var tempdatenew = moment(timedetnew, moment.defaultFormat).toDate();
    var startTimenew = moment(tempdatenew).format("hh:mm a");
    var returned_endatenew = moment(tempdatenew, dtFormatWithTime1)
      .add(durationnew, "minutes")
      .format("YYYY-MM-DD hh:mm a");
    var startdatenew = moment(tempdatenew, dtFormat).format("YYYY-MM-DD");
    var secondsnew = convertHMS(durationnew);
    var endTimenew = moment(outTime, "h:mm A").format("hh:mm a");
    var endDatenew = moment(returned_endatenew, "YYYY-MM-DD hh:mm a").format(
      "YYYY-MM-DD"
    );
    var MeetMode = Telehealthplatform;

    const repeatsString =
      repeats &&
      repeats
        .filter((value) => {
          if (typeof value === "number") {
            return !isNaN(value);
          }
          if (typeof value === "string") {
            const num = Number(value.trim());
            return !isNaN(num) && num !== "";
          }
          return false;
        })
        .map((value) =>
          typeof value === "string" ? Number(value.trim()) : value
        )
        .filter((num) => num !== 0)
        .sort((a, b) => a - b)
        .join(",");
    var article = {
      pc_pid: patientid,
      pc_catid: profileid,
      pc_billid: billingid !== "" ? Number(billingid) : null,
      pc_aid: providerid,
      pc_title: statusid_temp,
      pc_duration: secondsnew,
      pc_hometext: "",
      pc_apptstatus: statusid_temp,
      pc_eventDate: startdatenew,
      pc_endDate: endDatenew,
      pc_startTime: startTimenew,
      pc_endTime: endTimenew,
      pc_facility: officeid,
      pc_informant: providerid,
      pc_billing_location: "10",
      pc_select1: select1,
      pc_select2: select2,
      pc_untildate:
        untildate !== ""
          ? moment(untildate).format("YYYY-MM-DD")
          : moment(lastdateApp, dtFormat).format("YYYY-MM-DD"),

      pc_lastdate: moment(lastdateApp, dtFormat).format("YYYY-MM-DD"),
      pc_fromdate: fromdate,
      pc_todate: todate,
      days_wise: days_wise,
      days_of_week: repeatsString,
      // allday: alldaynew,
      repeats: repeats,
      appttype: appttypenew,
      walkin: walkinnew,
      examRoom: roomval,
      id: authorized === "1" ? userID : main_pro,
      pc_repeat_type: repeat_type,
      week_and_every_day: Week_and_every,
      month_and_every_day: Month_and_every,
      Month_and_every_Interval: Month_and_every_Interval,

      on_never_after: custChk,
      repeat_select: repvalue,
      pc_repeatselectmonth: repeatselectmonth,
      selectMonthAlp:
        repeatselectmonth === 1 || repeatselectmonth === "1"
          ? `Monthly on day ${dyOfMont}`
          : `Monthly on the ${wkOfMont} ${day}`,
      repeatSelectMonthOccurenceDates: MonthOccurences,
      speciality,
      mode: MeetMode,
      // billingFacilityId: billFacilityID,
    };

    let encryptedData = Encrypt_Value(article, "vozo");

    if (select1 === 0) {
      let error = 1;
      if (
        patientid === "" ||
        (Appttype !== 2 && profileid === "") ||
        providerid === "" ||
        providerid === null ||
        providerid === undefined ||
        aptdate === "" ||
        officeid === "" ||
        speciality === "" ||
        roomval === "" ||
        // billFacilityID === "" ||
        // billFacilityID === null ||
        (repvalue === "Week" && (weekday === "" || weekday.length == 0))
      ) {
        if (patientid === "") {
          setpatError(true);
          error = 2;
        }
        if (aptdate === "") {
          setDateErr(true);
          error = 2;
        }
        if (Appttype !== 2 && profileid === "") {
          setprofileError(true);
          error = 2;
        }
        if (roomval === "") {
          setexamroomError(true);
          error = 2;
        }

        if (repvalue === "Week" && (weekday === "" || weekday.length == 0)) {
          setselectdayError(true);
          error = 2;
        }
        if (
          providerid === "" ||
          providerid === null ||
          providerid === undefined
        ) {
          setprovError(true);
          error = 2;
        }
        if (officeid === "") {
          setofficeError(true);
          error = 2;
        }
        if (speciality === "") {
          setSpecialError(true);
          error = 2;
        }
        // if (billFacilityID === "" || billFacilityID === null) {
        //   setBillFacilityError(true);
        //   return;
        // }

        if (error > 1) {
          return false;
        }
      } else {
        // setSpinnerVal(true);
        setBtnDis(true);
        if (statusid === "doc_confirmed" || statusid === "EMAIL") {
          axiosInstance
            .get("/vozo/pat_mail_check?pid=" + patientid)
            .then((response) => {
              if (response.data == "pass") {
                props.setspinnerfornewapp();
                axiosInstance
                  .post("/vozo/appointment?pid=" + patientid, encryptedData)
                  .then((res) => {
                    let apptAdded = res.data;
                    // return;
                    if (
                      MeetMode === "GMeet" &&
                      videochck &&
                      (statusid === "doc_confirmed" || statusid === "EMAIL")
                    ) {
                      axiosInstance
                        .get("/vozo/getGMeet/data")
                        .then((response) => {
                          var res = Decrypt_Value(response.data, "vozo");

                          let article = {
                            start_time: res["start_time"],
                            end_time: res["end_time"],
                            attendees: res["attendees"],
                            start: res["pc_startTime"],
                            end: res["pc_endTime"],
                            eventdate: res["pc_eventDate"],
                            eid: res["pc_eid"],
                            aid: res["pc_aid"],
                            pid: res["pc_pid"],
                            fac_id: res["pc_facility"],
                          };

                          axios
                            .get(
                              fileurl +
                                "/interface/customized/g-meet/index.php?action=create",
                              { params: article }
                            )
                            .then((res) => {
                              axiosInstance
                                .get("/vozo/tele/checkemailsent")
                                .then((response) => {
                                  setSpinnerVal(false);
                                  props.savealertmsg(response.data, true);
                                  setTimeout(() => props.popuphide(), 2000);
                                })
                                .catch((err) => {
                                  setSpinnerVal(false);
                                  console.error(err);
                                });

                              if (apptAdded === "out of both range") {
                                setModalAlerShow1(true);
                                setModalMsg1(
                                  "Selected Date & time should be within the provider availability range."
                                );
                                setTimeout(
                                  () => setModalAlerShow1(false),
                                  2000
                                );
                                return;
                              } else if (apptAdded === "out of time range") {
                                setModalAlerShow1(true);
                                setModalMsg1(
                                  "Selected time should be within the provider availability range."
                                );
                                setTimeout(
                                  () => setModalAlerShow1(false),
                                  2000
                                );
                                return;
                              } else if (apptAdded === "out of date range") {
                                setModalAlerShow1(true);
                                setModalMsg1(
                                  "Selected Date should be within the provider availability range."
                                );
                                setTimeout(
                                  () => setModalAlerShow1(false),
                                  2000
                                );
                                return;
                              } else if (
                                apptAdded === "Provider Not Available"
                              ) {
                                setTimeout(() => {
                                  setModalAlerShow1(true);
                                  setModalMsg1("Provider Not Available");
                                }, 1);

                                setTimeout(
                                  () => setModalAlerShow1(false),
                                  2000
                                );
                                return;
                              } else if (
                                apptAdded ===
                                "You Can't place the Appointment at this time"
                              ) {
                                setTimeout(() => {
                                  setModalAlerShow1(true);
                                  setModalMsg1(
                                    "You Can't place the Appointment at this time"
                                  );
                                }, 1);

                                setTimeout(
                                  () => setModalAlerShow1(false),
                                  2000
                                );
                                return;
                              } else if (
                                apptAdded.includes(
                                  "You are only allowed to add telehealth appointments"
                                )
                              ) {
                                setTimeout(() => {
                                  setModalAlerShow1(true);
                                  setModalMsg1(apptAdded);
                                }, 1);

                                setTimeout(
                                  () => setModalAlerShow1(false),
                                  3000
                                );
                                return;
                              } else if (
                                apptAdded.includes(
                                  "You Already Have an Appointment on These Date's"
                                )
                              ) {
                                setTimeout(() => {
                                  setModalAlerShow1(true);
                                  setModalMsg1(apptAdded);
                                }, 1);

                                setTimeout(
                                  () => setModalAlerShow1(false),
                                  3000
                                );
                                return;
                              } else if (
                                apptAdded.includes(
                                  "The provider is unavailable on these dates"
                                )
                              ) {
                                setTimeout(() => {
                                  setModalAlerShow1(true);
                                  setModalMsg1(apptAdded);
                                }, 1);

                                setTimeout(
                                  () => setModalAlerShow1(false),
                                  3000
                                );
                                return;
                              } else {
                                setBtnDis(true);

                                props.savealertmsg(
                                  "New Appointment Added Successfully",
                                  true
                                );
                                props.closepopup();

                                // props.setBookAgain(false);
                              }
                              setTimeout(() => props.popuphide(), 2000);
                              props.FetchAppointments(
                                props.provarray,
                                props.facarray,
                                props.appFromDate,
                                props.apptoDate,
                                props.pat_share,
                                props.examroom
                              );
                            })
                            .catch((err) => {
                              console.log("failed");
                            });
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    } else {
                      if (
                        statusid === "doc_confirmed" ||
                        statusid === "EMAIL"
                      ) {
                        axiosInstance
                          .get("/vozo/tele/checkemailsent")
                          .then((response) => {
                            setSpinnerVal(false);
                            props.savealertmsg(response.data, true);
                            setTimeout(() => props.popuphide(), 2000);
                          })
                          .catch((err) => {
                            setSpinnerVal(false);
                            console.error(err);
                          });
                      }

                      if (apptAdded === "out of both range") {
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "Selected Date & time should be within the provider availability range."
                        );
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "out of time range") {
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "Selected time should be within the provider availability range."
                        );
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "out of date range") {
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "Selected Date should be within the provider availability range."
                        );
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (
                        apptAdded ===
                        "You Can't place the Appointment at this time"
                      ) {
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "You Can't place the Appointment at this time"
                        );
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (
                        apptAdded.includes(
                          "You are only allowed to add telehealth appointments"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      } else if (
                        apptAdded.includes(
                          "The provider is unavailable on these dates"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      } else if (
                        apptAdded.includes(
                          "You Already Have an Appointment on These Date's"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      } else {
                        props.savealertmsg(
                          "New Appointment Added Successfully",
                          true
                        );
                        props.closepopup();

                        // props.setBookAgain(false);
                      }
                      setTimeout(() => props.popuphide(), 2000);
                      props.FetchAppointments(
                        props.provarray,
                        props.facarray,
                        props.appFromDate,
                        props.apptoDate,
                        props.pat_share,
                        props.examroom
                      );
                    }
                  })
                  .catch((err) => console.error(err));
              } else {
                alert("Please update a valid Email in Patient Demographics");
                props.closepopup();
              }
              setBtnDis(false);
            })
            .catch((err) => {
              setSpinnerVal(false);
              setBtnDis(false);
              console.error(err);
            });
        } else {
          // props.setspinnerfornewapp();
          axiosInstance
            .post("/vozo/appointment?pid=" + patientid, encryptedData)
            .then((res) => {
              let apptAdded = res.data;
              // return;
              // props.setNewShow(false);
              // props.setBookAgain(false);

              if (
                MeetMode === "GMeet" &&
                videochck &&
                (statusid === "doc_confirmed" || statusid === "EMAIL")
              ) {
                axiosInstance
                  .get("/vozo/getGMeet/data")
                  .then((response) => {
                    var res = Decrypt_Value(response.data, "vozo");

                    let article = {
                      start_time: res["start_time"],
                      end_time: res["end_time"],
                      attendees: res["attendees"],
                      start: res["pc_startTime"],
                      end: res["pc_endTime"],
                      eventdate: res["pc_eventDate"],
                      eid: res["pc_eid"],
                      aid: res["pc_aid"],
                      pid: res["pc_pid"],
                    };

                    axios
                      .get(
                        fileurl +
                          "/interface/customized/g-meet/index.php?action=create",
                        { params: article }
                      )
                      .then((res) => {})
                      .catch((err) => {
                        console.log("failed");
                      });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
              if (statusid === "doc_confirmed") {
                axiosInstance
                  .get("/vozo/getGMeet/data")
                  .then((response) => {
                    // console.log(response);
                  })
                  .catch((err) => {
                    setSpinnerVal(false);
                    console.error(err);
                  });

                axiosInstance
                  .get("/vozo/tele/checkemailsent")
                  .then((response) => {
                    setSpinnerVal(false);
                    props.savealertmsg(response.data, true);
                    setTimeout(() => props.popuphide(), 2000);
                  })
                  .catch((err) => {
                    setSpinnerVal(false);
                    console.error(err);
                  });
              }

              if (apptAdded === "out of both range") {
                setModalAlerShow1(true);
                setModalMsg1(
                  "Selected Date & time should be within the provider availability range."
                );
                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (apptAdded === "out of time range") {
                setTimeout(() => {
                  setModalAlerShow1(true);
                  setModalMsg1(
                    "Selected time should be within the provider availability range."
                  );
                }, 1);

                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (apptAdded === "Provider Not Available") {
                setTimeout(() => {
                  setModalAlerShow1(true);
                  setModalMsg1("Provider Not Available");
                }, 1);

                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (
                apptAdded === "You Can't place the Appointment at this time"
              ) {
                setTimeout(() => {
                  setModalAlerShow1(true);
                  setModalMsg1("You Can't place the Appointment at this time");
                }, 1);

                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (apptAdded === "out of date range") {
                setModalAlerShow1(true);
                setModalMsg1(
                  "Selected Date should be within the provider availability range."
                );
                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (
                apptAdded.includes(
                  "You are only allowed to add telehealth appointments"
                )
              ) {
                setTimeout(() => {
                  setModalAlerShow1(true);
                  setModalMsg1(apptAdded);
                }, 1);

                setTimeout(() => setModalAlerShow1(false), 3000);
                return;
              } else if (
                apptAdded.includes("The provider is unavailable on these dates")
              ) {
                setTimeout(() => {
                  setModalAlerShow1(true);
                  setModalMsg1(apptAdded);
                }, 1);

                setTimeout(() => setModalAlerShow1(false), 3000);
                return;
              } else if (
                apptAdded.includes(
                  "You Already Have an Appointment on These Date's"
                )
              ) {
                setTimeout(() => {
                  setModalAlerShow1(true);
                  setModalMsg1(apptAdded);
                }, 1);

                setTimeout(() => setModalAlerShow1(false), 3000);
                return;
              } else {
                setBtnDis(true);
                props.savealertmsg("New Appointment Added Successfully", true);
                props.closepopup();

                // props.setBookAgain(false);
              }
              setTimeout(() => props.popuphide(), 2000);
              setSpinnerVal(false);

              props.FetchAppointments(
                props.provarray,
                props.facarray,
                props.appFromDate,
                props.apptoDate,
                props.pat_share,
                props.examroom
              );
              setBtnDis(false);
            })
            .catch((err) => {
              console.error(err);
              props.closepopup();
              setBtnDis(false);
            });
        }
      }
    } else if (select1 > 0) {
      let error = 1;
      if (
        patientid === "" ||
        profileid === "" ||
        providerid === "" ||
        providerid === null ||
        providerid === undefined ||
        // statusid === "" ||
        officeid === "" ||
        speciality === "" ||
        roomval === "" ||
        // billFacilityID === "" ||
        // billFacilityID === null ||
        (repvalue === "Week" && (weekday === "" || weekday.length == 0))
        // ||
        // untildate === ""
      ) {
        if (patientid === "") {
          setpatError(true);
          error = 2;
        }
        if (Appttype !== 2 && profileid === "") {
          setprofileError(true);
          error = 2;
        }
        if (roomval === "") {
          setexamroomError(true);
          error = 2;
        }
        // if (billFacilityID === "" || billFacilityID === null) {
        //   setBillFacilityError(true);
        //   error = 2;
        // }

        if (repvalue === "Week" && (weekday === "" || weekday.length == 0)) {
          setselectdayError(true);
          error = 2;
        }

        if (
          providerid === "" ||
          providerid === null ||
          providerid === undefined
        ) {
          setprovError(true);
          error = 2;
        }
        // if (billFacilityID === "" || billFacilityID === null) {
        //   setBillFacilityError(true);
        //   return;
        // }
        if (officeid === "") {
          setofficeError(true);
          error = 2;
        }

        if (speciality === "") {
          setSpecialError(true);
          error = 2;
        }

        if (untildate === "") {
          setuntildateError(true);
          error = 2;
        }

        if (error > 1) {
          return false;
        }
      } else {
        setBtnDis(true);
        if (statusid === "doc_confirmed") {
          axiosInstance
            .get("/vozo/pat_mail_check?pid=" + patientid)
            .then((response) => {
              if (response.data === "pass") {
                // props.setspinnerfornewapp();
                setSpinnerVal(false);

                axiosInstance
                  .post("/vozo/appointment?pid=" + patientid, encryptedData)
                  .then((res) => {
                    let apptAdded = res.data;
                    // return;
                    // console.log(
                    //   res.data,
                    //   "response for video call doc confirmed appt"
                    // );
                    if (
                      MeetMode === "GMeet" &&
                      videochck &&
                      (statusid === "doc_confirmed" || statusid === "EMAIL")
                    ) {
                      axiosInstance
                        .get("/vozo/getGMeet/data")
                        .then((response) => {
                          var res = Decrypt_Value(response.data, "vozo");
                          let article = {
                            start_time: res["start_time"],
                            end_time: res["end_time"],
                            attendees: res["attendees"],
                            start: res["pc_startTime"],
                            end: res["pc_endTime"],
                            eventdate: res["pc_eventDate"],
                            eid: res["pc_eid"],
                            aid: res["pc_aid"],
                            pid: res["pc_pid"],
                          };
                          axios
                            .get(
                              fileurl +
                                "/interface/customized/g-meet/index.php?action=create",
                              { params: article }
                            )
                            .then((res) => {})
                            .catch((err) => {
                              console.log("failed");
                            });
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                    if (statusid === "doc_confirmed") {
                      axiosInstance
                        .get("/vozo/tele/checkemailsent")
                        .then((response) => {
                          props.savealertmsg(response.data, true);
                          setTimeout(() => props.popuphide(), 2000);
                        })
                        .catch((err) => console.error(err));
                    }

                    if (apptAdded === "out of both range") {
                      setModalAlerShow1(true);
                      setModalMsg1(
                        "Selected Date & time should be within the provider availability range."
                      );
                      setTimeout(() => setModalAlerShow1(false), 2000);
                      return;
                    } else if (apptAdded === "out of time range") {
                      setModalAlerShow1(true);
                      setModalMsg1(
                        "Selected time should be within the provider availability range."
                      );
                      setTimeout(() => setModalAlerShow1(false), 2000);
                      return;
                    } else if (apptAdded === "out of date range") {
                      setModalAlerShow1(true);
                      setModalMsg1(
                        "Selected Date should be within the provider availability range."
                      );
                      setTimeout(() => setModalAlerShow1(false), 2000);
                      return;
                    } else if (apptAdded === "Provider Not Available") {
                      setTimeout(() => {
                        setModalAlerShow1(true);
                        setModalMsg1("Provider Not Available");
                      }, 1);

                      setTimeout(() => setModalAlerShow1(false), 2000);
                      return;
                    } else if (
                      apptAdded ===
                      "You Can't place the Appointment at this time"
                    ) {
                      setTimeout(() => {
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "You Can't place the Appointment at this time"
                        );
                      }, 1);

                      setTimeout(() => setModalAlerShow1(false), 2000);
                      return;
                    } else if (
                      apptAdded.includes(
                        "You are only allowed to add telehealth appointments"
                      )
                    ) {
                      setTimeout(() => {
                        setModalAlerShow1(true);
                        setModalMsg1(apptAdded);
                      }, 1);

                      setTimeout(() => setModalAlerShow1(false), 3000);
                      return;
                    } else if (
                      apptAdded.includes(
                        "The provider is unavailable on these dates"
                      )
                    ) {
                      setTimeout(() => {
                        setModalAlerShow1(true);
                        setModalMsg1(apptAdded);
                      }, 1);

                      setTimeout(() => setModalAlerShow1(false), 3000);
                      return;
                    } else if (
                      apptAdded.includes(
                        "You Already Have an Appointment on These Date's"
                      )
                    ) {
                      setTimeout(() => {
                        setModalAlerShow1(true);
                        setModalMsg1(apptAdded);
                      }, 1);

                      setTimeout(() => setModalAlerShow1(false), 3000);
                      return;
                    } else {
                      setBtnDis(true);

                      props.savealertmsg(
                        "New Appointment Added Successfully",
                        true
                      );
                      props.closepopup();

                      // props.setBookAgain(false);
                    }
                    setTimeout(() => props.popuphide(), 2000);
                    props.FetchAppointments(
                      props.provarray,
                      props.facarray,
                      props.appFromDate,
                      props.apptoDate,
                      props.pat_share,
                      props.examroom
                    );
                  })
                  .catch((err) => console.error(err));
              } else {
                alert("Please update a valid Email in Patient Demographics");
                props.closepopup();
              }
              setBtnDis(false);
            })
            .catch((err) => {
              console.error(err);
              setBtnDis(false);
            });
        } else {
          // props.setspinnerfornewapp();
          await axiosInstance
            .post("/vozo/appointment?pid=" + patientid, encryptedData)
            .then((res) => {
              let apptAdded = res.data;
              // console.log(res.data, "response for  appt");
              // return;
              if (
                MeetMode === "GMeet" &&
                videochck &&
                (statusid === "doc_confirmed" || statusid === "EMAIL")
              ) {
                axiosInstance
                  .get("/vozo/getGMeet/data")
                  .then((response) => {
                    var res = Decrypt_Value(response.data, "vozo");
                    let article = {
                      start_time: res["start_time"],
                      end_time: res["end_time"],
                      attendees: res["attendees"],
                      start: res["pc_startTime"],
                      end: res["pc_endTime"],
                      eventdate: res["pc_eventDate"],
                      eid: res["pc_eid"],
                      aid: res["pc_aid"],
                      pid: res["pc_pid"],
                    };
                    axios
                      .get(
                        fileurl +
                          "/interface/customized/g-meet/index.php?action=create",
                        { params: article }
                      )
                      .then((res) => {})
                      .catch((err) => {
                        console.log("failed");
                      });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
              if (statusid === "doc_confirmed") {
                axiosInstance
                  .get("/vozo/tele/checkemailsent")
                  .then((response) => {
                    props.savealertmsg(response.data, true);
                    setTimeout(() => props.popuphide(), 2000);
                  })
                  .catch((err) => console.error(err));
              }

              if (apptAdded === "out of both range") {
                setModalAlerShow1(true);
                setModalMsg1(
                  "Selected Date & time should be within the provider availability range."
                );
                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (apptAdded === "out of time range") {
                setModalAlerShow1(true);
                setModalMsg1(
                  "Selected time should be within the provider availability range."
                );
                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (apptAdded === "out of date range") {
                setModalAlerShow1(true);
                setModalMsg1(
                  "Selected Date should be within the provider availability range."
                );
                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (apptAdded === "Provider Not Available") {
                setTimeout(() => {
                  setModalAlerShow1(true);
                  setModalMsg1("Provider Not Available");
                }, 1);

                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (
                apptAdded === "You Can't place the Appointment at this time"
              ) {
                setTimeout(() => {
                  setModalAlerShow1(true);
                  setModalMsg1("You Can't place the Appointment at this time");
                }, 1);

                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (
                apptAdded.includes(
                  "You are only allowed to add telehealth appointments"
                )
              ) {
                setTimeout(() => {
                  setModalAlerShow1(true);
                  setModalMsg1(apptAdded);
                }, 1);

                setTimeout(() => setModalAlerShow1(false), 3000);
                return;
              } else if (
                apptAdded.includes("The provider is unavailable on these dates")
              ) {
                setTimeout(() => {
                  setModalAlerShow1(true);
                  setModalMsg1(apptAdded);
                }, 1);

                setTimeout(() => setModalAlerShow1(false), 3000);
                return;
              } else if (
                apptAdded.includes(
                  "You Already Have an Appointment on These Date's"
                )
              ) {
                setTimeout(() => {
                  setModalAlerShow1(true);
                  setModalMsg1(apptAdded);
                }, 1);

                setTimeout(() => setModalAlerShow1(false), 3000);
                return;
              } else {
                setBtnDis(true);
                props.savealertmsg("New Appointment Added Successfully", true);
                props.closepopup();
                // props.setBookAgain(false);
              }
              setTimeout(() => props.popuphide(), 2000);
              props.FetchAppointments(
                props.provarray,
                props.facarray,
                props.appFromDate,
                props.apptoDate,
                props.pat_share,
                props.examroom
              );
              setBtnDis(false);
            })
            .catch((err) => {
              console.error(err);
              props.closepopup();
              setBtnDis(false);
            });
        }
      }
    }
  };

  const checkFacility = (type) => {
    if (type === "classname") {
      return sourceoffice && sourceoffice.length === 0 ? "hide-label" : "";
    } else if (type === "spinner") {
      return sourceoffice &&
        officeid &&
        sourceoffice.length === 0 &&
        officeid.length > 0 ? (
        <Spinner
          animation="border"
          size="sm"
          variant="primary"
          className="add-spinner-sty"
        />
      ) : (
        ""
      );
    }
  };

  const onChange = (timeStandard, typeTime) => {
    if (Appttype === 2 && timeStandard === "Invalid date") {
      return;
    }

    if (timeStandard === "Invalid date" && typeTime === "inTime") {
      setInTime(moment(start).format("h:mm A"));
      setOutTime(
        moment(moment(start, "h:mm A").add(15, "minutes")).format("h:mm A")
      );

      let out = moment(moment(start, "h:mm A").add(15, "minutes")).format(
        "h:mm A"
      );
      let inTimeNew = inTime.split(":");
      let inTimeMin = inTimeNew[1].split(" ");
      if (inTimeMin[1] === "PM") {
        if (
          moment(out, "h:mm A").isBetween(
            moment("12:00 AM", "h:mm A"),
            moment("1:00 AM", "h:mm A"),
            null,
            "[)"
          )
        ) {
          out = moment(out, "h:mm A").add(1, "day");
        }
      }
      let dur = moment(out, "hh:mm a").diff(
        moment(inTime, "hh:mm a"),
        "minutes"
      );
      setEditing(dur);
    } else if (timeStandard === "Invalid date" && typeTime === "outTime") {
      setOutTime(
        moment(moment(inTime, "h:mm A").add(15, "minutes")).format("h:mm A")
      );

      let out = moment(moment(inTime, "h:mm A").add(15, "minutes")).format(
        "h:mm A"
      );

      let dur = moment(outTime, "hh:mm a").diff(
        moment(out, "hh:mm a"),
        "minutes"
      );
      setEditing(dur);
    } else {
      let time = timeStandard.split(":");
      let timeMin = time[1].split(" ");

      if (typeTime === "inTime") {
        let intim = moment(timeStandard, "h:mm A").format("h:mm A");
        let out = moment(
          moment(timeStandard, "h:mm A").add(15, "minutes")
        ).format("h:mm A");
        let dur = moment(out, "hh:mm a").diff(
          moment(intim, "hh:mm a"),
          "minutes"
        );
        setEditing(dur);

        setInTimeErr(false);
        setOutTime(
          moment(moment(timeStandard, "h:mm A").add(15, "minutes")).format(
            "h:mm A"
          )
        );
        if (timeMin[1] === "AM") {
          if (time[0] === "12") {
            time[0] = Number(time[0]) - 12;
          }
          if (time[0] < Number(startTime)) {
            settimelimiterrorbox(true);
            return false;
          } else {
            settimelimiterrorbox(false);
          }
        } else {
          if (time[0] !== "12") {
            time[0] = Number(time[0]) + 12;
          }
          if (time[0] >= Number(endTime)) {
            settimelimiterrorbox(true);
            return false;
          } else {
            settimelimiterrorbox(false);
          }
        }
        setapttime(timeStandard);
      } else {
        let inTimeNew = inTime.split(":");
        let inTimeMin = inTimeNew[1].split(" ");
        if (inTimeNew[0] === "12" && inTimeMin[1] === "AM") {
          inTimeNew[0] = "00";
        }

        let out = moment(timeStandard, "h:mm A").format("h:mm A");
        if (inTimeMin[1] === "PM") {
          if (
            moment(out, "h:mm A").isBetween(
              moment("12:00 AM", "h:mm A"),
              moment("1:00 AM", "h:mm A"),
              null,
              "[)"
            )
          ) {
            out = moment(out, "h:mm A").add(1, "day");
          }
        }
        let duration = moment(out, "hh:mm a").diff(
          moment(inTime, "hh:mm a"),
          "minutes"
        );
        setEditing(duration);

        if (inTime === timeStandard) {
          setInTimeErr(true);
        }
        if (inTime !== timeStandard) {
          setInTimeErr(false);
        }
        if (timeMin[1] === "AM") {
          if (inTimeMin[1] === "PM" && Number(inTimeNew[0]) !== 12) {
            inTimeNew[0] = Number(inTimeNew[0]) + 12;
          }
          if (time[0] > Number(endTime) || inTimeNew[0] < Number(startTime)) {
            setInTimeErr(true);
            return false;
          } else if (
            (inTimeMin[1] === "PM" && time[0] !== "12") ||
            (Number(inTimeNew[0]) > Number(time[0]) &&
              Number(time[0]) !== 12) ||
            (inTimeNew[0] === time[0] &&
              inTimeMin[0] >= timeMin[0] &&
              time[0] !== "12") ||
            (time[0] === inTimeNew[0] &&
              timeMin[0] <= inTimeMin[0] &&
              time[0] !== "12")
          ) {
            setInTimeErr(true);
            return false;
          } else if (
            (Number(inTimeNew[0]) > time[0] && time[0] !== "12") ||
            (Number(inTimeNew[0]) === time[0] && inTimeMin[0] > timeMin[0])
          ) {
            setInTimeErr(true);
            return false;
          } else {
            setInTimeErr(false);
            settimelimiterrorbox(false);
          }
        } else {
          if (Number(time[0]) !== 12) {
            time[0] = Number(time[0]) + 12;
          }
          if (inTimeMin[1] === "PM" && Number(inTimeNew[0]) !== 12) {
            inTimeNew[0] = Number(inTimeNew[0]) + 12;
          }
          if (inTimeMin[1] === "AM" && Number(inTimeNew[0]) === 12) {
            inTimeNew[0] = 0;
          }
          if (Number(time[0]) === Number(endTime) && Number(timeMin[0]) > 0) {
            settimelimiterrorbox(true);
            return false;
          }
          if (Number(time[0]) === Number(endTime) && Number(timeMin[0]) == 0) {
            settimelimiterrorbox(false);
          }
          if (Number(time[0]) > Number(endTime)) {
            settimelimiterrorbox(true);
            return false;
          }
          if (Number(time[0]) < Number(endTime)) {
            settimelimiterrorbox(false);
          }
          if (Number(inTimeNew[0]) > Number(time[0])) {
            settimelimiterrorbox(true);
            return false;
          }
          if (Number(inTimeNew[0]) < Number(time[0])) {
            settimelimiterrorbox(false);
          }
          if (
            Number(inTimeNew[0]) === Number(time[0]) &&
            Number(inTimeMin[0]) > Number(timeMin[0])
          ) {
            setInTimeErr(true);
            return false;
          }
          if (
            Number(inTimeNew[0]) === Number(time[0]) &&
            Number(inTimeMin[0]) < Number(timeMin[0])
          ) {
            setInTimeErr(false);
          }
          if (Number(inTimeNew[0]) < Number(startTime)) {
            settimelimiterrorbox(true);
          }
        }
      }
    }
  };

  const fornormalapp = () => {
    return (
      <>
        <div className="for-get-started-popup-for-res-2">
          <div className="dis-fl-just">
            <div className="get-st-billinfo-head">Add Appointment</div>
          </div>
          <div className="get-st-client-content-3">
            Schedule your medical appointment with your preferred doctor.
          </div>

          <div className="get-st-box-with-rad-but-2">
            <>
              <Radio.Group value={1}>
                <Radio value={1}></Radio>
                <Radio value={2}></Radio>
              </Radio.Group>
            </>

            <Button
              className="save_active get-st-next-btn"
              variant="primary"
              onClick={() => forexploreteleapp()}
            >
              Next
            </Button>
          </div>
        </div>
      </>
    );
  };

  const forteleapp = () => {
    return (
      <>
        <div className="for-get-started-popup-for-res-2">
          <div className="dis-fl-just">
            <div className="get-st-billinfo-head">Add Tele Appointment</div>
          </div>
          <div className="get-st-client-content-3">
            Select the facility and schedule a video visit on your own.
          </div>

          <div className="get-st-box-with-rad-but-2">
            <div className="m-t-10">
              <Radio.Group value={2}>
                <Radio value={1}></Radio>
                <Radio value={2}></Radio>
              </Radio.Group>
            </div>

            <Button
              className="save_active get-st-next-btn"
              variant="primary"
              onClick={() => forexploreteleappdone()}
            >
              Done
            </Button>
          </div>
        </div>
      </>
    );
  };

  const dataForBookAgain = (data) => {
    // checkbox22
    if (data.walkin) {
      setwalkincheck(true);
    }
    if (Number(props.dataObj.pc_telehealth) === 1) {
      setAppttype(2);
    } else {
      setAppttype(1);
    }
    // setApptty
    // Separate the assignments
    setofficeid(data.pc_facility);
    //appointment profile 11B
    setprofileid(data.pc_catid);
    // docname44
    setproviderid(data.pc_aid);
    // patient 11
    setpatientid(data.pc_pid);
    setsearch1(props.dataObj.pname);
    //room 5B
    getRoom(data.pc_facility);
    setRoomVal(data.pc_room);
    //status44B
    setstatusid(data.pc_apptstatus);
    //speciality33B
    setSpeciality(data.speciality);
    setEditing(15);
    let options;
    if (Number(props.dataObj.pc_telehealth) === 1) {
      options = teleprofile;
    } else {
      options = sourceprofile;
    }
    for (var j = 0; j < options.length; j++) {
      if (options[j].value.includes(data.pc_catid)) {
        if (options[j].value == data.pc_catid) {
          if (Number(props.dataObj.pc_telehealth) === 1) {
            setbillingid("");
          } else {
            if (
              options[j].pc_bill_profile !== "DEFAULT" &&
              (options[j].pc_bill_profile ?? []).length > 0
            ) {
              setbillingid(options[j].pc_bill_profile);
            } else {
              setbillingid("");
            }
          }
          var filter_pc_duration = options[j].pc_duration;
          if (options[j].pc_duration !== "" || options[j].pc_duration !== "0") {
            let outTimeVal = moment(inTime, "h:mm A").add(
              options[j].pc_duration,
              "minutes"
            );
            setOutTime(moment(outTimeVal).format("h:mm A"));
          }
        }
      }
    }

    setrepeatselectnew(data.pc_select1);
    setrepeatselect2new(data.pc_select2);
    if (data.pc_select2 === "1") {
      setWeek_and_every(true);
    }
    setrepValue(data.pc_repeat_select);
    setLastdateApp(moment(data.pc_endDate).format(dtFormat));

    setRepeatSelectMonth(data.pc_repeatselectmonth);
    setcustChk(parseInt(data.pc_on_after_never));

    let d1 = moment(data.pc_startTime, "h:mm A");
    let d2 = moment(data.pc_endTime, "h:mm A");

    setInTime(d1.format("h:mm A"));

    setOutTime(d2.format("h:mm A"));

    setapttime(d1.format("h:mm A"));

    if (data.pc_recurrtype > "0") {
      setchecking1(true);

      if (data.pc_recurrtype === "1" || data.pc_recurrtype === "2") {
        setrepeatselectnew(data.pc_recurrspec.event_repeat_freq);

        setrepeatselect2new(data.pc_recurrspec.event_repeat_freq_type);
      } else if (data.pc_recurrtype === "3") {
        let temp = data.pc_recurrspec.event_repeat_freq.split(",");
        var temp_a = [];
        for (let i = 0; i < temp.length; i++) {
          let text = Number(temp[i]);
          temp_a.push(text);
        }

        setweekday(temp_a);
      } else if (data.pc_recurrtype === "4") {
        let temp = data.pc_recurrspec.event_repeat_freq_by_day.split(",");
        var temp_a = [];
        for (let i = 0; i < temp.length; i++) {
          let text = Number(temp[i]);
          temp_a.push(text);
        }

        setweekday(temp_a);
      } else if (data.pc_recurrtype === "5") {
        let temp = data.pc_recurrspec.event_repeat_freq_by_day.split(",");
        var temp_a = [];
        for (let i = 0; i < temp.length; i++) {
          let text = Number(temp[i]);
          temp_a.push(text);
        }

        setweekday(temp_a);
      }
    } else {
      setchecking1(false);
    }
  };

  const WeekdayChange = (val) => {
    const hasOnlyEmptyStrings =
      val.length > 0 && val.every((item) => item === "");
    if (hasOnlyEmptyStrings) {
      setweekday("");
    } else {
      setweekday(val);
    }
    setselectdayError(false);
  };

  const forexploreteleapp = () => {
    localStorage.removeItem("normalapppopup");
    setnormalappointment(false);
    setforteleornor(2);
    setteleappointment(true);
  };

  const forexploreteleappdone = () => {
    setforteleornor(1);
    setteleappointment(false);
  };
  const handleDropDownSelect = (event, data) => {
    let options;
    if (videochck) {
      options = teleprofile;
    } else {
      options = sourceprofile;
    }
    for (var j = 0; j < options.length; j++) {
      if (options[j].value.includes(data.value)) {
        if (options[j].value == data.value) {
          if (videochck) {
            setbillingid("");
          } else {
            if (
              options[j].pc_bill_profile !== "DEFAULT" &&
              (options[j].pc_bill_profile ?? []).length > 0
            ) {
              setbillingid(options[j].pc_bill_profile);
            } else {
              setbillingid("");
            }
          }
          var filter_pc_duration = options[j].pc_duration;
          if (options[j].pc_duration !== "" || options[j].pc_duration !== "0") {
            let outTimeVal = moment(inTime, "h:mm A").add(
              options[j].pc_duration,
              "minutes"
            );
            setOutTime(moment(outTimeVal).format("h:mm A"));
          }
        }
      }
    }

    settimelimiterrorbox();
    setInTimeErr();
    setprofileid(data.value);
    setEditing(filter_pc_duration);
    setprofileError(false);
    setBillingError(false);
  };

  const getRoom = async (id) => {
    await axiosInstance
      .get("/vozonew/facilityRoom?id=" + id)
      .then((res) => {
        let decode = Decrypt_Value(res.data, "vozo");
        setRoomList(decode.examRoom);
      })
      .catch((err) => {});
  };

  var i = 0;
  const setRoomList = (val) => {
    setSourceRoom([]);

    i = parseInt(val);
    for (let j = 1; j <= i; j++) {
      let obj = {
        id: "" + j,
        text: "Exam Room " + j,
        title: "Exam Room " + j,
        value: "" + j,
      };
      // test build end

      setSourceRoom((item) => [...item, obj]);
    }
    // setRoomVal("");
  };

  const handleDropDownSelectOffice = (event, data) => {
    setproviderid("");
    setSpeciality("");
    setofficeid(data.value);
    setofficeError(false);
    getRoom(data.value);
  };

  const changeSpeciality = (data, from = "") => {
    if (from === "") {
      setproviderid("");
    }
    setSpeciality(data);
    setSpecialError(false);
    setInTimeErr(false);
  };

  const handleDropDownSelectStatus = (event, data) => {
    setstatusid(data.value);
    setstatusError(false);
  };

  const setSpecialityByusers = (value) => {
    axiosInstance
      .get("/vozo/getspeciality/users?id=" + value)
      .then((res) => {
        let temp_role_spec_user = Decrypt_Value(res.data, "vozo");

        changeSpeciality(temp_role_spec_user.specialty, "fromProv");
      })
      .catch(() => {});
  };

  const handleDropDownSelectProvider = (event, data) => {
    if (provError) {
      setprovError(false);
    }
    setproviderid(data.value);
    setprovError(false);
    setInTimeErr(false);
    setSpecialityByusers(data.value);
  };

  const handleBillingFacility = (event, data) => {
    setBillFacilityID(data.value);
    setBillFacilityError(false);
  };

  const handleDropDownSelectPatient = (event, data) => {
    setsearch1(data.title);
    setpatientid(data.value);
    setpatError(false);
    if (accountType > "0" || (accountType === "0" && trial)) {
      axiosInstance
        .get("/vozo/getpatient/flags?pid=" + data.value)
        .then((res) => {
          let temp_role_pat_flag = Decrypt_Value(res.data, "vozo");
          setPatFlag(temp_role_pat_flag);
        })
        .catch(() => {
          setPatFlag([]);
        });
    }
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const walkinclick = () => {
    setwalkincheck(!walkincheck);
  };

  const onChangeRadio = (e) => {
    if (e === 2) {
      e = { target: { value: 2 } };
    }

    if (!storedNames.appt_a && telehealth[0]?.value != officeid) {
      setAppttype(1);
      setvideochck(false);
      alert("Provider's facility is not a telehealth facility");
    } else {
      setAppttype(e.target.value);
      if (e.target.value == 2) {
        setaptdate(moment().format(dtFormat));
        //check selected times
        let t1 = inTime.split(" ");
        let t2 = outTime.split(" ");

        let from = disabledFromTimes(t1[0], t1[1], e.target.value);
        let to = disabledToTime(t2[0], t2[1], fromId, e.target.value);

        if (!from) {
          let intime = moment(start);
          let now = moment();

          if (intime.isAfter(now)) {
            if (props.view === "month") {
              let intime = moment(props.startTime1, "H").format("h:mm A");
              setInTime(intime);

              let outtime = moment(intime, "h:mm A")
                .add(15, "minutes")
                .format("h:mm A");
              setOutTime(outtime);

              let dur = moment(outtime, "h:mm A").diff(
                moment(intime, "h:mm A"),
                "minutes"
              );
              setEditing(dur);
            } else {
              let intime = now.format("h:mm A");
              setInTime(intime);

              let out = moment(intime, "h:mm A")
                .add(15, "minutes")
                .format("h:mm A");
              setOutTime(out);
              let dur = moment(out, "hh:mm a").diff(
                moment(intime, "hh:mm a"),
                "minutes"
              );
              setEditing(dur);
            }
          }
        } else {
          let now = moment();

          let intime = now.format("h:mm A");

          let checktime = moment(aptdate, dtFormatsec);

          if (checktime.isBefore(now.format(dtFormat))) {
            setInTime("");
            setOutTime("");
            setEditing("");
          } else {
            let out = moment(
              moment(now.format("h:mm A"), "h:mm A").add(15, "minutes")
            ).format("h:mm A");

            setInTime(intime);
            setOutTime(out);
            let dur = moment(out, "hh:mm a").diff(
              moment(now, "hh:mm a"),
              "minutes"
            );
            setEditing(dur);
            setapttime(intime);
          }
        }
        // setofficeid(telehealth[0] ? telehealth[0].value : "");
        setprofileError(false);
        //  setEditing(teleprofile[0] ? teleprofile[0].pc_duration : "");
        setstatusid("");
        setbillingid("");
        setprofileid("");
      } else if (e.target.value == 1) {
        let now = moment();

        let intime = moment(start).format("h:mm A");
        setInTime(moment(start).format("h:mm A"));

        let checktime = moment(aptdate, dtFormatsec);
        if (checktime.isBefore(now.format(dtFormat))) {
          setInTime("");
          setOutTime("");
          setEditing("");
        } else {
          if (props.view === "month") {
            let inmonth = moment(props.startTime1, "H").format("h:mm A");
            setInTime(moment(props.startTime1, "H").format("h:mm A"));

            let out = moment(
              moment(props.startTime1, "H").add(15, "minutes")
            ).format("h:mm A");
            setOutTime(out);
            let dur = moment(out, "hh:mm a").diff(
              moment(inmonth, "hh:mm a"),
              "minutes"
            );
            setEditing(dur);
            setapttime(inmonth);
          } else {
            let out = moment(
              moment(intime, "h:mm A").add(15, "minutes")
            ).format("h:mm A");
            setInTime(intime);
            setOutTime(out);
            let dur = moment(out, "hh:mm a").diff(
              moment(now, "hh:mm a"),
              "minutes"
            );
            setEditing(dur);
            setapttime(intime);
          }
        }
        let ofc_firstId = "";
        if (props.officeId) {
          const idArray = props.officeId.split(",");
          ofc_firstId = idArray[0];
        }
        setofficeid(ofc_firstId);
        setprofileid("");
        setstatusid("");
        setbillingid("");
      }
    }
  };

  const checkvideovisit = () => {
    const currentTime = moment();
    if ((accountType === "1" && teleFlag === "0") || accountType === "-1") {
      setvideochck(false);
    } else {
      if (Appttype !== 3) {
        setproviderid("");
      }
      setvideochck(true);
      if (props.view === "month") {
        let intime = moment(props.startTime1, "H").format("h:mm A");
        setInTime(intime);

        let outtime = moment(intime, "h:mm A")
          .add(15, "minutes")
          .format("h:mm A");
        setOutTime(outtime);

        let dur = moment(outtime, "h:mm A").diff(
          moment(intime, "h:mm A"),
          "minutes"
        );
        setEditing(dur);
      } else {
        setInTime(currentTime.format("h:mm A"));
        setOutTime(moment(currentTime.add(15, "minutes")).format("h:mm A"));
      }
    }
  };

  const checkapt = () => {
    if (Appttype !== 3) {
      setproviderid("");
    }
    setvideochck(false);
  };

  const handleRoomVal = (data) => {
    setRoomVal(data);
    setexamroomError(false);
  };

  const handleSelect2 = (id, val) => {
    setOccurence("1");
    if (id === "new-app-select1") {
      setrepeatselectnew(val);
      seteverydayError(false);
    } else {
      setweekday("");
      if (val === "1") {
        setrepeatselect2new("1");
        if (
          repeatselectnew === "4" ||
          repeatselectnew === "5" ||
          repeatselectnew === "6"
        ) {
          setrepeatselectnew("");
        }

        setdayswise(true);
        setswict_check(true);

        setWeek_and_every(true);
        setswict_check_mnth(false);
      } else if (val === "2") {
        setrepeatselect2new("2");
        if (repeatselectnew === "5" || repeatselectnew === "6") {
          setrepeatselectnew("");
        }
        setswict_check_mnth(true);
        setswict_check(false);

        setdayswise(false);
        setWeek_and_every(false);
      } else if (val === "3") {
        setrepeatselect2new("3");
        setswict_check_mnth(false);
        setswict_check(false);

        setdayswise(false);
        setWeek_and_every(false);
      } else {
        setswict_check(false);

        setdayswise(false);
        setWeek_and_every(false);
        setswict_check_mnth(false);
      }
      setrepeatselect2new(val);
    }
  };

  const getDatas = async () => {
    let Data_frsh_doc =
      "authorized=" +
      authorized +
      "&main_pro=" +
      main_pro +
      "&group_id=" +
      group_id;
    let Data_enc_doc = Encrypt_Value(Data_frsh_doc, "vozo");
    await axiosInstance
      .get("/vozonew/facility?search=" + Data_enc_doc)
      .then((response) => {
        let decryptval = Decrypt_Value(response.data, "vozo");
        setSourceoffice(decryptval);
      })
      .catch((err) => console.error(err));
    if (!props.pid) {
      let alle_data =
        "limit=40&authorized=" +
        authorized +
        "&main_pro=" +
        main_pro +
        "&userID=" +
        userID +
        "&pat_share=" +
        storedNames.pat_share +
        "&owner_id=" +
        owner_id +
        "&activepatlist=" +
        1;

      let alle_data_enc = Encrypt_Value(alle_data, "vozo");

      await axiosInstance
        .get("/vozo/patientsearch?search=" + alle_data_enc)
        .then((response) => {
          let temp_role = Decrypt_Value(response.data, "vozo");

          setSource(temp_role);
        })
        .catch(() => {
          setSource([]);
        });
    }

    await axiosInstance
      .get("/vozo/appstatus/category")
      .then((response) => {
        let temp_role_cat = Decrypt_Value(response.data, "vozo");
        setSourcestatus(temp_role_cat[0]);
        setSourcestatustele(temp_role_cat[1]);
        setSourceprofile(temp_role_cat[2]);
        setteleprofile(temp_role_cat[2]);
        setBillingProfile(temp_role_cat[4] ?? []);
        setSpinnerVal(false);
      })
      .catch((err) => console.error(err));

    await axiosInstance
      .get("/vozonew/facility/telehealth")
      .then((response) => {
        let temp_role_tele_cat = Decrypt_Value(response.data, "vozo");
        settelehealth(temp_role_tele_cat);
      })
      .catch((err) => console.error(err));

    if (props.pid !== undefined) {
      setpatientid(props.pid);
    }
    if (props.pid !== undefined) {
      await axiosInstance
        .get("/vozonew/patient?pid=" + props.pid)
        .then((response) => {
          let temp_role_pat_flag = Decrypt_Value(response.data, "vozo");

          let alle_data =
            "limit=40&fname=" +
            temp_role_pat_flag.patient_name.toLowerCase() +
            "&authorized=" +
            authorized +
            "&main_pro=" +
            main_pro +
            "&userID=" +
            userID +
            "&pat_share=" +
            storedNames.pat_share +
            "&owner_id=" +
            owner_id +
            "&activepatlist=" +
            1;

          let alle_data_enc = Encrypt_Value(alle_data, "vozo");

          axiosInstance
            .get("/vozo/patientsearch?search=" + alle_data_enc)
            .then((response) => {
              let temp_role = Decrypt_Value(response.data, "vozo");

              setsearch1(temp_role[0].name);
              setSource(temp_role);
            })
            .catch(() => {
              setSource([]);
              setsearch1("");
            });

          setpatientid(temp_role_pat_flag.pid);
        })
        .catch((err) => console.error(err));

      await axiosInstance
        .get("/vozo/getpatient/flags?pid=" + props.pid)
        .then((res) => {
          let temp_role_pat_flag = Decrypt_Value(res.data, "vozo");
          setPatFlag(temp_role_pat_flag);
        })
        .catch(() => {
          setPatFlag([]);
        });
    }
  };

  const handlecloseUpgrade = () => setUpgrade(false);

  const modalhide = () => {
    setModalAlerShow1(false);
    setModalMsg1("");
  };

  const generateDateArray = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dateArray = [];

    while (start <= end) {
      dateArray.push(
        `${start.getFullYear()}-${String(start.getMonth() + 1).padStart(
          2,
          "0"
        )}-${String(start.getDate()).padStart(2, "0")}`
      );
      start.setDate(start.getDate() + 1); // Increment date by 1 day
    }

    return dateArray;
  };

  const generateSpecificWeekdayArray = (startDate, endDate, weekdays) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dateArray = [];
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    while (start <= end) {
      const dayIndex = start.getDay(); // Get the day index (0-Sunday, 1-Monday, etc.)
      if (weekdays.includes(dayIndex.toString())) {
        // Check if the current day matches the desired weekdays
        dateArray.push({
          date: `${start.getFullYear()}-${String(start.getMonth() + 1).padStart(
            2,
            "0"
          )}-${String(start.getDate()).padStart(2, "0")}`,
          day: dayNames[dayIndex],
        });
      }
      start.setDate(start.getDate() + 1); // Increment date by 1 day
    }

    return dateArray;
  };

  const handleFromTime = () => {
    if (fromShow === false) {
      setFromShow(true);
      setToShow(false);
      setTempOpen(true);
    } else {
      setFromShow(false);
    }
  };
  const handleToTime = () => {
    if (toShow === false) {
      setToShow(true);
      setFromShow(false);
      setTempOpen(true);
    } else {
      setToShow(false);
    }
  };
  const handleFromButton = (e, index) => {
    e.preventDefault();
    if (fromShow === true || toShow === true) {
      setFromShow(false);
      setToShow(false);
    }
  };
  const handleToButton = (e, index) => {
    e.preventDefault();
    if (fromShow === true || toShow === true) {
      setFromShow(false);
      setToShow(false);
    }
  };

  // nisha end //

  window.onclick = function (e) {
    if (fromShow && tempOpen === false) {
      setFromShow(false);
    } else if (toShow && tempOpen === false) {
      setToShow(false);
    }
  };
  var regexFirstNum = /^[a-zA-Z].*/;

  const handleAddPatient = (e) => {
    setTemShow(false);
    setShow(true);
  };
  const handleSubmit = (e) => {
    if (changeTab === "1") {
      buttonClickActions(e);
    } else {
      dispatch(SetPatientBool(true));
    }
  };
  // ------------------------------ Functions End--------------------------------------------------- //

  // ------------------------------ useEffect Start--------------------------------------------------- //
  useEffect(() => {
    if (aptdate !== "") {
      const formattedApptDate = moment(aptdate, dtFormat);
      const newDate = formattedApptDate.clone().add(1, "days");
      setAftDate(newDate.format(dtFormat));
      setOnDate(newDate.format(dtFormat));
      setLastdateApp(newDate.format(dtFormat));
    }
  }, [aptdate]);

  useEffect(() => {
    if (props.defaultTele === true) {
      onChangeRadio(2);
      checkvideovisit();
      props.handlesetDefaultTele();
    }
  }, [props.defaultTele]);

  useEffect(() => {
    if (props.ApptTab === "2") {
      if (props.start.Id !== undefined && props.start.Id !== "0") {
        const pc_eventDateW = props.start.pc_eventDate;
        const pc_endDateW = props.start.pc_endDate;

        const id = props.start.Id;
        const recType = props.start.pc_recurrtype;
        let repDates;
        if (recType !== "0") {
          //for weekly START
          if (props.start.customDate && props.start.customDate.length > 0) {
            const weekdays = props.start.customDate; // Monday, Wednesday, Friday (1-based mapping)

            const resultDates = generateSpecificWeekdayArray(
              pc_eventDateW,
              pc_endDateW,
              weekdays
            );
            repDates = resultDates;
          } else {
            const Fudates = generateDateArray(pc_eventDateW, pc_endDateW);
            repDates = Fudates;
          }
        }

        axiosInstance
          .get("/vozo/getappointmenttemplate?id=" + props.start.Id)
          .then((response) => {
            let temp_role = Decrypt_Value(response.data, "vozo");

            var currdate = moment().format("YYYY-MM-DD");
            const futureDates =
              repDates && repDates.length > 0
                ? repDates.filter((date) =>
                    moment(date).isSameOrAfter(currdate)
                  )
                : [];

            temp_role.futureDates = futureDates[0];
            temp_role.futureDatesall = futureDates;
            temp_role.repDates = repDates;
            temp_role.recType = recType;

            setSingleRow(temp_role);
          });
      } else {
        setSingleRow([]);
      }
      props.handlesetDefaultTele();
    }
  }, [props.ApptTab]);

  useEffect(() => {
    axiosInstance
      .get("/vozo/telehealth/getPlatform")
      .then((response) => {
        let decodingResponse = Decrypt_Value(response.data, "vozo");
        setTelehealthplatform(decodingResponse);
      })
      .catch((err) => {
        setTelehealthplatform("zoom");
      });
  }, []);
  useEffect(() => {
    const getWeeklyData = async () => {
      await axiosInstance
        .get("/vozo/calendarsettingdata")
        .then((response) => {
          let decodingResponse = Decrypt_Value(response.data, "vozo");
          setWeeklyDisable(decodingResponse[1].gl_value);
        })
        .catch(() => {});
    };

    const getAvailability = async () => {
      await axiosInstance
        .get("/vozo/settingdocumentdataAvailability")
        .then((response) => {
          let temp_role_setng_doc_data = Decrypt_Value(response.data, "vozo");

          setswitchvalue(
            temp_role_setng_doc_data[0].gl_value === "1" ? true : false
          );
        })
        .catch((err) => console.error(err));
    };
    getAvailability();
    getWeeklyData();
  }, []);

  useEffect(() => {
    let ofc_firstId = "";
    if (props.officeId) {
      const idArray = props.officeId.split(",");
      ofc_firstId = idArray[0];
    }
    if (providerid !== "" && providerid !== null && providerid !== undefined) {
      axiosInstance.get("/sprovider?uid=" + providerid).then((response) => {
        const data = Decrypt_Value(response.data, "vozo");
        setAllowAppt(Number(data.appt_type));
        if (Number(data.appt_type) === 3 && Appttype !== 2) {
          setofficeid(ofc_firstId);
          setAppttype(1);
          setvideochck(false);
          // setprofileid("");
          if (props.type !== "book again") {
            setstatusid("");
          }
          setbillingid("");
          let dur = moment(outTime, "hh:mm a").diff(
            moment(inTime, "hh:mm a"),
            "minutes"
          );
          setEditing(dur);
        } else if (Number(data.appt_type) === 2) {
          setAppttype(2);
          let t1 = inTime.split(" ");
          let t2 = outTime.split(" ");

          let from = disabledFromTimes(t1[0], t1[1], 2);

          if (!from) {
            let intime = moment(start);
            let now = moment();

            if (intime.isAfter(now)) {
              setInTime(moment(start).format("h:mm A"));
              let intime = moment(start).format("h:mm A");
              let out = moment(
                moment(start, "h:mm A").add(15, "minutes")
              ).format("h:mm A");
              setOutTime(out);
              let dur = moment(out, "hh:mm a").diff(
                moment(intime, "hh:mm a"),
                "minutes"
              );
              setEditing(dur);
            }
          } else {
            let now = moment();

            let intime = now.format("h:mm A");

            let checktime = moment(aptdate, dtFormatsec);

            if (checktime.isBefore(now.format(dtFormat))) {
              setInTime("");
              setOutTime("");
              setEditing("");
            } else {
              let out = moment(
                moment(now.format("h:mm A"), "h:mm A").add(15, "minutes")
              ).format("h:mm A");

              setInTime(intime);
              setOutTime(out);
              let dur = moment(out, "hh:mm a").diff(
                moment(now, "hh:mm a"),
                "minutes"
              );
              setEditing(dur);
              setapttime(intime);
            }
          }

          setprofileError(false);
          // setEditing(teleprofile[0] ? teleprofile[0].pc_duration : "");
          if (props.type !== "book again") {
            setstatusid("");
          }
          setbillingid("");
          setprofileid("");
          setvideochck(true);
        } else if (Number(data.appt_type) === 1) {
          setofficeid(ofc_firstId);
          setAppttype(1);
          setvideochck(false);
          setprofileid("");
          if (props.type !== "book again") {
            setstatusid("");
          }
          setbillingid("");
        }
      });
    }
  }, [providerid, teleprofile]);

  useEffect(() => {
    const tempIntime = moment(start).format("h:mm A");
    if (props.view === "month") {
      let intime = moment(props.startTime1, "H").format("h:mm A");
      setInTime(intime);

      let outtime = moment(intime, "h:mm A")
        .add(15, "minutes")
        .format("h:mm A");
      setOutTime(outtime);

      let dur = moment(outtime, "h:mm A").diff(
        moment(intime, "h:mm A"),
        "minutes"
      );
      setEditing(dur);
    } else {
      setInTime(moment(start).format("h:mm A"));
      const tempIntime = moment(start).format("h:mm A");
      setOutTime(
        moment(moment(tempIntime, "h:mm A").add(15, "minutes")).format("h:mm A")
      );
    }

    const intimeSplit = tempIntime.split(" ");
    const intimeComp = moment(intimeSplit[0], "h:mm A").format("h:mm A");

    getRoom(props.officeId);
    if (props.type !== "book again") {
      setaptdate(moment(start).format(dtFormat));
    }
    setLastdateApp(moment(start).format(dtFormat));

    setapttime(moment(start).format("h:mm A"));
    setappfromdate(moment(start).format("YYYY-MM-DD"));
    setapptodate(moment(start).format("YYYY-MM-DD"));
    setproviderid(
      props.start === undefined
        ? props.provider
        : props.examroom !== true
        ? props.start.resourceId
        : props.provider
    );
    setSpecialityByusers(
      props.start === undefined
        ? props.provider
        : props.examroom !== true
        ? props.start.resourceId
        : props.provider
    );
    setRoomVal(
      props.start === undefined
        ? ""
        : props.examroom === true
        ? props.start.resourceId
        : ""
    );
    let ofc_firstId = "";
    if (props.officeId) {
      const idArray = props.officeId.split(",");
      ofc_firstId = idArray[0];
    }
    setofficeid(ofc_firstId);
    getDatas();
  }, [props.start]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      let data =
        "pat_share=" +
        storedNames.appt_a +
        "&main_pro=" +
        main_pro +
        "&owner_id=" +
        owner_id +
        "&userID=" +
        userID +
        "&authorized=" +
        authorized +
        "&facilityId=" +
        officeid +
        // "&speciality=" +
        // speciality +
        "&apttype=" +
        (Appttype == 1
          ? "Appointment"
          : Appttype == 2
          ? "Telehealth"
          : "Appointment");

      let data1 = Encrypt_Value(data, "vozo");

      let url;
      let user = authorized === "1" ? userID : main_pro;

      if (storedNames.appt_a) {
        url = "/vozonew/provider?search=" + data1;
      } else {
        url = "/vozonew/provider?uid=" + user;
      }

      axiosInstance
        .get(url)
        .then((response) => {
          let temp_role_prov = Decrypt_Value(response.data, "vozo");

          setSourceprovider(temp_role_prov);
          if (!storedNames.appt_a || authorized === "0") {
            setNotDisabledSpec(temp_role_prov[0].specialty);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [officeid, speciality, Appttype]);
  useEffect(() => {
    if (officeid !== "" && officeid !== undefined) {
      axiosInstance
        .get("vozo/speciality/getspeciality?officeid=" + officeid)
        .then((res) => {
          let decryptval = Decrypt_Value(res.data, "vozo");

          const countDict = decryptval.reduce((acc, item) => {
            acc[item.specialty] = item.count;
            return acc;
          }, {});

          const matchedSpecialties = specialityList.map((specialty) => ({
            specialty,
            count: countDict[specialty] || 0,
          }));
          matchedSpecialties.sort((a, b) => b.count - a.count);

          setSortSpecility(matchedSpecialties);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [officeid, speciality]);

  useEffect(() => {
    axiosInstance
      .get("vozo/getBillingFacility")
      .then((res) => {
        // let decryptval = Decrypt_Value(res.data, "vozo");
        setBillFacility(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (patsrchvalue !== "") {
      let isCancelled = false;
      const handlechange = async () => {
        await timeout(1000);

        if (!isCancelled) {
          let alle_data =
            "limit=40&fname=" +
            patsrchvalue.toLowerCase() +
            "&authorized=" +
            authorized +
            "&main_pro=" +
            main_pro +
            "&userID=" +
            userID +
            "&pat_share=" +
            storedNames.pat_share +
            "&owner_id=" +
            owner_id +
            "&activepatlist=" +
            1;

          let alle_data_enc = Encrypt_Value(alle_data, "vozo");

          axiosInstance
            .get("/vozo/patientsearch?search=" + alle_data_enc)
            .then((response) => {
              let temp_role = Decrypt_Value(response.data, "vozo");
              setFetchingOptions(false);
              setSource(temp_role); // get 10 datas from response.data main array
            })
            .catch(() => {
              setSource([]);
            });
        }
      };
      handlechange();
      return () => {
        isCancelled = true;
      };
    }
  }, [patsrchvalue]);

  useEffect(() => {
    setBillFacId(bill_facility_id);
    axiosInstance
      .get("vozo/calendarsettingdata/main")
      .then((response) => {
        let temp_role_cal = Decrypt_Value(response.data, "vozo");
        setworkDays(temp_role_cal.cal_drop);
        setStartTime(
          temp_role_cal.schedule_start === "0"
            ? "0"
            : temp_role_cal.schedule_start
        );
        setEndTime(
          temp_role_cal.schedule_end === "0" ? "24" : temp_role_cal.schedule_end
        );

        const timeSlots1 = [];
        const timeSlots2 = [];
        let i = 0;
        let j = 0;
        let skipFirstMidnight = true;
        if (
          temp_role_cal.schedule_start === "0" &&
          temp_role_cal.schedule_end === "24"
        ) {
          for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
              let ampm = hour < 12 ? "AM" : "PM";
              let hourFormatted = hour === 0 ? 0 : hour > 12 ? hour - 12 : hour;
              if (hour === 24 && minute === 0) {
                ampm = "AM";
                hourFormatted = 12;
              }
              if (hour === 0) {
                hourFormatted = 12;
              }

              const time = `${hourFormatted
                .toString()
                .padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
              timeSlots1.push({ id: ++i, time: time, meridiem: ampm });
              if (time === "12:00" && ampm === "AM" && skipFirstMidnight) {
                skipFirstMidnight = false;
                continue;
              }
              if (time !== "00:00") {
                timeSlots2.push({ id: ++j, time: time, meridiem: ampm });
              }
              if (hour === 23 && minute === 45) {
                if (
                  timeSlots2[timeSlots2.length - 1].time === "11:45" &&
                  timeSlots2[timeSlots2.length - 1].meridiem === "PM"
                ) {
                  timeSlots2.push({ id: ++j, time: "12:00", meridiem: "AM" });
                }
              }
            }
          }
        } else {
          for (
            let hour = Number(temp_role_cal.schedule_start);
            hour < Number(temp_role_cal.schedule_end);
            hour++
          ) {
            for (let minute = 0; minute < 60; minute += 15) {
              const ampm = hour < 12 ? "AM" : "PM";
              const hourFormatted = hour > 12 ? hour - 12 : hour;
              const time = `${hourFormatted
                .toString()
                .padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
              timeSlots1.push({ id: ++i, time: time, meridiem: ampm });
            }
          }
          const scheduleStart = Number(temp_role_cal.schedule_start);
          const scheduleEnd = Number(temp_role_cal.schedule_end);

          for (let hour = scheduleStart; hour <= scheduleEnd; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
              const isPM = hour >= 12;
              const ampm = isPM ? "PM" : "AM";
              let hourFormatted = hour % 12;
              if (hourFormatted === 0) hourFormatted = 12;
              const time = `${hourFormatted
                .toString()
                .padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;

              timeSlots2.push({ id: ++j, time: time, meridiem: ampm });

              if (hour === scheduleEnd && minute === 0) break;
            }
          }
        }
        setFromTimeSlots(timeSlots1);
        var frSlotTmId = timeSlots1
          .filter((ele) => {
            return (
              moment(ele.time + ele.meridiem, "HH:mm A").format("HH:mm A") >=
              moment(start).format("HH:mm A")
            );
          })
          .map((ele) => {
            return ele.id;
          });

        setFromId(frSlotTmId.slice(0, 1));
        setToTimeSlots(timeSlots2);

        if (temp_role_cal.schedule_end >= 12) {
          if (temp_role_cal.schedule_end === "24") {
            setEndErrTime("12:00 AM");
          } else if (temp_role_cal.schedule_end !== "12")
            setEndErrTime(temp_role_cal.schedule_end - 12 + ":00 PM");
          else setEndErrTime("12:00 PM");
        } else {
          setEndErrTime(temp_role_cal.schedule_end + ":00 AM");
        }
        if (temp_role_cal.schedule_start >= 12) {
          if (temp_role_cal.schedule_start !== "12")
            setStartErrTime(temp_role_cal.schedule_start - 12 + ":00 PM");
          else setStartErrTime("12:00 PM");
        } else {
          setStartErrTime(temp_role_cal.schedule_start + ":00 AM");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [bill_facility_id, props.start]);

  useEffect(() => {
    const week_day = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = new Date(moment(aptdate, dtFormat).format("YYYY-MM-DD"));
    setDay(week_day[day.getDay()]);

    setMonth(month[day.getMonth()]);

    setDyOfMont(day.getDate());

    const weekNum = Math.ceil(day.getDate() / 7);
    if (weekNum == 1) {
      setWkOfMont("First");
    } else if (weekNum == 2) {
      setWkOfMont("Second");
    } else if (weekNum == 3) {
      setWkOfMont("Third");
    } else if (weekNum == 4) {
      setWkOfMont("Fourth");
    } else {
      setWkOfMont("Fifth");
    }
  }, [aptdate, repeatselectmonth]);
  useEffect(() => {
    if (repeatselect2new === "0") {
      if (repeatselectnew === "1") {
        setOccurenceday(365);
      } else if (repeatselectnew === "2") {
        setOccurenceday(182);
      } else if (repeatselectnew === "3") {
        setOccurenceday(120);
      } else if (repeatselectnew === "4") {
        setOccurenceday(91);
      } else if (repeatselectnew === "5") {
        setOccurenceday(73);
      }
    } else if (repeatselect2new === "1") {
      if (repeatselectnew === "1") {
        setOccurenceday(52);
      } else if (repeatselectnew === "2") {
        setOccurenceday(26);
      } else if (repeatselectnew === "3") {
        setOccurenceday(13);
      }
    } else if (repeatselect2new === "2") {
      if (repeatselectnew === "1") {
        setOccurenceday(12);
      } else if (repeatselectnew === "2") {
        setOccurenceday(6);
      } else if (repeatselectnew === "3") {
        setOccurenceday(4);
      } else if (repeatselectnew === "4") {
        setOccurenceday(3);
      }
    }
    if (occurenceday < occurence) {
      setOccurencedayErr(true);
    } else {
      setOccurencedayErr(false);
    }
  }, [handleSelect2]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (props.type === "book again") {
          const data = await props.getAppointmentData();
          dispatch(setBookingData(data));
          // setBillFacilityID(data.name)
        }
      } catch (error) {
        console.error("Error fetching data in useEffect:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (props.type === "book again") {
      dataForBookAgain(bookingData);
    }
  }, [teleprofile, sourceprofile, bookingData]);

  // ------------------------------ useEffect----end---------------------------------------------------- //
  return (
    <>
      {/* changes by sridar */}

      <Modal
        className="client-popup topcreate_popup_centre wid_562"
        centered
        show={show}
        onHide={() => {
          setShow(false);
          setTemShow(true);
        }}
      >
        <CreateClient
          setTemShow={setTemShow}
          clientShow={setShow}
          dataFetch={() => console.log("dummy function")}
          ClientAdd={ClientAdd}
        />
      </Modal>

      <Modal className="alert-popup-message" show={clientAddIs}>
        <div className="alert msg alert-success" role="alert">
          <BsCheckCircle className="icons-bs" /> Successfully added...
        </div>
      </Modal>

      {/* ...................... */}
      <div style={{ display: temShow ? "block" : "none" }}>
        <Modal.Header
          closeButton
          className={
            props.type == "book again"
              ? "bookAgain_bottom_border"
              : "new_appointment_pop_topHeading "
          }
        >
          <Modal.Title className="table-top ">
            {props.type == "book again" ? "Book Again" : "Schedule Appointment"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="c-pop New-App_Mod-Body" id="new-appts">
          {SpinnerVal ? (
            <SpinnerDiv height="432px">
              <Spinner animation="border" variant="primary" />
            </SpinnerDiv>
          ) : (
            <>
              {props.type !== "book again" ? (
                <Tabs
                  activeKey={changeTab}
                  className="tabheader for_add_app_avail"
                  onChange={(val) => setChangeTag(val)}
                >
                  <TabPane
                    tab="New Appointment"
                    key="1"
                    className="appt-dash-hed p_l_10"
                  >
                    <Div className="c-pop-one " margin="20px 0px 0px -20px">
                      <Form.Row className="app-block  antd-select-height1">
                        {accountType > "0" || (accountType === "0" && trial) ? (
                          <>
                            {patFlag.map((item, ind) => (
                              <Div
                                key={ind}
                                className="new_appt_flag pt-fg-app-new"
                                style={{ backgroundColor: item.flag_color }}
                              >
                                <IoFlagOutline className="new_appt_flag_icon" />
                                <span className="new_appt_flag_amt">{`${item.flag_name} : ${item.description}`}</span>
                              </Div>
                            ))}
                          </>
                        ) : (
                          ""
                        )}
                        <div className="d_flex">
                          <div className="m_l_10">
                            <Div
                              alignItems="center"
                              className="search-pat  wholediv patient_name_out"
                            >
                              <Div margin="-20px 0px 0px -2px">
                                <PeopleOutline className="people" />
                              </Div>
                              <Div className="dropdiv " id="PatId">
                                {/* changes by sridar */}
                                <Select
                                  showSearch
                                  placeholder="Patient Name"
                                  id="new-app-patient_1 appt-inp-patsrch"
                                  suffixIcon={<BsFillCaretDownFill />}
                                  className={
                                    patError
                                      ? "err_mess_border w-100 b1"
                                      : "w-100"
                                  }
                                  onSearch={(value) => {
                                    setpatsrchvalue(value);
                                    setFetchingOptions(true);
                                  }}
                                  onChange={(e, data) =>
                                    handleDropDownSelectPatient(e, data)
                                  }
                                  optionFilterProp="children"
                                  filterOption={(input, option) => {
                                    const searchValue = input
                                      .toLowerCase()
                                      .trim()
                                      .replace(/[^\d]/g, "");
                                    const searchValue1 = input
                                      .toLowerCase()
                                      .trim()
                                      .replace(/[^a-z0-9@.]/g, "");
                                    const {
                                      title,
                                      phone_cell,
                                      ss,
                                      email,
                                      dob,
                                    } = option.props;
                                    const dobFormatted = dob
                                      ? moment(dob).format("MMDDYYYY")
                                      : "";
                                    const dobMMDD = dobFormatted.substring(
                                      0,
                                      4
                                    );
                                    const normalizedPhoneCell = phone_cell
                                      ?.trim()
                                      .replace(/[^\d]/g, "");
                                    const normalizedss = ss
                                      ?.toLowerCase()
                                      .trim()
                                      .replace(/[^\d]/g, "");
                                    const normalizedtitle = title
                                      ?.toLowerCase()
                                      .trim();
                                    const normalizedemail = email
                                      ?.toLowerCase()
                                      .trim();

                                    if (searchValue.length === 0) return true;

                                    if (searchValue.length <= 2) {
                                      return (
                                        normalizedPhoneCell?.startsWith(
                                          searchValue
                                        ) ||
                                        dobFormatted.startsWith(searchValue) ||
                                        normalizedss
                                          ?.toLowerCase()
                                          .includes(searchValue) ||
                                        normalizedemail
                                          ?.toLowerCase()
                                          .includes(searchValue1)
                                      );
                                    } else if (searchValue.length <= 4) {
                                      return (
                                        normalizedPhoneCell?.startsWith(
                                          searchValue
                                        ) ||
                                        dobMMDD === searchValue ||
                                        dobFormatted.substring(0, 4) ===
                                          searchValue ||
                                        normalizedss
                                          ?.toLowerCase()
                                          .includes(searchValue) ||
                                        normalizedemail
                                          ?.toLowerCase()
                                          .includes(searchValue1)
                                      );
                                    } else if (searchValue.length <= 8) {
                                      return (
                                        normalizedPhoneCell?.startsWith(
                                          searchValue
                                        ) ||
                                        dobFormatted === searchValue ||
                                        normalizedss
                                          ?.toLowerCase()
                                          .includes(searchValue) ||
                                        normalizedemail
                                          ?.toLowerCase()
                                          .includes(searchValue1)
                                      );
                                    }

                                    return (
                                      (normalizedtitle ?? "")
                                        .toLowerCase()
                                        .includes(searchValue) ||
                                      normalizedPhoneCell.includes(
                                        searchValue
                                      ) ||
                                      (normalizedss ??
                                        ""
                                          .toLowerCase()
                                          .includes(searchValue)) ||
                                      (normalizedemail ??
                                        "".includes(searchValue1.toLowerCase()))
                                    );
                                  }}
                                  notFoundContent={
                                    fetchingOptions
                                      ? "Loading..."
                                      : "No Data found"
                                  }
                                  value={patientid ? search1 : undefined}
                                  getPopupContainer={(trigger) => document.body}
                                  dropdownAlign={{
                                    points: ["tl", "bl"],
                                    offset: [0, 4],
                                  }}
                                  dropdownStyle={{
                                    zIndex: 1050,
                                  }}
                                  dropdownRender={(menu) => (
                                    <>
                                      {menu}
                                      <StyledDiv>
                                        <StyledButton
                                          onClick={() => {
                                            setTemShow(false);
                                            setShow(true);
                                          }}
                                          onMouseDown={(e) =>
                                            e.preventDefault()
                                          }
                                          // onClick={handleAddPatient}
                                        >
                                          <StyledSvg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                          >
                                            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" />
                                          </StyledSvg>
                                          <span onClick={handleAddPatient}>
                                            Add Patient
                                          </span>
                                        </StyledButton>
                                      </StyledDiv>
                                    </>
                                  )}
                                >
                                  {source.map((i) => {
                                    const titleLength = i.text.length;

                                    return (
                                      <Option
                                        key={i.value}
                                        value={i.value}
                                        title={i.text}
                                        phone_cell={i.phone_cell}
                                        dob={i.dob}
                                        ss={i.ss}
                                        email={i.email}
                                      >
                                        <div>
                                          {titleLength < 15 ? (
                                            <>
                                              {i.text} -{" "}
                                              {i.dob
                                                ? moment(i.dob).format(dtFormat)
                                                : " "}
                                            </>
                                          ) : (
                                            <>
                                              <div>{i.text} -</div>
                                              <div>
                                                {i.dob
                                                  ? moment(i.dob).format(
                                                      dtFormat
                                                    )
                                                  : " "}
                                              </div>
                                            </>
                                          )}
                                        </div>
                                        <div>{i.phone_cell}</div>
                                      </Option>
                                    );
                                  })}
                                </Select>
                                {/* ..................... */}

                                {patError && (
                                  <p className="errormessage">
                                    Please select Patient
                                  </p>
                                )}
                              </Div>
                            </Div>

                            <Div
                              alignItems="center"
                              className="appointment_poup_div appointment_poup_div_w"
                            >
                              <Div
                                id="new_appt_radio"
                                className="appt-spn-vdovis new_app_radio_group"
                              >
                                <Radio.Group
                                  onChange={
                                    accountType === "-1" ||
                                    (accountType === "1" && teleFlag === "0")
                                      ? (e) => {
                                          setUpgrade(true);
                                        }
                                      : onChangeRadio
                                  }
                                  value={
                                    forteleornor === 2 ? forteleornor : Appttype
                                  }
                                >
                                  <Radio
                                    className="radio_appt_btn"
                                    value={1}
                                    onChange={checkapt}
                                    disabled={allowAppt === 2}
                                  >
                                    Appointment
                                  </Radio>

                                  <Radio
                                    value={2}
                                    onChange={checkvideovisit}
                                    disabled={allowAppt === 1}
                                  >
                                    Telehealth
                                  </Radio>

                                  <Radio
                                    className="app-dashboard-radioBut"
                                    onClick={walkinclick}
                                    disabled={videochck}
                                    id="new-app-walkin"
                                  >
                                    Walk-in
                                  </Radio>
                                </Radio.Group>
                              </Div>
                            </Div>

                            <Div margin="13px 0 0 0">
                              <Div
                                alignItems="center"
                                className=" alx wholediv new_app_facility"
                              >
                                <Div margin="-20px 0px 0px -2px">
                                  <RoomOutlined />
                                </Div>
                                <Div className="dropdiv" id="officeId">
                                  <Select
                                    showSearch
                                    placeholder="Hospital Name"
                                    id="new-app-facility_1"
                                    suffixIcon={<BsFillCaretDownFill />}
                                    className={
                                      officeError
                                        ? `err_mess_border w-100 to-hide-ant-label ${checkFacility(
                                            "classname"
                                          )}`
                                        : `to-hide-ant-label w-100 ${checkFacility(
                                            "classname"
                                          )}`
                                    }
                                    onChange={(e, data) =>
                                      handleDropDownSelectOffice(e, data)
                                    }
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      (option?.title ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    value={officeid ? officeid : undefined}
                                    getPopupContainer={() =>
                                      document.getElementById("officeId")
                                    }
                                  >
                                    {sourceoffice.map((i) => (
                                      <Option
                                        key={i.value}
                                        value={i.value}
                                        title={i.text}
                                      >
                                        {i.text}
                                      </Option>
                                    ))}
                                  </Select>
                                  {checkFacility("spinner")}
                                  {officeError && (
                                    <p className="errormessage">
                                      Please select Office
                                    </p>
                                  )}
                                </Div>
                              </Div>
                            </Div>

                            <Div
                              alignItems="center"
                              className="search-pat new_app_select_prov appointment_patient_div"
                            >
                              <Div margin="-2px 0px 0px -2px">
                                <PeopleOutline />
                              </Div>
                              <Div className="appointment_drop_div" id="provId">
                                <Select
                                  showSearch
                                  placeholder="Doctor Name"
                                  suffixIcon={<BsFillCaretDownFill />}
                                  id="new-app-provider new-app-provider_1"
                                  className={
                                    provError
                                      ? "err_mess_border w-100 "
                                      : "w-100"
                                  }
                                  onChange={(e, data) =>
                                    handleDropDownSelectProvider(e, data)
                                  }
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    (option?.title ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  value={providerid ? providerid : undefined}
                                  getPopupContainer={() =>
                                    document.getElementById("provId")
                                  }
                                >
                                  {sourceprovider.map((i) => (
                                    <Option
                                      key={i.value}
                                      value={i.value}
                                      title={i.text}
                                    >
                                      {i.text}
                                    </Option>
                                  ))}
                                </Select>
                                {provError && (
                                  <p className="errormessage">
                                    Please select Provider
                                  </p>
                                )}
                              </Div>
                            </Div>

                            <Div
                              className="app_repeat_dropdown app_dummy_class"
                              margin=" 0px 0px 10px 40px"
                            >
                              <BsFillCaretDownFill className="down_arrow_doesnt does-not-repeat-icon-new" />

                              <DropdownButton
                                id="arrow-newAppt"
                                className="app-nav-dropdown-for-newApp-repect "
                                title={repvalue ? repvalue : "Does not repeat"}
                                // title=""
                                onSelect={handlerepSelect}
                                variant="light"
                              >
                                <Dropdownant.Item eventKey="Does not repeat">
                                  Does not repeat
                                </Dropdownant.Item>
                                <Dropdownant.Item
                                  onClick={handleShowuntildate}
                                  eventKey="Daily"
                                >
                                  Daily
                                </Dropdownant.Item>
                                <Dropdownant.Item
                                  onClick={handleShowuntildate}
                                  eventKey={"Week"}
                                >
                                  Week
                                </Dropdownant.Item>
                              </DropdownButton>
                            </Div>

                            {repvalue === "Week" && (
                              <Div margin="5px 4px 14px 40px">
                                <ToggleButtonGroup
                                  type="checkbox"
                                  value={weekday}
                                  className="appt_repeats but-act app_gap "
                                  onChange={WeekdayChange}
                                  style={
                                    selectdayError
                                      ? {
                                          border: "1px solid red",
                                          borderRadius: "5px",
                                        }
                                      : {}
                                  }
                                >
                                  <ToggleButton
                                    disabled={
                                      WeeklyDisable === "Show only weekdays" ||
                                      WeeklyDisable === "Don't show Sundays"
                                        ? true
                                        : false
                                    }
                                    className={
                                      WeeklyDisable === "Show only weekdays" ||
                                      (WeeklyDisable === "Don't show Sundays" &&
                                        "weekDisableBtn")
                                    }
                                    id="tbg-btn-1"
                                    value={1}
                                  >
                                    S
                                  </ToggleButton>
                                  <ToggleButton id="tbg-btn-2" value={2}>
                                    M
                                  </ToggleButton>
                                  <ToggleButton id="tbg-btn-3" value={3}>
                                    T
                                  </ToggleButton>
                                  <ToggleButton id="tbg-btn-3" value={4}>
                                    W
                                  </ToggleButton>
                                  <ToggleButton id="tbg-btn-3" value={5}>
                                    T
                                  </ToggleButton>
                                  <ToggleButton id="tbg-btn-3" value={6}>
                                    F
                                  </ToggleButton>
                                  <ToggleButton
                                    disabled={
                                      WeeklyDisable === "Show only weekdays"
                                        ? true
                                        : false
                                    }
                                    id="tbg-btn-3"
                                    value={7}
                                  >
                                    S
                                  </ToggleButton>
                                </ToggleButtonGroup>

                                {selectdayError && (
                                  <p className="errormessage">
                                    Please select atleast a day
                                  </p>
                                )}
                              </Div>
                            )}

                            {repvalue !== "" &&
                              repvalue !== undefined &&
                              repvalue !== null &&
                              repvalue !== "Does not repeat" &&
                              repvalue !== "Does not repeat " && (
                                <Div margin=" 0px 0px 10px 30px" dis="flex">
                                  <Span
                                    marginRight="3px"
                                    color="#000000 !important"
                                  >
                                    Until:
                                  </Span>
                                  <Div position="relative">
                                    <DatePicker
                                      disabledDate={(current) => {
                                        const oneYearFromNow = moment()
                                          .add(1, "year")
                                          .startOf("day");
                                        return (
                                          current.isBefore(
                                            moment(aptdate, dtFormat),
                                            "day"
                                          ) ||
                                          current.isAfter(oneYearFromNow, "day")
                                        );
                                      }}
                                      onChange={(date) => {
                                        if (date) {
                                          setLastdateApp(
                                            moment(date).format(dtFormat)
                                          );
                                        } else {
                                          setLastdateApp(
                                            moment().format(dtFormat)
                                          );
                                        }
                                      }}
                                      placeholder="From-"
                                      format={dtFormat}
                                      value={
                                        lastdateApp
                                          ? moment(lastdateApp, dtFormat)
                                          : null
                                      }
                                      allowClear={false}
                                    />
                                  </Div>
                                </Div>
                              )}
                          </div>

                          <div>
                            <Div
                              className=" w-100 m_b_12"
                              // id="border2"
                              // onClick={hidehr}
                              // style={{ marginBottom: "12px" }}
                            >
                              <Div
                                className="wholediv"
                                // className="alx p-r-20"
                              >
                                <Div margin="-20px 0px 0px -2px">
                                  <PersonOutline />
                                </Div>
                                <Div className="dropdiv" id="ProfId">
                                  <Select
                                    className={
                                      profileError
                                        ? "err_mess_border w-100"
                                        : "w-100"
                                    }
                                    showSearch
                                    placeholder="Appt Profile"
                                    id="new-app-profile_1 appt-inp-profsrch"
                                    suffixIcon={<BsFillCaretDownFill />}
                                    onChange={(e, data) =>
                                      handleDropDownSelect(e, data)
                                    }
                                    // onBlur={changeborder2}
                                    // onClick={hidehr}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      (option?.title ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    value={profileid ? profileid : undefined}
                                    // getPopupContainer={() =>
                                    //   document.getElementById("ProfId")
                                    // }
                                    getPopupContainer={(trigger) =>
                                      document.body
                                    }
                                    dropdownAlign={{
                                      points: ["tl", "bl"],
                                      offset: [0, 4],
                                    }}
                                    dropdownStyle={{
                                      zIndex: 1050,
                                    }}
                                  >
                                    {sourceprofile.map((i) => (
                                      <Option
                                        key={i.value}
                                        value={i.value}
                                        title={i.text}
                                      >
                                        {i.text}
                                      </Option>
                                    ))}
                                  </Select>

                                  {profileError && (
                                    <p className="errormessage">
                                      Please select Appointment Profile
                                    </p>
                                  )}
                                </Div>
                              </Div>
                            </Div>

                            <Div
                              className="new_app_date"
                              //  style={{ display: "flex", margin: "-19px 0 4px 2px" }}
                            >
                              <Div className="access_icon">
                                <AccessTimeIcon className="people" />
                              </Div>
                              <form
                                className={`${classes.container} frm-apoint`}
                                noValidate
                              >
                                <div
                                  id="crt_appt-time-ok"
                                  className="date-pick date-pick-parent app-datepick"
                                >
                                  <DatePicker
                                    style={{ width: "100px" }}
                                    onChange={(date) => {
                                      if (date) {
                                        setaptdate(
                                          moment(date).format(dtFormat)
                                        );
                                      } else {
                                        setaptdate(moment().format(dtFormat));
                                      }
                                    }}
                                    placeholder="Select date"
                                    format={dtFormat}
                                    value={
                                      aptdate ? moment(aptdate, dtFormat) : null
                                    }
                                    allowClear={false}
                                    disabledDate={
                                      Appttype == 2 &&
                                      ((current) => {
                                        // Disable dates before today
                                        return (
                                          current &&
                                          current < moment().startOf("day")
                                        );
                                      })
                                    }
                                  />

                                  <div className="App-time ">
                                    <div className="showTime">
                                      <input
                                        className="fromTime-toTime-start"
                                        onClick={() => {
                                          handleFromTime();
                                        }}
                                        onMouseLeave={() => {
                                          setTempOpen(false);
                                        }}
                                        value={inTime}
                                        onChange={(e) => {
                                          if (
                                            !regexFirstNum.test(e.target.value)
                                          ) {
                                            setFromShow(false);
                                            setToShow(false);
                                            setInTime(e.target.value);
                                            setTimeout(() => {
                                              setInTime(
                                                moment(
                                                  e.target.value,
                                                  "h:mm A"
                                                ).format("h:mm A")
                                              );
                                              onChange(
                                                moment(
                                                  e.target.value,
                                                  "h:mm A"
                                                ).format("h:mm A"),
                                                "inTime"
                                              );
                                            }, 2000);
                                          }
                                        }}
                                        onBlur={(e) => {
                                          setInTime(
                                            moment(
                                              e.target.value,
                                              "h:mm A"
                                            ).format("h:mm A")
                                          );
                                          onChange(
                                            moment(
                                              e.target.value,
                                              "h:mm A"
                                            ).format("h:mm A"),
                                            "inTime"
                                          );
                                        }}
                                      />
                                      <p className="time--space">-</p>
                                      <input
                                        className="fromTime-toTime"
                                        onClick={() => {
                                          handleToTime();
                                        }}
                                        onChange={(e) => {
                                          if (
                                            !regexFirstNum.test(e.target.value)
                                          ) {
                                            setFromShow(false);
                                            setToShow(false);
                                            setOutTime(e.target.value);
                                            setTimeout(() => {
                                              setOutTime(
                                                moment(
                                                  e.target.value,
                                                  "h:mm A"
                                                ).format("h:mm A")
                                              );
                                              onChange(
                                                moment(
                                                  e.target.value,
                                                  "h:mm A"
                                                ).format("h:mm A"),
                                                "outTime"
                                              );
                                            }, 2000);
                                          }
                                        }}
                                        onMouseLeave={() => {
                                          setTempOpen(false);
                                        }}
                                        onBlur={(e) => {
                                          setOutTime(
                                            moment(
                                              e.target.value,
                                              "h:mm A"
                                            ).format("h:mm A")
                                          );
                                          onChange(
                                            moment(
                                              e.target.value,
                                              "h:mm A"
                                            ).format("h:mm A"),
                                            "outTime"
                                          );
                                        }}
                                        value={outTime}
                                      />
                                      {/* {outTime}
                          </p> */}
                                    </div>

                                    {/* From Button */}
                                    <div
                                      className={
                                        fromShow
                                          ? "fromArray-toArray"
                                          : "fromArray_hide"
                                      }
                                    >
                                      {frtimeSlots.map((item, index) => {
                                        return (
                                          <button
                                            // disabled={toId >= item.id ? true : false}
                                            key={item.id}
                                            value={
                                              item.time + " " + item.meridiem
                                            }
                                            disabled={disabledFromTimes(
                                              item.time,
                                              item.meridiem,
                                              Appttype
                                            )}
                                            onClick={async (e) => {
                                              setInTime(
                                                moment(
                                                  e.target.value,
                                                  "h:mm A"
                                                ).format("h:mm A")
                                              );
                                              await setFromId(item.id);
                                              handleFromButton(e);
                                              onChange(
                                                moment(
                                                  e.target.value,
                                                  "h:mm A"
                                                ).format("h:mm A"),
                                                "inTime"
                                              );
                                            }}
                                          >
                                            {item.time + " " + item.meridiem}
                                          </button>
                                        );
                                      })}
                                    </div>
                                    {/* To button */}
                                    <div
                                      className={
                                        toShow
                                          ? "fromArray-toArray"
                                          : "toArray_hide"
                                      }
                                      style={{ left: 70 }}
                                    >
                                      {totimeSlots.map((item, index) => {
                                        return (
                                          <button
                                            disabled={disabledToTime(
                                              item.time,
                                              item.meridiem,
                                              item.id,
                                              Appttype
                                            )}
                                            key={index}
                                            value={
                                              item.time + " " + item.meridiem
                                            }
                                            onClick={async (e) => {
                                              setOutTime(
                                                moment(
                                                  e.target.value,
                                                  "h:mm A"
                                                ).format("h:mm A")
                                              );

                                              handleToButton(e);
                                              onChange(
                                                moment(
                                                  e.target.value,
                                                  "h:mm A"
                                                ).format("h:mm A"),
                                                "outTime"
                                              );
                                            }}
                                          >
                                            {item.time + " " + item.meridiem}
                                          </button>
                                        );
                                      })}
                                    </div>
                                  </div>
                                  {/* nisha end */}
                                </div>
                              </form>
                            </Div>
                            {inTimeErr && (
                              <Form.Text
                                id="new-app-time-codetext appt-warn-time"
                                className="colour-red p-l-66 m-t-0-imp m-b-3"
                              >
                                Please Select valid Time
                              </Form.Text>
                            )}
                            {timelimiterrorbox && (
                              <Form.Text
                                id="new-app-time-codetext appt-warn-time"
                                className="colour-red p-l-66 m-t-0-imp m-b-3"
                              >
                                Provider Only Available Between {startErrTime}{" "}
                                to {endErrTime}
                              </Form.Text>
                            )}

                            {/* <Div alignItems="center" className="appointment_popup_div"> */}
                            <Div style={{ textAlign: "left" }}>
                              <Popup
                                content={fornormalapp}
                                open={normalappointment}
                                trigger={
                                  <Button
                                    id="click_event_app_normal"
                                    content="Button"
                                  />
                                }
                                position="left center"
                              />
                            </Div>
                            <Div>
                              <Popup
                                content={forteleapp}
                                open={teleappointment}
                                trigger={
                                  <Button
                                    id="click_event_app_tele"
                                    content="Button"
                                  />
                                }
                                position="left center"
                              />
                            </Div>

                            <Div
                              className=" w-100"
                              // id="border2"
                              // onClick={hidehr}
                            >
                              <Div
                                className="wholediv"
                                // className="alx p-r-20"
                              >
                                <Div margin="-20px 0px 0px -2px" size="20px">
                                  <Image
                                    src={doctorLogo}
                                    alt="speciality"
                                    height="20px"
                                    width="20px"
                                  />
                                </Div>

                                <Div className="dropdiv">
                                  <Select
                                    showSearch
                                    placeholder="Select Speciality"
                                    suffixIcon={<BsFillCaretDownFill />}
                                    className={
                                      specialError
                                        ? "err_mess_border w-100"
                                        : "w-100"
                                    }
                                    onChange={(data) => changeSpeciality(data)}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      (option?.title ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    value={speciality ? speciality : undefined}
                                    optionLabelProp="label"
                                    dropdownClassName="speciality-option"
                                  >
                                    {/* {sortSpeciality.length !== 0 &&
                                    sortSpeciality.map((item) => ( */}

                                    {sortSpeciality &&
                                      sortSpeciality
                                        .filter(
                                          (item) =>
                                            (storedNames.appt_a ||
                                              item.specialty ===
                                                notDisabledSpec) &&
                                            item.count > 0
                                        ) // Filter disabled options
                                        .map((item) => (
                                          <Option
                                            key={item.specialty}
                                            value={item.specialty}
                                            title={item.specialty}
                                            disabled={
                                              (!storedNames.appt_a &&
                                                item.specialty !==
                                                  notDisabledSpec) ||
                                              item.count === 0
                                              // : item.count === 0
                                            }
                                            id={
                                              !storedNames.appt_a &&
                                              item.specialty !== notDisabledSpec
                                                ? "clr-disable"
                                                : "" || item.count == 0
                                                ? "clr-disable"
                                                : ""
                                            }
                                            className={
                                              (!storedNames.appt_a &&
                                                item.speciality !==
                                                  notDisabledSpec) ||
                                              item.count === 0
                                            }
                                          >
                                            {item.speciality}
                                          </Option>
                                        ))}
                                  </Select>
                                  {specialError && (
                                    <p className="errormessage">
                                      Select a speciality
                                    </p>
                                  )}
                                </Div>
                              </Div>
                            </Div>

                            <Div className=" w-100" id="border">
                              <Div
                                className="wholediv"
                                // className="alx p-r-20"
                              >
                                <Div margin="-20px 0px 0px -2px">
                                  <LocalHospitalOutlined />
                                </Div>
                                <Div
                                  className={
                                    videochck
                                      ? "dropdiv downDropDown"
                                      : "dropdiv"
                                  }
                                  id="statId"
                                >
                                  <Select
                                    showSearch
                                    placeholder="Status"
                                    id="new-app-status_1 appt-inp-stas"
                                    suffixIcon={<BsFillCaretDownFill />}
                                    // onClick={changeborder}
                                    // className="form-control "
                                    className={
                                      statusError
                                        ? "err_mess_border w-100"
                                        : "w-100"
                                    }
                                    onChange={(e, data) =>
                                      handleDropDownSelectStatus(e, data)
                                    }
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      (option?.title ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    value={statusid ? statusid : undefined}
                                    // getPopupContainer={() =>
                                    //   document.getElementById("statId")
                                    // }
                                    getPopupContainer={(trigger) =>
                                      document.body
                                    }
                                    dropdownAlign={{
                                      points: ["tl", "bl"],
                                      offset: [0, 4],
                                    }}
                                    dropdownStyle={{
                                      zIndex: 1050,
                                    }}
                                  >
                                    {checking1 ? (
                                      <></>
                                    ) : (
                                      <>
                                        {videochck ? (
                                          <>
                                            {sourcestatustele.map((i) => (
                                              <Option
                                                key={i.value}
                                                value={i.value}
                                                title={i.text}
                                              >
                                                {i.text}
                                              </Option>
                                            ))}
                                          </>
                                        ) : (
                                          <>
                                            {sourcestatus.map((i) => (
                                              <Option
                                                key={i.value}
                                                value={i.value}
                                                title={i.text}
                                              >
                                                {i.text}
                                              </Option>
                                            ))}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </Select>
                                  {statusError && (
                                    <p className="errormessage">
                                      Please select Status
                                    </p>
                                  )}
                                </Div>
                              </Div>
                            </Div>

                            <Div
                              className=" w-100"
                              // id="border2"
                              // onClick={hidehr}
                            >
                              <Div
                                className="wholediv "

                                // className="alx p-r-20"
                              >
                                {/* <Div margin="-20px 0px 0px -2px">
                                  <Image
                                    src={BillingFacilityIcon}
                                    alt="bill"
                                    height="20px"
                                    width="20px"
                                  />
                                </Div> */}

                                {/* <Div className="dropdiv" id="billingFac">
                                  <Select
                                    showSearch
                                    placeholder="Billing Facility"
                                    suffixIcon={<BsFillCaretDownFill />}
                                    id="new-app-status_1 appt-inp-stas"
                                    className={
                                      billFacilityError
                                        ? "err_mess_border w-100"
                                        : "w-100"
                                    }
                                    onChange={(e, data) =>
                                      handleBillingFacility(e, data)
                                    }
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      (option?.title ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    value={
                                      billFacilityID
                                        ? billFacilityID
                                        : undefined
                                    }
                                    // getPopupContainer={() =>
                                    //   document.getElementById("billingFac")
                                    // }
                                    getPopupContainer={(trigger) =>
                                      document.body
                                    }
                                    dropdownAlign={{
                                      points: ["tl", "bl"],
                                      offset: [0, 4],
                                    }}
                                    dropdownStyle={{
                                      zIndex: 1050,
                                    }}
                                  >
                                    {billFacility?.map((i) => (
                                      <Option
                                        key={i.value}
                                        value={i.value}
                                        title={i.text}
                                      >
                                        {i.text}
                                      </Option>
                                    ))}
                                  </Select>
                                  {billFacilityError && (
                                    <p className="errormessage">
                                      Please Select the Facility
                                    </p>
                                  )}
                                </Div> */}
                              </Div>

                              {/* 2222nd */}
                              <Div
                                className="wholediv"
                                // className="alx p-r-20"
                              >
                                <Div margin="-20px 0px 0px -2px">
                                  <HomeOutlinedIcon />
                                </Div>
                                <Div className="dropdiv" id="ExaId">
                                  <Select
                                    showSearch
                                    placeholder="Exam Room"
                                    id="new-app-profile_1"
                                    suffixIcon={<BsFillCaretDownFill />}
                                    // onClick={hidehr}
                                    className={
                                      examroomError
                                        ? "err_mess_border w-100"
                                        : "w-100"
                                    }
                                    onChange={(e, data) =>
                                      handleRoomVal(data.value)
                                    }
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      (option?.title ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    value={roomval ? roomval : undefined}
                                    // getPopupContainer={() =>
                                    //   document.getElementById("ExaId")
                                    // }
                                    getPopupContainer={(trigger) =>
                                      document.body
                                    }
                                    dropdownAlign={{
                                      points: ["tl", "bl"],
                                      offset: [0, 4],
                                    }}
                                    dropdownStyle={{
                                      zIndex: 1050,
                                    }}
                                  >
                                    {sourceroom.map((i) => (
                                      <Option
                                        key={i.value}
                                        value={i.value}
                                        title={i.text}
                                      >
                                        {i.text}
                                      </Option>
                                    ))}
                                  </Select>
                                  {examroomError && (
                                    <p className="errormessage">
                                      Please select Exam Room
                                    </p>
                                  )}
                                </Div>
                              </Div>
                            </Div>
                          </div>
                        </div>
                      </Form.Row>
                    </Div>
                  </TabPane>

                  <TabPane
                    tab="Add Availability"
                    key="2"
                    className="appt-dash-hed"
                  >
                    <Div padding="10px 0px" className="avl">
                      <Div>
                        <p>
                          By default, provider availability is set to "In
                          Office," allowing appointments to be scheduled anytime
                          based on calendar hours. To mark unavailable times,
                          enable provider availability and click "Add Provider
                          Availability" to input those periods.
                        </p>
                      </Div>
                      <Div>
                        <div className="Provider_availability_switch_box m_10">
                          <span className="enable_pro_avail">
                            Enable Provider Availability
                          </span>

                          <Switch
                            size="small"
                            className="switch-align-pro"
                            onChange={(checked) => enableSwitch(checked)}
                            checked={switchvalue}
                            id="setting_email_switch"
                          />
                        </div>
                      </Div>
                      {switchvalue && (
                        <Div margin="10px 0px 5px 0px">
                          <AddAppTemplate
                            closepopup={handleRefresh}
                            clientShow={() => AddTemplate()}
                            dataFetch={handleRefresh}
                            id={
                              props.start?.Id !== "" && props.ApptTab === "2"
                                ? props.start?.Id
                                : id
                            }
                            setEid={() => setEid("")}
                            row={singleRow}
                            from={"new_appointment"}
                            where={props.where}
                          />
                        </Div>
                      )}
                    </Div>
                  </TabPane>
                </Tabs>
              ) : (
                //edit appointment
                <Div
                  className="c-pop-one "
                  margin={
                    props.type !== "book again"
                      ? "20px 0px 0px -20px"
                      : "20px 0px 0px 10px"
                  }
                >
                  <Form.Row className="app-block  antd-select-height1">
                    {accountType > "0" || (accountType === "0" && trial) ? (
                      <>
                        {patFlag.map((item, ind) => (
                          <Div
                            key={ind}
                            className="new_appt_flag pt-fg-app-new"
                            style={{ backgroundColor: item.flag_color }}
                          >
                            <IoFlagOutline className="new_appt_flag_icon" />
                            <span className="new_appt_flag_amt">{`${item.flag_name} : ${item.description}`}</span>
                          </Div>
                        ))}
                      </>
                    ) : (
                      ""
                    )}
                    <div className="d_flex">
                      <div className="m_l_10">
                        <Div
                          alignItems="center"
                          className="search-pat  wholediv patient_name_out"
                        >
                          <Div margin="-20px 0px 0px -2px">
                            <PeopleOutline className="people" />
                          </Div>
                          <Div className="dropdiv " id="PatId">
                            {/* changes by sridar */}
                            <Select
                              showSearch
                              placeholder="Patient Name"
                              id="new-app-patient_1 appt-inp-patsrch"
                              suffixIcon={<BsFillCaretDownFill />}
                              className={
                                patError ? "err_mess_border w-100 b1" : "w-100"
                              }
                              onSearch={(value) => {
                                setpatsrchvalue(value);
                                setFetchingOptions(true);
                              }}
                              onChange={(e, data) =>
                                handleDropDownSelectPatient(e, data)
                              }
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                const searchValue = input
                                  .toLowerCase()
                                  .trim()
                                  .replace(/[^\d]/g, "");
                                const searchValue1 = input
                                  .toLowerCase()
                                  .trim()
                                  .replace(/[^a-z0-9@.]/g, "");
                                const { title, phone_cell, ss, email, dob } =
                                  option.props;
                                const dobFormatted = dob
                                  ? moment(dob).format("MMDDYYYY")
                                  : "";
                                const dobMMDD = dobFormatted.substring(0, 4);
                                const normalizedPhoneCell = phone_cell
                                  ?.trim()
                                  .replace(/[^\d]/g, "");
                                const normalizedss = ss
                                  ?.toLowerCase()
                                  .trim()
                                  .replace(/[^\d]/g, "");
                                const normalizedtitle = title
                                  ?.toLowerCase()
                                  .trim();
                                const normalizedemail = email
                                  ?.toLowerCase()
                                  .trim();

                                if (searchValue.length === 0) return true;

                                if (searchValue.length <= 2) {
                                  return (
                                    normalizedPhoneCell?.startsWith(
                                      searchValue
                                    ) ||
                                    dobFormatted.startsWith(searchValue) ||
                                    normalizedss
                                      ?.toLowerCase()
                                      .includes(searchValue) ||
                                    normalizedemail
                                      ?.toLowerCase()
                                      .includes(searchValue1)
                                  );
                                } else if (searchValue.length <= 4) {
                                  return (
                                    normalizedPhoneCell?.startsWith(
                                      searchValue
                                    ) ||
                                    dobMMDD === searchValue ||
                                    dobFormatted.substring(0, 4) ===
                                      searchValue ||
                                    normalizedss
                                      ?.toLowerCase()
                                      .includes(searchValue) ||
                                    normalizedemail
                                      ?.toLowerCase()
                                      .includes(searchValue1)
                                  );
                                } else if (searchValue.length <= 8) {
                                  return (
                                    normalizedPhoneCell?.startsWith(
                                      searchValue
                                    ) ||
                                    dobFormatted === searchValue ||
                                    normalizedss
                                      ?.toLowerCase()
                                      .includes(searchValue) ||
                                    normalizedemail
                                      ?.toLowerCase()
                                      .includes(searchValue1)
                                  );
                                }

                                return (
                                  (normalizedtitle ?? "")
                                    .toLowerCase()
                                    .includes(searchValue) ||
                                  normalizedPhoneCell.includes(searchValue) ||
                                  (normalizedss ??
                                    "".toLowerCase().includes(searchValue)) ||
                                  (normalizedemail ??
                                    "".includes(searchValue1.toLowerCase()))
                                );
                              }}
                              notFoundContent={
                                fetchingOptions ? "Loading..." : "No Data found"
                              }
                              value={search1}
                              getPopupContainer={(trigger) => document.body}
                              dropdownAlign={{
                                points: ["tl", "bl"],
                                offset: [0, 4],
                              }}
                              dropdownStyle={{
                                zIndex: 1050,
                              }}
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  {props.type !== "book again" && (
                                    <StyledDiv>
                                      <StyledButton
                                        onClick={() => {
                                          setTemShow(false);
                                          setShow(true);
                                        }}
                                        onMouseDown={(e) => e.preventDefault()}
                                        // onClick={handleAddPatient}
                                      >
                                        <StyledSvg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 448 512"
                                        >
                                          <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" />
                                        </StyledSvg>
                                        <span onClick={handleAddPatient}>
                                          Add Patient
                                        </span>
                                      </StyledButton>
                                    </StyledDiv>
                                  )}
                                </>
                              )}
                            >
                              {source.map((i) => {
                                const titleLength = i.text.length;

                                return (
                                  <Option
                                    key={i.value}
                                    value={i.value}
                                    title={i.text}
                                    phone_cell={i.phone_cell}
                                    dob={i.dob}
                                    ss={i.ss}
                                    email={i.email}
                                  >
                                    <div>
                                      {titleLength < 15 ? (
                                        <>
                                          {i.text} -{" "}
                                          {i.dob
                                            ? moment(i.dob).format(dtFormat)
                                            : " "}
                                        </>
                                      ) : (
                                        <>
                                          <div>{i.text} -</div>
                                          <div>
                                            {i.dob
                                              ? moment(i.dob).format(dtFormat)
                                              : " "}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <div>{i.phone_cell}</div>
                                  </Option>
                                );
                              })}
                            </Select>
                            {/* ..................... */}

                            {patError && (
                              <p className="errormessage">
                                Please select Patient
                              </p>
                            )}
                          </Div>
                        </Div>

                        <Div
                          alignItems="center"
                          className="appointment_poup_div appointment_poup_div_w"
                        >
                          <Div
                            id="new_appt_radio"
                            className="appt-spn-vdovis new_app_radio_group"
                          >
                            <Radio.Group
                              onChange={
                                accountType === "-1" ||
                                (accountType === "1" && teleFlag === "0")
                                  ? (e) => {
                                      setUpgrade(true);
                                    }
                                  : onChangeRadio
                              }
                              value={
                                forteleornor === 2 ? forteleornor : Appttype
                              }
                            >
                              <Radio
                                className="radio_appt_btn"
                                value={1}
                                onChange={checkapt}
                                disabled={allowAppt === 2}
                              >
                                Appointment
                              </Radio>

                              <Radio
                                value={2}
                                onChange={checkvideovisit}
                                disabled={allowAppt === 1}
                              >
                                Telehealth
                              </Radio>

                              <Radio
                                className="app-dashboard-radioBut"
                                onClick={walkinclick}
                                disabled={videochck}
                                id="new-app-walkin"
                              >
                                Walk-in
                              </Radio>
                            </Radio.Group>
                          </Div>
                        </Div>

                        <Div margin="13px 0 0 0">
                          <Div
                            alignItems="center"
                            className=" alx wholediv new_app_facility"
                          >
                            <Div margin="-20px 0px 0px -2px">
                              <RoomOutlined />
                            </Div>
                            <Div className="dropdiv" id="officeId">
                              <Select
                                showSearch
                                placeholder="Hospital Name"
                                id="new-app-facility_1"
                                suffixIcon={<BsFillCaretDownFill />}
                                className={
                                  officeError
                                    ? `err_mess_border w-100 to-hide-ant-label ${checkFacility(
                                        "classname"
                                      )}`
                                    : `to-hide-ant-label w-100 ${checkFacility(
                                        "classname"
                                      )}`
                                }
                                onChange={(e, data) =>
                                  handleDropDownSelectOffice(e, data)
                                }
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.title ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                value={officeid ? officeid : null}
                                getPopupContainer={() =>
                                  document.getElementById("officeId")
                                }
                              >
                                {sourceoffice.map((i) => (
                                  <Option
                                    key={i.value}
                                    value={i.value}
                                    title={i.text}
                                  >
                                    {i.text}
                                  </Option>
                                ))}
                              </Select>
                              {checkFacility("spinner")}
                              {officeError && (
                                <p className="errormessage">
                                  Please select Office
                                </p>
                              )}
                            </Div>
                          </Div>
                        </Div>

                        <Div
                          alignItems="center"
                          className="search-pat new_app_select_prov appointment_patient_div"
                        >
                          <Div margin="-2px 0px 0px -2px">
                            <PeopleOutline />
                          </Div>
                          <Div className="appointment_drop_div" id="provId">
                            <Select
                              showSearch
                              placeholder="Doctor Name"
                              suffixIcon={<BsFillCaretDownFill />}
                              id="new-app-provider new-app-provider_1"
                              className={
                                provError ? "err_mess_border w-100 " : "w-100"
                              }
                              onChange={(e, data) =>
                                handleDropDownSelectProvider(e, data)
                              }
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.title ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={providerid ? providerid : undefined}
                              getPopupContainer={() =>
                                document.getElementById("provId")
                              }
                            >
                              {sourceprovider.map((i) => (
                                <Option
                                  key={i.value}
                                  value={i.value}
                                  title={i.text}
                                >
                                  {i.text}
                                </Option>
                              ))}
                            </Select>
                            {provError && (
                              <p className="errormessage">
                                Please select Provider
                              </p>
                            )}
                          </Div>
                        </Div>

                        <Div
                          className="app_repeat_dropdown position-relative app_dummy_class"
                          margin=" 0px 0px 10px 40px"
                        >
                          <BsFillCaretDownFill className="down_arrow_doesnt does-not-repeat-icon" />

                          <DropdownButton
                            id="arrow-newAppt"
                            className="app-nav-dropdown-for-newApp-repect "
                            title={repvalue ? repvalue : "Does not repeat"}
                            // title=""
                            onSelect={handlerepSelect}
                            variant="light"
                          >
                            <Dropdownant.Item eventKey="Does not repeat">
                              Does not repeat
                            </Dropdownant.Item>
                            <Dropdownant.Item
                              onClick={handleShowuntildate}
                              eventKey="Daily"
                            >
                              Daily
                            </Dropdownant.Item>
                            <Dropdownant.Item
                              onClick={handleShowuntildate}
                              eventKey={"Week"}
                            >
                              Week
                            </Dropdownant.Item>
                          </DropdownButton>
                        </Div>

                        {repvalue === "Week" && (
                          <Div margin="5px 4px 14px 40px">
                            <ToggleButtonGroup
                              type="checkbox"
                              value={weekday}
                              className="appt_repeats but-act app_gap "
                              onChange={WeekdayChange}
                              style={
                                selectdayError
                                  ? {
                                      border: "1px solid red",
                                      borderRadius: "5px",
                                    }
                                  : {}
                              }
                            >
                              <ToggleButton
                                disabled={
                                  WeeklyDisable === "Show only weekdays" ||
                                  WeeklyDisable === "Don't show Sundays"
                                    ? true
                                    : false
                                }
                                className={
                                  WeeklyDisable === "Show only weekdays" ||
                                  (WeeklyDisable === "Don't show Sundays" &&
                                    "weekDisableBtn")
                                }
                                id="tbg-btn-1"
                                value={1}
                              >
                                S
                              </ToggleButton>
                              <ToggleButton id="tbg-btn-2" value={2}>
                                M
                              </ToggleButton>
                              <ToggleButton id="tbg-btn-3" value={3}>
                                T
                              </ToggleButton>
                              <ToggleButton id="tbg-btn-3" value={4}>
                                W
                              </ToggleButton>
                              <ToggleButton id="tbg-btn-3" value={5}>
                                T
                              </ToggleButton>
                              <ToggleButton id="tbg-btn-3" value={6}>
                                F
                              </ToggleButton>
                              <ToggleButton
                                disabled={
                                  WeeklyDisable === "Show only weekdays"
                                    ? true
                                    : false
                                }
                                id="tbg-btn-3"
                                value={7}
                              >
                                S
                              </ToggleButton>
                            </ToggleButtonGroup>

                            {selectdayError && (
                              <p className="errormessage">
                                Please select atleast a day
                              </p>
                            )}
                          </Div>
                        )}

                        {repvalue !== "" &&
                          repvalue !== undefined &&
                          repvalue !== null &&
                          repvalue !== "Does not repeat" &&
                          repvalue !== "Does not repeat " && (
                            <Div margin=" 0px 0px 10px 30px" dis="flex">
                              <Span
                                marginRight="3px"
                                color="#000000 !important"
                              >
                                Until:
                              </Span>
                              <Div position="relative">
                                <DatePicker
                                  disabledDate={(current) => {
                                    const oneYearFromNow = moment()
                                      .add(1, "year")
                                      .startOf("day");
                                    return (
                                      current.isBefore(
                                        moment(aptdate, dtFormat),
                                        "day"
                                      ) ||
                                      current.isAfter(oneYearFromNow, "day")
                                    );
                                  }}
                                  onChange={(date) => {
                                    if (date) {
                                      setLastdateApp(
                                        moment(date).format(dtFormat)
                                      );
                                    } else {
                                      setLastdateApp(moment().format(dtFormat));
                                    }
                                  }}
                                  placeholder="From-"
                                  format={dtFormat}
                                  value={
                                    lastdateApp
                                      ? moment(lastdateApp, dtFormat)
                                      : null
                                  }
                                  allowClear={false}
                                />
                              </Div>
                            </Div>
                          )}
                      </div>

                      <div>
                        <Div
                          className=" w-100 m_b_12"
                          // id="border2"
                          // onClick={hidehr}
                          // style={{ marginBottom: "12px" }}
                        >
                          <Div
                            className="wholediv"
                            // className="alx p-r-20"
                          >
                            <Div margin="-20px 0px 0px -2px">
                              <PersonOutline />
                            </Div>
                            <Div className="dropdiv" id="ProfId">
                              <Select
                                className={
                                  profileError
                                    ? "err_mess_border w-100"
                                    : "w-100"
                                }
                                showSearch
                                placeholder="Appt Profile"
                                id="new-app-profile_1 appt-inp-profsrch"
                                suffixIcon={<BsFillCaretDownFill />}
                                onChange={(e, data) =>
                                  handleDropDownSelect(e, data)
                                }
                                // onBlur={changeborder2}
                                // onClick={hidehr}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.title ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                value={profileid ? profileid : null}
                                // getPopupContainer={() =>
                                //   document.getElementById("ProfId")
                                // }
                                getPopupContainer={(trigger) => document.body}
                                dropdownAlign={{
                                  points: ["tl", "bl"],
                                  offset: [0, 4],
                                }}
                                dropdownStyle={{
                                  zIndex: 1050,
                                }}
                              >
                                {sourceprofile.map((i) => (
                                  <Option
                                    key={i.value}
                                    value={i.value}
                                    title={i.text}
                                  >
                                    {i.text}
                                  </Option>
                                ))}
                              </Select>

                              {profileError && (
                                <p className="errormessage">
                                  Please select Appointment Profile
                                </p>
                              )}
                            </Div>
                          </Div>
                        </Div>

                        <Div
                          className="new_app_date_bookAgain"
                          //  style={{ display: "flex", margin: "-19px 0 4px 2px" }}
                        >
                          <Div className="access_icon">
                            <AccessTimeIcon className="people" />
                          </Div>
                          <form
                            className={`${classes.container} frm-apoint`}
                            noValidate
                          >
                            <div
                              id="crt_appt-time-ok"
                              className="date-pick date-pick-parent app-datepick"
                            >
                              <DatePicker
                                style={{ width: "100px" }}
                                onChange={(date) => {
                                  if (date) {
                                    setDateErr(false);

                                    setaptdate(moment(date).format(dtFormat));
                                  } else {
                                    setaptdate(moment().format(dtFormat));
                                  }
                                }}
                                className={dateErr ? "err-bdr-date" : ""}
                                placeholder="Select date"
                                format={dtFormat}
                                value={
                                  aptdate ? moment(aptdate, dtFormat) : null
                                }
                                allowClear={false}
                                disabledDate={
                                  Appttype == 2 &&
                                  ((current) => {
                                    // Disable dates before today
                                    return (
                                      current &&
                                      current < moment().startOf("day")
                                    );
                                  })
                                }
                              />

                              <div className="App-time ">
                                <div className="showTime">
                                  <input
                                    className="fromTime-toTime-start"
                                    onClick={() => {
                                      handleFromTime();
                                    }}
                                    onMouseLeave={() => {
                                      setTempOpen(false);
                                    }}
                                    value={inTime}
                                    onChange={(e) => {
                                      if (!regexFirstNum.test(e.target.value)) {
                                        setFromShow(false);
                                        setToShow(false);
                                        setInTime(e.target.value);
                                        setTimeout(() => {
                                          setInTime(
                                            moment(
                                              e.target.value,
                                              "h:mm A"
                                            ).format("h:mm A")
                                          );
                                          onChange(
                                            moment(
                                              e.target.value,
                                              "h:mm A"
                                            ).format("h:mm A"),
                                            "inTime"
                                          );
                                        }, 2000);
                                      }
                                    }}
                                    onBlur={(e) => {
                                      setInTime(
                                        moment(e.target.value, "h:mm A").format(
                                          "h:mm A"
                                        )
                                      );
                                      onChange(
                                        moment(e.target.value, "h:mm A").format(
                                          "h:mm A"
                                        ),
                                        "inTime"
                                      );
                                    }}
                                  />
                                  <p className="time--space">-</p>
                                  <input
                                    className="fromTime-toTime"
                                    onClick={() => {
                                      handleToTime();
                                    }}
                                    onChange={(e) => {
                                      if (!regexFirstNum.test(e.target.value)) {
                                        setFromShow(false);
                                        setToShow(false);
                                        setOutTime(e.target.value);
                                        setTimeout(() => {
                                          setOutTime(
                                            moment(
                                              e.target.value,
                                              "h:mm A"
                                            ).format("h:mm A")
                                          );
                                          onChange(
                                            moment(
                                              e.target.value,
                                              "h:mm A"
                                            ).format("h:mm A"),
                                            "outTime"
                                          );
                                        }, 2000);
                                      }
                                    }}
                                    onMouseLeave={() => {
                                      setTempOpen(false);
                                    }}
                                    onBlur={(e) => {
                                      setOutTime(
                                        moment(e.target.value, "h:mm A").format(
                                          "h:mm A"
                                        )
                                      );
                                      onChange(
                                        moment(e.target.value, "h:mm A").format(
                                          "h:mm A"
                                        ),
                                        "outTime"
                                      );
                                    }}
                                    value={outTime}
                                  />
                                  {/* {outTime}
                     </p> */}
                                </div>

                                {/* From Button */}
                                <div
                                  className={
                                    fromShow
                                      ? "fromArray-toArray"
                                      : "fromArray_hide"
                                  }
                                >
                                  {frtimeSlots.map((item, index) => {
                                    return (
                                      <button
                                        // disabled={toId >= item.id ? true : false}
                                        key={item.id}
                                        value={item.time + " " + item.meridiem}
                                        disabled={disabledFromTimes(
                                          item.time,
                                          item.meridiem,
                                          Appttype
                                        )}
                                        onClick={async (e) => {
                                          setInTime(
                                            moment(
                                              e.target.value,
                                              "h:mm A"
                                            ).format("h:mm A")
                                          );
                                          await setFromId(item.id);
                                          handleFromButton(e);
                                          onChange(
                                            moment(
                                              e.target.value,
                                              "h:mm A"
                                            ).format("h:mm A"),
                                            "inTime"
                                          );
                                        }}
                                      >
                                        {item.time + " " + item.meridiem}
                                      </button>
                                    );
                                  })}
                                </div>
                                {/* To button */}
                                <div
                                  className={
                                    toShow
                                      ? "fromArray-toArray"
                                      : "toArray_hide"
                                  }
                                  style={{ left: 70 }}
                                >
                                  {totimeSlots.map((item, index) => {
                                    return (
                                      <button
                                        disabled={disabledToTime(
                                          item.time,
                                          item.meridiem,
                                          item.id,
                                          Appttype
                                        )}
                                        key={index}
                                        value={item.time + " " + item.meridiem}
                                        onClick={async (e) => {
                                          setOutTime(
                                            moment(
                                              e.target.value,
                                              "h:mm A"
                                            ).format("h:mm A")
                                          );

                                          handleToButton(e);
                                          onChange(
                                            moment(
                                              e.target.value,
                                              "h:mm A"
                                            ).format("h:mm A"),
                                            "outTime"
                                          );
                                        }}
                                      >
                                        {item.time + " " + item.meridiem}
                                      </button>
                                    );
                                  })}
                                </div>
                              </div>
                              {/* nisha end */}
                            </div>
                            {dateErr && (
                              <Form.Text
                                id="new-app-time-codetext appt-warn-time"
                                className="colour-red m-t-0-imp_editAppointment "
                              >
                                Please Select valid date
                              </Form.Text>
                            )}
                          </form>
                        </Div>
                        {inTimeErr && (
                          <Form.Text
                            id="new-app-time-codetext appt-warn-time"
                            className="colour-red p-l-66 m-t-0-imp m-b-3"
                          >
                            Please Select valid Time
                          </Form.Text>
                        )}
                        {timelimiterrorbox && (
                          <Form.Text
                            id="new-app-time-codetext appt-warn-time"
                            className="colour-red p-l-66 m-t-0-imp m-b-3"
                          >
                            Provider Only Available Between {startErrTime} to{" "}
                            {endErrTime}
                          </Form.Text>
                        )}

                        {/* <Div alignItems="center" className="appointment_popup_div"> */}
                        <Div style={{ textAlign: "left" }}>
                          <Popup
                            content={fornormalapp}
                            open={normalappointment}
                            trigger={
                              <Button
                                id="click_event_app_normal"
                                content="Button"
                              />
                            }
                            position="left center"
                          />
                        </Div>
                        <Div>
                          <Popup
                            content={forteleapp}
                            open={teleappointment}
                            trigger={
                              <Button
                                id="click_event_app_tele"
                                content="Button"
                              />
                            }
                            position="left center"
                          />
                        </Div>

                        <Div
                          className=" w-100"
                          // id="border2"
                          // onClick={hidehr}
                        >
                          <Div
                            className="wholediv"
                            // className="alx p-r-20"
                          >
                            <Div margin="-20px 0px 0px -2px" size="20px">
                              <Image
                                src={doctorLogo}
                                alt="speciality"
                                height="20px"
                                width="20px"
                              />
                            </Div>

                            <Div className="dropdiv">
                              <Select
                                showSearch
                                placeholder="Select Speciality"
                                suffixIcon={<BsFillCaretDownFill />}
                                className={
                                  specialError
                                    ? "err_mess_border w-100"
                                    : "w-100"
                                }
                                onChange={(data) => changeSpeciality(data)}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.title ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                value={speciality ? speciality : null}
                                optionLabelProp="label"
                                dropdownClassName="speciality-option"
                              >
                                {/* {sortSpeciality.length !== 0 &&
                               sortSpeciality.map((item) => ( */}

                                {sortSpeciality &&
                                  sortSpeciality
                                    .filter(
                                      (item) =>
                                        (storedNames.appt_a ||
                                          item.specialty === notDisabledSpec) &&
                                        item.count > 0
                                    ) // Filter disabled options
                                    .map((item) => (
                                      <Option
                                        key={item.specialty}
                                        value={item.specialty}
                                        title={item.specialty}
                                        disabled={
                                          (!storedNames.appt_a &&
                                            item.specialty !==
                                              notDisabledSpec) ||
                                          item.count === 0
                                          // : item.count === 0
                                        }
                                        id={
                                          !storedNames.appt_a &&
                                          item.specialty !== notDisabledSpec
                                            ? "clr-disable"
                                            : "" || item.count == 0
                                            ? "clr-disable"
                                            : ""
                                        }
                                        className={
                                          (!storedNames.appt_a &&
                                            item.speciality !==
                                              notDisabledSpec) ||
                                          item.count === 0
                                        }
                                      >
                                        {item.speciality}
                                      </Option>
                                    ))}
                              </Select>
                              {specialError && (
                                <p className="errormessage">
                                  Select a speciality
                                </p>
                              )}
                            </Div>
                          </Div>
                        </Div>

                        <Div className=" w-100" id="border">
                          <Div
                            className="wholediv"
                            // className="alx p-r-20"
                          >
                            <Div margin="-20px 0px 0px -2px">
                              <LocalHospitalOutlined />
                            </Div>
                            <Div
                              className={
                                videochck ? "dropdiv downDropDown" : "dropdiv"
                              }
                              id="statId"
                            >
                              <Select
                                showSearch
                                placeholder="Status"
                                id="new-app-status_1 appt-inp-stas"
                                suffixIcon={<BsFillCaretDownFill />}
                                // onClick={changeborder}
                                // className="form-control "
                                className={
                                  statusError
                                    ? "err_mess_border w-100"
                                    : "w-100"
                                }
                                onChange={(e, data) =>
                                  handleDropDownSelectStatus(e, data)
                                }
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.title ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                value={statusid ? statusid : null}
                                // getPopupContainer={() =>
                                //   document.getElementById("statId")
                                // }
                                getPopupContainer={(trigger) => document.body}
                                dropdownAlign={{
                                  points: ["tl", "bl"],
                                  offset: [0, 4],
                                }}
                                dropdownStyle={{
                                  zIndex: 1050,
                                }}
                              >
                                {checking1 ? (
                                  <></>
                                ) : (
                                  <>
                                    {videochck ? (
                                      <>
                                        {sourcestatustele.map((i) => (
                                          <Option
                                            key={i.value}
                                            value={i.value}
                                            title={i.text}
                                          >
                                            {i.text}
                                          </Option>
                                        ))}
                                      </>
                                    ) : (
                                      <>
                                        {sourcestatus.map((i) => (
                                          <Option
                                            key={i.value}
                                            value={i.value}
                                            title={i.text}
                                          >
                                            {i.text}
                                          </Option>
                                        ))}
                                      </>
                                    )}
                                  </>
                                )}
                              </Select>
                              {statusError && (
                                <p className="errormessage">
                                  Please select Status
                                </p>
                              )}
                            </Div>
                          </Div>
                        </Div>

                        {/* <Div
                            className="wholediv "

                            // className="alx p-r-20"
                          >
                            <Div margin="-20px 0px 0px -2px">
                              <Image
                                src={BillingFacilityIcon}
                                alt="bill"
                                height="20px"
                                width="20px"
                              />
                            </Div>

                            {/* <Div className="dropdiv" id="billingFac">
                              <Select
                                showSearch
                                placeholder="Billing Facility"
                                suffixIcon={<BsFillCaretDownFill />}
                                id="new-app-status_1 appt-inp-stas"
                                className={
                                  billFacilityError
                                    ? "err_mess_border w-100"
                                    : "w-100"
                                }
                                onChange={(e, data) =>
                                  handleBillingFacility(e, data)
                                }
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.title ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                value={
                                  billFacilityID
                                    ? billFacilityID
                                    :billFacilityID
                                }
                                // getPopupContainer={() =>
                                //   document.getElementById("billingFac")
                                // }
                                getPopupContainer={(trigger) => document.body}
                                dropdownAlign={{
                                  points: ["tl", "bl"],
                                  offset: [0, 4],
                                }}
                                dropdownStyle={{
                                  zIndex: 1050,
                                }}
                              >
                                {billFacility?.map((i) => (
                                  <Option
                                    key={i.value}
                                    value={i.value}
                                    title={i.text}
                                  >
                                    {i.text}
                                  </Option>
                                ))}
                              </Select>
                              {billFacilityError && (
                                <p className="errormessage">
                                  Please Select the Facility
                                </p>
                              )}
                            </Div> 
                          </Div> */}
                        <Div
                          className=" w-100"
                          // id="border2"
                          // onClick={hidehr}
                        >
                          {/* 2222nd */}
                          <Div
                            className="wholediv"
                            // className="alx p-r-20"
                          >
                            <Div margin="-20px 0px 0px -2px">
                              <HomeOutlinedIcon />
                            </Div>
                            <Div className="dropdiv" id="ExaId">
                              <Select
                                showSearch
                                placeholder="Exam Room"
                                id="new-app-profile_1"
                                suffixIcon={<BsFillCaretDownFill />}
                                // onClick={hidehr}
                                className={
                                  examroomError
                                    ? "err_mess_border w-100"
                                    : "w-100"
                                }
                                onChange={(e, data) =>
                                  handleRoomVal(data.value)
                                }
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.title ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                value={roomval ? roomval : null}
                                // getPopupContainer={() =>
                                //   document.getElementById("ExaId")
                                // }
                                getPopupContainer={(trigger) => document.body}
                                dropdownAlign={{
                                  points: ["tl", "bl"],
                                  offset: [0, 4],
                                }}
                                dropdownStyle={{
                                  zIndex: 1050,
                                }}
                              >
                                {sourceroom.map((i) => (
                                  <Option
                                    key={i.value}
                                    value={i.value}
                                    title={i.text}
                                  >
                                    {i.text}
                                  </Option>
                                ))}
                              </Select>
                              {examroomError && (
                                <p className="errormessage">
                                  Please select Exam Room
                                </p>
                              )}
                            </Div>
                          </Div>
                        </Div>
                      </div>
                    </div>
                  </Form.Row>
                </Div>
              )}
            </>
          )}
        </Modal.Body>
        {/* {changeTab === "1" &&  */}
        <Modal.Footer className="client-app new_appt_btn_alt">
          <Button
            className="close_active close_active2 "
            variant="light"
            onClick={props.closepopup}
          >
            Cancel
          </Button>
          <Button
            id="appt-btn-sve"
            className="save_active save-btn"
            variant="primary"
            type="submit"
            onClick={
              switchvalue === false && changeTab === "2"
                ? () => handleRefresh()
                : (e) => handleSubmit(e)
            }
            disabled={btnDis}
          >
            Save
          </Button>
        </Modal.Footer>
        {/* } */}
      </div>
      {/* <EditSchedule /> */}
      <Modal
        className="alert-popup-message eramsg"
        show={ModalAlerShow1}
        onHide={modalhide}
      >
        <div role="alert" className="alert-danger erapad">
          <BsExclamationOctagon className="icons-bs alert-icons-bts" />
          <span>{ModalMsg1}</span>
        </div>
      </Modal>

      {upgrade && (
        <UpgradeRequired
          title="Enable Remote Consultation"
          description="Consult patients through video visit remotely conserving resources and improving the care."
          boxContent={
            <span>
              Available in
              <span className="upgradFont"> Premium</span> plan
            </span>
          }
          handlecloseUpgrade={handlecloseUpgrade}
        />
      )}
    </>
  );
};
export default memo(NewAppointment);
