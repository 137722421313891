import React, { useState, useEffect, memo, useRef } from "react";
import { Modal, Button, Image, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import format from "date-fns/format";

import { BsCheckCircle, FiTrash2, IoCloseSharp } from "react-icons/all";
import { IoVideocamOutline, AiOutlinePlus } from "react-icons/all";
import { FiEdit3 } from "react-icons/fi";
import NewAppointmentDetail from "../popups/NewAppointmentDetail";
import AppointmentServiceDetail from "../popups/AppointmentServiceDetail";
import AppointmentBillingDetail from "../popups/AppointmentBillingDetail";
import { Tabs, Collapse, Select } from "antd";
import axiosInstance from "../../axios/axios";
import VitalsTable from "./VitalsTable";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import {
  changeAppointmentValues,
  changeAppointmentStatusAll,
} from "../../StateManagement/Reducers/ScheduleState";
import {
  RepeatPopupBlock,
  RepeatPopupHeader,
  RepeatPopupBodyText,
  RepeatPopupBodyBtn,
  RepeatBtn,
  RepeatFooter,
  RepeatBtnConfirm,
} from "../schedule/styles";
import { ProfileFileURL } from "../../axios/url.js";

const { TabPane } = Tabs;

const { Panel } = Collapse;

const AppointmentDetail = (props, ref) => {
  // ---------------------------------------- State and Variables Start--------------------------------------------------- //
  const history = useHistory();
  const dispatch = useDispatch();

  const [Refresh, setRefresh] = useState("");
  const [Refresh1, setRefresh1] = useState(0);
  const [time, setTime] = useState("");
  const [eventdate, setEventdate] = useState("");

  const [patientdetails, getpatientdetails] = useState([]);
  const [tabstatus, gettabstatus] = useState(true);
  const [tabstatus_arv, gettabstatus_arv] = useState(false);
  const [startvideo, setstartvideo] = useState("none");
  const [stopvideo, setstopvideo] = useState("none");
  const [mar_left, setmar_left] = useState("10px");
  const [note_dis, setnote_dis] = useState(false);
  const [tele_dis, settele_dis] = useState(false);
  const [OpenTextChange, setOpenTextChange] = useState(false);

  const [loadVal, setLoadVal] = useState(false);
  const [FullScreen, setFullScreen] = useState(
    props.savebox ? props.savebox : true
  );
  const [serviceRefreshKey, setServiceRefreshKey] = useState(0);

  // const [StatusCheck, setStatusCheck] = useState(false);

  const [one, setOne] = useState("");
  const [two, setTwo] = useState("");
  const [three, setThree] = useState("");
  const [four, setFour] = useState("");
  const btnDis = useSelector((state) => state.Schedule.btnDis);
  const SpinnerValForStatus = useSelector(
    (state) => state.Schedule.ChangeStatus
  );

  const [key, setKey] = useState([""]);
  const [start_dis, setstart_dis] = useState(false);

  const { Option } = Select;
  const [insuranceType, setInsuranceType] = useState("primary");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const loadChng = () => {
    setLoadVal(true);
  };

  var app_id = props.start.Id;
  var eid = props.start.encounter;
  var appt_endTime = props.start.pc_endTime;
  var appt_status = props.start.title;

  var storedNames = Decrypt_Value(localStorage.getItem("setroles"), "vozo");

  const saveButton = useSelector((state) => state.Schedule.saveButton);
  const repeats_checked = useSelector(
    (state) => state.Schedule.repeats_checked
  );

  const SpinnerVal = useSelector((state) => state.Schedule.SpinnerVal);

  // ---------------------------------------- State and Variables End--------------------------------------------------- //

  // ---------------------------------------- Functions Start----------------------------------------------------------- //
  const ServiceRefresh = () => {
    setServiceRefreshKey((prevKey) => prevKey + 1);
  };
  const openService = (val) => {
    if (val === true) {
      setmar_left("auto");
    }
  };

  const handleDelete = () => {
    props.repeatpopupenable(repeats_checked);
  };

  const refreshBilling = () => {
    setRefresh1(Refresh1 + 1);
  };

  const handleSave = () => {
    if (saveButton["appointment"]) {
      dispatch(
        changeAppointmentValues({ name: "call function", value: "appointment" })
      );
    }
    if (saveButton["billing"]) {
      dispatch(
        changeAppointmentValues({ name: "call function", value: "billing" })
      );
    }
    if (saveButton["vitals"]) {
      dispatch(
        changeAppointmentValues({ name: "call function", value: "vitals" })
      );
    }
    if (
      !saveButton["appointment"] &&
      !saveButton["billing"] &&
      !saveButton["vitals"]
    ) {
      props.closepopupedit(0, 0, "no changes");
    }
  };

  const patdashedit = () => {
    history.push("/patient/patientdashboard/" + props.start.pc_pid);
  };

  const gotonote = () => {
    axiosInstance
      .put("/vozo/appoinment/noteupdate?id=" + props.start.Id)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
    history.push("/clinicalnotes");
    localStorage.setItem("notesback", true);
    localStorage.setItem("place", "calender");
    localStorage.setItem("NewPid", patientdetails.pid);
    localStorage.setItem("NewEid", props.start.Id);
    localStorage.setItem("NewEncounter", props.start.encounter);
  };

  const start_videoset = () => {
    var current = moment().format("HH:mm:ss");
    var currdate = moment(new Date()).format("YYYY-MM-DD");

    if (currdate <= eventdate) {
      if (current < time) {
        alert(`Your Meeting is scheduled at ${eventdate} ${time}`);
        return false;
      }
    }

    window.open(props.start.start_url, "_blank");
    setstartvideo("none");
    setstopvideo("block");
    axiosInstance
      .post("/vozo/telehealth/start?id=" + app_id)
      .then(() => {})
      .catch((err) => console.error(err));
  };

  const stop_videoset = () => {
    props.popshowHide();
    setstart_dis(true);
    setstopvideo("none");
    setstartvideo("block");
    axiosInstance
      .post("/vozo/telehealth/stop?eid=" + app_id)
      .then(() => {
        setmar_left("auto");
      })
      .catch((err) => console.error(err));
  };
  console.log(props.type);
  const appDatas = async () => {
    setLoading(true);
    await axiosInstance
      .get("/vozo/patient/details?app_id=" + app_id)
      .then((response) => {
        let temp_role_pat_det = Decrypt_Value(response.data, "vozo");
        getpatientdetails(temp_role_pat_det);
        setTime(temp_role_pat_det.pc_startTime);
        setEventdate(temp_role_pat_det.pc_eventDate);
        if (Number(eid) === 0) {
          gettabstatus_arv(true);
        }
        if (temp_role_pat_det.telehealth === "1") {
          setOpenTextChange(true);
        } else {
          setOpenTextChange(false);
        }
        if (
          temp_role_pat_det.telehealth === "1" &&
          temp_role_pat_det.pc_apptstatus !== "doc_completed"
        ) {
          // settele_dis(true);
          gettabstatus_arv(true);

          // setstartvideo("block");
          setmar_left("10px");
        }
        if (
          temp_role_pat_det.telehealth === "1" &&
          temp_role_pat_det.pc_apptstatus === "doc_confirmed" &&
          props?.start?.start_url
        ) {
          settele_dis(true);

          setstartvideo("block");
          setmar_left("10px");
        } else {
          setstartvideo("none");
          setmar_left("10px");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });

    await axiosInstance
      .get("/vozo/countservices?eid=" + eid)
      .then((response) => {
        let temp_role_count_ser = Decrypt_Value(response.data, "vozo");
        temp_role_count_ser === "true"
          ? gettabstatus(true)
          : gettabstatus(false);
      })
      .catch((err) => console.error(err));
    if (props.start.encounter > 0) {
      setnote_dis(true);
    }
  };
  // const CheckStatus = () => {
  //   setStatusCheck(true);
  // };
  // const SaveChangeStatus = () => {
  //   setStatusCheck(false);
  //   dispatch(
  //     changeAppointmentStatusAll({ value: true, RepeatOrNot: repeats_checked })
  //   );
  // };

  // ------------------------------ Functions End--------------------------------------------------- //

  // ------------------------------ useEffect Start--------------------------------------------------- //

  useEffect(() => {
    appDatas();
  }, [SpinnerValForStatus]);

  useEffect(() => {
    setFullScreen(props.savebox);
  }, [props.savebox]);

  useEffect(() => {
    props.stopvdobtncls(stopvideo);
  }, [stopvideo]);

  // ------------------------------ useEffect End--------------------------------------------------- //

  return (
    <>
      {loading && SpinnerValForStatus ? (
        <div style={{ height: 500, display: "grid", placeItems: "center" }}>
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <Modal.Body className="p-0 cal-mod">
            {props.type === "full" && (
              <div className="d-flex d-a-t-block pad-inv-apd apt-full-body">
                {/* Patient details block */}
                {patientdetails.img_url !== null ? (
                  <>
                    <Image
                      src={ProfileFileURL + patientdetails.img_url}
                      className="img-pat-bts"
                    />
                  </>
                ) : (
                  <div className="patname_first img-pat-bts bts-pat-text">
                    {patientdetails.uname.charAt(0).toUpperCase()}
                  </div>
                )}
                <>
                  <div className="d-flex m-l-10">
                    <div
                      className=""
                      style={{ fontSize: "18px", fontWeight: "600" }}
                    >
                      <span className="curs" onClick={patdashedit}>
                        {patientdetails.uname}
                      </span>
                    </div>
                  </div>
                </>

                {/* Appointment details block */}

                <>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto 1fr",
                      columnGap: "8px",
                      rowGap: "4px",
                      marginLeft: "24px",
                    }}
                  >
                    <div style={{ fontWeight: "500" }}>Appointment Date:</div>
                    <div>
                      {moment(patientdetails.pc_eventDate).format("DD/MM/YYYY")}
                    </div>

                    <div style={{ fontWeight: "500" }}>Appointment Time:</div>
                    <div>
                      {moment(patientdetails.pc_startTime, "HH:mm").format(
                        "h:mm a"
                      )}{" "}
                      - {moment(appt_endTime, "HH:mm").format("h:mm a")}
                    </div>

                    <div style={{ fontWeight: "500" }}>Provider Name:</div>
                    <div>{patientdetails.pro_name}</div>

                    <div style={{ fontWeight: "500" }}>Status:</div>
                    <div style={{ color: "green", fontWeight: "500" }}>
                      {appt_status}
                    </div>
                  </div>
                </>
                {tele_dis && (
                  <Button
                    variant="success"
                    className="m-l-au tele-call-btn"
                    style={{ display: startvideo }}
                    onClick={start_videoset}
                  >
                    Start Video Call <IoVideocamOutline />
                  </Button>
                )}
                {tele_dis && (
                  <Button
                    variant="danger"
                    className=" m-l-au tele-call-btn"
                    style={{ display: stopvideo }}
                    onClick={stop_videoset}
                  >
                    Stop Video Call <IoVideocamOutline />
                  </Button>
                )}

                {note_dis && storedNames.notes === true && (
                  <>
                    {loadVal && (
                      <div className="d-flex" style={{ marginLeft: "auto" }}>
                        <Button
                          variant="light"
                          style={{ marginLeft: mar_left }}
                          id="dropdown-basic4"
                          className={` pat-mob-dd  app_det_close_active close_active  appt-edit-note-btn ${
                            tele_dis && "join"
                          }`}
                          onClick={gotonote}
                        >
                          <AiOutlinePlus className="m-t--1" /> Add Note
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}

            {/* </Div> 
            </div>*/}
            {/* <div className="d-flex d-a-t-block pad-inv-apd apt-full-body">
              {patientdetails.img_url !== null ? (
                <>
                  <Image
                    src={ProfileFileURL + patientdetails.img_url}
                    className="img-pat-bts"
                  />
                </>
              ) : (
                <div className="patname_first img-pat-bts bts-pat-text">
                  {patientdetails.uname.charAt(0).toUpperCase()}
                </div>
              )}
              <>
                <div className="d-flex m-l-10">
                  <div className="pat-name-demo">
                    <span className="curs" onClick={patdashedit}>
                      {patientdetails.uname}
                    </span>
                    <div className="app-num">
                      {patientdetails.phone_home}
                      <span className="app-home">Home</span>
                    </div>
                  </div>
                </div>
              </>
              {tele_dis ? (
                <Button
                  variant="success"
                  className="m-l-au tele-call-btn"
                  style={{ display: startvideo }}
                  onClick={start_videoset}
                >
                  Start Video Call <IoVideocamOutline />
                </Button>
              ) : (
                <></>
              )}
              {tele_dis && (
                <Button
                  variant="danger"
                  className=" m-l-au tele-call-btn"
                  style={{ display: stopvideo }}
                  onClick={stop_videoset}
                >
                  Stop Video Call <IoVideocamOutline />
                </Button>
              )}
              {/* {FullScreen ? (
                <Button
                  className={`appt-det-full-screen-btn ${tele_dis && "move"}`}
                  onClick={handleFullscreen}
                >
                  <FiEdit3 />
                </Button>
              ) : (
                ""
              )} 
              {note_dis && storedNames.notes === true && (
                <>
                  {loadVal && (
                    <Button
                      variant="light"
                      style={{ marginLeft: mar_left }}
                      id="dropdown-basic4"
                      className={` pat-mob-dd  app_det_close_active close_active  appt-edit-note-btn ${
                        tele_dis && "join"
                      }`}
                      onClick={gotonote}
                    >
                      <AiOutlinePlus className="m-t--1" /> Add Note
                    </Button>
                  )}
                </>
              )}
              {/* </Div> 
            </div> */}

            <div style={{ background: "#fff" }}>
              {/************************* Appointment Tab Start **************************/}

              {tabstatus_arv && (
                <div className="left-side appt_appt width-auto">
                  <NewAppointmentDetail
                    dataObj={props.dataObj}
                    validpopShow={props.validpopShow}
                    stopvideo={stopvideo}
                    deleteapp={props.deleteapp}
                    pid={patientdetails.pid}
                    start={props.start}
                    closepopupedit={props.closepopupedit}
                    setspinnerfornewapp={props.setspinnerfornewapp}
                    savealertmsg={props.savealertmsg}
                    popuphide={props.popuphide}
                    provarray={props.provarray}
                    facarray={props.facarray}
                    pat_share={props.pat_share}
                    FetchAppointments={props.FetchAppointments}
                    repeatpopupenable={props.repeatpopupenable}
                    slotDate={props.slotDate}
                    appFromDate={props.appFromDate}
                    apptoDate={props.apptoDate}
                    examroom={props.examroom}
                    loadVal={loadChng}
                    // FullScreen={FullScreen}
                    start_dis={start_dis}
                    tabstatus1={tabstatus_arv}
                    tabstatus2={tabstatus}
                  />
                </div>
              )}
              {/************************* Appointment Tab End **************************/}

              {/************************* Vitals Tab Start **************************/}
              {!tabstatus_arv && loading === false && (
                <>
                  <div
                    className={
                      tabstatus_arv
                        ? "full-width disabled_edit_app"
                        : "full-width "
                    }
                    onClick={(e) => {
                      if (tabstatus_arv) {
                        e.stopPropagation();
                        // CheckStatus();
                      }
                    }}
                  >
                    <VitalsTable
                      name={eid}
                      deleteapp={props.deleteapp}
                      closepopupedit={props.closepopupedit}
                      savealertmsg={props.savealertmsg}
                      popuphide={props.popuphide}
                      provarray={props.provarray}
                      facarray={props.facarray}
                      pat_share={props.pat_share}
                      setspinnerfornewapp={props.setspinnerfornewapp}
                      FetchAppointments={props.FetchAppointments}
                      appFromDate={props.appFromDate}
                      apptoDate={props.apptoDate}
                      examroom={props.examroom}
                      tabstatus={tabstatus_arv}
                      loadVal={loadChng}
                    />
                  </div>
                  {/************************* Vitals Tab End **************************/}

                  {/************************* Services Tab Start **************************/}
                  <div
                    className={
                      tabstatus_arv
                        ? "full-width appt_service border_#cbd5e0 disabled_edit_app"
                        : "full-width appt_service border_#cbd5e0"
                    }
                    onClick={(e) => {
                      if (tabstatus_arv) {
                        e.stopPropagation();
                        // CheckStatus();
                      }
                    }}
                  >
                    <AppointmentServiceDetail
                      id={eid}
                      deleteapp={props.deleteapp}
                      closepopupedit={props.closepopupedit}
                      tabstatus={gettabstatus}
                      rerender={Refresh}
                      apptId={app_id}
                      openService={(val) => openService(val)}
                      changeRefresh={refreshBilling}
                      serviceRefreshKey={serviceRefreshKey}
                    />
                  </div>
                </>
              )}
              {/************************* Services Tab End **************************/}

              {/************************* Billing Tab Start **************************/}
              {!tabstatus_arv && !tabstatus && loading === false && (
                <div
                  className={
                    tabstatus_arv
                      ? "right-side appt_billing disabled_edit_app width-auto"
                      : tabstatus
                      ? " right-side appt_billing disabled_edit_app_services width-auto"
                      : "right-side appt_billing width-auto"
                  }
                  onClick={(e) => {
                    if (tabstatus_arv) {
                      e.stopPropagation();
                      // CheckStatus();
                    }
                  }}
                >
                  <div className="appt_heading">Billing</div>
                  <AppointmentBillingDetail
                    start={props.start.encounter}
                    rerender={Refresh}
                    renderTab={Refresh1}
                    eventid={eid}
                    appID={app_id}
                    pid={patientdetails.pid}
                    deleteapp={props.deleteapp}
                    closepopupedit={props.closepopupedit}
                    savealertmsg={props.savealertmsg}
                    popuphide={props.popuphide}
                    provarray={props.provarray}
                    facarray={props.facarray}
                    pat_share={props.pat_share}
                    setspinnerfornewapp={props.setspinnerfornewapp}
                    FetchAppointments={props.FetchAppointments}
                    appFromDate={props.appFromDate}
                    apptoDate={props.apptoDate}
                    examroom={props.examroom}
                    // FullScreen={FullScreen}
                    tabstatus1={tabstatus_arv}
                    tabstatus2={tabstatus}
                    ServiceRefresh={ServiceRefresh}
                  />
                </div>
              )}
              {/* </div> */}
              {/************************* Billing Tab End **************************/}

              <div className="d-flex appt_details_bottom">
                {props.type === "full" && (
                  <FiTrash2
                    className="trash_icon_appt_det m-l-3"
                    onClick={handleDelete}
                    style={{ display: SpinnerVal ? "none" : "block" }}
                  />
                )}
                <div>
                  <Button
                    className="close_active close_active2 m-r-20"
                    variant="light"
                    onClick={props.closepopupedit}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="save_active save-btn"
                    variant="primary"
                    disabled={btnDis}
                    onClick={handleSave}
                  >
                    Done
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
          {showModal && (
            <Modal
              className="alert-popup-message"
              show={showModal}
              onHide={() => setShowModal(false)}
            >
              <div
                className={
                  alertMessage === "Cant Verified" ||
                  alertMessage ===
                    "Please fill the necessary fields before checking eligibility"
                    ? "alert msg alert-danger"
                    : "alert msg alert-success"
                }
                role="alert"
              >
                {alertMessage === "Cant Verified" ||
                  (alertMessage ===
                  "Please fill the necessary fields before checking eligibility" ? (
                    <IoCloseSharp className="icons-bs" />
                  ) : alertMessage === "Check in progress..." ? (
                    ""
                  ) : (
                    <BsCheckCircle className="icons-bs" />
                  ))}

                {alertMessage}
              </div>
            </Modal>
          )}
          {/* <Modal
            className="app-popup repeat-popup rept-modal popup_centre edit_conf_border"
            centered
            backdrop="static"
            keyboard={false}
            show={StatusCheck}
          >
            <RepeatPopupBlock>
              <RepeatPopupHeader>Edit Confirmation</RepeatPopupHeader>
              <RepeatPopupBodyText>
                Please confirm the current status of the patient's appointment
                {OpenTextChange
                  ? " Has the telehealth meeting been completed yet?"
                  : " Has the patient arrived in the lobby"}
              </RepeatPopupBodyText>
              <RepeatPopupBodyBtn></RepeatPopupBodyBtn>
              <RepeatFooter>
                <RepeatBtn
                  active={false}
                  onClick={() => {
                    setStatusCheck(false);
                  }}
                  className="close_active close_active2"
                >
                  No
                </RepeatBtn>
                <RepeatBtnConfirm confirm={true} onClick={SaveChangeStatus}>
                  Yes
                </RepeatBtnConfirm>
              </RepeatFooter>
            </RepeatPopupBlock>
          </Modal> */}
        </>
      )}
    </>
  );
};
export default memo(React.forwardRef(AppointmentDetail));
