import axios from "axios";
import { Checkurl, DBHost } from "../../axios/url.js";

// let DBHost;
var headers = {};

headers.Authorization = `${localStorage.token}`;

var baseURL = Checkurl + DBHost + "/api";

export const providerListApi = async (data) =>
  await axios.post(baseURL + "/schedule_widget/get/provider/list", data);

export const BookScheduleApi = async (data) =>
  await axios.post(baseURL + "/schedule_widget/book/schedule", data);
