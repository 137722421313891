import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Span } from "../index";
import { globalFont } from "../../components/utils/RepeatFunctions/Schedule";
const StripeAlert = (props) => {
  return (
    <>
      <Modal.Header
        closeButton
        style={{
          backgroundColor: "#ffffff",
          borderTopRightRadius: "8px",
          borderTopLeftRadius: "8px",
          alignItems: "center",
        }}
      >
        <Modal.Title>Stripe Account</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-20">
        <Span
          color="#2E2E2E"
          fontSize="14px"
          fontFamily={globalFont}
          textAlign="left"
        >
          Please enter the stripe key in Settings to receive the payment amount
        </Span>
        <Span
          color="#2E2E2E"
          fontSize="14px"
          fontFamily={globalFont}
          textAlign="left"
        >
          Don’t have a stripe account to enter the key?
        </Span>
        <Button
          className="save_active save-btn"
          variant="primary"
          style={{ width: "120px", margin: "6px 0px" }}
          onClick={() => window.open("https://stripe.com", "_blank")}
        >
          Create Account
        </Button>
      </Modal.Body>
    </>
  );
};

export default StripeAlert;
