import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col } from "react-bootstrap";
import { Radio, Select } from "antd";
import suffixIcon from "./CustomSuffixIcon";
import axiosInstance from "../../axios/axios";
import { BsCheckCircle } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Encrypt_Value = (Val, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(Val), key, {
    format: Encode_Decode_JSON,
  }).toString();
};

const Decrypt_Value = (Val, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(Val, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};

function AppointmentDetailsAddCodes({ open, setOpen }) {
  const { Option } = Select;
  const [allcustomecode, setallcustomecode] = useState([]);
  const [codeval, setcodeval] = useState("");
  const [codetextval, setcodetextval] = useState("");
  const [feeval, setfeeval] = useState("");
  const [cer, setcer] = useState(false);
  const [cer1, setcer1] = useState(false);
  const [ter, setter] = useState(false);
  const [yer, setyer] = useState(false);
  const [codetname, setcodetname] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [ModalMsg, setModalMsg] = useState("");
  const [disable, setDisable] = useState(false);
  const [ModalAlerShow1, setModalAlerShow1] = useState(false);

  var provider = Decrypt_Value(localStorage.getItem("user_id"), "vozo");

  const modalAlerShowFls = () => setModalAlerShow(false);

  const modalAlerShow1Fls = () => setModalAlerShow(false);
  const codeValSet = (e) => {
    setcer(false);
    setcer1(false);
    setcodeval(e.target.value);
  };
  const codeTnameVal = (e) => {
    setyer(false);
    setcodetname(e);
  };
  const saveCodes = async (e) => {
    e.preventDefault();

    var err = 0;
    if (codeval === "") {
      setcer(true);
      err = 3;
    }
    if (codetname === "") {
      setyer(true);
      err = 2;
    }
    if (feeval === "") {
      setter(true);
      err = 2;
    }

    await axiosInstance
      .get("/vozo/allcustomcodeNames")
      .then((response) => {
        var res3 = Decrypt_Value(response.data, "vozo");
        const result = res3.find((item) => item.code === codeval);

        if (result) {
          setcer1(true);
          err = 2;
        }
      })
      .catch((err) => {});

    if (err > 0) {
      return false;
    }
    var ssfees = `{"standard":"${feeval}"}`;
    const articles = {
      code: codeval,
      codetype: codetname,
      codetext: codetextval,
      fees: feeval,
      actiontype: "new",
      updateby: provider,
      sfees: ssfees,
    };
    let data1 = Encrypt_Value(articles, "vozo");
    setDisable(true);
    axiosInstance
      .post("/vozo/insertcustomcode_1", data1)
      .then((response) => {
        setcodeval("");
        setcodetname("");
        setcodetextval("");
        setfeeval("");

        setModalMsg("Service code added successfully");
        setModalAlerShow(true);
        setTimeout(() => setModalAlerShow(false), 1500);
        setDisable(false);

        setTimeout(() => handleClose(), 1500);
      })
      .catch((e) => {
        setcodeval("");
        setcodetname("");
        setcodetextval("");
        setfeeval("");
        setOpen(false);
        setDisable(false);
        setModalMsg("Service code added Failed");
        setModalAlerShow1(true);
        setTimeout(() => setModalAlerShow1(false), 1500);
      });
  };
  const feeValSet = (e) => {
    if (!isNaN(e.target.value) || e.target.value === "") {
      setter(false);
      setfeeval(e.target.value);
    }
  };

  const codeTxtValSet = (e) => setcodetextval(e.target.value);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axiosInstance
      .get("/vozo/allcustomcode")
      .then((response) => {
        var res3 = Decrypt_Value(response.data, "vozo");

        setallcustomecode(res3);
      })
      .catch((err) => {
        setallcustomecode([]);
      });
  }, []);
  return (
    <>
      <Modal
        className="add_new_payer  SSB-MD mdl-close topcreate_popup_centre add_service_popup"
        centered
        show={open}
        onHide={handleClose}
      >
        <Modal.Header className="  mdl-padtpbtm1" closeButton>
          <Modal.Title className="m-t-0">Add Services</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mdl-padtpbtm2 md-ssb">
          <Form className="add-contact-form">
            <Form.Row className="code_typ_st_serv">
              <Form.Group className="t-a-l average_container p-r-0" as={Col}>
                <Form.Label className="p-r-0 fl-mrnil">Code Type</Form.Label>
                <div>
                  <Select
                    placeholder="Select"
                    suffixIcon={suffixIcon}
                    // className="arrow-adj"
                    // id="setting_service_code"
                    style={
                      yer
                        ? {
                            border: "1px solid red",
                            borderRadius: "5px",
                            width: "267px",
                          }
                        : { width: "267px" }
                    }
                    onChange={(e) => codeTnameVal(e)}
                    value={codetname ? codetname : undefined}
                  >
                    {allcustomecode.map((row, i) => (
                      <Option
                        key={i}
                        value={row.ct_id}
                        id={`setting_service_code` + i}
                      >
                        {row.ct_label}
                      </Option>
                    ))}
                  </Select>
                  {yer ? (
                    <div className="errormessage">Enter a valid code type</div>
                  ) : (
                    ""
                  )}
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row className="fee_bil_serv">
              <Form.Group as={Col} className="average_container">
                <Form.Label className="bil_srvc_code">Code</Form.Label>
                <div>
                  <Form.Control
                    style={
                      cer || cer1
                        ? {
                            border: "1px solid red",
                            borderRadius: "5px",
                            width: 266,
                          }
                        : { width: 266 }
                    }
                    onChange={(e) => codeValSet(e)}
                    value={codeval}
                    id="setting_service_codein"
                  />
                  {cer ? (
                    <div className="errormessage">Enter a valid code</div>
                  ) : cer1 ? (
                    <div className="errormessage">This Code already Exists</div>
                  ) : (
                    ""
                  )}
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row className="bil_serv_add">
              <Form.Group as={Col} className="average_container m-l-0">
                <Form.Label className="fee_bil_serv">fee</Form.Label>
                <br></br>
                <div className="vald_fee_bilserv">
                  <Form.Control
                    type="text"
                    id="setting_service_fee"
                    style={
                      ter
                        ? {
                            border: "1px solid red",
                            borderRadius: "5px",
                            width: "266px",
                            marginLeft: "0",
                          }
                        : { width: "266px", marginLeft: "0" }
                    }
                    /* //update by cheran 14-7-22 start */
                    onChange={(e) => feeValSet(e)}
                    value={feeval}
                    min="0"
                    className="we-none"
                  />
                  {ter ? (
                    <div className="errormessage">Enter a valid fee</div>
                  ) : (
                    ""
                  )}
                </div>
              </Form.Group>
            </Form.Row>

            <Form.Row className="fee_bil_serv desc_wid">
              <Form.Group as={Col} className="average_container super-pat-data">
                <Form.Label className="desc_details">Description</Form.Label>
                <div>
                  <Form.Control
                    as="textarea"
                    value={codetextval}
                    rows={4}
                    onChange={(e) => codeTxtValSet(e)}
                    id="setting_service_desc"
                  />
                </div>
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="pf-modalfooter ">
          <Button
            className="close_active close-btn"
            variant="light"
            onClick={handleClose}
            id="setting_service_cancelbtn"
          >
            Cancel
          </Button>
          <Button
            className="save_active save-btn m-l-20"
            type="submit"
            variant="primary"
            disabled={disable}
            onClick={saveCodes} //update by cheran 14-7-22
            id="setting_service_savebtn"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        className="alert-popup-message"
        show={ModalAlerShow}
        onHide={modalAlerShowFls}
      >
        <div className="alert msg alert-success" role="alert">
          <BsCheckCircle className="icons-bs" />
          {ModalMsg}
        </div>
      </Modal>

      <Modal
        className="alert-popup-message"
        show={ModalAlerShow1}
        onHide={modalAlerShow1Fls}
      >
        <div className="alert msg alert-danger" role="alert">
          <IoCloseSharp className="icons-bs" />
          {ModalMsg}
        </div>
      </Modal>
    </>
  );
}

export default AppointmentDetailsAddCodes;
