// import { memo } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";

function PrintSuperBill(
  patAppt,
  patData,
  insData,
  code,
  billed,
  insAmt,
  patAmt,
  totalBal,
  adjmt,
  symbol,
  logo,
  footer,
  signLine,
  codeText,
  logoext,
  base64
) {
  const doc = new jsPDF();

  var patAddress =
    patData.pat_street !== "" && patData.pat_street !== null
      ? patData.pat_street + " ,"
      : "";

  patAddress +=
    patData.pat_city !== "" && patData.pat_city !== null
      ? patData.pat_city + " ,"
      : "";

  patAddress +=
    patData.pat_state !== "" && patData.pat_state !== null
      ? patData.pat_state + " - "
      : "";
  patAddress +=
    patData.pat_postal_code !== "" && patData.pat_postal_code !== null
      ? patData.pat_postal_code
      : "";

  var providerAddress =
    patData.us_street !== "" && patData.us_street !== null
      ? patData.us_street + " ,"
      : "";
  providerAddress +=
    patData.us_city !== "" && patData.us_city !== null
      ? patData.us_city + " ,"
      : "";
  providerAddress +=
    patData.us_state !== "" && patData.us_state !== null
      ? patData.us_state + " - "
      : "";
  providerAddress +=
    patData.us_postal_code !== "" && patData.us_postal_code !== null
      ? patData.us_postal_code
      : "";

  var splittedText = doc.splitTextToSize(patAddress, 72);
  var splittedText1 = doc.splitTextToSize(providerAddress, 72);

  const tableColumn = [
    `Date of Service`,
    `Billing Code`,
    `D Ptrs`,
    `Qty`,
    `Fee`,
    `Total`,
  ];
  const tableRows = [];

  const cols = ["DX Pointer", "Diagnosis Code"];
  const rows = [];

  if (Number(codeText) === 1) {
    for (let j = 0; j < code.length; j++) {
      rows.push([
        `${code[j].diagnosis_pointer}`,
        `${code[j].code} - ${code[j].code_text}`,
      ]);
    }
  } else {
    for (let j = 0; j < code.length; j++) {
      rows.push([`${code[j].diagnosis_pointer}`, `${code[j].code}`]);
    }
  }

  for (let i = 0; i < patAppt.length; i++) {
    tableRows.push([
      `${patAppt[i].appt}`,
      `${patAppt[i].code_type} - ${patAppt[i].code}`,
      `${patAppt[i].diagnosis_pointer}`,
      `${patAppt[i].units}`,
      `${symbol} ${patAppt[i].fee}`,
      `${symbol} ${patAppt[i].total}`,
    ]);
  }

  if (billed !== "0" && billed !== undefined) {
    tableRows.push(["", "", "", "", `Total Charges`, `${symbol} ${parseFloat(billed).toFixed(2)}`]);
  }
  if (patAmt !== "0" && patAmt !== undefined) {
    tableRows.push(["", "", "", "", `Patient Paid`, `${symbol} ${parseFloat(patAmt).toFixed(2)}`]);
  }
  if (insAmt !== "0" && insAmt !== undefined) {
    tableRows.push(["", "", "", "", `Insurance Paid`, `${symbol} ${parseFloat(insAmt).toFixed(2)}`]);
  }
  
  if (adjmt !== undefined) {
    tableRows.push(["", "", "", "", `Adjsutment`, `${symbol} ${parseFloat(adjmt).toFixed(2)}`]);
  }
  if (totalBal !== undefined) {
    tableRows.push(["", "", "", "", `Due`, `${symbol} ${parseFloat(totalBal).toFixed(2)}`]);
  }

  var len = tableRows.length;
  var y;

  y = len - 4;

  const date = Date().split(" ");

  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];

  if (logo == "1") {
    // doc.addImage(`${base64}`, logoext, 5, 5, 30, 15);
   
    const validImageBase64 = base64;
    const extension = logoext.toLowerCase();

    if (extension === 'jpg' && validImageBase64.startsWith('data:image/jpg;base64,')) {
      doc.addImage(validImageBase64, 'jpg', 5, 5, 30, 15);
    }
    else if (extension === 'png' && validImageBase64.startsWith('data:image/png;base64,')) {
      doc.addImage(validImageBase64, 'PNG', 5, 5, 30, 15);
    } else if ((extension === 'jpeg' || extension === 'jpg') && validImageBase64.startsWith('data:image/jpeg;base64,')) {

      doc.addImage(validImageBase64, 'JPEG', 5, 5, 30, 15);
    } else if (extension === 'bmp' && validImageBase64.startsWith('data:image/bmp;base64,')) {
      doc.addImage(validImageBase64, 'BMP', 5, 5, 30, 15);
    } else if (extension === 'jfif' && validImageBase64.startsWith('data:image/jfif;base64,')) {
      doc.addImage(validImageBase64, 'JPEG', 5, 5, 30, 15);
    } else {
      console.error('Unsupported image format. Please provide a valid PNG, JPG, JPEG, BMP, or JFIF image.');
    }

  }

  doc.setFontSize(14);
  doc.setFont("helvetica", "bold");
  doc.text("Patient Super Bill", 5, 30);
  doc.setFontSize(13);
  doc.setFont("helvetica", "normal");
  doc.text("Appointment Date :", 5, 40);

  doc.setTextColor(44, 123, 229);
  doc.text(`${patData.appt_date}`, 50, 40);
  doc.setTextColor(0, 0, 0);
  doc.setLineWidth(0.1);
  doc.setDrawColor(235, 234, 237);
  doc.rect(5, 45, 93, 66.5); // left box
  doc.setFont("helvetica", "normal");
  doc.setFontSize(12);
  doc.text("Provider Information", 9, 52.5);
  doc.line(5, 57, 98, 57); // first line

  doc.rect(111, 45, 93, 66.5); // right box
  doc.line(111, 57, 204, 57); // first line
  doc.text("Patient Information", 115, 52.5);

  doc.setFontSize(10.5);
  doc.setTextColor(132, 129, 138);
  doc.setFont("helvetica", "bold");
  doc.text("Name", 9, 66);
  doc.text("Service Code", 9, 76);
  doc.text("Provider Address", 9, 84);
  doc.text("Office Phone", 9, 102);
  doc.text("Email", 9, 110);

  doc.text("Name", 115, 66);
  doc.text("Date Of Birth", 115, 76);
  doc.text("Patient Address", 115, 84);
  doc.text("Patient Phone", 115, 102);
  doc.text("Email", 115, 110);

  doc.rect(5, 122, 93, 36);
  doc.text("Insurer", 9, 130);
  doc.text("Subscriber", 9, 138);
  doc.text("Group #", 9, 147);
  doc.text("Member #", 9, 155);

  doc.setTextColor(0, 0, 0);
  doc.setFont("helvetica", "normal");
  doc.text(`${patData.pat_name}`, 148, 66);
  doc.text(`${patData.DOB !== null ? patData.DOB : ""}`, 148, 76);
  let final1 = 84;
  if (splittedText.length > 1) {
    for (let k = 0; k < splittedText.length; k++) {
      doc.text(148, final1, `${splittedText[k]}`);
      final1 = final1 + 4;
    }
  } else {
    doc.text(148, final1, `${splittedText}`);
  }
  doc.text(`${patData.phone_cell}`, 148, 102);
  doc.text(`${patData.pat_email}`, 148, 110);

  doc.text(`${patData.pro_name}`, 43, 66);
  doc.text(`${patData.pid}`, 43, 76);
  let final2 = 84;
  if (splittedText1.length > 1) {
    for (let k = 0; k < splittedText1.length; k++) {
      doc.text(43, final2, `${splittedText1[k]}`);
      final2 = final2 + 4;
    }
  } else {
    doc.text(43, final2, `${splittedText1}`);
  }
  doc.text(`${patData.pro_phone}`, 43, 102);
  doc.text(`${patData.pro_email}`, 43, 110, { maxWidth: 48 });

  doc.text(
    `${insData.insurer}` !== "undefined" ? `${insData.insurer}` : "",
    35,
    130
  );
  doc.text(
    `${insData.subscriber_name}` !== "undefined"
      ? `${insData.subscriber_name}`
      : "",
    35,
    138
  );
  doc.text(
    `${insData.group_number}` !== "undefined" ? `${insData.group_number}` : "",
    35,
    147
  );
  doc.text(
    `${insData.member_id}` !== "undefined" ? `${insData.member_id}` : "",
    35,
    155
  );

  if (code.length !== 0) {
    doc.autoTable(cols, rows, {
      headStyles: {
        fillColor: (247, 247, 247),
        textColor: (0, 0, 0),
        fontStyle: "bold",
        halign: "left",
        fontSize: 11,
      },
      startY: 165,
      theme: "plain",
      tableWidth: 220,
      margin: { top: 20, bottom: 20, left: 0 },
      styles: {
        cellPadding: { top: 4, bottom: 4, left: 5, right: 8 },
        fontSize: 11,
      },

      willDrawCell: (data) => {
        if (data.row.section !== "head") {
          doc.setDrawColor(216, 224, 240);
          doc.setLineWidth(0.2);
          doc.line(
            data.cell.x + data.column.width,
            data.cell.y + data.row.height,
            data.cell.x,
            data.cell.y + data.row.height
          );
        } else {
          if (data.pageCount > 1) {
            return false;
          }
        }
      },
    });
    var lastline = doc.previousAutoTable.finalY;

    doc.autoTable(tableColumn, tableRows, {
      headStyles: {
        fillColor: (247, 247, 247),
        textColor: (0, 0, 0),
        fontStyle: "bold",
        halign: "left",
        fontSize: 11,
      },
      startY: lastline + 10,
      theme: "plain",
      tableWidth: 220,
      margin: { top: 20, bottom: 20, left: 0 },
      styles: {
        cellPadding: { top: 4, bottom: 4, left: 5, right: 8 },
        fontSize: 11,
      },

      willDrawCell: (data) => {
        if (data.row.index !== 0 && data.row.index < y) {
          doc.setDrawColor(216, 224, 240);
          doc.setLineWidth(0.2);
          doc.line(
            data.cell.x + data.column.width,
            data.cell.y,
            data.cell.x,
            data.cell.y
          );
        }
        if (data.row.section === "head") {
          if (data.pageCount > 1) {
            return false;
          }
        }
      },
    });
  } else {
    doc.autoTable(tableColumn, tableRows, {
      headStyles: {
        fillColor: (247, 247, 247),
        textColor: (0, 0, 0),
        fontStyle: "bold",
        halign: "left",
        fontSize: 11,
      },
      startY: 165,
      theme: "plain",
      tableWidth: 220,
      margin: { top: 20, bottom: 20, left: 0 },
      styles: {
        cellPadding: { top: 4, bottom: 4, left: 5, right: 8 },
        fontSize: 11,
      },

      willDrawCell: (data) => {
        if (data.row.index !== 0 && data.row.index < y) {
          doc.setDrawColor(216, 224, 240);
          doc.setLineWidth(0.2);
          doc.line(
            data.cell.x + data.column.width,
            data.cell.y,
            data.cell.x,
            data.cell.y
          );
        }
        if (data.row.section === "head") {
          if (data.pageCount > 1) {
            return false;
          }
        }
      },
    });
  }

  const checkfinal1 = (a) => {
    if (a > 265) {
      doc.addPage();
      last = 44;
    } else {
      last = a + 10;
    }
  };

  var last = doc.previousAutoTable.finalY;

  checkfinal1(last);

  //sign line
  if (signLine == "1") {
    doc.text(
      "I authorize the release of any medical information necessary to process this claim.",
      10,
      last,
      {
        baseline: "bottom",
      }
    );

    checkfinal1(last);

    doc.text("Date:", 10, last, { baseline: "bottom" });
    doc.text("Patient signature:", 70, last, { baseline: "bottom" });

    doc.setLineWidth(0.1);
    doc.line(101, last, 135, last);

    doc.line(20, last, 50, last);

    checkfinal1(last);

    doc.text("Date:", 10, last, { baseline: "bottom" });
    doc.text("Provider signature:", 70, last, {
      baseline: "bottom",
    });

    doc.line(101, last, 135, last);

    doc.line(20, last, 50, last);
  }

  const pageCount = doc.internal.getNumberOfPages();

  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    doc.line(0, pageHeight - 20, pageWidth, pageHeight - 20);
    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);

    doc.text(
      footer,
      pageWidth / 2 - doc.getTextWidth(footer) / 2,
      pageHeight - 10,
      { baseline: "bottom" }
    );
  }

  doc.save(`report_${dateStr}.pdf`);
}

export default PrintSuperBill;
