import React, { useState, useEffect, memo, useRef, forwardRef } from "react";
import { useDispatch } from "react-redux";
import { Spinner, Button, Modal, FormControl } from "react-bootstrap";
import AddAllergy from "../popups/AddAllergy";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import axiosInstance from "../../axios/axios";
import moment from "moment";
import { BsDownload } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import { FiTrash2 } from "react-icons/all";
import { CSVLink } from "react-csv";
import GeneratePDF from "./GeneratePdf";
import { changeAppointmentValues } from "../../StateManagement/Reducers/ScheduleState";
import { SpinnerDiv } from "../../StyledComponents";

import {
  Table,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  TableHead,
} from "../setting/PatientPortalSettings/StyledComponents";

import { Tablerow } from "../patient/StyledComponent";
import { useSelector } from "react-redux";
import UpgradeRequired from "./UpgradeRequired";
import { globalColor } from "../utils/RepeatFunctions/Schedule";

var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Decrypt_Value = (data, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(data, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};

const Encrypt_Value = (Val, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(Val), key, {
    format: Encode_Decode_JSON,
  }).toString();
};

const VitalsTable = (props, ref) => {
  // ------------------------------  State and Variables Start  --------------------------------------------------- //

  const csvLinkRef = useRef(null);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [encDate, setEncDate] = useState(moment("MM-DD-YYYY"));
  const [date, setdate] = useState("");
  const [bloodp1, setbloodp1] = useState("");
  const [bloodp2, setbloodp2] = useState("");
  const [height, setheight] = useState("");
  const [weight, setweight] = useState("");
  const [pulse, setpulse] = useState("");
  const [rrate, setrrate] = useState("");
  const [temp, settemp] = useState("");
  const [prebp1, setprebp1] = useState("");
  const [prebp2, setprebp2] = useState("");
  const [preheight, setpreheight] = useState("");
  const [preweight, setpreweight] = useState("");
  const [prepulse, setprepulse] = useState("");
  const [prerrate, setprerrate] = useState("");
  const [pretemp, setpretemp] = useState("");
  const [dt, setdt] = useState("");
  const [sex, setsex] = useState("");
  const [pro, setpro] = useState("");
  const [now, setnow] = useState("");
  const [render, setrender] = useState(false);
  const [SpinnerVal, setSpinnerVal] = useState(true);
  const [csvData, setcsvData] = useState([]);
  const callFunction = useSelector((state) => state.Schedule.callFunction);
  const [tblVert, setTblVert] = useState("");
  const [upgrade, setUpgrade] = useState(false);

  let accountType = Decrypt_Value(localStorage.getItem("account_type"), "vozo");
  let trialPeriod = Decrypt_Value(localStorage.getItem("trial_period"), "vozo");
  // ------------------------------ State and Variables end--------------------------------------------------- //
  // ------------------------------  Functions start  --------------------------------------------------- //

  const removeNull = (x, y = "integer") => {
    if (x && x !== null) {
      return x;
    } else {
      return y === "date" ? "" : 0;
    }
  };

  const handleClose = () => {
    setShow(false);
    setrender(false);
  };

  const bloodp1Function = (e) => {
    const value = e.target.value;
    if (value === "" || (!isNaN(value) && /^-?\d*\.?\d*$/.test(value))) {
      setbloodp1(e.target.value);
      dispatch(
        changeAppointmentValues({ name: "save button", value: "vitals" })
      );
    }
  };
  const bloodp2Function = (e) => {
    const value = e.target.value;
    if (value === "" || (!isNaN(value) && /^-?\d*\.?\d*$/.test(value))) {
      setbloodp2(e.target.value);
      dispatch(
        changeAppointmentValues({ name: "save button", value: "vitals" })
      );
    }
  };
  const tempFunction = (e) => {
    const value = e.target.value;
    if (value === "" || (!isNaN(value) && /^-?\d*\.?\d*$/.test(value))) {
      settemp(value);
      dispatch(
        changeAppointmentValues({ name: "save button", value: "vitals" })
      );
    }
  };
  const pulseFunction = (e) => {
    const value = e.target.value;
    if (value === "" || (!isNaN(value) && /^-?\d*\.?\d*$/.test(value))) {
      setpulse(value);
      dispatch(
        changeAppointmentValues({ name: "save button", value: "vitals" })
      );
    }
  };
  const weightFunction = (e) => {
    const value = e.target.value;
    if (value === "" || (!isNaN(value) && /^-?\d*\.?\d*$/.test(value))) {
      setweight(value);
      dispatch(
        changeAppointmentValues({ name: "save button", value: "vitals" })
      );
    }
  };
  const heightFunction = (e) => {
    const value = e.target.value;
    if (value === "" || (!isNaN(value) && /^-?\d*\.?\d*$/.test(value))) {
      setheight(value);
      dispatch(
        changeAppointmentValues({ name: "save button", value: "vitals" })
      );
    }
  };
  const rRateFunction = (e) => {
    const value = e.target.value;
    if (value === "" || (!isNaN(value) && /^-?\d*\.?\d*$/.test(value))) {
      setrrate(value);
      dispatch(
        changeAppointmentValues({ name: "save button", value: "vitals" })
      );
    }
  };

  const generate = () => {
    const input = document.getElementById("PdfRender");
    html2canvas(input).then((canvas) => {
      let imgWidth = 213;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;

      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("vitals.pdf");
    });
  };

  const buttonDetailClickActions = async (e) => {
    e !== "avoid" && e.preventDefault();
    var apt_id = props.name;
    let ddate = moment(new Date()).format("YYYY-MM-DD H:mm:ss");
    var vitalarticle = {
      date: ddate,
      bps1: bloodp1,
      bps2: bloodp2,
      temperature: temp,
      pulse: pulse,
      weight: weight,
      height: height,
      respiration: rrate,
    };

    let encryptedData = Encrypt_Value(vitalarticle, "vozo");

    props.setspinnerfornewapp();
    axiosInstance
      .post("/vozo/form/vitals/insert?id=" + apt_id, encryptedData)
      .then(() => {
        props.savealertmsg("Vitals Details Updated", true);
        setTimeout(() => props.popuphide(), 2000);
        props.FetchAppointments(
          props.provarray,
          props.facarray,
          props.appFromDate,
          props.apptoDate,
          props.pat_share,
          props.examroom
        );
      })

      .catch((err) => console.error(err));
  };
  const printVital = () => {
    axiosInstance
      .get("/vozo/patient/vital?id=" + props.name)
      .then((response) => {
        let temp_role_pat_vital = Decrypt_Value(response.data, "vozo");

        GeneratePDF(temp_role_pat_vital);
      })
      .catch((err) => console.error(err));
  };

  const getVitalsData = async () => {
    await axiosInstance
      .get("/vozo/patient/vital?id=" + props.name)
      .then((response) => {
        let temp_role_pat_vital = Decrypt_Value(response.data, "vozo");
        setEncDate(temp_role_pat_vital.encounter_date);
        setdt(temp_role_pat_vital.p_name);
        setsex(temp_role_pat_vital.sex);
        if (temp_role_pat_vital.prev_date) {
          setdate(temp_role_pat_vital.prev_date);
        }
        if (temp_role_pat_vital.bps_1) {
          setbloodp1(temp_role_pat_vital.bps_1);
        }
        if (temp_role_pat_vital.bps_2) {
          setbloodp2(temp_role_pat_vital.bps_2);
        }
        if (temp_role_pat_vital.height) {
          setheight(temp_role_pat_vital.height);
        }
        if (temp_role_pat_vital.weight) {
          setweight(temp_role_pat_vital.weight);
        }
        if (temp_role_pat_vital.pulse) {
          setpulse(temp_role_pat_vital.pulse);
        }
        if (temp_role_pat_vital.respiration) {
          setrrate(temp_role_pat_vital.respiration);
        }
        if (temp_role_pat_vital.temperature) {
          settemp(temp_role_pat_vital.temperature);
        }
        setSpinnerVal(false);
        if (temp_role_pat_vital.prev_bps1 === null) {
          setprebp1("");
        } else {
          setprebp1(temp_role_pat_vital.prev_bps1);
        }

        if (temp_role_pat_vital.prev_bps2 === null) {
          setprebp2("");
        } else {
          setprebp2(temp_role_pat_vital.prev_bps2);
        }

        if (temp_role_pat_vital.prev_ht === null) {
          setpreheight("");
        } else {
          setpreheight(temp_role_pat_vital.prev_ht);
        }

        if (temp_role_pat_vital.prev_wt === null) {
          setpreweight("");
        } else {
          setpreweight(temp_role_pat_vital.prev_wt);
        }

        if (temp_role_pat_vital.prev_temp === null) {
          setpretemp("");
        } else {
          setpretemp(temp_role_pat_vital.prev_temp);
        }

        if (temp_role_pat_vital.prev_pulse) {
          setprepulse(temp_role_pat_vital.prev_pulse);
        }

        if (temp_role_pat_vital.prev_rate) {
          setprerrate(temp_role_pat_vital.prev_rate);
        }

        if (temp_role_pat_vital.u_name) {
          setpro(temp_role_pat_vital.u_name);
        }
        if (temp_role_pat_vital.date) {
          setnow(temp_role_pat_vital.date);
        }
      })
      .catch((err) => console.error(err));
    await axiosInstance
      .get("/vozo/patient/vital?id=" + props.name)
      .then((response) => {
        let temp_role_pat_vital1 = Decrypt_Value(response.data, "vozo");

        var temp = [
          [
            `Patient : ${temp_role_pat_vital1.p_name} date : ${temp_role_pat_vital1.encounter_date}`,
          ],
          [
            "",
            `Last Service : ${removeNull(
              temp_role_pat_vital1.prev_date,
              "date"
            )}`,
            "Current Service",
          ],
          [
            "Blood Pressure",
            `${removeNull(temp_role_pat_vital1.prev_bps1)}/${removeNull(
              temp_role_pat_vital1.prev_bps2
            )} mmHG`,
            `${removeNull(temp_role_pat_vital1.bps_1)}/${removeNull(
              temp_role_pat_vital1.bps_2
            )} mmHG`,
          ],
          [
            "Temperature",
            temp_role_pat_vital1.prev_temp + " f",

            `${removeNull(temp_role_pat_vital1.temperature)} f`,
          ],
          [
            "Pulse",
            temp_role_pat_vital1.prev_pulse + " bps",

            `${removeNull(temp_role_pat_vital1.pulse)} bps`,
          ],
          [
            "Weight",
            temp_role_pat_vital1.prev_wt + " lbs",

            `${removeNull(temp_role_pat_vital1.weight)} lbs`,
          ],
          [
            "Height",
            temp_role_pat_vital1.prev_ht + " in",
            `${removeNull(temp_role_pat_vital1.height)} in`,
          ],
          [
            "Respiratory Rate",
            temp_role_pat_vital1.prev_rate + " rpm",
            `${removeNull(temp_role_pat_vital1.respiration)} rpm`,
          ],
        ];
        setcsvData(temp);
      })
      .catch((err) => console.error(err));
    if (props.loadVal) props.loadVal();
  };

  const handleExportClick = (event) => {
    event.preventDefault();
    if (accountType > "1" || (accountType === "0" && trialPeriod)) {
      csvLinkRef.current.link.click();
    } else {
      setUpgrade(true);
    }
  };

  const handlecloseUpgrade = () => setUpgrade(false);

  // ------------------------------ Functions End--------------------------------------------------- //

  // --------------------------=---- useEffect start--------------------------------------------------- //

  useEffect(() => {
    getVitalsData();

    if (props.open) {
      setrender(true);
    }
  }, []);

  useEffect(() => {
    if (callFunction["vitals"] > 1) {
      buttonDetailClickActions("avoid");
    }
  }, [callFunction["vitals"]]);

  // --------------------------=---- useEffect End--------------------------------------------------- //

  return (
    <>
      {SpinnerVal ? (
        <SpinnerDiv height="320px">
          <Spinner animation="border" variant="primary" />
        </SpinnerDiv>
      ) : (
        <>
          <Modal.Body className="vitalsModal adj-mdb-ht-scrll">
            <div className="for-vitals-border tab_container vitals_whole_div">
              <div className="appt_heading">Vitals</div>
              <Table
                className={`testing_tab servicetable-width vitaltable-width`}
              >
                <TableBody className="appt-head vitals_table_body">
                  <Tablerow
                    color={globalColor}
                    className={`schedule-head-row vit_tabl_vertical_rowhead vit-tbl-first-row`}
                  >
                    <TableBodyCell width="auto"></TableBodyCell>
                    <TableBodyCell
                      className={`${date.length <= 0 && "height"} vit_font`}
                      textAlign="center"
                    >
                      {date.length > 0 ? date : "&nbsp;"}
                    </TableBodyCell>

                    <TableBodyCell
                      textAlign="center"
                      className="vit_tab_current_serv vit_font"
                    >
                      Current Readings
                    </TableBodyCell>
                  </Tablerow>

                  <Tablerow className="table-data-row schedule-body-row vit_tabl_vertical_rowbody1">
                    <TableBodyCell className="vit_font" textAlign="center">
                      Blood Pressure (mmHG)
                    </TableBodyCell>

                    <TableBodyCell
                      className="vit_font"
                      textAlign="center"
                    >{`${prebp1} / ${prebp2}`}</TableBodyCell>

                    <TableBodyCell className="vit_font" textAlign="center">
                      <div className="flex-center">
                        <FormControl
                          id="vitalbpressure1"
                          className="vitals-bp vit_font"
                          value={bloodp1}
                          onChange={(e) => bloodp1Function(e)}
                          onKeyPress={bloodp1Function}
                          // disabled={props.FullScreen}
                        />
                        <div className="slash-vitals">/</div>
                        <FormControl
                          className="vitals-bp"
                          id="vitalbpressure2"
                          value={bloodp2}
                          onChange={(e) => bloodp2Function(e)}
                          onKeyPress={bloodp2Function}
                          // disabled={props.FullScreen}
                        />
                      </div>
                    </TableBodyCell>
                  </Tablerow>

                  <Tablerow className="table-data-row schedule-body-row vit_tabl_vertical_rowbody2">
                    <TableBodyCell className="vit_font" textAlign="center">
                      Temperature (f)
                    </TableBodyCell>
                    <TableBodyCell className="vit_font" textAlign="center">
                      {pretemp}
                    </TableBodyCell>

                    <TableBodyCell className="vit_font" textAlign="center">
                      <div className="flex-center">
                        <FormControl
                          id="vitaltemprature"
                          className="w-100px vit_font"
                          name="temp"
                          value={temp}
                          onChange={(e) => tempFunction(e)}
                          onKeyPress={tempFunction}
                          // disabled={props.FullScreen}
                        />
                      </div>
                    </TableBodyCell>
                  </Tablerow>

                  <Tablerow className="table-data-row schedule-body-row">
                    <TableBodyCell className="vit_font" textAlign="center">
                      Pulse (bps)
                    </TableBodyCell>

                    <TableBodyCell className="vit_font" textAlign="center">
                      {prepulse}
                    </TableBodyCell>

                    <TableBodyCell textAlign="center">
                      <div className="flex-center">
                        <FormControl
                          id="vitalpulse"
                          className="w-100px vit_font"
                          name="pulse"
                          value={pulse}
                          onChange={(e) => pulseFunction(e)}
                          // disabled={props.FullScreen}
                          onKeyPress={pulseFunction}
                        />
                      </div>
                    </TableBodyCell>
                  </Tablerow>

                  <Tablerow className="table-data-row schedule-body-row">
                    <TableBodyCell className="vit_font" textAlign="center">
                      Weight (lbs)
                    </TableBodyCell>

                    <TableBodyCell className="vit_font" textAlign="center">
                      {preweight}
                    </TableBodyCell>

                    <TableBodyCell textAlign="center">
                      <div className="flex-center">
                        <FormControl
                          id="vitalweight"
                          className="w-100px vit_font"
                          name="weight"
                          value={weight}
                          onChange={(e) => weightFunction(e)}
                          // disabled={props.FullScreen}
                          onKeyPress={weightFunction}
                        />
                      </div>
                    </TableBodyCell>
                  </Tablerow>

                  <Tablerow className="table-data-row schedule-body-row">
                    <TableBodyCell className="vit_font" textAlign="center">
                      Height (in)
                    </TableBodyCell>

                    <TableBodyCell className="vit_font" textAlign="center">
                      {preheight}
                    </TableBodyCell>

                    <TableBodyCell textAlign="center">
                      <div className="flex-center">
                        <FormControl
                          id="vitalheight"
                          className="w-100px vit_font"
                          name="height"
                          value={height}
                          onChange={(e) => heightFunction(e)}
                          // disabled={props.FullScreen}
                          onKeyPress={heightFunction}
                        />
                      </div>
                    </TableBodyCell>
                  </Tablerow>

                  <Tablerow className="table-data-row schedule-body-row">
                    <TableBodyCell className="vit_font" textAlign="center">
                      Respiratory Rate (rpm)
                    </TableBodyCell>

                    <TableBodyCell className="vit_font" textAlign="center">
                      {prerrate}
                    </TableBodyCell>

                    <TableBodyCell textAlign="center">
                      <div className="flex-center">
                        <FormControl
                          id="vitalrespiration"
                          className="w-100px vit_font"
                          name="resp"
                          value={rrate}
                          onChange={(e) => rRateFunction(e)}
                          // disabled={props.FullScreen}
                          onKeyPress={rRateFunction}
                        />
                      </div>
                    </TableBodyCell>
                  </Tablerow>
                </TableBody>
              </Table>
              <div
                // style={{display:'flex'}}
                className={`bbn-align vital-bottom-btn flex `}
              >
                <div className="vital_history_heading">Full Vital History</div>
                <div className="d-flex">
                  <Button
                    className="vitalbutton"
                    variant="outline-secondary grey-out-btn m-r-16"
                    onClick={printVital}
                    disabled={props.tabstatus}
                  >
                    Download as PDF
                  </Button>

                  <CSVLink
                    data={csvData}
                    filename={"vitals_csv_" + encDate}
                    ref={csvLinkRef}
                  >
                    <Button
                      className="vitalbutton1"
                      disabled={props.tabstatus}
                      variant="outline-secondary grey-out-btn"
                      onClick={handleExportClick}
                    >
                      Download as CSV
                    </Button>
                  </CSVLink>
                </div>
              </div>
            </div>
          </Modal.Body>
        </>
      )}

      <Modal className="client-popup" show={show} onHide={handleClose}>
        <AddAllergy />
        <Modal.Footer>
          <Button className="close-btn" variant="light" onClick={handleClose}>
            Cancel
          </Button>

          <Button className="save-btn" variant="primary" onClick={handleClose}>
            Save
          </Button>
          <Button
            className="fill-btn m-l-au grey-color"
            variant="outline-secondary"
            onClick={handleClose}
          >
            Save and Add Another
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="client-popup pdfgen" show={render} onHide={handleClose}>
        <Modal.Header className="modal-header1">
          <div className="btn  float-right ">
            <BsDownload
              onClick={() => generate()}
              className="h-icon bsa-font"
            />
          </div>

          <div className="add-task-close">
            <IoCloseSharp className="bsa-font" onClick={handleClose} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div id="PdfRender">
            <div className="flex-cen-space m-r-20 m-l-20">
              <div>
                <span className="bold">Patient : </span>
                <span>{dt}</span>
              </div>
              <div>
                <span className="bold">Sex : </span>
                <span>{sex}</span>
              </div>
            </div>
            <div className="flex-cen-space m-r-20 m-l-20">
              <div>
                <span className="bold">Provider : </span>
                <span>{pro}</span>
              </div>
              <div>
                <span className="bold">Chart ID : </span>
              </div>
            </div>
            <hr />
            <div className="w-100 vsn-his">{`VITAL SIGNS HISTORY FOR ${dt}`}</div>
            <div className="w-100 al-margin">
              <span className="bold">Created On : </span>
              <span>{now}</span>
            </div>
            <div className="flex-cen-space mini-heading">
              <div>System Vitals</div>
              <div>{now}</div>
            </div>
            <div className="formstyle">
              <div>Blood Pressure (mmHG)</div>
              <div>{`${bloodp1}/${bloodp2}`}</div>
            </div>
            <div className="formstyle">
              <div>Weight (lbs)</div>
              <div>{weight}</div>
            </div>
            <div className="formstyle">
              <div>Height (in)</div>
              <div>{height}</div>
            </div>
            <div className="formstyle">
              <div>pulse (bps)</div>
              <div>{pulse}</div>
            </div>
            <div className="formstyle">
              <div>Temperature (f)</div>
              <div>{temp}</div>
            </div>
            <div className="formstyle">
              <div>Respiratory Rate (rpm)</div>
              <div>{rrate}</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {upgrade && (
        <UpgradeRequired
          title="Secure your Data by Exporting"
          description="Ensure the data privacy, security and integrity of your data by exporting and securely storing the data. "
          boxContent={
            <span>
              Available in
              <span className="upgradFont"> Premium</span> plan
            </span>
          }
          handlecloseUpgrade={handlecloseUpgrade}
        />
      )}
    </>
  );
};

export default memo(forwardRef(VitalsTable));
