import React from "react";
import ReactToPrint from "react-to-print";
import { Col, Row } from "antd";
import axiosInstance from "../../axios/axios";
import InvoicePdf from "./InvoiceReportGenerator";
import moment from "moment";
import Loader from "../popups/Loading";
import { fileurl } from "../../axios/url.js";

var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Decrypt_Value = (data, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(data, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};

const Encrypt_Value = (Val, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(Val), key, {
    format: Encode_Decode_JSON,
  }).toString();
};

class ComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props);

    // ------------------------------------------- State and Variables Start--------------------------------------------------- //

    this.state = {
      eid: this.props.eid,
      shareinvoice: [],
      invoicename: "",
      invoicetotal: "",
      invodate: "",
      units: "",
      patname: "",
      pataddr: "",
      patcity: "",
      patzip: "",
      patstate: "",
      patmob: "",
      patemail: "",
      proname: "",
      facilityname: "",
      proaddr: "",
      prostate: "",
      procounty: "",
      protax: "",
      proemail: "",
      status: this.props.invs,
      InvoiceLogo: "",
      invoiceFooter: "",
      code_text: "",
      testurl:
        fileurl +
        "/sites/" +
        Decrypt_Value(localStorage.getItem("DBHost"), "vozo") +
        "/documentimg/",
      logoname: "",
      copay: this.props.copay,
      article: { invoicename: this.props.invoid },
      len: 0,
      base64: "",
      symbol: "",
      user_ID: Decrypt_Value(localStorage.getItem("user_id"), "vozo"),
      dateFormat: localStorage.getItem("dateFormat"),

      SpinnerVal: true,
    };
    this.getDatas = this.getDatas.bind(this);
  }

  // ------------------------------------------- State and Variables Start--------------------------------------------------- //
  getDatas = async () => {
    if (this.state.copay === "1") {
      var insert_invpoice_encryptedData = Encrypt_Value(
        this.state.article,
        "vozo"
      );
      await axiosInstance
        .put(
          "/vozo/showinsuranceinvoice?eid=" + this.state.eid,
          insert_invpoice_encryptedData
        )
        .then((response) => {
          var temp_role_show_inv_name = Decrypt_Value(response.data, "vozo");
          this.props.needData(temp_role_show_inv_name);
          this.setState({ code_text: temp_role_show_inv_name[0].code_text });

          this.setState({ shareinvoice: temp_role_show_inv_name });
          this.setState({ invoicename: temp_role_show_inv_name[0].invoice_id });
          this.setState({ invodate: temp_role_show_inv_name[0].date });
          this.setState({ invoicetotal: temp_role_show_inv_name[0].fee });
          this.setState({ SpinnerVal: false });
        });
    } else {
      let insert_invpoice_encryptedData = Encrypt_Value(
        this.state.article,
        "vozo"
      );
      await axiosInstance
        .put(
          "/vozo/insertinvoice?eid=" + this.state.eid + "&status=showdata",
          insert_invpoice_encryptedData
        )
        .then((response) => {
          var temp_role_insert_invoice = Decrypt_Value(response.data, "vozo");

          var count = temp_role_insert_invoice.length;

          this.props.needData(temp_role_insert_invoice);

          this.setState({ shareinvoice: temp_role_insert_invoice });
          this.setState({
            invoicename: temp_role_insert_invoice[0].invoice_id,
          });
          this.setState({ invodate: temp_role_insert_invoice[0].date });
          this.setState({ units: temp_role_insert_invoice[0].units });
          this.setState({ len: temp_role_insert_invoice.length });
          this.setState({
            invoicetotal: temp_role_insert_invoice[count - 1].totalfee,
          });

          this.setState({ SpinnerVal: false });
        })
        .catch((err) => {
          this.setState({ SpinnerVal: false });
          console.log(err.message);
        });
    }

    await axiosInstance
      .get(
        "/vozo/invoice/getdetails?eid=" +
          this.state.eid +
          "&uid=" +
          this.state.user_ID
      )
      .then((response) => {
        var temp_role_get_inv_details = Decrypt_Value(response.data, "vozo");
        this.props.prov_patDetails(temp_role_get_inv_details);
        this.setState({
          patname:
            temp_role_get_inv_details[0].fname +
            " " +
            temp_role_get_inv_details[0].lname,
        });
        this.setState({ pataddr: temp_role_get_inv_details[0].street });
        this.setState({ patcity: temp_role_get_inv_details[0].city });
        this.setState({ patstate: temp_role_get_inv_details[0].state });
        this.setState({ patzip: temp_role_get_inv_details[0].postal_code });
        this.setState({ patmob: temp_role_get_inv_details[0].phone_cell });
        this.setState({ patemail: temp_role_get_inv_details[0].email });
        this.setState({
          proname:
            temp_role_get_inv_details[1].fname +
            " " +
            temp_role_get_inv_details[1].lname,
        });
        this.setState({
          facilityname: temp_role_get_inv_details[1].facilityname,
        });
        this.setState({ proaddr: temp_role_get_inv_details[1].billingaddress });
        this.setState({ prostate: temp_role_get_inv_details[1].billingstate });
        this.setState({
          procounty: temp_role_get_inv_details[1].billingcountry,
        });
        this.setState({ protax: temp_role_get_inv_details[1].federaltaxid });
        this.setState({ proemail: temp_role_get_inv_details[1].email });

        axiosInstance
          .get(
            "/vozo/setting/billing/currencydata?uid=" +
              temp_role_get_inv_details[1].id
          )
          .then((response) => {
            let temp_role = Decrypt_Value(response.data, "vozo");
            this.props.currency(temp_role[0].currency);
            this.setState({ symbol: temp_role[0].currency });
          })
          .catch((err) => {
            this.setState({ symbol: "" });
            console.log(err);
          });
      });

    await axiosInstance.get("/vozo/settingdocumentdata").then((response) => {
      var temp_role_set_doc_data = Decrypt_Value(response.data, "vozo");
      this.props.document(temp_role_set_doc_data);
      this.setState({
        logoname: temp_role_set_doc_data[4]["gl_value"],
        InvoiceLogo: temp_role_set_doc_data[3]["gl_value"],
        invoiceFooter: temp_role_set_doc_data[2]["gl_value"],
      });

      let imgfile = {
        url: this.state.testurl + temp_role_set_doc_data[4]["gl_value"],
      };
      axiosInstance
        .post("/vozo/document/img/base64", imgfile)
        .then((res) => {
          this.props.base(res.data);
          this.setState({
            base64: res.data,
          });
        })
        .catch((err) => {
          this.setState({
            base64: "",
          });
        });
    });
  };
  // ------------------------------ componentDidMount Start--------------------------------------------------- //

  componentDidMount() {
    this.getDatas();
  }

  // ------------------------------ componentDidMount End--------------------------------------------------- //

  render() {
    return (
      <>
        {this.state.SpinnerVal ? (
          <Loader />
        ) : (
          <div className="text-center invoice-content w-100">
            <Row className="bg-bill1 inv-header-pad">
              <Col span={8}>
                {this.state.InvoiceLogo === "1" ? (
                  <div className="invoice-no-d dis-flex-center w-100 h-100">
                    <img
                      src={this.state.testurl + this.state.logoname}
                      className="align-center in-logo-exm"
                      alt="Logo"
                    ></img>
                  </div>
                ) : (
                  ""
                )}
              </Col>
              <Col span={7}>
                {this.state.status === "Paid" ? (
                  <div className="inv-stat">PAID</div>
                ) : (
                  ""
                )}
              </Col>
              <Col span={9}>
                <div className="invoice-no-dt">
                  <div className="invoice fw-500">Invoice</div>

                  <div className="fixed-height">
                    <table className="font-s" cellSpacing="2">
                      <tbody>
                        <tr className="fxh-pop fs-15">
                          <td className="align-top" width="130">
                            Invoice Number&nbsp;
                          </td>
                          <td rowSpan="2" className="hr-line1"></td>
                          <td className="text-left td-border-data1 pl-2">
                            {this.state.invoicename}
                          </td>
                        </tr>
                        <tr className="fs-16 lh-19">
                          <td className="text-left td-border">Issue Date</td>

                          <td className="td-border-data1 pl-2">
                            {this.state.invodate
                              ? moment(this.state.invodate).format(
                                  this.state.dateFormat
                                )
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="bg-bill pt-24-pb-10">
              <Col span={8}>
                <Col className="pl-40">
                  <div className="invoice-no-d">
                    <div className="bill-from">Bill From:</div>
                    <div className="invoice-date bill-text-clr">
                      {this.state.facilityname}
                    </div>
                    <div className="invoice-date bill-text-clr">
                      {this.state.proaddr}
                    </div>
                    <div className="invoice-date bill-text-clr">
                      {this.state.prostate + ","} {this.state.procounty}
                    </div>
                  </div>
                </Col>
              </Col>
              <Col span={7}></Col>
              <Col span={9}>
                <div className="invoice-no-dt">
                  <div className="bill-from ">Bill To:</div>
                  <div className="invoice-date bill-text-clr ">
                    {this.state.patname}
                  </div>
                  <div className="invoice-date bill-text-clr ">
                    {this.state.pataddr !== "" ? this.state.pataddr + ", " : ""}
                    {this.state.patcity}
                  </div>
                  <div className="invoice-date bill-text-clr ">
                    {this.state.patstate !== "" && this.state.patstate !== null
                      ? this.state.patstate + ", "
                      : ""}
                    {this.state.patzip}
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="bg-bill Invoice_table_Row">
              <Col span={8} className="pl-40">
                <div className="invoice-no-d">
                  <div className="bill-from">Provider:</div>
                  <div className="invoice-date bill-text-clr">
                    {this.state.proname}
                  </div>
                  <div className="invoice-date bill-text-clr">
                    Tax ID: {this.state.protax}
                  </div>
                  <div className="invoice-date bill-text-clr">
                    {this.state.proemail}
                  </div>
                </div>
              </Col>
              <Col span={7} className="bill-bottom"></Col>
              <Col span={9} className="bill-bottom">
                <div className="invoice-no-dt">
                  <div className="bill-from ">Client:</div>
                  <div className="invoice-date bill-text-clr ">
                    {this.state.patname}
                  </div>
                  <div className="invoice-date bill-text-clr ">
                    {this.state.patmob}
                  </div>
                  <div className="invoice-date bill-text-clr ">
                    {this.state.patemail}
                  </div>
                </div>
              </Col>
              <table className="table table-s Invoice_table_">
                {this.state.copay === "1" ? (
                  <>
                    <thead className="bg-bill1">
                      <tr>
                        <th className="text-left pl-5" colSpan={3}>
                          Description
                        </th>
                        <th></th>
                        <th className="text-left pl-5" colSpan={3}>
                          Amount
                        </th>
                      </tr>
                    </thead>
                  </>
                ) : (
                  <>
                    <thead className="bg-bill1">
                      <tr>
                        <th className="text-left pl-5" scope="col">
                          Date
                        </th>
                        <th className="text-left pl-4" scope="col">
                          Description
                        </th>
                        <th className="text-left pl-4" scope="col">
                          Units
                        </th>
                        <th className="text-left pl-5" scope="col">
                          Amount
                        </th>
                      </tr>
                    </thead>
                  </>
                )}
                <tbody>
                  {this.state.shareinvoice !== "" ? (
                    <>
                      {this.state.shareinvoice.map((row, index) => (
                        <React.Fragment key={index}>
                          {row.units !== undefined && (
                            <tr key={index} className="in-tab-exm">
                              <td className="text-left pl-5 color-td ">
                                {row.date
                                  ? moment(row.date).format(
                                      this.state.dateFormat
                                    )
                                  : ""}
                              </td>
                              <td className="text-left pl-4 color-td">
                                {row.code_text}
                              </td>
                              <td className="text-left pl-4 color-td">
                                {row.units}
                              </td>
                              <td className="text-left pl-5 color-td1">
                                <div>
                                  {row.fee
                                    ? `${this.state.symbol}${row.fee}`
                                    : ""}
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                  {this.state.copay === "1" ? (
                    <>
                      <>
                        <tr
                          key={this.state.invoicetotal}
                          className="in-tab-exm"
                        >
                          <td colSpan={3} className="text-left pl-5 color-td1">
                            {this.state.code_text}
                          </td>
                          <td></td>
                          <td colSpan={3} className="text-left pl-5 color-td1">
                            {this.state.symbol} {this.state.invoicetotal}
                          </td>
                        </tr>
                      </>
                    </>
                  ) : (
                    <></>
                  )}
                  {this.state.copay === "1" ? (
                    <tr key={this.state.facilityname}>
                      <td colSpan={2} className="bt-none"></td>
                      <td></td>
                      <td className="text-left pr-5 color-td in-tab-footer make_width">
                        Total
                        <div className="m-t-16 m-l">Make payments to</div>
                      </td>
                      <td className="text-left pl-5 color-td2 in-tab-footer fw-500">
                        <div>
                          {[this.state.symbol, this.state.invoicetotal]}
                        </div>
                        <div className="color-td m-t-16 fw-400">
                          {this.state.facilityname}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr key={this.state.facilityname}>
                      <td colSpan={2} className="bt-none"></td>
                      <td className="text-left pr-5 color-td in-tab-footer make_width">
                        Total
                        <div className="m-t-16">Make payments to</div>
                      </td>
                      <td className="text-left pl-5 color-td2 in-tab-footer fw-500">
                        <div>
                          {[this.state.symbol, this.state.invoicetotal]}
                        </div>
                        <div className="color-td m-t-16 fw-400">
                          {this.state.facilityname}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Row>
            {this.state.invoiceFooter !== "" ? (
              <Row className="white-color">
                <div className="inv-footer-print grey-color w-100 fs-16 m-t-20 m-b-20 alg-cen">
                  {this.state.invoiceFooter}
                </div>
              </Row>
            ) : null}
          </div>
        )}
      </>
    );
  }
}

class Example extends React.Component {
  constructor(props) {
    super(props);
    // ------------------------------------------- State and Variables Start--------------------------------------------------- //

    this.state = {
      eaid: this.props.eid,
      invoiceid: this.props.invoiceid,
      invoiceFooter: "",
      copay: this.props.copay,
      invoiceStatus: this.props.invs,

      shareinvoice: [],
      patprov: [],
      documentdata: [],
      symbol: "",
      base64: "",
    };
  }

  // ------------------------------------------- State and Variables Start--------------------------------------------------- //

  // ------------------------------ componentDidMount Start--------------------------------------------------- //

  componentDidMount() {
    axiosInstance.get("/vozo/invoice/logo_footer").then((response) => {
      var temp_role_inv_logo_footer = Decrypt_Value(response.data, "vozo");
      this.setState({
        invoiceFooter: temp_role_inv_logo_footer[0]["gl_value"],
      });
    });
  }

  // ------------------------------ componentDidMount End--------------------------------------------------- //

  setFromOutside() {
    document.getElementById("printbtn").click();
  }

  needData = (a) => {
    this.setState({
      shareinvoice: a,
    });
  };
  document = (a) => {
    this.setState({
      documentdata: a,
    });
  };
  prov_patDetails = (a) => {
    this.setState({
      patprov: a,
    });
  };
  currency = (a) => {
    this.setState({
      symbol: a,
    });
  };
  base = (a) => {
    this.setState({
      base64: a,
    });
  };
  generatePDF() {
    InvoicePdf(
      this.state.shareinvoice,
      this.state.documentdata,
      this.state.patprov,

      this.state.base64,

      this.state.symbol,
      this.props.invs,
      this.state.copay
    );
  }

  render() {
    return (
      <div id="PdfRender">
        <ReactToPrint
          pageStyle="
          @page { 
           margin: 0mm ; }
           @media print { body { -webkit-print-color-adjust: exact; } }
            @media print {
            div.inv-footer-print {
            position: fixed;
            bottom:0mm;
            width: 100%;
            height: 50px;
            font-size: 16px;
            color:grey;
           page-break-after: always;
            }}
            "
          trigger={() => (
            <button className="btn btn-primary hidden" id="printbtn">
              Print this out!
            </button>
          )}
          content={() => this.componentRef}
        />
        <ComponentToPrint
          ref={(el) => (this.componentRef = el)}
          invs={this.state.invoiceStatus}
          eid={this.state.eaid}
          invoid={this.state.invoiceid}
          copay={this.state.copay}
          needData={this.needData}
          document={this.document}
          prov_patDetails={this.prov_patDetails}
          currency={this.currency}
          base={this.base}
        />
      </div>
    );
  }
}

export default Example;
