const LoadScripts = (callback) => {
  const existingScript = document.getElementById("clinicaltables");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src =
      "https://clinicaltables.nlm.nih.gov/autocomplete-lhc-versions/17.0.2/autocomplete-lhc.min.js";
    script.id = "clinicaltables";
    script.setAttribute("defer", "defer");
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};
export default LoadScripts;
