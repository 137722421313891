import React, { useState, useEffect, memo, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  changeAppointmentValues,
  changeAppointmentStatus,
  changeAppointmentStatusAll,
} from "../../StateManagement/Reducers/ScheduleState";
import { Modal, Form } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import PeopleOutline from "@material-ui/icons/PeopleOutline";
import RoomOutlined from "@material-ui/icons/RoomOutlined";
import LocalHospitalOutlined from "@material-ui/icons/LocalHospitalOutlined";
import PersonOutline from "@material-ui/icons/PersonOutline";
import moment from "moment";
import axiosInstance from "../../axios/axios";
import axios from "axios";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { IoFlagOutline, IoWalletOutline } from "react-icons/io5";
import doctorLogo from "../../assets/images/fontisto_doctor.png";
import BillingFacilityIcon from "../../assets/images/BillingFacilityIcon.png";
import { BsExclamationOctagon } from "react-icons/bs";
import { DatePicker, Checkbox, Select, Radio, InputNumber } from "antd";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { BsFillCaretDownFill } from "react-icons/bs";
import { MdOutlineArrowDropUp, MdOutlineArrowDropDown } from "react-icons/md";

import {
  Spinner,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import {
  RepeatPopupBlock,
  RepeatPopupHeader,
  RepeatPopupBodyText,
  RepeatPopupBodyBtn,
  RepeatBtn,
  RepeatFooter,
  RepeatBtnConfirm,
} from "../schedule/styles";
import { Dropdown as Dropdownant } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Decrypt_Value, Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { Image, SpinnerDiv } from "../../StyledComponents";
import { Div, Span } from "../billing/CardTransactions/styles";
import { useSelector } from "react-redux";
import { repeatArray } from "../utils/RepeatFunctions/Schedule";
import AvailabilityError from "../../StyledComponents/AvailabilityError/AvailabilityError";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const NewAppointmentDetail = (props, ref) => {
  const classes = useStyles();
  const { Option } = Select;

  // ---------------------------------------- State and Variables Start--------------------------------------------------- //

  const dispatch = useDispatch();
  var regexFirstNum = /^[a-zA-Z].*/;

  const [dayswise, setdayswise] = useState(false);

  var start = props.start.start;
  var end = props.start.end;
  var id = props.start.Id;
  const dtFormat = localStorage.getItem("dateFormat");
  if (props.start.start === undefined) {
    start = new Date(`${props.start.pc_eventDate} ${props.start.pc_startTime}`);
    end = new Date(`${props.start.pc_eventDate} ${props.start.pc_endTime}`);
  }
  var encounter = props.start.encounter;

  var startformat = moment(start).format("YYYY-MM-DD HH:mm:ss");
  var endformat = moment(end).format("YYYY-MM-DD HH:mm:ss");
  var duration = moment(endformat).diff(moment(startformat), "minutes");
  const modifyAppointment = useSelector(
    (state) => state.ScheduleWidget.modifyAppointment
  );
  const ChangeStatusForAll = useSelector(
    (state) => state.Schedule.ChangeStatusAll
  );
  const repeatCheckForStatusChange = useSelector(
    (state) => state.Schedule.repeatCheck
  );

  const [sourceroom, setSourceRoom] = useState([]);
  const [roomval, setRoomVal] = useState("");

  const [editing, setEditing] = useState(duration);
  const [SpinnerVal, setSpinnerVal] = useState(true);
  const [repeatselectnew, setrepeatselectnew] = useState("");
  const [repeatselect2new, setrepeatselect2new] = useState("");
  const [profileError, setprofileError] = useState(false);
  const [provError, setprovError] = useState(false);
  const [examroomError, setexamroomError] = useState(false);
  const [officeError, setofficeError] = useState(false);

  const [selectdayError, setselectdayError] = useState(false);

  const [apptdate, setapptdate] = useState(
    moment(
      props.start.start === undefined
        ? props.start.pc_eventDate
        : props.start.start
    ).format(dtFormat)
  );

  const [WeeklyDisable, setWeeklyDisable] = useState("");
  const [statusCheck, setStatusCheck] = useState(false);
  const [appttimedet, setappttimedet] = useState();
  const [patid, setpatid] = useState();
  const [sourceprovider, setSourceprovider] = useState([]);
  const [providerid, setproviderid] = useState("");
  const [sourceprofile, setSourceprofile] = useState([]);
  const [profileid, setprofileid] = useState("");
  const [sourceoffice, setSourceoffice] = useState([]);
  const [officeid, setofficeid] = useState();
  const [sourcestatus, setSourcestatus] = useState([]);
  const [sourcestatustele, setSourcestatustele] = useState([]);
  const [teleprofile, setteleprofile] = useState([]);
  const [statusid, setstatusid] = useState(null);
  const [patFlag, setPatFlag] = useState([]);
  const [weekday, setweekday] = useState("");

  const [teleCheck, setTeleCheck] = useState(false);
  const [aptfromdate, setappfromdate] = useState("");
  const [apttodate, setapptodate] = useState("");
  const [swict_check, setswict_check] = useState(true);
  const [swict_drop, setswict_drop] = useState(false);

  const [repeats_checked, setrepeats_checked] = useState(false);

  const [dayswise_c, setdayswise_c] = useState(false);
  const [timelimiterrorbox, settimelimiterrorbox] = useState(false);

  const [notDisabledSpec, setNotDisabledSpec] = useState("");
  const dateFormat = "YYYY-MM-DD";
  const [apttype, setapttype] = useState("");
  const [custState, setCustState] = useState(false);
  const [ModalAlerShow1, setModalAlerShow1] = useState(false);
  const [ModalMsg1, setModalMsg1] = useState("");
  var userID = Decrypt_Value(localStorage.getItem("user_id"), "vozo");

  var main_pro = Decrypt_Value(localStorage.getItem("main_pro"), "vozo");
  var owner_id = Decrypt_Value(localStorage.getItem("owner_id"), "vozo");
  var authorized = Decrypt_Value(localStorage.getItem("authorized"), "vozo");
  var accountType = Decrypt_Value(localStorage.getItem("account_type"), "vozo");
  var trial = Decrypt_Value(localStorage.getItem("trial_period"), "vozo");

  var storedNames = Decrypt_Value(localStorage.getItem("setroles"), "vozo");
  var group_id = Decrypt_Value(localStorage.getItem("group_id"), "vozo");
  const defaultRepeatBtn = {
    current: false,
    future: false,
    all: false,
  };

  const [repeatbtn, setrepeatbtn] = useState(defaultRepeatBtn);
  const [repeatpopup, setrepeatpopup] = useState(false);

  const initialRender = useRef(true);

  const [startTime, setStartTime] = useState(9);
  const [endTime, setEndTime] = useState(17);
  const [btnDis, setBtnDis] = useState(false);
  const callFunction = useSelector((state) => state.Schedule.callFunction);

  const [repvalue, setrepValue] = useState("");

  const [billingid, setbillingid] = useState("");
  const [billingprofile, setBillingProfile] = useState([]);
  const [billingError, setBillingError] = useState(false);
  const [inTimeErr, setInTimeErr] = useState(false);

  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");

  const [dyOfMont, setDyOfMont] = useState("");
  const [wkOfMont, setWkOfMont] = useState("");
  const [checkValue, setCheckValue] = useState("");
  const [tempOpen, setTempOpen] = useState(false);

  const [inTime, setInTime] = useState("");
  const [outTime, setOutTime] = useState("");
  const [fromShow, setFromShow] = useState(false);
  const [toShow, setToShow] = useState(false);
  const [repeatselectmonth, setRepeatSelectMonth] = useState("1");
  const [custChk, setcustChk] = useState("");
  const [Week_and_every, setWeek_and_every] = useState(false);
  const [Month_and_every, setMonth_and_every] = useState(false);

  const [does_repeat_edit, setdoes_repeat_edit] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [antDropdown, setAntDropdown] = useState("");
  const [endErrTime, setEndErrTime] = useState("");
  const [startErrTime, setStartErrTime] = useState("");
  const [frtimeSlots, setFromTimeSlots] = useState([]);
  const [totimeSlots, setToTimeSlots] = useState([]);
  const [billFacility, setBillFacility] = useState([]);

  const [billFacilityID, setBillFacilityID] = useState("");
  const [billFacilityError, setBillFacilityError] = useState(false);

  let GMeetURL = "";
  let firstDot1 = window.location.hostname.split(".");
  if (firstDot1[0] !== "localhost") {
    GMeetURL = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot1[0]
    );
  } else {
    GMeetURL = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  const [speciality, setSpeciality] = useState("");
  const [specialError, setSpecialError] = useState(false);
  const [walkInRatio, setWalkInRatio] = useState(false);

  const [userAvail, setUserAvail] = useState([]);

  const [lastdate, setLastdate] = useState(false);
  const [lastdateApp, setLastdateApp] = useState("");

  const [sortSpeciality, setSortSpecility] = useState("");
  const specialityList = [
    "Plastic Surgery",
    "Dermatology",
    "Sleep Medicine",
    "Allergy & Immunology",
    "Concierge Medicine",
    "Oncology",
    "Palliative Care",
    "Endocrinology",
    "Sports Medicine",
    "Hospice",
    "Podiatry",
    "Pediatrics",
    "Pathology",
    "Obstetrics and Gynecology",
    "Hand Surgery",
    "Hematology",
    "Emergency Medicine",
    "Surgery",
    "Cardiology",
    "Vascular Surgery",
    "Pulmonology",
    "Austism",
    "Radiology",
    "ECG Management",
    "Physical Medicine & Rehabilitation",
    "Opthalmology",
    "Geriatric Medicine",
    "Neurology",
    "Infectious Disease",
    "Nephrology",
    "Ambulatory Care & Surgery",
    "Otolaryngology (ENT)",
    "Rheumatology",
    "Dental Health",
    "Chiropractic",
    "Psychiatry",
    "Urology",
    "Wound Care",
    "Orthopedic Surgery",
    "Behaviour Health",
    "Gastroenterology",
    "Anesthesiology",
    "Family Medicine",
    "Behavioral health therapy",
    "Acupuncture",
    "Lactation consulting",
    "Massage therapy",
    "Counseling",
    "Marriage and family therapy",
    "Psychology",
    "Social work",
    "Dietetics or nutrition counseling",
    "Applied behavior analysis",
    "Occupational therapy",
    "Physical therapy",
    "Speech-language pathology",
    "Substance use counseling",
    "Other",
  ];

  const mouseClick = () => {
    setShowOptions(true);
    setAntDropdown("");
  };

  const handleMouseLeave = () => {
    setShowOptions(false);
    setAntDropdown("disp_newAppt");
  };

  const modalhide = () => {
    setModalAlerShow1(false);
    setModalMsg1("");
  };

  const handleDropdownVisibleChange = (visible) => setShowOptions(visible);

  window.onclick = function (e) {
    if (fromShow && tempOpen === false) {
      setFromShow(false);
    } else if (toShow && tempOpen === false) {
      setToShow(false);
    }
  };

  // ---------------------------------------- State and Variables End--------------------------------------------------- //

  // ---------------------------------------- Functions Start----------------------------------------------------------- //
  const handleBillingFacility = (event, data) => {
    setBillFacilityID(data.value);
    setBillFacilityError(false);
    dispatch(
      changeAppointmentValues({ name: "save button", value: "appointment" })
    );
  };
  const disabledFromTimes = (time, meridiem) => {
    const now = moment();
    const currentTime = moment(`${time} ${meridiem}`, "h:mm A");
    const appointmentDate = moment(apptdate);
    if (appointmentDate.isSame(now, "day")) {
      if (teleCheck && currentTime.isBefore(now)) {
        return true;
      }
    }

    return false;
  };

  const disabledToTime = (time, meridiem, id) => {
    if (!inTime) return false;

    const fromTime = moment(inTime, "h:mm A");
    const toTime = moment(`${time} ${meridiem}`, "h:mm A");
    if (toTime.format("h:mm A") === "12:00 AM") {
      return false;
    }
    if (fromTime.isAfter(toTime) || fromTime.isSame(toTime)) {
      return true;
    }

    return false;
  };

  const WeekdayChange = (val) => {
    const hasOnlyEmptyStrings =
      val.length > 0 && val.every((item) => item === "");
    if (hasOnlyEmptyStrings) {
      setweekday("");
    } else {
      setweekday(val);
    }
    setselectdayError(false);
    dispatch(
      changeAppointmentValues({ name: "save button", value: "appointment" })
    );
  };

  const onChange = (timeStandard, typeTime) => {
    if (timeStandard === "Invalid date" && typeTime === "inTime") {
      setInTime(moment(start).format("h:mm A"));
      setOutTime(
        moment(moment(start, "h:mm A").add(15, "minutes")).format("h:mm A")
      );
      let out = moment(moment(start, "h:mm A").add(15, "minutes")).format(
        "h:mm A"
      );
      let dur = moment(out, "hh:mm a").diff(
        moment(inTime, "hh:mm a"),
        "minutes"
      );

      setEditing(dur);
    } else if (timeStandard === "Invalid date" && typeTime === "outTime") {
      setOutTime(
        moment(moment(inTime, "h:mm A").add(15, "minutes")).format("h:mm A")
      );

      let out = moment(moment(inTime, "h:mm A").add(15, "minutes")).format(
        "h:mm A"
      );

      let dur = moment(outTime, "hh:mm a").diff(
        moment(out, "hh:mm a"),
        "minutes"
      );

      setEditing(dur);
    } else {
      let time = timeStandard.split(":");
      let timeMin = time[1].split(" ");

      if (typeTime === "inTime") {
        setInTimeErr(false);
        setOutTime(
          moment(moment(timeStandard, "h:mm A").add(15, "minutes")).format(
            "h:mm A"
          )
        );

        let intim = moment(timeStandard, "h:mm A").format("h:mm A");
        let out = moment(
          moment(timeStandard, "h:mm A").add(15, "minutes")
        ).format("h:mm A");
        if (intim[1] === "PM") {
          if (
            moment(out, "h:mm A").isBetween(
              moment("12:00 AM", "h:mm A"),
              moment("1:00 AM", "h:mm A"),
              null,
              "[)"
            )
          ) {
            out = moment(out, "h:mm A").add(1, "day");
          }
        }
        let dur = moment(out, "hh:mm a").diff(
          moment(intim, "hh:mm a"),
          "minutes"
        );
        setEditing(dur);

        if (timeMin[1] === "AM") {
          if (time[0] === "12") {
            time[0] = Number(time[0]) - 12;
          }
          if (time[0] < Number(startTime)) {
            settimelimiterrorbox(true);
            return false;
          } else {
            settimelimiterrorbox(false);
          }
        } else {
          if (time[0] !== "12") {
            time[0] = Number(time[0]) + 12;
          }
          if (time[0] >= Number(endTime)) {
            settimelimiterrorbox(true);
            return false;
          } else {
            settimelimiterrorbox(false);
          }
        }
        setappttimedet(timeStandard);
      } else {
        let inTimeNew = inTime.split(":");
        let inTimeMin = inTimeNew[1].split(" ");
        if (inTimeNew[0] === "12" && inTimeMin[1] === "AM") {
          inTimeNew[0] = "00";
        }
        let out = moment(timeStandard, "h:mm A").format("h:mm A");
        if (inTimeMin[1] === "PM") {
          if (
            moment(out, "h:mm A").isBetween(
              moment("12:00 AM", "h:mm A"),
              moment("1:00 AM", "h:mm A"),
              null,
              "[)"
            )
          ) {
            out = moment(out, "h:mm A").add(1, "day");
          }
        }
        let duration = moment(out, "hh:mm a").diff(
          moment(inTime, "hh:mm a"),
          "minutes"
        );

        setEditing(duration);

        if (inTime === timeStandard) {
          setInTimeErr(true);
        }
        if (inTime !== timeStandard) {
          setInTimeErr(false);
        }
        if (timeMin[1] === "AM") {
          if (inTimeMin[1] === "PM" && Number(inTimeNew[0]) !== 12) {
            inTimeNew[0] = Number(inTimeNew[0]) + 12;
          }
          if (time[0] > Number(endTime) || inTimeNew[0] < Number(startTime)) {
            setInTimeErr(true);
            return false;
          } else if (
            (inTimeMin[1] === "PM" && time[0] !== "12") ||
            (Number(inTimeNew[0]) > Number(time[0]) &&
              Number(time[0]) !== 12) ||
            (inTimeNew[0] === time[0] &&
              inTimeMin[0] >= timeMin[0] &&
              time[0] !== "12") ||
            (time[0] === inTimeNew[0] &&
              timeMin[0] <= inTimeMin[0] &&
              time[0] !== "12")
          ) {
            setInTimeErr(true);
            return false;
          } else if (
            (Number(inTimeNew[0]) > time[0] && time[0] !== "12") ||
            (Number(inTimeNew[0]) === time[0] && inTimeMin[0] > timeMin[0])
          ) {
            setInTimeErr(true);
            return false;
          } else {
            setInTimeErr(false);
            settimelimiterrorbox(false);
          }
        } else {
          if (Number(time[0]) !== 12) {
            time[0] = Number(time[0]) + 12;
          }
          if (inTimeMin[1] === "PM" && Number(inTimeNew[0]) !== 12) {
            inTimeNew[0] = Number(inTimeNew[0]) + 12;
          }
          if (inTimeMin[1] === "AM" && Number(inTimeNew[0]) === 12) {
            inTimeNew[0] = 0;
          }
          if (Number(time[0]) === Number(endTime) && Number(timeMin[0]) > 0) {
            settimelimiterrorbox(true);
            return false;
          }
          if (Number(time[0]) === Number(endTime) && Number(timeMin[0]) == 0) {
            settimelimiterrorbox(false);
          }
          if (Number(time[0]) > Number(endTime)) {
            settimelimiterrorbox(true);
            return false;
          }
          if (Number(time[0]) < Number(endTime)) {
            settimelimiterrorbox(false);
          }
          if (Number(inTimeNew[0]) > Number(time[0])) {
            settimelimiterrorbox(true);
            return false;
          }
          if (Number(inTimeNew[0]) < Number(time[0])) {
            settimelimiterrorbox(false);
          }
          if (
            Number(inTimeNew[0]) === Number(time[0]) &&
            Number(inTimeMin[0]) > Number(timeMin[0])
          ) {
            setInTimeErr(true);
            return false;
          }
          if (
            Number(inTimeNew[0]) === Number(time[0]) &&
            Number(inTimeMin[0]) < Number(timeMin[0])
          ) {
            setInTimeErr(false);
          }
          if (Number(inTimeNew[0]) < Number(startTime)) {
            settimelimiterrorbox(true);
          }
        }
      }
    }
  };

  const changeSpeciality = (data) => {
    setproviderid("");
    setSpeciality(data);
    setSpecialError(false);
    setInTimeErr(false);
    dispatch(
      changeAppointmentValues({ name: "save button", value: "appointment" })
    );
  };

  const handleDropDownSelect = (event, data) => {
    let options;
    if (teleCheck) {
      options = teleprofile;
    } else {
      options = sourceprofile;
    }
    for (var j = 0; j < options.length; j++) {
      if (options[j].value.includes(data.value)) {
        if (options[j].value == data.value) {
          if (teleCheck) {
            setbillingid("");
          } else {
            if (
              options[j].pc_bill_profile !== "DEFAULT" &&
              (options[j].pc_bill_profile ?? []).length > 0
            ) {
              setbillingid(options[j].pc_bill_profile);
            } else {
              setbillingid("");
            }
          }
          var filter_pc_duration = options[j].pc_duration;
          if (options[j].pc_duration !== "" || options[j].pc_duration !== "0") {
            let outTimeVal = moment(inTime, "h:mm A").add(
              options[j].pc_duration,
              "minutes"
            );
            setOutTime(moment(outTimeVal).format("h:mm A"));
          }
        }
      }
    }

    settimelimiterrorbox();
    setInTimeErr();
    setprofileid(data.value);
    setEditing(filter_pc_duration);
    dispatch(
      changeAppointmentValues({ name: "save button", value: "appointment" })
    );
  };

  const getRoom = (id) => {
    axiosInstance
      .get("/vozonew/facilityRoom?id=" + id)
      .then((res) => {
        let decode = Decrypt_Value(res.data, "vozo");
        setRoomList(decode.examRoom);
      })
      .catch((err) => {});
  };

  const handleRoomVal = (value) => {
    setRoomVal(value);
    setexamroomError(false);
    dispatch(
      changeAppointmentValues({ name: "save button", value: "appointment" })
    );
  };

  var i = 0;
  const setRoomList = (val) => {
    setSourceRoom([]);
    i = parseInt(val);
    for (let j = 1; j <= i; j++) {
      let obj = {
        text: "Exam Room " + j,
        title: "Exam Room " + j,
        value: `${j}`,
      };
      setSourceRoom((item) => [...item, obj]);
    }
  };
  const changeCheckbox = (value) => {
    if (new Date(apptdate) > new Date() && value === "arrived") {
      setStatusCheck(true);
      setTimeout(() => {
        setStatusCheck(false);
      }, 3000);
      return false;
    }
    setCheckValue(() => {
      return value;
    });
    if (value === "arrived") {
      if (teleCheck) {
        setstatusid("doc_completed");
      } else {
        setstatusid("@");
      }
    } else if (value === "cancelled") {
      if (teleCheck) {
        setstatusid("doc_cancelled");
      } else {
        setstatusid("x");
      }
    }
    dispatch(
      changeAppointmentValues({ name: "save button", value: "appointment" })
    );
  };

  const handleDropDownSelectOffice = (event, data) => {
    setofficeid(data.value);
    setRoomVal("");
    setSpeciality("");
    dispatch(
      changeAppointmentValues({ name: "save button", value: "appointment" })
    );

    setproviderid("");
    getRoom(data.value);
  };

  const handleCloseAlert = () => {
    setStatusCheck(false);
  };

  const handleDropDownSelectStatus = (event, data) => {
    if (
      new Date(apptdate) > new Date() &&
      data.value === "@" &&
      Number(encounter) === 0
    ) {
      setStatusCheck(true);
      setTimeout(() => {
        setStatusCheck(false);
      }, 3000);
      return false;
    }
    setstatusid(data.value);
    setCheckValue("");
    // if (
    //   (encounter === "0" || Number(encounter) === 0) &&
    //   (data.value === "@" || data.value === "doc_completed")
    // ) {
    //   if (props.start.recurrance === 0 || props.start.recurrance === "0") {
    //   } else {
    //     setrepeatbtn((repeatbtn) => ({
    //       ...defaultRepeatBtn,
    //       current: true,
    //     }));
    //   }
    //   dispatch(
    //     changeAppointmentStatus({
    //       value: true,
    //       id: id,
    //       repeatOrNot: props.start.recurrance,
    //     })
    //   );

    //   // buttonDetailClickActions(data.value);
    // }
    dispatch(
      changeAppointmentValues({ name: "save button", value: "appointment" })
    );
  };

  const handleDropDownSelectProvider = (event, data) => {
    setproviderid(data.value);
    setprovError(false);
    setInTimeErr(false);
    dispatch(
      changeAppointmentValues({ name: "save button", value: "appointment" })
    );
  };

  const days_check = () => {
    setswict_check(!swict_check);
    setswict_drop(!swict_drop);
    setdayswise(!dayswise);
    setdayswise_c(!dayswise_c);
    setrepeatselectnew("");
    setrepeatselect2new("");
    dispatch(
      changeAppointmentValues({ name: "save button", value: "appointment" })
    );
  };

  const handleClick = () => {
    if (props.start_dis) {
      props.closepopupedit();
    }
    if (repeats_checked || does_repeat_edit) {
      if (props.start.recurrance) {
        setrepeatpopup(true);
      } else {
        buttonDetailClickActions("");
      }
    } else {
      buttonDetailClickActions("");
    }
  };

  const handleDropDownBilling = (e, data) => {
    setbillingid(data.value);
    setBillingError(false);
    dispatch(
      changeAppointmentValues({ name: "save button", value: "appointment" })
    );
  };

  const convertHMS = (value) => {
    return Math.floor(value * 60);
  };

  function reformatDate(inputDate, ddFormat) {
    // Determine the indices of day, month, and year from ddFormat
    const formatParts = ddFormat.split(/[-/]/);
    const dateParts = inputDate.split(/[-/]/);

    let dayIndex, monthIndex, yearIndex;
    let day, month, year;

    if (formatParts.includes("DD")) {
      dayIndex = formatParts.indexOf("DD");
    } else {
      throw new Error("Date format must include 'DD'");
    }

    if (formatParts.includes("MM")) {
      monthIndex = formatParts.indexOf("MM");
    } else {
      throw new Error("Date format must include 'MM'");
    }

    if (formatParts.includes("YYYY") || formatParts.includes("YY")) {
      yearIndex =
        formatParts.indexOf("YYYY") !== -1
          ? formatParts.indexOf("YYYY")
          : formatParts.indexOf("YY");
    } else {
      throw new Error("Date format must include 'YYYY' or 'YY'");
    }

    // Extract day, month, and year based on indices
    day = parseInt(dateParts[dayIndex], 10);
    month = parseInt(dateParts[monthIndex], 10) - 1; // Month is zero-based in JavaScript
    year = parseInt(dateParts[yearIndex], 10);

    const date = new Date(Date.UTC(year, month, day));

    if (isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }

    return date.toISOString().split("T")[0];
  }

  const getWeekdayDistance = (eventDate1, targetWeekday) => {
    const eventDate = new Date(eventDate1);

    const targetWeekdayJavaScript = targetWeekday % 7; // Adjust to JavaScript's weekday index

    const currentWeekday = eventDate.getDay(); // JavaScript's weekday index (0 for Sunday)

    // Calculate days to the target weekday
    const daysToWeekday = (targetWeekdayJavaScript - currentWeekday + 7) % 7;
    const targetDate = new Date(eventDate);
    targetDate.setDate(targetDate.getDate() + daysToWeekday);

    // Calculate the distance in days
    const distance = Math.abs((targetDate - eventDate) / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    return distance;
  };

  function getNthWeekdayOfMonth(year, month, weekday, n) {
    // Get the first day of the month
    let date = new Date(year, month, 1);

    // Adjust weekday index if Sunday is represented as 1
    let dayOfWeek = date.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    let diff = (weekday - dayOfWeek + 7) % 7;
    date.setDate(1 + diff);

    // Move to the nth occurrence
    date.setDate(date.getDate() + (n - 1) * 7);

    return date;
  }

  const buttonDetailClickActions = async (statusNewValue = "") => {
    let MonthOccurences;
    if (inTimeErr || timelimiterrorbox) {
      dispatch(changeAppointmentValues({ name: "reset call", value: "" }));
      return false;
    }
    if (props.stopvideo === "block") {
      props.validpopShow();
      dispatch(changeAppointmentValues({ name: "reset call", value: "" }));
      return false;
    }

    let inserttype = "Does not repeat";
    var statusid_temp;
    if (statusNewValue !== "") {
      statusid_temp = statusNewValue;
    } else {
      if (repeats_checked === true) {
        statusid_temp = "";
        inserttype = "weekly";
      } else {
        statusid_temp = statusid;
      }
    }
    const timenew_alert = moment(appttimedet, ["h:mm A"]).format("HH:mm");
    const time = timenew_alert.split(":");
    const timeHour = time[0];
    const a = moment(timenew_alert, "hh:mm A")
      .add(editing, "minutes")
      .format("hh:mm A");

    const maintime = moment(a, "h:mma");
    var b = moment(`${endTime}:00pm`, "h:mma");
    if (timeHour < Number(startTime) || maintime.isAfter(b)) {
      settimelimiterrorbox(true);

      dispatch(changeAppointmentValues({ name: "reset call", value: "" }));
      return false;
    } else {
      settimelimiterrorbox(false);
    }

    let splitTime = appttimedet.split(":");
    let splitMin = splitTime[1].split(" ");

    if (splitMin[1] === "PM" && Number(splitTime[0]) !== Number(12)) {
      let totTime = +splitTime[0] + +12;
      if (totTime >= outTime) {
        settimelimiterrorbox(true);
        return false;
      } else if (totTime < startTime) {
        settimelimiterrorbox(true);
        return false;
      }
    }
    let availcheckfilt = userAvail.filter((x) => {
      return x.pc_eventDate === apptdate;
    });

    if (availcheckfilt.length !== 0) {
      for (let appointment of availcheckfilt) {
        let intimeString = appointment.pc_eventDate + " " + inTime;
        let outtimeString = appointment.pc_eventDate + " " + outTime;

        let intimeDate = new Date(intimeString);
        let outtimeDate = new Date(outtimeString);
        let startTimeString =
          appointment.pc_eventDate + " " + appointment.pc_startTime;
        let endTimeString =
          appointment.pc_eventDate + " " + appointment.pc_endTime;
        let startTimeDate = new Date(startTimeString);
        let endTimeDate = new Date(endTimeString);

        // Check if outtime is the same as pc_startTime
        if (outtimeDate.getTime() === startTimeDate.getTime()) {
          // Skip the if condition and continue to the next appointment
          continue;
        }

        // Check if intime is the same as pc_endTime
        if (intimeDate.getTime() === endTimeDate.getTime()) {
          // Skip the if condition and continue to the next appointment
          continue;
        }

        // Check for overlap only if the conditions above are not met
        if (
          (startTimeDate <= outtimeDate && endTimeDate >= intimeDate) ||
          (endTimeDate >= intimeDate && startTimeDate <= outtimeDate) ||
          (startTimeDate <= intimeDate && endTimeDate >= outtimeDate)
        ) {
          setInTimeErr(true);
          dispatch(changeAppointmentValues({ name: "reset call", value: "" }));
          return false;
          // You might add a break statement here if you want to exit the loop after finding an overlap
        } else {
          setInTimeErr(false);
        }
      }
    }

    let outSplitTime = outTime.split(":");
    let outSplitMin = outSplitTime[1].split("");

    if (outSplitMin[1] === "PM" && Number(outSplitTime[0]) !== Number(12)) {
      let totTime = +outSplitTime[0] + +12;
      if (totTime >= outTime) {
        settimelimiterrorbox(true);
        return false;
      }
    }

    var dttime = apptdate + " " + appttimedet;
    var dtFormatWithTime1 = `${dtFormat} hh:mm a`;

    var appttimedet1 = moment(dttime, dtFormatWithTime1).format("hh:mm A");
    var id = props.start.Id;
    var encounternew = encounter;
    var timenew = apptdate + " " + appttimedet1;

    var timedetnew = moment(timenew, dtFormatWithTime1).format(
      "YYYY-MM-DD HH:mm a"
    );
    var meetmode = "";
    if (
      statusid_temp == "doc_confirmed" ||
      statusid_temp == "doc_cancelled" ||
      statusid_temp == "doc_none"
    ) {
      await axiosInstance
        .get("/vozo/getGMeet/data?aid=" + id)
        .then((response) => {
          let decodingResponse = Decrypt_Value(response.data, "vozo");
          if (decodingResponse === "GMeet") {
            meetmode = "GMeet";
          } else {
            meetmode = "zoom";
          }
        })
        .catch((err) => {
          meetmode = "zoom";
        });
    }

    var durationnew = editing;
    // var alldaynew = alldaydisable;
    var repeats;
    if (weekday != null) {
      if (weekday) {
        repeats = weekday;
      } else {
        repeats = "";
      }
    } else {
      repeats = "";
    }
    var select1, select2, untildate, fromdate, todate, days_wise;
    if (repeatselectnew) {
      select1 = repeatselectnew;
    } else {
      select1 = 0;
    }
    if (repeatselect2new) {
      select2 = repeatselect2new;
    } else {
      select2 = 0;
    }

    var repeat_type;
    var recurranceCount;

    if (repeats_checked) {
      untildate = "";
      repeat_type = "notCustom";
    } else {
      untildate = "";
      repeat_type = "norepeat";
    }

    if (aptfromdate) {
      fromdate = aptfromdate;
    } else {
      fromdate = "";
    }

    if (apttodate) {
      todate = apttodate;
    } else {
      todate = "";
    }

    if (dayswise != null) {
      if (dayswise === true) {
        days_wise = true;
      } else {
        days_wise = false;
      }
    } else {
      days_wise = false;
    }

    var tempdatenew = moment(timedetnew, moment.defaultFormat).toDate();
    var startTimenew = moment(tempdatenew).format("hh:mm a");
    var returned_endatenew = moment(tempdatenew, dtFormatWithTime1)
      .add(durationnew, "minutes")
      .format("YYYY-MM-DD hh:mm a");
    var startdatenew = moment(tempdatenew, dtFormat).format("YYYY-MM-DD");
    var secondsnew = convertHMS(durationnew);
    var endTimenew = moment(outTime, "h:mm A").format("hh:mm a");
    var endDatenew = moment(returned_endatenew, "YYYY-MM-DD hh:mm a").format(
      "YYYY-MM-DD"
    );
    const repeatsString =
      repeats &&
      repeats
        .filter((value) => {
          if (typeof value === "number") {
            return !isNaN(value);
          }
          if (typeof value === "string") {
            const num = Number(value.trim());
            return !isNaN(num) && num !== "";
          }
          return false;
        })
        .map((value) =>
          typeof value === "string" ? Number(value.trim()) : value
        )
        .filter((num) => num !== 0)
        .sort((a, b) => a - b)
        .join(",");

    const sortedWeekdays =
      repeats &&
      repeats.sort(
        (a, b) =>
          getWeekdayDistance(startdatenew, a) -
          getWeekdayDistance(startdatenew, b)
      );

    var article = {
      appttype: teleCheck ? 2 : 1,
      pc_pid: patid,
      pc_catid: Number(profileid),
      pc_billid: billingid !== "" ? Number(billingid) : null,
      pc_aid: providerid,
      pc_title: "",
      pc_duration: secondsnew,
      pc_hometext: "",
      pc_apptstatus: statusid_temp,
      pc_eventDate: startdatenew,
      pc_endDate: endDatenew,
      pc_startTime: startTimenew,
      pc_endTime: endTimenew,
      pc_facility: officeid,
      pc_informant: authorized === "1" ? userID : main_pro,
      pc_billing_location: "",
      pc_select1: select1,
      pc_select2: select2,
      pc_untildate:
        repvalue === "Custom" && untildate !== ""
          ? moment(untildate).format("YYYY-MM-DD")
          : moment(lastdateApp, dtFormat).format("YYYY-MM-DD"),
      pc_lastdate: moment(lastdateApp, dtFormat).format("YYYY-MM-DD"),
      pc_fromdate: fromdate,
      pc_todate: todate,
      days_wise: days_wise,
      days_of_week: repeatsString,
      // allday: alldaynew,
      repeats: sortedWeekdays,
      encounter: encounternew,
      futureCheck: repeatbtn.future,
      slotDate: props.slotDate,
      insertType: inserttype,
      all: repeatbtn.all,
      recurrance: props.start.recurrance,
      pc_room: roomval,
      pc_repeat_type: repeat_type,
      week_and_every_day: Week_and_every,
      month_and_every_day: Month_and_every,

      ocurrance_count: recurranceCount,
      on_never_after: custChk,
      repeat_select: repvalue,
      pc_repeatselectmonth: repeatselectmonth,
      id: authorized === "1" ? userID : main_pro,
      speciality,
      mode: meetmode,
      selectMonthAlp:
        repeatselectmonth === 1 || repeatselectmonth === "1"
          ? `Monthly on day ${dyOfMont}`
          : `Monthly on the ${wkOfMont} ${day}`,
      repeatSelectMonthOccurenceDates: MonthOccurences,
      // billingFacilityId: billFacilityID,
      accepted: props.start.Accepted,
      telehealth: props.start.pc_telehealth,
      modify: modifyAppointment,
    };

    let encryptedData = Encrypt_Value(article, "vozo");

    var error = 0;
    if (select1 === 0) {
      let error = 0;
      if (
        patid === "" ||
        profileid === "" ||
        providerid === "" ||
        providerid === null ||
        providerid === undefined ||
        // statusid === "" ||
        officeid === "" ||
        roomval === ""
      ) {
        if (profileid === "") {
          setprofileError(true);
          error = 2;
        }
        if (
          providerid === "" ||
          providerid === null ||
          providerid === undefined
        ) {
          setprovError(true);
          error = 2;
        }
        if (roomval === "" || roomval === null || roomval === undefined) {
          setexamroomError(true);
          error = 2;
        }
        if (officeid === "") {
          setofficeError(true);
          error = 2;
        }

        if (error > 0) {
          dispatch(changeAppointmentValues({ name: "reset call", value: "" }));
          return false;
        }
      } else {
        setBtnDis(true);
        if (statusid === "doc_confirmed") {
          setBtnDis(true);
          dispatch(changeAppointmentValues({ name: "disable", value: true }));
          axiosInstance
            .get("/vozo/pat_mail_check?pid=" + patid)
            .then((response) => {
              if (response.data === "pass") {
                // props.setspinnerfornewapp();

                axiosInstance
                  .put(
                    "/vozo/appointment?eid=" + id + "&pid=" + patid,
                    encryptedData
                  )
                  .then((response) => {
                    let apptAdded = response.data;

                    if (meetmode === "GMeet") {
                      if (
                        statusid_temp == "doc_cancelled" ||
                        statusid_temp == "doc_none"
                      ) {
                        let article = {
                          app_id: id,
                        };
                        axios
                          .get(
                            GMeetURL +
                              "/interface/customized/g-meet/index.php?action=delete",
                            { params: article }
                          )
                          .then((res) => {
                            if (apptAdded === "out of both range") {
                              setModalAlerShow1(true);
                              setModalMsg1(
                                "Selected Date & time should be within the provider availability range."
                              );
                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (apptAdded === "out of time range") {
                              setModalAlerShow1(true);
                              setModalMsg1(
                                "Selected time should be within the provider availability range."
                              );
                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (apptAdded === "Provider Not Available") {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1("Provider Not Available");
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (
                              apptAdded ===
                              "You Can't place the Appointment at this time"
                            ) {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1(
                                  "You Can't place the Appointment at this time"
                                );
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (apptAdded === "out of date range") {
                              setModalAlerShow1(true);
                              setModalMsg1(
                                "Selected Date should be within the provider availability range."
                              );
                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (apptAdded === "1" || apptAdded === 1) {
                              dispatch(
                                changeAppointmentValues({
                                  name: "disable",
                                  value: true,
                                })
                              );
                              setBtnDis(true);
                              props.savealertmsg(
                                "Appointment Updated Successfully",
                                "true"
                              );
                            } else if (
                              apptAdded.includes(
                                "You are only allowed to add telehealth appointments on these dates"
                              )
                            ) {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1(apptAdded);
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 3000);
                              return;
                            } else if (
                              apptAdded.includes(
                                "You Already Have an Appointment on These Date's"
                              )
                            ) {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1(apptAdded);
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 3000);
                              return;
                            } else if (
                              apptAdded.includes(
                                "The provider is unavailable on these dates"
                              )
                            ) {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1(apptAdded);
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 3000);
                              return;
                            }
                            setTimeout(() => props.closepopupedit(), 2000);
                            setTimeout(() => props.popuphide(), 2000);
                            props.FetchAppointments(
                              [
                                Decrypt_Value(
                                  localStorage.getItem("user_id"),
                                  "vozo"
                                ),
                              ],
                              [
                                Decrypt_Value(
                                  localStorage.getItem("facility_id"),
                                  "vozo"
                                ),
                              ],
                              props.appFromDate,
                              props.apptoDate,
                              props.pat_share,
                              props.examroom
                            );
                          })
                          .catch((err) => {
                            console.log("failed");
                          });
                      } else {
                        let article = {
                          appoin_id: id,
                          start_time: startdatenew + " " + startTimenew,
                          end_time: startdatenew + " " + endTimenew,
                          date: startdatenew,
                          starttime: startTimenew,
                          endtime: endTimenew,
                        };
                        axios
                          .get(
                            GMeetURL +
                              "/interface/customized/g-meet/index.php?action=update",
                            { params: article }
                          )
                          .then((res) => {
                            if (apptAdded === "out of both range") {
                              setModalAlerShow1(true);
                              setModalMsg1(
                                "Selected Date & time should be within the provider availability range."
                              );
                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (apptAdded === "out of time range") {
                              setModalAlerShow1(true);
                              setModalMsg1(
                                "Selected time should be within the provider availability range."
                              );
                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (apptAdded === "out of date range") {
                              setModalAlerShow1(true);
                              setModalMsg1(
                                "Selected Date should be within the provider availability range."
                              );
                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (
                              apptAdded ===
                              "You Can't place the Appointment at this time"
                            ) {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1(
                                  "You Can't place the Appointment at this time"
                                );
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (apptAdded === "1" || apptAdded === 1) {
                              props.savealertmsg(
                                "Appointment Updated Successfully",
                                "true"
                              );
                            } else if (
                              apptAdded.includes(
                                "You are only allowed to add telehealth appointments on these dates"
                              )
                            ) {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1(apptAdded);
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 3000);
                              return;
                            } else if (
                              apptAdded.includes(
                                "You Already Have an Appointment on These Date's"
                              )
                            ) {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1(apptAdded);
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 3000);
                              return;
                            } else if (
                              apptAdded.includes(
                                "The provider is unavailable on these dates"
                              )
                            ) {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1(apptAdded);
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 3000);
                              return;
                            }
                            setTimeout(() => props.closepopupedit(), 2000);
                            setTimeout(() => props.popuphide(), 2000);
                            props.FetchAppointments(
                              [
                                Decrypt_Value(
                                  localStorage.getItem("user_id"),
                                  "vozo"
                                ),
                              ],
                              [
                                Decrypt_Value(
                                  localStorage.getItem("facility_id"),
                                  "vozo"
                                ),
                              ],
                              props.appFromDate,
                              props.apptoDate,
                              props.pat_share,
                              props.examroom
                            );
                          })
                          .catch((err) => {
                            console.log("failed");
                          });
                      }
                    } else {
                      if (response.data === "confirm1") {
                        if (
                          window.confirm(
                            "Do you want to send email to the patient"
                          )
                        ) {
                          axiosInstance
                            .put("/vozo/alert/patient/mail?eid=" + id, article)
                            .then((res) => {
                              props.savealertmsg(res.data, "true");
                              setTimeout(() => props.popuphide(), 2000);
                            })
                            .catch((err) => console.error(err));
                        }
                      } else if (
                        response.data === "meeting cancelled successfully1"
                      ) {
                        let msg = response.data;
                        let alertMsg = msg.slice(0, -1);
                        props.savealertmsg(alertMsg, "true");
                        setTimeout(() => props.popuphide(), 2000);
                      } else if (response.data === "Mail sent successfully1") {
                        let msg = response.data;
                        let alertMsg = msg.slice(0, -1);
                        props.savealertmsg(alertMsg, "true");
                        setTimeout(() => props.popuphide(), 2000);
                      }
                      if (apptAdded === "out of both range") {
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "Selected Date & time should be within the provider availability range."
                        );
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "out of time range") {
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "Selected time should be within the provider availability range."
                        );
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "out of date range") {
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "Selected Date should be within the provider availability range."
                        );
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (
                        apptAdded ===
                        "You Can't place the Appointment at this time"
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(
                            "You Can't place the Appointment at this time"
                          );
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "1" || apptAdded === 1) {
                        props.savealertmsg(
                          "Appointment Updated Successfully",
                          "true"
                        );
                      } else if (
                        apptAdded.includes(
                          "You are only allowed to add telehealth appointments on these dates"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      } else if (
                        apptAdded.includes(
                          "You Already Have an Appointment on These Date's"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      } else if (
                        apptAdded.includes(
                          "The provider is unavailable on these dates"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      }
                      setTimeout(() => props.closepopupedit(), 2000);
                      setTimeout(() => props.popuphide(), 2000);
                      props.FetchAppointments(
                        [
                          Decrypt_Value(
                            localStorage.getItem("user_id"),
                            "vozo"
                          ),
                        ],
                        [
                          Decrypt_Value(
                            localStorage.getItem("facility_id"),
                            "vozo"
                          ),
                        ],
                        props.appFromDate,
                        props.apptoDate,
                        props.pat_share,
                        props.examroom
                      );
                    }
                  })
                  .catch((err) => console.error(err));
              } else {
                alert("Please update a valid Email in Patient Demographics");
                dispatch(
                  changeAppointmentValues({ name: "reset call", value: "" })
                );
                return false;
              }
              setBtnDis(false);
              dispatch(
                changeAppointmentValues({ name: "disable", value: false })
              );
            })
            .catch((err) => {
              console.error(err);
              setBtnDis(false);
              dispatch(
                changeAppointmentValues({ name: "disable", value: false })
              );
            });
        } else {
          // props.setspinnerfornewapp();
          // setBtnDis(true);
          // dispatch(changeAppointmentValues({ name: "disable", value: true }));
          axiosInstance
            .put("/vozo/appointment?eid=" + id + "&pid=" + patid, encryptedData)
            .then((response) => {
              let apptAdded = response.data;

              // return;
              if (meetmode === "GMeet") {
                if (
                  statusid_temp == "doc_cancelled" ||
                  statusid_temp == "doc_none"
                ) {
                  let article = {
                    app_id: id,
                  };
                  axios
                    .get(
                      GMeetURL +
                        "/interface/customized/g-meet/index.php?action=delete",
                      { params: article }
                    )
                    .then((res) => {
                      if (apptAdded === "out of both range") {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(
                            "Selected Date & time should be within the provider availability range."
                          );
                        }, 1);
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "out of time range") {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(
                            "Selected time should be within the provider availability range."
                          );
                        }, 1);
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "out of date range") {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(
                            "Selected Date should be within the provider availability range."
                          );
                        }, 1);
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "Provider Not Available") {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1("Provider Not Available");
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (
                        apptAdded ===
                        "You Can't place the Appointment at this time"
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(
                            "You Can't place the Appointment at this time"
                          );
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "1" || apptAdded === 1) {
                        dispatch(
                          changeAppointmentValues({
                            name: "disable",
                            value: true,
                          })
                        );
                        props.savealertmsg(
                          "Appointment Updated Successfully",
                          "true"
                        );
                      } else if (
                        apptAdded.includes(
                          "You are only allowed to add telehealth appointments on these dates"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      } else if (
                        apptAdded.includes(
                          "You Already Have an Appointment on These Date's"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      } else if (
                        apptAdded.includes(
                          "The provider is unavailable on these dates"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      }
                      setTimeout(() => props.closepopupedit(), 2000);
                      setTimeout(() => props.popuphide(), 2000);
                      props.FetchAppointments(
                        [
                          Decrypt_Value(
                            localStorage.getItem("user_id"),
                            "vozo"
                          ),
                        ],
                        [
                          Decrypt_Value(
                            localStorage.getItem("facility_id"),
                            "vozo"
                          ),
                        ],
                        props.appFromDate,
                        props.apptoDate,
                        props.pat_share,
                        props.examroom
                      );
                    })
                    .catch((err) => {
                      console.log("failed");
                    });
                } else {
                  let article = {
                    appoin_id: id,
                    start_time: startdatenew + " " + startTimenew,
                    end_time: startdatenew + " " + endTimenew,
                    date: startdatenew,
                    starttime: startTimenew,
                    endtime: endTimenew,
                  };
                  axios
                    .get(
                      GMeetURL +
                        "/interface/customized/g-meet/index.php?action=update",
                      { params: article }
                    )
                    .then((res) => {
                      if (apptAdded === "out of both range") {
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "Selected Date & time should be within the provider availability range."
                        );
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "out of time range") {
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "Selected time should be within the provider availability range."
                        );
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "out of date range") {
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "Selected Date should be within the provider availability range."
                        );
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "Provider Not Available") {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1("Provider Not Available");
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (
                        apptAdded ===
                        "You Can't place the Appointment at this time"
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(
                            "You Can't place the Appointment at this time"
                          );
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "1" || apptAdded === 1) {
                        setBtnDis(true);
                        props.savealertmsg(
                          "Appointment Updated Successfully",
                          "true"
                        );
                      } else if (
                        apptAdded.includes(
                          "You are only allowed to add telehealth appointments on these dates"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      } else if (
                        apptAdded.includes(
                          "You Already Have an Appointment on These Date's"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      } else if (
                        apptAdded.includes(
                          "The provider is unavailable on these dates"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      }
                      setTimeout(() => props.closepopupedit(), 2000);
                      setTimeout(() => props.popuphide(), 2000);
                      props.FetchAppointments(
                        [
                          Decrypt_Value(
                            localStorage.getItem("user_id"),
                            "vozo"
                          ),
                        ],
                        [
                          Decrypt_Value(
                            localStorage.getItem("facility_id"),
                            "vozo"
                          ),
                        ],
                        props.appFromDate,
                        props.apptoDate,
                        props.pat_share,
                        props.examroom
                      );
                    })
                    .catch((err) => {
                      console.log("failed");
                    });
                }
                setBtnDis(false);
                dispatch(
                  changeAppointmentValues({ name: "disable", value: false })
                );
              } else {
                if (response.data === "confirm1") {
                  if (
                    window.confirm("Do you want to send email to the patient")
                  ) {
                    axiosInstance
                      .put("/vozo/alert/patient/mail?eid=" + id, article)
                      .then((res) => {
                        props.savealertmsg(res.data, "true");
                        setTimeout(() => props.popuphide(), 2000);
                      })
                      .catch((err) => console.error(err));
                  }
                } else if (
                  response.data === "meeting cancelled successfully1"
                ) {
                  var msg = response.data;
                  var alertMsg = msg.slice(0, -1);

                  props.savealertmsg(alertMsg, "true");
                  setTimeout(() => props.popuphide(), 2000);
                } else if (response.data === "Mail sent successfully1") {
                  let msg = response.data;
                  let alertMsg = msg.slice(0, -1);
                  props.savealertmsg(alertMsg, "true");
                  setTimeout(() => props.popuphide(), 2000);
                }
                if (apptAdded === "out of both range") {
                  setModalAlerShow1(true);
                  setModalMsg1(
                    "Selected Date & time should be within the provider availability range."
                  );
                  setTimeout(() => setModalAlerShow1(false), 2000);
                  return;
                } else if (apptAdded === "out of time range") {
                  setModalAlerShow1(true);
                  setModalMsg1(
                    "Selected time should be within the provider availability range."
                  );
                  setTimeout(() => setModalAlerShow1(false), 2000);
                  return;
                } else if (apptAdded === "out of date range") {
                  setModalAlerShow1(true);
                  setModalMsg1(
                    "Selected Date should be within the provider availability range."
                  );
                  setTimeout(() => setModalAlerShow1(false), 2000);
                  return;
                } else if (
                  apptAdded === "You Can't place the Appointment at this time"
                ) {
                  setTimeout(() => {
                    setModalAlerShow1(true);
                    setModalMsg1(
                      "You Can't place the Appointment at this time"
                    );
                  }, 1);

                  setTimeout(() => setModalAlerShow1(false), 2000);
                  return;
                } else if (apptAdded === "1" || apptAdded === 1) {
                  props.savealertmsg(
                    "Appointment Updated Successfully",
                    "true"
                  );
                  setTimeout(() => props.closepopupedit(), 2000);
                } else if (
                  apptAdded.includes(
                    "You are only allowed to add telehealth appointments on these dates"
                  )
                ) {
                  setTimeout(() => {
                    setModalAlerShow1(true);
                    setModalMsg1(apptAdded);
                  }, 1);

                  setTimeout(() => setModalAlerShow1(false), 3000);
                  return;
                } else if (
                  apptAdded.includes(
                    "You Already Have an Appointment on These Date's"
                  )
                ) {
                  setTimeout(() => {
                    setModalAlerShow1(true);
                    setModalMsg1(apptAdded);
                  }, 1);

                  setTimeout(() => setModalAlerShow1(false), 3000);
                  return;
                } else if (
                  apptAdded.includes(
                    "The provider is unavailable on these dates"
                  )
                ) {
                  setTimeout(() => {
                    setModalAlerShow1(true);
                    setModalMsg1(apptAdded);
                  }, 1);

                  setTimeout(() => setModalAlerShow1(false), 3000);
                  return;
                }
                setTimeout(() => props.popuphide(), 2000);

                props.FetchAppointments(
                  [Decrypt_Value(localStorage.getItem("user_id"), "vozo")],
                  [Decrypt_Value(localStorage.getItem("facility_id"), "vozo")],
                  props.appFromDate,
                  props.apptoDate,
                  props.pat_share,
                  props.examroom
                );
                setBtnDis(false);
                dispatch(
                  changeAppointmentValues({ name: "disable", value: false })
                );
              }
            })
            .catch((err) => {
              console.error(err);
              props.closepopupedit();
              setBtnDis(false);
              dispatch(
                changeAppointmentValues({ name: "disable", value: false })
              );
            });
        }
      }
    } else if (select1 > 0) {
      if (
        profileid === "" ||
        providerid === "" ||
        // statusid === "" ||
        officeid === "" ||
        roomval === ""
        // ||        untildate === ""
      ) {
        if (profileid === "") {
          setprofileError(true);
          error = 2;
        }
        if (
          providerid === "" ||
          providerid === null ||
          providerid === undefined
        ) {
          setprovError(true);
          error = 2;
        }
        if (roomval === "" || roomval === null || roomval === undefined) {
          setexamroomError(true);
          error = 2;
        }

        if (officeid === "") {
          setofficeError(true);
          error = 2;
        }

        if (error > 1) {
          dispatch(changeAppointmentValues({ name: "reset call", value: "" }));
          return false;
        }
      } else {
        setBtnDis(true);
        if (statusid === "doc_confirmed") {
          axiosInstance
            .get("/vozo/pat_mail_check?pid=" + patid)
            .then((response) => {
              if (response.data === "pass") {
                // props.setspinnerfornewapp();

                axiosInstance
                  .put(
                    "/vozo/appointment?eid=" + id + "&pid=" + patid,
                    encryptedData
                  )
                  .then((response) => {
                    let apptAdded = response.data;

                    if (meetmode === "GMeet") {
                      if (
                        statusid_temp == "doc_cancelled" ||
                        statusid_temp == "doc_none"
                      ) {
                        let article = {
                          app_id: id,
                        };
                        axios
                          .get(
                            GMeetURL +
                              "/interface/customized/g-meet/index.php?action=delete",
                            { params: article }
                          )
                          .then((res) => {
                            if (apptAdded === "out of both range") {
                              setModalAlerShow1(true);
                              setModalMsg1(
                                "Selected Date & time should be within the provider availability range."
                              );
                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (apptAdded === "out of time range") {
                              setModalAlerShow1(true);
                              setModalMsg1(
                                "Selected time should be within the provider availability range."
                              );
                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (apptAdded === "out of date range") {
                              setModalAlerShow1(true);
                              setModalMsg1(
                                "Selected Date should be within the provider availability range."
                              );
                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (apptAdded === "Provider Not Available") {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1("Provider Not Available");
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (
                              apptAdded ===
                              "You Can't place the Appointment at this time"
                            ) {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1(
                                  "You Can't place the Appointment at this time"
                                );
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (apptAdded === "1" || apptAdded === 1) {
                              setBtnDis(true);
                              props.savealertmsg(
                                "Appointment Updated Successfully",
                                "true"
                              );
                              dispatch(
                                changeAppointmentValues({
                                  name: "disable",
                                  value: true,
                                })
                              );
                            } else if (
                              apptAdded.includes(
                                "You are only allowed to add telehealth appointments on these dates"
                              )
                            ) {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1(apptAdded);
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 3000);
                              return;
                            } else if (
                              apptAdded.includes(
                                "You Already Have an Appointment on These Date's"
                              )
                            ) {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1(apptAdded);
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 3000);
                              return;
                            } else if (
                              apptAdded.includes(
                                "The provider is unavailable on these dates"
                              )
                            ) {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1(apptAdded);
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 3000);
                              return;
                            }
                            setTimeout(() => props.closepopupedit(), 2000);
                            setTimeout(() => props.popuphide(), 2000);
                            props.FetchAppointments(
                              [
                                Decrypt_Value(
                                  localStorage.getItem("user_id"),
                                  "vozo"
                                ),
                              ],
                              [
                                Decrypt_Value(
                                  localStorage.getItem("facility_id"),
                                  "vozo"
                                ),
                              ],
                              props.appFromDate,
                              props.apptoDate,
                              props.pat_share,
                              props.examroom
                            );
                          })
                          .catch((err) => {
                            console.log("failed");
                          });
                      } else {
                        let article = {
                          appoin_id: id,
                          start_time: startdatenew + " " + startTimenew,
                          end_time: startdatenew + " " + endTimenew,
                          date: startdatenew,
                          starttime: startTimenew,
                          endtime: endTimenew,
                        };
                        axios
                          .get(
                            GMeetURL +
                              "/interface/customized/g-meet/index.php?action=update",
                            { params: article }
                          )
                          .then((res) => {
                            if (apptAdded === "out of both range") {
                              setModalAlerShow1(true);
                              setModalMsg1(
                                "Selected Date & time should be within the provider availability range."
                              );
                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (apptAdded === "out of time range") {
                              setModalAlerShow1(true);
                              setModalMsg1(
                                "Selected time should be within the provider availability range."
                              );
                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (apptAdded === "out of date range") {
                              setModalAlerShow1(true);
                              setModalMsg1(
                                "Selected Date should be within the provider availability range."
                              );
                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (
                              apptAdded ===
                              "You Can't place the Appointment at this time"
                            ) {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1(
                                  "You Can't place the Appointment at this time"
                                );
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 2000);
                              return;
                            } else if (apptAdded === "1" || apptAdded === 1) {
                              props.savealertmsg(
                                "Appointment Updated Successfully",
                                "true"
                              );
                            } else if (
                              apptAdded.includes(
                                "You are only allowed to add telehealth appointments on these dates"
                              )
                            ) {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1(apptAdded);
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 3000);
                              return;
                            } else if (
                              apptAdded.includes(
                                "You Already Have an Appointment on These Date's"
                              )
                            ) {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1(apptAdded);
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 3000);
                              return;
                            } else if (
                              apptAdded.includes(
                                "The provider is unavailable on these dates"
                              )
                            ) {
                              setTimeout(() => {
                                setModalAlerShow1(true);
                                setModalMsg1(apptAdded);
                              }, 1);

                              setTimeout(() => setModalAlerShow1(false), 3000);
                              return;
                            }
                            setTimeout(() => props.closepopupedit(), 2000);
                            setTimeout(() => props.popuphide(), 2000);
                            props.FetchAppointments(
                              [
                                Decrypt_Value(
                                  localStorage.getItem("user_id"),
                                  "vozo"
                                ),
                              ],
                              [
                                Decrypt_Value(
                                  localStorage.getItem("facility_id"),
                                  "vozo"
                                ),
                              ],
                              props.appFromDate,
                              props.apptoDate,
                              props.pat_share,
                              props.examroom
                            );
                          })
                          .catch((err) => {
                            console.log("failed");
                          });
                      }
                    } else {
                      if (response.data === "confirm1") {
                        if (
                          window.confirm(
                            "Do you want to send email to the patient"
                          )
                        ) {
                          axiosInstance
                            .put("/vozo/alert/patient/mail?eid=" + id, article)
                            .then((res) => {
                              props.savealertmsg(res.data, "true");
                              setTimeout(() => props.popuphide(), 2000);
                            })
                            .catch((err) => console.error(err));
                        }
                      } else if (
                        response.data === "meeting cancelled successfully1"
                      ) {
                        let msg = response.data;
                        let alertMsg = msg.slice(0, -1);
                        props.savealertmsg(alertMsg, "true");
                        setTimeout(() => props.popuphide(), 2000);
                      } else if (response.data === "Mail sent successfully1") {
                        let msg = response.data;
                        let alertMsg = msg.slice(0, -1);
                        props.savealertmsg(alertMsg, "true");
                        setTimeout(() => props.popuphide(), 2000);
                      }
                      if (apptAdded === "out of both range") {
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "Selected Date & time should be within the provider availability range."
                        );
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "out of time range") {
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "Selected time should be within the provider availability range."
                        );
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "out of date range") {
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "Selected Date should be within the provider availability range."
                        );
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (
                        apptAdded ===
                        "You Can't place the Appointment at this time"
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(
                            "You Can't place the Appointment at this time"
                          );
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "1" || apptAdded === 1) {
                        props.savealertmsg(
                          "Appointment Updated Successfully",
                          "true"
                        );
                      } else if (
                        apptAdded.includes(
                          "You are only allowed to add telehealth appointments on these dates"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      } else if (
                        apptAdded.includes(
                          "You Already Have an Appointment on These Date's"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      } else if (
                        apptAdded.includes(
                          "The provider is unavailable on these dates"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      }
                      setTimeout(() => props.closepopupedit(), 2000);
                      setTimeout(() => props.popuphide(), 2000);
                      props.FetchAppointments(
                        [
                          Decrypt_Value(
                            localStorage.getItem("user_id"),
                            "vozo"
                          ),
                        ],
                        [
                          Decrypt_Value(
                            localStorage.getItem("facility_id"),
                            "vozo"
                          ),
                        ],
                        props.appFromDate,
                        props.apptoDate,
                        props.pat_share,
                        props.examroom
                      );
                    }
                  })
                  .catch((err) => console.error(err));
              } else {
                alert("Please update a valid Email in Patient Demographics");
                dispatch(
                  changeAppointmentValues({ name: "reset call", value: "" })
                );
                return false;
              }
              setBtnDis(false);
              dispatch(
                changeAppointmentValues({ name: "disable", value: false })
              );
            })
            .catch((err) => {
              console.error(err);
              setBtnDis(false);
              dispatch(
                changeAppointmentValues({ name: "disable", value: false })
              );
            });
        } else {
          // props.setspinnerfornewapp();
          // setBtnDis(true);
          // dispatch(changeAppointmentValues({ name: "disable", value: true }));
          axiosInstance
            .put("/vozo/appointment?eid=" + id + "&pid=" + patid, encryptedData)
            .then((response) => {
              let apptAdded = response.data;

              // return;
              if (meetmode === "GMeet") {
                if (
                  statusid_temp == "doc_cancelled" ||
                  statusid_temp == "doc_none"
                ) {
                  let article = {
                    app_id: id,
                  };
                  axios
                    .get(
                      GMeetURL +
                        "/interface/customized/g-meet/index.php?action=delete",
                      { params: article }
                    )
                    .then((res) => {
                      if (apptAdded === "out of both range") {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(
                            "Selected Date & time should be within the provider availability range."
                          );
                        }, 1);
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "out of time range") {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(
                            "Selected time should be within the provider availability range."
                          );
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "out of date range") {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(
                            "Selected Date should be within the provider availability range."
                          );
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "Provider Not Available") {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1("Provider Not Available");
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (
                        apptAdded ===
                        "You Can't place the Appointment at this time"
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(
                            "You Can't place the Appointment at this time"
                          );
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "1" || apptAdded === 1) {
                        setBtnDis(true);
                        props.savealertmsg(
                          "Appointment Updated Successfully",
                          "true"
                        );
                        dispatch(
                          changeAppointmentValues({
                            name: "disable",
                            value: true,
                          })
                        );
                      } else if (
                        apptAdded.includes(
                          "You are only allowed to add telehealth appointments on these dates"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      } else if (
                        apptAdded.includes(
                          "You Already Have an Appointment on These Date's"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      } else if (
                        apptAdded.includes(
                          "The provider is unavailable on these dates"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      }
                      setTimeout(() => props.closepopupedit(), 2000);
                      setTimeout(() => props.popuphide(), 2000);
                      props.FetchAppointments(
                        [
                          Decrypt_Value(
                            localStorage.getItem("user_id"),
                            "vozo"
                          ),
                        ],
                        [
                          Decrypt_Value(
                            localStorage.getItem("facility_id"),
                            "vozo"
                          ),
                        ],
                        props.appFromDate,
                        props.apptoDate,
                        props.pat_share,
                        props.examroom
                      );
                    })
                    .catch((err) => {
                      console.log("failed");
                    });
                } else {
                  let article = {
                    appoin_id: id,
                    start_time: startdatenew + " " + startTimenew,
                    end_time: startdatenew + " " + endTimenew,
                    date: startdatenew,
                    starttime: startTimenew,
                    endtime: endTimenew,
                  };
                  axios
                    .get(
                      GMeetURL +
                        "/interface/customized/g-meet/index.php?action=update",
                      { params: article }
                    )
                    .then((res) => {
                      if (apptAdded === "out of both range") {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(
                            "Selected Date & time should be within the provider availability range."
                          );
                        }, 1);
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "out of time range") {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(
                            "Selected time should be within the provider availability range."
                          );
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "out of date range") {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(
                            "Selected Date should be within the provider availability range."
                          );
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "Provider Not Available") {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1("Provider Not Available");
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "1" || apptAdded === 1) {
                        props.savealertmsg(
                          "Appointment Updated Successfully",
                          "true"
                        );
                      } else if (
                        apptAdded.includes(
                          "You are only allowed to add telehealth appointments on these dates"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      } else if (
                        apptAdded.includes(
                          "You Already Have an Appointment on These Date's"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      } else if (
                        apptAdded.includes(
                          "The provider is unavailable on these dates"
                        )
                      ) {
                        setTimeout(() => {
                          setModalAlerShow1(true);
                          setModalMsg1(apptAdded);
                        }, 1);

                        setTimeout(() => setModalAlerShow1(false), 3000);
                        return;
                      }
                      setTimeout(() => props.closepopupedit(), 2000);
                      setTimeout(() => props.popuphide(), 2000);
                      props.FetchAppointments(
                        [
                          Decrypt_Value(
                            localStorage.getItem("user_id"),
                            "vozo"
                          ),
                        ],
                        [
                          Decrypt_Value(
                            localStorage.getItem("facility_id"),
                            "vozo"
                          ),
                        ],
                        props.appFromDate,
                        props.apptoDate,
                        props.pat_share,
                        props.examroom
                      );
                    })
                    .catch((err) => {
                      console.log("failed");
                    });
                }
                setBtnDis(false);
                dispatch(
                  changeAppointmentValues({ name: "disable", value: false })
                );
              } else {
                if (response.data === "confirm1") {
                  if (
                    window.confirm("Do you want to send email to the patient")
                  ) {
                    axiosInstance
                      .put("/vozo/alert/patient/mail?eid=" + id, article)
                      .then((res) => {
                        props.savealertmsg(res.data, "true");
                        setTimeout(() => props.popuphide(), 2000);
                      })
                      .catch((err) => console.error(err));
                  }
                } else if (
                  response.data === "meeting cancelled successfully1"
                ) {
                  let msg = response.data;
                  let alertMsg = msg.slice(0, -1);
                  props.savealertmsg(alertMsg, "true");
                  setTimeout(() => props.popuphide(), 2000);
                } else if (response.data === "Mail sent successfully1") {
                  let msg = response.data;
                  let alertMsg = msg.slice(0, -1);
                  props.savealertmsg(alertMsg, "true");
                  setTimeout(() => props.popuphide(), 2000);
                }

                // props.setspinnerfornewapp();
                if (apptAdded === "out of both range") {
                  setTimeout(() => {
                    setModalAlerShow1(true);
                    setModalMsg1(
                      "Selected Date & time should be within the provider availability range."
                    );
                  }, 1);

                  setTimeout(() => setModalAlerShow1(false), 2000);
                  return;
                } else if (apptAdded === "out of time range") {
                  setTimeout(() => {
                    setModalAlerShow1(true);
                    setModalMsg1(
                      "Selected time should be within the provider availability range."
                    );
                  }, 1);

                  setTimeout(() => setModalAlerShow1(false), 2000);
                  return;
                } else if (apptAdded === "out of date range") {
                  setTimeout(() => {
                    setModalAlerShow1(true);
                    setModalMsg1(
                      "Selected Date should be within the provider availability range."
                    );
                  }, 1);

                  setTimeout(() => setModalAlerShow1(false), 2000);
                  return;
                } else if (apptAdded === "Provider Not Available") {
                  setTimeout(() => {
                    setModalAlerShow1(true);
                    setModalMsg1("Provider Not Available");
                  }, 1);

                  setTimeout(() => setModalAlerShow1(false), 2000);
                  return;
                } else if (
                  apptAdded === "You Can't place the Appointment at this time"
                ) {
                  setTimeout(() => {
                    setModalAlerShow1(true);
                    setModalMsg1(
                      "You Can't place the Appointment at this time"
                    );
                  }, 1);

                  setTimeout(() => setModalAlerShow1(false), 2000);
                  return;
                } else if (apptAdded === "1" || apptAdded === 1) {
                  props.savealertmsg(
                    "Appointment Updated Successfully",
                    "true"
                  );
                } else if (
                  apptAdded.includes(
                    "You are only allowed to add telehealth appointments on these dates"
                  )
                ) {
                  setTimeout(() => {
                    setModalAlerShow1(true);
                    setModalMsg1(apptAdded);
                  }, 1);

                  setTimeout(() => setModalAlerShow1(false), 3000);
                  return;
                } else if (
                  apptAdded.includes(
                    "You Already Have an Appointment on These Date's"
                  )
                ) {
                  setTimeout(() => {
                    setModalAlerShow1(true);
                    setModalMsg1(apptAdded);
                  }, 1);

                  setTimeout(() => setModalAlerShow1(false), 3000);
                  return;
                } else if (
                  apptAdded.includes(
                    "The provider is unavailable on these dates"
                  )
                ) {
                  setTimeout(() => {
                    setModalAlerShow1(true);
                    setModalMsg1(apptAdded);
                  }, 1);

                  setTimeout(() => setModalAlerShow1(false), 3000);
                  return;
                }
                setTimeout(() => props.closepopupedit(), 2000);
                setTimeout(() => props.popuphide(), 2000);
                props.FetchAppointments(
                  [Decrypt_Value(localStorage.getItem("user_id"), "vozo")],
                  [Decrypt_Value(localStorage.getItem("facility_id"), "vozo")],
                  props.appFromDate,
                  props.apptoDate,
                  props.pat_share,
                  props.examroom
                );
                setBtnDis(false);
                dispatch(
                  changeAppointmentValues({ name: "disable", value: false })
                );
              }
            })
            .catch((err) => {
              console.error(err);
              props.closepopupedit();
              setBtnDis(false);
              dispatch(
                changeAppointmentValues({ name: "disable", value: false })
              );
            });
        }
      }
    }
  };

  const datas_1 = async () => {
    await axiosInstance
      .get("/vozo/appstatus/category")
      .then((response) => {
        let temp_role_cat = Decrypt_Value(response.data, "vozo");
        setSourcestatus(temp_role_cat[0]);
        setSourcestatustele(temp_role_cat[1]);
        setSourceprofile(temp_role_cat[2]);
        setteleprofile(temp_role_cat[2]);
        setBillingProfile(temp_role_cat[4] ?? []);
      })
      .catch((err) => console.error(err));
    let Data_frsh_doc =
      "authorized=" +
      authorized +
      "&main_pro=" +
      main_pro +
      "&group_id=" +
      group_id;
    let Data_enc_doc = Encrypt_Value(Data_frsh_doc, "vozo");
    await axiosInstance
      .get("/vozonew/facility?search=" + Data_enc_doc)
      .then((response) => {
        let decryptval = Decrypt_Value(response.data, "vozo");
        setSourceoffice(decryptval);
      })
      .catch((err) => console.error(err));

    await axiosInstance
      .get("vozo/calendarsettingdata/main")
      .then((response) => {
        let temp_role_cal = Decrypt_Value(response.data, "vozo");

        setStartTime(
          temp_role_cal.schedule_start === "0"
            ? "0"
            : temp_role_cal.schedule_start
        );
        setEndTime(
          temp_role_cal.schedule_end === "0" ? "24" : temp_role_cal.schedule_end
        );

        const timeSlots1 = [];
        const timeSlots2 = [];
        let i = 0;
        let j = 0;
        let skipFirstMidnight = true;
        if (
          temp_role_cal.schedule_start === "0" &&
          temp_role_cal.schedule_end === "24"
        ) {
          for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
              let ampm = hour < 12 ? "AM" : "PM";
              let hourFormatted = hour === 0 ? 0 : hour > 12 ? hour - 12 : hour;
              if (hour === 0) {
                hourFormatted = 12;
              }
              if (hour === 24 && minute === 0) {
                ampm = "AM";
                hourFormatted = 12;
              }
              if (hour === 0) {
                hourFormatted = 12;
              }

              const time = `${hourFormatted
                .toString()
                .padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
              timeSlots1.push({ id: ++i, time: time, meridiem: ampm });
              if (time === "12:00" && ampm === "AM" && skipFirstMidnight) {
                skipFirstMidnight = false;
                continue;
              }
              if (time !== "00:00") {
                timeSlots2.push({ id: ++j, time: time, meridiem: ampm });
              }
              if (hour === 23 && minute === 45) {
                if (
                  timeSlots2[timeSlots2.length - 1].time === "11:45" &&
                  timeSlots2[timeSlots2.length - 1].meridiem === "PM"
                ) {
                  timeSlots2.push({ id: ++j, time: "12:00", meridiem: "AM" });
                }
              }
            }
          }
        } else {
          for (
            let hour = Number(temp_role_cal.schedule_start);
            hour < Number(temp_role_cal.schedule_end);
            hour++
          ) {
            for (let minute = 0; minute < 60; minute += 15) {
              const ampm = hour < 12 ? "AM" : "PM";
              const hourFormatted = hour > 12 ? hour - 12 : hour;
              const time = `${hourFormatted
                .toString()
                .padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
              timeSlots1.push({ id: ++i, time: time, meridiem: ampm });
            }
          }
          const scheduleStart = Number(temp_role_cal.schedule_start);
          const scheduleEnd = Number(temp_role_cal.schedule_end);
          for (let hour = scheduleStart; hour <= scheduleEnd; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
              const isPM = hour >= 12;
              const ampm = isPM ? "PM" : "AM";
              let hourFormatted = hour % 12;
              if (hourFormatted === 0) hourFormatted = 12;

              const time = `${hourFormatted
                .toString()
                .padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
              timeSlots2.push({ id: ++j, time: time, meridiem: ampm });

              if (hour === scheduleEnd && minute === 0) break;
            }
          }
        }
        setFromTimeSlots(timeSlots1);
        var frSlotTmId = timeSlots1
          .filter((ele) => {
            return (
              moment(ele.time + ele.meridiem, "HH:mm A").format("HH:mm A") >=
              moment(start).format("HH:mm A")
            );
          })
          .map((ele) => {
            return ele.id;
          });

        setToTimeSlots(timeSlots2);
        if (temp_role_cal.schedule_end >= 12) {
          if (temp_role_cal.schedule_end === "24") {
            setEndErrTime("12:00 AM");
          } else if (temp_role_cal.schedule_end !== "12")
            setEndErrTime(temp_role_cal.schedule_end - 12 + ":00 PM");
          else setEndErrTime("12:00 PM");
        } else {
          setEndErrTime(temp_role_cal.schedule_end + ":00 AM");
        }
        if (temp_role_cal.schedule_start >= 12) {
          if (temp_role_cal.schedule_start !== "12")
            setStartErrTime(temp_role_cal.schedule_start - 12 + ":00 PM");
          else setStartErrTime("12:00 PM");
        } else {
          setStartErrTime(temp_role_cal.schedule_start + ":00 AM");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getDatas_1 = async () => {
    let flagpid;
    await axiosInstance
      .get("/vozo/patientappointmentinfo?id=" + id)
      .then((response) => {
        let temp_role_app_info = Decrypt_Value(response.data, "vozo");
        let tempend = new Date(temp_role_app_info.pc_endDate);
        setWalkInRatio(() => (temp_role_app_info.pc_walkin > 0 ? true : false));
        getRoom(temp_role_app_info.pc_facility);
        setRoomVal(temp_role_app_info.pc_room);
        setpatid(temp_role_app_info.pc_pid);
        flagpid = temp_role_app_info.pc_pid;
        setappttimedet(temp_role_app_info.pc_startTime);

        setproviderid(temp_role_app_info.pc_aid);

        setprofileid(temp_role_app_info.pc_catid);

        setofficeid(temp_role_app_info.pc_facility);

        setstatusid(
          temp_role_app_info.pc_apptstatus
            ? temp_role_app_info.pc_apptstatus
            : null
        );

        setbillingid(temp_role_app_info.pc_billprofile);

        setrepeatselectnew(temp_role_app_info.pc_select1);

        setrepeatselect2new(temp_role_app_info.pc_select2);

        setSpeciality(temp_role_app_info.speciality);

        // setBillFacilityID(temp_role_app_info.billing_facility_id);

        if (temp_role_app_info.pc_select2 === "1") {
          setWeek_and_every(true);
        }

        setrepValue(temp_role_app_info.pc_repeat_select);
        if (
          temp_role_app_info.pc_repeat_select === "Does not repeat" ||
          temp_role_app_info.pc_repeat_select === "Custom" ||
          temp_role_app_info.pc_repeat_select === "" ||
          temp_role_app_info.pc_repeat_select === null
        ) {
          setLastdate(false);
        } else {
          setLastdate(true);
        }

        setLastdateApp(moment(temp_role_app_info.pc_endDate).format(dtFormat));

        setRepeatSelectMonth(temp_role_app_info.pc_repeatselectmonth);
        setcustChk(parseInt(temp_role_app_info.pc_on_after_never));

        let d1 = moment(temp_role_app_info.pc_startTime, "h:mm A");
        let d2 = moment(temp_role_app_info.pc_endTime, "h:mm A");

        setInTime(d1.format("h:mm A"));

        setOutTime(d2.format("h:mm A"));

        if (temp_role_app_info.pc_recurrtype > "0") {
          setrepeats_checked(true);
          dispatch(
            changeAppointmentValues({ name: "repeats checked", value: true })
          );

          if (
            temp_role_app_info.pc_recurrtype === "1" ||
            temp_role_app_info.pc_recurrtype === "2"
          ) {
            setrepeatselectnew(
              temp_role_app_info.pc_recurrspec.event_repeat_freq
            );

            setrepeatselect2new(
              temp_role_app_info.pc_recurrspec.event_repeat_freq_type
            );
          } else if (temp_role_app_info.pc_recurrtype === "3") {
            let temp =
              temp_role_app_info.pc_recurrspec.event_repeat_freq.split(",");
            var temp_a = [];
            for (let i = 0; i < temp.length; i++) {
              let text = Number(temp[i]);
              temp_a.push(text);
            }

            setweekday(temp_a);

            setdayswise_c(true);
            days_check();
          } else if (temp_role_app_info.pc_recurrtype === "4") {
            let temp =
              temp_role_app_info.pc_recurrspec.event_repeat_freq_by_day.split(
                ","
              );
            var temp_a = [];
            for (let i = 0; i < temp.length; i++) {
              let text = Number(temp[i]);
              temp_a.push(text);
            }

            setweekday(temp_a);
          } else if (temp_role_app_info.pc_recurrtype === "5") {
            let temp =
              temp_role_app_info.pc_recurrspec.event_repeat_freq_by_day.split(
                ","
              );
            var temp_a = [];
            for (let i = 0; i < temp.length; i++) {
              let text = Number(temp[i]);
              temp_a.push(text);
            }

            setweekday(temp_a);
          }
        } else {
          setrepeats_checked(false);
          dispatch(
            changeAppointmentValues({ name: "repeats checked", value: false })
          );
        }
        if (temp_role_app_info.pc_telehealth === "1") {
          setTeleCheck(true);
        }
        if (temp_role_app_info.pc_catname === "Telehealth") {
          setapttype("Telehealth");
        } else {
          setapttype("Appointment");
        }
      })
      .catch((err) => console.error(err));

    if (
      accountType > "0" ||
      (accountType === "0" && trial && flagpid !== undefined)
    ) {
      axiosInstance
        .get("/vozo/getpatient/flags?pid=" + flagpid)
        .then((res) => {
          let temp_role_pat_flag = Decrypt_Value(res.data, "vozo");
          setPatFlag(temp_role_pat_flag);
        })
        .catch(() => {
          setPatFlag([]);
        });
    }
    setTimeout(() => {
      setSpinnerVal(false);
      dispatch(changeAppointmentValues({ name: "spinner", value: false }));
      if (props.loadVal) props.loadVal();
    }, 3000);
  };
  const handlerepSelect = (e) => {
    dispatch(
      changeAppointmentValues({ name: "save button", value: "appointment" })
    );
    if (e === "Does not repeat") {
      setrepeats_checked(false);
      if (props.start.recurrance) {
        setdoes_repeat_edit(true);
      }
      setCustState(false);
      setdayswise(false);
      setMonth_and_every(false);
      setrepeatselectnew("");
      setrepeatselect2new("");
      setRepeatSelectMonth("1");

      setweekday("");
      setselectdayError(false);
    } else if (e === "Daily") {
      setstatusid(null);
      setrepeats_checked(true);
      setrepeatselectnew("1");
      setrepeatselect2new("0");
      setdayswise(false);
      setMonth_and_every(false);
      setCheckValue("");
      setselectdayError(false);
    } else if (e.includes("Week")) {
      setstatusid(null);
      setrepeats_checked(true);
      setdayswise(true);
      setMonth_and_every(false);
    }
    setrepValue(e);
  };

  const handleShowuntildate = () => {
    setLastdate(true);
  };
  const handleShowuntildate1 = () => {
    setLastdate(false);
  };

  const handleFromTime = () => {
    if (fromShow === false) {
      setFromShow(true);
      setToShow(false);
      setTempOpen(true);
    } else {
      setFromShow(false);
    }
  };
  const handleToTime = () => {
    if (toShow === false) {
      setToShow(true);
      setFromShow(false);
      setTempOpen(true);
    } else {
      setToShow(false);
    }
  };
  const handleFromButton = (e, index) => {
    e.preventDefault();
    if (fromShow === true || toShow === true) {
      setFromShow(false);
      setToShow(false);
    }
  };
  const handleToButton = (e, index) => {
    e.preventDefault();
    if (fromShow === true || toShow === true) {
      setFromShow(false);
      setToShow(false);
    }
  };

  // ------------------------------ Functions End--------------------------------------------------- //

  // ------------------------------ useEffect Start--------------------------------------------------- //

  useEffect(() => {
    if (ChangeStatusForAll) {
      if (repeatCheckForStatusChange) {
        setrepeats_checked(false);
        if (props.start.recurrance) {
          setdoes_repeat_edit(true);
        }
        setCustState(false);
        setdayswise(false);
        setMonth_and_every(false);
        setrepeatselectnew("");
        setrepeatselect2new("");
        setRepeatSelectMonth("1");

        setweekday("");
        setrepeatbtn((repeatbtn) => ({
          ...defaultRepeatBtn,
          current: true,
        }));
        if (teleCheck) {
          let data = {
            key: "doc_completed",
            value: "doc_completed",
            children: "Meet Completed",
            title: "Meet Completed",
          };
          handleDropDownSelectStatus("e", data);
        } else {
          let data = {
            key: "@",
            value: "@",
            children: "@ Arrived/Lobby",
            title: "@ Arrived/Lobby",
          };
          handleDropDownSelectStatus("e", data);
        }
      } else {
        if (teleCheck) {
          let data = {
            key: "doc_completed",
            value: "doc_completed",
            children: "Meet Completed",
            title: "Meet Completed",
          };
          handleDropDownSelectStatus("e", data);
        } else {
          let data = {
            key: "@",
            value: "@",
            children: "@ Arrived/Lobby",
            title: "@ Arrived/Lobby",
          };
          handleDropDownSelectStatus("e", data);
        }
      }
      dispatch(
        changeAppointmentStatusAll({
          value: false,
          RepeatOrNot: repeats_checked,
        })
      );
    }
  }, [ChangeStatusForAll]);

  useEffect(() => {
    setappfromdate(moment(start).format("YYYY-MM-DD"));
    setapptodate(moment(start).format("YYYY-MM-DD"));
    setLastdate(moment(start).format("YYYY-MM-DD"));

    const tempIntime = moment(start).format("h:mm A");

    const intimeSplit = tempIntime.split(" ");
    const intimeComp = moment(intimeSplit[0], "h:mm A").format("h:mm A");

    getDatas_1();
    datas_1();
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      let data =
        "pat_share=" +
        storedNames.appt_a +
        "&main_pro=" +
        main_pro +
        "&owner_id=" +
        owner_id +
        "&userID=" +
        userID +
        "&authorized=" +
        authorized +
        "&facilityId=" +
        officeid +
        "&speciality=" +
        speciality +
        "&apttype=" +
        apttype;

      let data1 = Encrypt_Value(data, "vozo");

      let url;
      let user = authorized === "1" ? userID : main_pro;
      if (storedNames.appt_a) {
        url = "/vozonew/provider?search=" + data1;
      } else {
        url = "/vozonew/provider?uid=" + user;
      }
      if (speciality !== "") {
        axiosInstance
          .get(url)
          .then((response) => {
            let temp_role_prov = Decrypt_Value(response.data, "vozo");
            setSourceprovider(temp_role_prov);
            if (!storedNames.appt_a || authorized === "0") {
              setNotDisabledSpec(temp_role_prov[0].specialty);
            }
          })
          .catch((err) => console.error(err));
      }
    }
    axiosInstance
      .get("vozo/speciality/getspeciality?officeid=" + officeid)
      .then((res) => {
        let decryptval = Decrypt_Value(res.data, "vozo");
        const countDict = decryptval.reduce((acc, item) => {
          acc[item.specialty] = item.count;
          return acc;
        }, {});

        const matchedSpecialties = specialityList.map((specialty) => ({
          specialty,
          count: countDict[specialty] || 0,
        }));
        matchedSpecialties.sort((a, b) => b.count - a.count);
        setSortSpecility(matchedSpecialties);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [officeid, speciality, apttype]);

  useEffect(() => {
    if (callFunction["appointment"] > 1) {
      handleClick();
    }
  }, [callFunction["appointment"]]);

  useEffect(() => {
    const week_day = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = new Date(moment(apptdate, dtFormat).format("YYYY-MM-DD"));
    setDay(week_day[day.getDay()]);

    setMonth(month[day.getMonth()]);

    setDyOfMont(day.getDate());

    const weekNum = Math.ceil(day.getDate() / 7);
    if (weekNum == 1) {
      setWkOfMont("First");
    } else if (weekNum == 2) {
      setWkOfMont("Second");
    } else if (weekNum == 3) {
      setWkOfMont("Third");
    } else if (weekNum == 4) {
      setWkOfMont("Fourth");
    } else {
      setWkOfMont("Fifth");
    }

    if (apptdate !== "") {
      const formattedApptDate = moment(apptdate, dtFormat);
      const newDate = formattedApptDate.clone().add(1, "days");
    }
  }, [apptdate]);

  useEffect(() => {
    if (apptdate !== "" && providerid !== "") {
      let encrypt = Encrypt_Value(
        { date: apptdate, uid: providerid, office: officeid },
        "vozo"
      );
      axiosInstance
        .post("/vozo/check/provider/availablity", encrypt)
        .then((response) => {
          let decodingResponse = Decrypt_Value(response.data, "vozo");
          if (decodingResponse.length !== 0) {
            let RepeatValues = decodingResponse?.filter(
              (pat) => pat.pc_recurrtype !== "0"
            );
            let NotRepeatValues = decodingResponse?.filter(
              (pat) => pat.pc_recurrtype === "0"
            );

            var repeatValues = repeatArray(RepeatValues, "FutureAppointment");

            let FinalResult = repeatValues[1].concat(NotRepeatValues);
            setUserAvail(FinalResult);
          }
        })
        .catch((err) => {
          setUserAvail([]);
          console.error(err);
        });
    }
  }, [apptdate, providerid, officeid]);

  useEffect(() => {
    axiosInstance
      .get("vozo/getBillingFacility")
      .then((res) => {
        setBillFacility(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axiosInstance
      .get("/vozo/calendarsettingdata")
      .then((response) => {
        let decodingResponse = Decrypt_Value(response.data, "vozo");
        setWeeklyDisable(decodingResponse[1].gl_value);
      })
      .catch(() => {});
  }, []);
  // ------------------------------ useEffect End--------------------------------------------------- //

  return (
    <div id="new-appts" className="new_appt_dett p-t-none">
      <Modal.Body className={"p-0 over-none-body bill_appt_edit"}>
        {SpinnerVal ? (
          <SpinnerDiv height="380px">
            <Spinner animation="border" variant="primary" />
          </SpinnerDiv>
        ) : (
          <>
            {/* <div className="appt-detail-top-head">Appointment</div> */}

            <Form.Row className="app-block">
              <Div dis="flex" flexWrap="wrap" width="100%">
                {patFlag.map((item, ind) => (
                  <div
                    key={ind}
                    className="new_appt_flag pt-fg-app-new m-t-0-imp"
                    style={{
                      backgroundColor: item.flag_color,
                      width: "fit-content",
                    }}
                  >
                    <IoFlagOutline className="new_appt_flag_icon" />
                    <span className="new_appt_flag_amt">{`${item.flag_name} : ${item.description}`}</span>
                  </div>
                ))}
              </Div>

              <Div dis="flex" width="100%" alignItems="start">
                <Div
                // className={`d-block left-side-first-block ${
                //   (props.tabstatus1 || props.tabstatus2) && "full-width-appt"
                // }`}
                // marginLeft="30px"
                >
                  <Div
                    style={{ padding: "6px 0px 16px 0px" }}
                    //  paddingBottom="0px"
                    alignItems="center"
                    className="search-pat wholediv patient_name_out"
                  >
                    <Div margin="-20px 0px 0px -2px">
                      <PeopleOutline className="people" />
                    </Div>
                    <Div className="dropdiv " id="PatId">
                      {/* changes by sridar */}
                      <Select
                        showSearch
                        placeholder="Patient Name"
                        id="new-app-patient_1 appt-inp-patsrch"
                        suffixIcon={<BsFillCaretDownFill />}
                        value={props.dataObj.pname}
                        disabled
                      ></Select>
                      {/* ..................... */}
                    </Div>
                  </Div>

                  {/* check box */}
                  <Div
                    paddingBottom="16px"
                    alignItems="center"
                    className="appointment_poup_div appointment_poup_div_w"
                  >
                    <Div
                      id="new_appt_radio"
                      className="appt-spn-vdovis new_app_radio_group"
                    >
                      <Radio.Group
                        defaultValue={
                          Number(teleCheck) === 1
                            ? 2
                            : Number(walkInRatio) === 1
                            ? 3
                            : 1
                        }
                      >
                        {/* Appointment Radio */}
                        <Radio
                          className="radio_appt_btn"
                          value={1}
                          disabled={
                            Number(teleCheck) == 1 || Number(walkInRatio) == 1
                          }
                        >
                          Appointment
                        </Radio>

                        {/* Telehealth Radio */}
                        <Radio value={2} disabled={Number(teleCheck) !== 1}>
                          Telehealth
                        </Radio>

                        {/* Walk-in Radio */}
                        <Radio
                          className="app-dashboard-radioBut"
                          value={3}
                          disabled={Number(walkInRatio) !== 1}
                          id="new-app-walkin"
                        >
                          Walk-in
                        </Radio>
                      </Radio.Group>
                    </Div>
                  </Div>

                  {/* hospital */}
                  <Div
                    alignItems="center"
                    className=" wholediv1"
                    marginleft="0px"
                  >
                    <Div margin="-14px 0px 0px 0px">
                      <RoomOutlined />
                    </Div>
                    <Div className="dropdiv1">
                      <Select
                        showSearch
                        placeholder=" Office"
                        id="new-app-facility_1"
                        suffixIcon={<BsFillCaretDownFill />}
                        disabled={
                          teleCheck ||
                          !storedNames.appt_a ||
                          Number(encounter) !== 0
                        }
                        className={
                          officeError ? "err_mess_border w-100" : "w-100"
                        }
                        onChange={(e, data) =>
                          handleDropDownSelectOffice(e, data)
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={officeid ? officeid : undefined}
                        dropdownClassName={`custom-dropdown ${antDropdown}`}
                        onMouseLeave={handleMouseLeave}
                        onClick={mouseClick}
                        onDropdownVisibleChange={handleDropdownVisibleChange}
                        dropdownRender={(menu) => (
                          <div onMouseLeave={handleMouseLeave}>
                            {showOptions && (
                              <div style={{ margin: 0, padding: 0 }}>
                                {menu}
                              </div>
                            )}
                          </div>
                        )}
                      >
                        {sourceoffice.map((i) => (
                          <Option key={i.value} value={i.value} title={i.text}>
                            {i.text}
                          </Option>
                        ))}
                      </Select>

                      {officeError ? (
                        <p className="errormessage">Please select Office</p>
                      ) : (
                        ""
                      )}
                    </Div>
                  </Div>

                  {/* Provider name */}
                  <Div
                    marginleft="0px"
                    alignItems="center"
                    className="search-pat wholediv1 new-apt-pat-sel"
                  >
                    <Div margin="-14px 0px 4px 0px">
                      <PeopleOutline />
                    </Div>
                    <Div className="dropdiv1">
                      <Select
                        showSearch
                        placeholder=" Provider"
                        id="new-app-provider_1"
                        suffixIcon={<BsFillCaretDownFill />}
                        disabled={
                          !storedNames.appt_a || Number(encounter) !== 0
                        }
                        className={
                          provError ? "err_mess_border w-100 " : "w-100"
                        }
                        onChange={(e, data) =>
                          handleDropDownSelectProvider(e, data)
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={providerid ? providerid : undefined}
                        dropdownClassName={`custom-dropdown ${antDropdown}`}
                        onMouseLeave={handleMouseLeave}
                        onClick={mouseClick}
                        onDropdownVisibleChange={handleDropdownVisibleChange}
                        dropdownRender={(menu) => (
                          <div onMouseLeave={handleMouseLeave}>
                            {showOptions && (
                              <div style={{ margin: 0, padding: 0 }}>
                                {menu}
                              </div>
                            )}
                          </div>
                        )}
                      >
                        {sourceprovider.map((i) => (
                          <Option key={i.value} value={i.value} title={i.text}>
                            {i.text}
                          </Option>
                        ))}
                      </Select>

                      {provError && (
                        <p className="errormessage">Please select Provider</p>
                      )}
                    </Div>
                  </Div>

                  {/* date and time */}

                  {/* donse not repect */}

                  <Div
                    className="app_repeat_dropdown position-relative app_dummy_class"
                    margin=" 0px 0px 10px 35px"
                  >
                    <BsFillCaretDownFill className="down_arrow_doesnt does-not-repeat-icon-edit" />

                    <DropdownButton
                      id="arrow-newAppt"
                      className="app-nav-dropdown-for-newApp-repect_edit "
                      title={repvalue ? repvalue : "Does not repeat"}
                      // title=""
                      onSelect={handlerepSelect}
                      variant="light"
                    >
                      <Dropdownant.Item eventKey="Does not repeat">
                        Does not repeat
                      </Dropdownant.Item>
                      <Dropdownant.Item
                        onClick={handleShowuntildate}
                        eventKey="Daily"
                      >
                        Daily
                      </Dropdownant.Item>
                      <Dropdownant.Item
                        onClick={handleShowuntildate}
                        eventKey={"Week"}
                      >
                        Week
                      </Dropdownant.Item>
                    </DropdownButton>
                  </Div>

                  {repvalue === "Week" && (
                    <Div margin="5px 4px 14px 40px">
                      <ToggleButtonGroup
                        type="checkbox"
                        value={weekday}
                        className="appt_repeats but-act app_gap "
                        onChange={WeekdayChange}
                        style={
                          selectdayError
                            ? {
                                border: "1px solid red",
                                borderRadius: "5px",
                              }
                            : {}
                        }
                      >
                        <ToggleButton
                          disabled={
                            WeeklyDisable === "Show only weekdays" ||
                            WeeklyDisable === "Don't show Sundays"
                              ? true
                              : false
                          }
                          className={
                            WeeklyDisable === "Show only weekdays" ||
                            (WeeklyDisable === "Don't show Sundays" &&
                              "weekDisableBtn")
                          }
                          id="tbg-btn-1"
                          value={1}
                        >
                          S
                        </ToggleButton>
                        <ToggleButton id="tbg-btn-2" value={2}>
                          M
                        </ToggleButton>
                        <ToggleButton id="tbg-btn-3" value={3}>
                          T
                        </ToggleButton>
                        <ToggleButton id="tbg-btn-3" value={4}>
                          W
                        </ToggleButton>
                        <ToggleButton id="tbg-btn-3" value={5}>
                          T
                        </ToggleButton>
                        <ToggleButton id="tbg-btn-3" value={6}>
                          F
                        </ToggleButton>
                        <ToggleButton
                          disabled={
                            WeeklyDisable === "Show only weekdays"
                              ? true
                              : false
                          }
                          id="tbg-btn-3"
                          value={7}
                        >
                          S
                        </ToggleButton>
                      </ToggleButtonGroup>

                      {selectdayError && (
                        <p className="errormessage">
                          Please select atleast a day
                        </p>
                      )}
                    </Div>
                  )}

                  {repvalue !== "" &&
                    repvalue !== undefined &&
                    repvalue !== null &&
                    repvalue !== "Does not repeat" &&
                    repvalue !== "Does not repeat " && (
                      <Div margin=" 0px 0px 10px 30px" dis="flex">
                        <Span marginRight="3px" color="#000000 !important">
                          Until:
                        </Span>
                        <Div position="relative">
                          <DatePicker
                            disabledDate={(current) => {
                              const oneYearFromNow = moment()
                                .add(1, "year")
                                .startOf("day");
                              return (
                                current.isBefore(
                                  moment(
                                    // aptdate,
                                    dtFormat
                                  ),
                                  "day"
                                ) || current.isAfter(oneYearFromNow, "day")
                              );
                            }}
                            onChange={(date) => {
                              if (date) {
                                setLastdateApp(moment(date).format(dtFormat));
                              } else {
                                setLastdateApp(moment().format(dtFormat));
                              }
                            }}
                            placeholder="From-"
                            format={dtFormat}
                            value={
                              lastdateApp ? moment(lastdateApp, dtFormat) : null
                            }
                            allowClear={false}
                          />
                        </Div>
                      </Div>
                    )}

                  {/* <Div
                    className="app_repeat_dropdown position-relative"
                    margin=" 18px 0px 16px 52px"
                  >
                    <BsFillCaretDownFill className="aptDet_down_arrow_doesnt " />
                    <DropdownButton
                      align="left"
                      id="arrow-newAppt"
                      className={`app-nav-dropdown ${
                        (props.tabstatus1 || props.tabstatus2) &&
                        "full-width-appt-repeat-btn"
                      }`}
                      title={repvalue ? repvalue : "Does not repeat "}
                      onSelect={handlerepSelect}
                      disabled={Number(encounter) !== 0}
                      variant="light"
                    >
                      <p></p>
                      <Dropdownant.Item
                        onClick={handleShowuntildate1}
                        eventKey="Does not repeat"
                      >
                        Does not repeat
                      </Dropdownant.Item>
                      <Dropdownant.Item
                        onClick={handleShowuntildate}
                        eventKey="Daily"
                      >
                        Daily
                      </Dropdownant.Item>
                      <Dropdownant.Item
                        onClick={handleShowuntildate}
                        eventKey="Week"
                      >
                        Week
                      </Dropdownant.Item>
                    </DropdownButton>
                  </Div>
                  {repvalue === "Week" && (
                    <Div margin="5px 4px 14px 40px">
                      <ToggleButtonGroup
                        type="checkbox"
                        value={weekday}
                        className="appt_repeats but-act app_gap "
                        onChange={WeekdayChange}
                        style={
                          selectdayError
                            ? {
                                border: "1px solid red",
                                borderRadius: "5px",
                              }
                            : {}
                        }
                      >
                        <ToggleButton
                          disabled={
                            WeeklyDisable === "Show only weekdays" ||
                            WeeklyDisable === "Don't show Sundays"
                              ? true
                              : false
                          }
                          className={
                            WeeklyDisable === "Show only weekdays" ||
                            (WeeklyDisable === "Don't show Sundays" &&
                              "weekDisableBtn")
                          }
                          id="tbg-btn-1"
                          value={1}
                        >
                          S
                        </ToggleButton>
                        <ToggleButton id="tbg-btn-2" value={2}>
                          M
                        </ToggleButton>
                        <ToggleButton id="tbg-btn-3" value={3}>
                          T
                        </ToggleButton>
                        <ToggleButton id="tbg-btn-3" value={4}>
                          W
                        </ToggleButton>
                        <ToggleButton id="tbg-btn-3" value={5}>
                          T
                        </ToggleButton>
                        <ToggleButton id="tbg-btn-3" value={6}>
                          F
                        </ToggleButton>
                        <ToggleButton
                          disabled={
                            WeeklyDisable === "Show only weekdays"
                              ? true
                              : false
                          }
                          id="tbg-btn-3"
                          value={7}
                        >
                          S
                        </ToggleButton>
                      </ToggleButtonGroup>

                      {selectdayError && (
                        <p className="errormessage">
                          Please select atleast a day
                        </p>
                      )}
                    </Div>
                  )}
                  {lastdate && (
                    <Div margin=" 0px 0px 16px 52px" dis="flex">
                      <Span marginRight="3px" color="#000000 !important">
                        Until:
                      </Span>

                      <DatePicker
                        disabled={Number(encounter) !== 0}
                        disabledDate={(current) => {
                          const oneYearFromNow = moment()
                            .add(1, "year")
                            .startOf("day");
                          return (
                            current.isBefore(
                              moment(apptdate, dtFormat),
                              "day"
                            ) || current.isAfter(oneYearFromNow, "day")
                          );
                        }}
                        className="app-inp-disabled"
                        onChange={(date) => {
                          if (date) {
                            setLastdateApp(date.format(dtFormat));
                            dispatch(
                              changeAppointmentValues({
                                name: "save button",
                                value: "appointment",
                              })
                            );
                          } else {
                            setLastdateApp(moment().format(dtFormat));
                          }
                        }}
                        placeholder="From-"
                        format={dtFormat}
                        value={
                          lastdateApp ? moment(lastdateApp, dtFormat) : null
                        }
                        allowClear={false}
                      />
                    </Div>
                  )} */}
                </Div>

                <Div
                  className={`d-block left-side-second-block_edit  ${
                    (props.tabstatus1 || props.tabstatus2) && "full-width-appt"
                  }`}
                >
                  {/* appointment profile */}
                  <Div alignItems="center" className=" wholediv1">
                    <Div margin="-14px 0px 3px 0px">
                      <PersonOutline />
                    </Div>
                    <Div className="dropdiv1">
                      <Select
                        showSearch
                        placeholder="Appointment Profile"
                        id="new-app-profile_1"
                        suffixIcon={<BsFillCaretDownFill />}
                        className={
                          profileError ? "err_mess_border w-100 " : "w-100"
                        }
                        onChange={(e, data) => handleDropDownSelect(e, data)}
                        disabled={Number(encounter) !== 0}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={profileid ? profileid : undefined}
                        onClick={mouseClick}
                        dropdownClassName={`custom-dropdown ${antDropdown}`}
                        onMouseLeave={handleMouseLeave}
                        onDropdownVisibleChange={handleDropdownVisibleChange}
                        dropdownRender={(menu) => (
                          <div onMouseLeave={handleMouseLeave}>
                            {showOptions && (
                              <div style={{ margin: 0, padding: 0 }}>
                                {menu}
                              </div>
                            )}
                          </div>
                        )}
                      >
                        {sourceprofile.map((i) => (
                          <Option key={i.value} value={i.value} title={i.text}>
                            {i.text}
                          </Option>
                        ))}
                      </Select>

                      {profileError && (
                        <p className="errormessage">
                          Please select Appointment Profile
                        </p>
                      )}
                    </Div>
                  </Div>

                  {/* date and time */}
                  <Div
                    display="inline"
                    className={
                      (props.tabstatus1 || props.tabstatus2) &&
                      "full-width-appt-date"
                    }
                  >
                    <Div className="inner-grid-newappdetails">
                      <Div margin="0px 0px 9px 0px" alignSelf="flex-start">
                        <AccessTimeIcon className="apt-edit-time-icon" />
                      </Div>
                      <form
                        className={`${classes.container} frm-apoint apt-det-date-time-picker`}
                        noValidate
                      >
                        <Div
                          className="for-edit-app-insert-app-patient date-pick-parentInEdit"
                          dis="flex"
                        >
                          <DatePicker
                            style={{ padding: "0px", border: "none" }}
                            disabled={Number(encounter) !== 0}
                            className="app-inp-disabled datePickerW"
                            onChange={(date) => {
                              if (date) {
                                setapptdate(date.format(dtFormat));
                                dispatch(
                                  changeAppointmentValues({
                                    name: "save button",
                                    value: "appointment",
                                  })
                                );
                              } else {
                                setapptdate(moment().format(dtFormat));
                              }
                            }}
                            placeholder="From-"
                            format={dtFormat}
                            value={apptdate ? moment(apptdate, dtFormat) : null}
                            allowClear={false}
                          />
                          <div className="App-time new_appt_detail_time_edit">
                            <div className="showTime">
                              <input
                                className="fromTime-toTime"
                                onClick={() => {
                                  handleFromTime();
                                }}
                                onMouseLeave={() => {
                                  setTempOpen(false);
                                }}
                                disabled={Number(encounter) !== 0}
                                value={inTime}
                                onChange={(e) => {
                                  dispatch(
                                    changeAppointmentValues({
                                      name: "save button",
                                      value: "appointment",
                                    })
                                  );
                                  if (!regexFirstNum.test(e.target.value)) {
                                    setFromShow(false);
                                    setToShow(false);
                                    setInTime(e.target.value);
                                    setTimeout(() => {
                                      setInTime(
                                        moment(e.target.value, "h:mm A").format(
                                          "h:mm A"
                                        )
                                      );
                                      onChange(
                                        moment(e.target.value, "h:mm A").format(
                                          "h:mm A"
                                        ),
                                        "inTime"
                                      );
                                    }, 2000);
                                  }
                                }}
                                onBlur={(e) => {
                                  setInTime(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    )
                                  );
                                  onChange(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    ),
                                    "inTime"
                                  );
                                }}
                              />
                              <p className="time--space">-</p>
                              <input
                                className="fromTime-toTime"
                                onClick={() => {
                                  handleToTime();
                                }}
                                disabled={Number(encounter) !== 0}
                                onChange={(e) => {
                                  dispatch(
                                    changeAppointmentValues({
                                      name: "save button",
                                      value: "appointment",
                                    })
                                  );
                                  if (!regexFirstNum.test(e.target.value)) {
                                    setFromShow(false);
                                    setToShow(false);
                                    setOutTime(e.target.value);
                                    setTimeout(() => {
                                      setOutTime(
                                        moment(e.target.value, "h:mm A").format(
                                          "h:mm A"
                                        )
                                      );
                                      onChange(
                                        moment(e.target.value, "h:mm A").format(
                                          "h:mm A"
                                        ),
                                        "outTime"
                                      );
                                    }, 2000);
                                  }
                                }}
                                onMouseLeave={() => {
                                  setTempOpen(false);
                                }}
                                onBlur={(e) => {
                                  setOutTime(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    )
                                  );
                                  onChange(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    ),
                                    "outTime"
                                  );
                                }}
                                value={outTime}
                              />
                            </div>
                            <div
                              className={
                                fromShow
                                  ? "fromArray-toArray"
                                  : "fromArray_hide"
                              }
                            >
                              {frtimeSlots.map((item, index) => {
                                return (
                                  <button
                                    key={item.id}
                                    value={item.time + " " + item.meridiem}
                                    disabled={disabledFromTimes(
                                      item.time,
                                      item.meridiem
                                    )}
                                    onClick={async (e) => {
                                      dispatch(
                                        changeAppointmentValues({
                                          name: "save button",
                                          value: "appointment",
                                        })
                                      );
                                      setInTime(
                                        moment(e.target.value, "h:mm A").format(
                                          "h:mm A"
                                        )
                                      );

                                      handleFromButton(e);
                                      onChange(
                                        moment(e.target.value, "h:mm A").format(
                                          "h:mm A"
                                        ),
                                        "inTime"
                                      );
                                    }}
                                  >
                                    {item.time + " " + item.meridiem}
                                  </button>
                                );
                              })}
                            </div>
                            <div
                              className={
                                toShow ? "fromArray-toArray" : "toArray_hide"
                              }
                              style={{ left: 70 }}
                            >
                              {totimeSlots.map((item, index) => {
                                return (
                                  <button
                                    disabled={disabledToTime(
                                      item.time,
                                      item.meridiem,
                                      item.id
                                    )}
                                    key={index}
                                    value={item.time + " " + item.meridiem}
                                    onClick={async (e) => {
                                      dispatch(
                                        changeAppointmentValues({
                                          name: "save button",
                                          value: "appointment",
                                        })
                                      );
                                      setOutTime(
                                        moment(e.target.value, "h:mm A").format(
                                          "h:mm A"
                                        )
                                      );

                                      handleToButton(e);
                                      onChange(
                                        moment(e.target.value, "h:mm A").format(
                                          "h:mm A"
                                        ),
                                        "outTime"
                                      );
                                    }}
                                  >
                                    {item.time + " " + item.meridiem}
                                  </button>
                                );
                              })}
                            </div>
                          </div>
                        </Div>
                      </form>
                    </Div>
                  </Div>

                  {inTimeErr && (
                    <Form.Text
                      id="new-app-time-codetext appt-warn-time"
                      className="colour-red p-l-66 m-t-0-imp m-b-3"
                    >
                      Please Select valid Time
                    </Form.Text>
                  )}
                  {timelimiterrorbox && (
                    <Form.Text
                      id="new-app-time-codetext appt-warn-time"
                      className="colour-red p-l-66 m-t-0-imp m-b-3"
                    >
                      Provider Only Available Between {startErrTime} to{" "}
                      {endErrTime}
                    </Form.Text>
                  )}

                  {/* specility */}
                  <Div alignItems="center" className=" wholediv1 mt-4">
                    <Div margin="-14px 0px 0px 0px">
                      <Image
                        src={doctorLogo}
                        alt="speciality"
                        height="20px"
                        width="20px"
                      />
                    </Div>
                    <Div className="dropdiv1">
                      <Select
                        showSearch
                        placeholder="Select Speciality"
                        suffixIcon={<BsFillCaretDownFill />}
                        className={
                          specialError ? "err_mess_border w-100" : "w-100"
                        }
                        onChange={(data) => changeSpeciality(data)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        disabled={Number(encounter) !== 0}
                        value={speciality ? speciality : undefined}
                        dropdownClassName="speciality-option"
                      >
                        {sortSpeciality.map((item) => (
                          <Option
                            key={item.specialty}
                            value={item.specialty}
                            title={item.specialty}
                            disabled={
                              !storedNames.appt_a
                                ? item.specialty !== notDisabledSpec
                                : false || item.count == 0
                            }
                            id={
                              !storedNames.appt_a &&
                              item.specialty !== notDisabledSpec
                                ? "clr-disable"
                                : "" || item.count == 0
                                ? "clr-disable"
                                : ""
                            }
                            className={
                              !storedNames.appt_a &&
                              item.specialty !== notDisabledSpec
                                ? "custom-disabled-option"
                                : ""
                            }
                          >
                            {item.specialty}
                          </Option>
                        ))}
                      </Select>
                      {specialError ? (
                        <p className="errormessage">Select a speciality</p>
                      ) : (
                        ""
                      )}
                    </Div>
                  </Div>

                  {/* billing facility */}
                  {/* <Div alignItems="center" className=" wholediv1">
                    <Div margin="-14px 0px 5px 0px">
                      <Image
                        src={BillingFacilityIcon}
                        alt="bill"
                        height="20px"
                        width="20px"
                      />
                    </Div>
                    <Div className="dropdiv1" id="billingFac">
                      <Select
                        disabled={Number(encounter) !== 0}
                        showSearch
                        placeholder="Billing Facility"
                        suffixIcon={<BsFillCaretDownFill />}
                        id="new-app-status_1 appt-inp-stas"
                        className={
                          billFacilityError ? "err_mess_border w-100" : "w-100"
                        }
                        onChange={(e, data) => handleBillingFacility(e, data)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={billFacilityID ? billFacilityID : undefined}
                        getPopupContainer={() =>
                          document.getElementById("billingFac")
                        }
                      >
                        {billFacility?.map((i) => (
                          <Option key={i.value} value={i.value} title={i.text}>
                            {i.text}
                          </Option>
                        ))}
                      </Select>
                      {billFacilityError && (
                        <p className="errormessage">
                          Please Select the Facility
                        </p>
                      )}
                    </Div>
                  </Div> */}
                  {/* <div className="apt-det-div-splitter"> */}
                  {/* <Div className=" w-100"> */}

                  {/* </Div> */}
                  {/* status */}
                  <Div alignItems="center" className=" wholediv1">
                    <Div margin="-14px 0px 0px 0px">
                      <LocalHospitalOutlined />
                    </Div>
                    <Div className="dropdiv1">
                      <Select
                        disabled={Number(encounter) !== 0}
                        showSearch
                        placeholder=" Status"
                        id="new-app-status_1 appt_status_input"
                        suffixIcon={<BsFillCaretDownFill />}
                        className="w-100"
                        onChange={(e, data) => {
                          handleDropDownSelectStatus(e, data);
                        }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={statusid}
                        dropdownClassName={`custom-dropdown ${antDropdown}`}
                        onMouseLeave={handleMouseLeave}
                        onClick={mouseClick}
                        onDropdownVisibleChange={handleDropdownVisibleChange}
                        dropdownRender={(menu) => (
                          <div onMouseLeave={handleMouseLeave}>
                            {showOptions && (
                              <div style={{ margin: 0, padding: 0 }}>
                                {menu}
                              </div>
                            )}
                          </div>
                        )}
                      >
                        {repeats_checked ? (
                          <></>
                        ) : (
                          <>
                            {teleCheck ? (
                              <>
                                {sourcestatustele.map((i) => (
                                  <Option
                                    key={i.value}
                                    value={i.value}
                                    title={i.text}
                                  >
                                    {i.text}
                                  </Option>
                                ))}
                              </>
                            ) : (
                              <>
                                {sourcestatus.map((i) => (
                                  <Option
                                    key={i.value}
                                    value={i.value}
                                    title={i.text}
                                  >
                                    {i.text}
                                  </Option>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </Select>
                    </Div>
                  </Div>

                  {/* exam rooms */}
                  <Div alignItems="center" className=" wholediv1">
                    <Div margin="-14px 0px 5px 0px">
                      <HomeOutlinedIcon />
                    </Div>
                    <Div className="dropdiv1">
                      <Select
                        showSearch
                        placeholder="Exam Room"
                        id="new-app-profile_1"
                        disabled={Number(encounter) !== 0}
                        suffixIcon={<BsFillCaretDownFill />}
                        className={
                          examroomError ? "err_mess_border w-100 " : "w-100"
                        }
                        onChange={(e, data) => handleRoomVal(data.value)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={roomval ? roomval : undefined}
                        dropdownClassName={`custom-dropdown ${antDropdown}`}
                        onClick={mouseClick}
                        onMouseLeave={handleMouseLeave}
                        onDropdownVisibleChange={handleDropdownVisibleChange}
                        dropdownRender={(menu) => (
                          <div onMouseLeave={handleMouseLeave}>
                            {showOptions && (
                              <div style={{ margin: 0, padding: 0 }}>
                                {menu}
                              </div>
                            )}
                          </div>
                        )}
                      >
                        {sourceroom.map((i) => (
                          <Option key={i.value} value={i.value} title={i.text}>
                            {i.text}
                          </Option>
                        ))}
                      </Select>
                      {examroomError && (
                        <p className="errormessage">Please select Exam Room</p>
                      )}
                    </Div>
                  </Div>

                  {/* unwanted */}
                  {/* <Div alignItems="center" className="wholediv2">
                    <Div margin="13px 0px 26px 30px">
                      <Checkbox
                        value="Set as Arrived"
                        name="Set as Arrived"
                        className="security-check arrived_check"
                        onClick={() => changeCheckbox("arrived")}
                        checked={
                          checkValue === "arrived" ||
                          statusid === "@" ||
                          statusid === "doc_completed"
                        }
                        disabled={
                          (teleCheck
                            ? statusid === "doc_completed"
                            : statusid === "@") ||
                          repeats_checked ||
                          custState
                        }
                      >
                        {teleCheck ? "Set as Completed" : "Set as Arrived"}
                      </Checkbox>
                    </Div>
                    <Div Div margin="13px 0px 26px 0px">
                      <Checkbox
                        value=" Set as Cancelled"
                        name=" Set as Cancelled"
                        className="security-check cancel_check"
                        onClick={() => changeCheckbox("cancelled")}
                        checked={
                          checkValue === "cancelled" ||
                          statusid === "x" ||
                          statusid === "doc_cancelled"
                        }
                        disabled={
                          (teleCheck
                            ? statusid === "doc_cancelled"
                            : statusid === "x") ||
                          repeats_checked ||
                          custState ||
                          Number(encounter) !== 0
                        }
                      >
                        Set as Cancelled
                      </Checkbox>
                    </Div>
                  </Div> */}
                </Div>
              </Div>
            </Form.Row>
          </>
        )}
      </Modal.Body>
      <Modal
        className="app-popup repeat-popup rept-modal popup_centre edit_conf_border"
        centered
        backdrop="static"
        keyboard={false}
        show={repeatpopup}
      >
        <RepeatPopupBlock>
          <RepeatPopupHeader>Edit Confirmation</RepeatPopupHeader>
          <RepeatPopupBodyText>
            Please select & confirm the below option before take action
          </RepeatPopupBodyText>
          <RepeatPopupBodyBtn>
            <RepeatBtn
              onClick={() => {
                setrepeatbtn((repeatbtn) => ({
                  ...defaultRepeatBtn,
                  current: true,
                }));
              }}
              active={repeatbtn.current}
            >
              Current
            </RepeatBtn>
            <RepeatBtn
              onClick={() => {
                setrepeatbtn((repeatbtn) => ({
                  ...defaultRepeatBtn,
                  future: true,
                }));
              }}
              active={repeatbtn.future}
            >
              Future
            </RepeatBtn>
            <RepeatBtn
              onClick={() => {
                setrepeatbtn((repeatbtn) => ({
                  ...defaultRepeatBtn,
                  all: true,
                }));
              }}
              active={repeatbtn.all}
            >
              All
            </RepeatBtn>{" "}
          </RepeatPopupBodyBtn>
          <RepeatFooter>
            <RepeatBtn
              active={false}
              onClick={() => {
                setrepeatbtn(defaultRepeatBtn);
                setrepeatpopup(false);
              }}
              className="close_active close_active2"
            >
              Cancel
            </RepeatBtn>
            <RepeatBtnConfirm
              confirm={true}
              onClick={() => buttonDetailClickActions("")}
            >
              Confirm
            </RepeatBtnConfirm>
          </RepeatFooter>
        </RepeatPopupBlock>
      </Modal>

      <Modal
        className="alert-popup-message eramsg"
        show={ModalAlerShow1}
        onHide={modalhide}
      >
        <div role="alert" className="alert-danger erapad">
          <BsExclamationOctagon className="icons-bs alert-icons-bts" />
          <span>{ModalMsg1}</span>
        </div>
      </Modal>

      <Modal
        className="client-popup pat-pay-tab w-100 topcreate_popup_centre striper"
        centered
        show={statusCheck}
        onHide={handleCloseAlert}
      >
        <AvailabilityError
          handleModalClick={handleCloseAlert}
          type={"status"}
        />
      </Modal>
    </div>
  );
};
export default memo(React.forwardRef(NewAppointmentDetail));
