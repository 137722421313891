import React, { memo, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ServicesAddTable from "./ServicesAddTable";
import { FiTrash2 } from "react-icons/all";
import { useEffect } from "react";
import LoadScripts from "./scriptinject";
import axiosInstance from "../../axios/axios";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";

const AppointmentServiceDetail = (props) => {
  const [open, setOpen] = useState(false);

  const openServiceTab = () => setOpen(true);
  useEffect(() => {
    LoadScripts();
  }, []);

  useEffect(() => {
    axiosInstance
      .get("/vozo/allbill/withall?eid=" + props.eventeid)
      .then((response) => {
        let data = Decrypt_Value(response.data, "vozo");
      })
      .catch((err) => console.log(err.message));

    props.openService(open);
  }, [open]);
  return (
    <>
      <Modal.Body className="bill-appt-pdb servic-tab-apd  position-relative">
        <ServicesAddTable
          start={props.start}
          eventeid={props.id}
          tabstatus={props.tabstatus}
          rerender={props.rerender}
          savebox={props.savebox}
          apptId={props.apptId}
          changeRefresh={props.changeRefresh}
          openServiceTab={openServiceTab}
          serviceRefreshKey={props.serviceRefreshKey}
        />
      </Modal.Body>
      {/* {props.savebox && (
        <Modal.Footer className="client-app new_appt_detail_footer">
          <div
            className={`apt-det-del-btn m-l-3 ${props.savebox && "disabled"}`}
          >
            <FiTrash2
              className={`trash_icon_appt_det ${props.savebox && "disabled"}`}
              onClick={props.deleteapp}
            />
          </div>
          <Button
            className="save-btn m-r-10"
            variant="primary"
            disabled={props.savebox}
            onClick={props.closepopupedit}
          >
            Done
          </Button>
        </Modal.Footer>
      )} */}
    </>
  );
};
export default memo(AppointmentServiceDetail);
