// import { memo } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";

function GeneratePDF(data) {
  const doc = new jsPDF();

  let weight,
    bps_1,
    bps_2,
    height,
    pulse,
    temperature,
    respiration,
    p_name,
    u_name,
    sex,
    pubpid;
  if (data.weight === undefined || data.weight === null) {
    weight = "0.00";
  } else {
    weight = data.weight;
  }
  if (data.bps_1 === undefined || data.bps_1 === null) {
    bps_1 = "0.00";
  } else {
    bps_1 = data.bps_1;
  }
  if (data.bps_2 === undefined || data.bps_2 === null) {
    bps_2 = "0.00";
  } else {
    bps_2 = data.bps_2;
  }
  if (data.height === undefined || data.height === null) {
    height = "0.00";
  } else {
    height = data.height;
  }
  if (data.pulse === undefined || data.pulse === null) {
    pulse = "0.00";
  } else {
    pulse = data.pulse;
  }
  if (data.temperature === undefined || data.temperature === null) {
    temperature = "0.00";
  } else {
    temperature = data.temperature;
  }
  if (data.respiration === undefined || data.respiration === null) {
    respiration = "0.00";
  } else {
    respiration = data.respiration;
  }

  if (data.pubpid === undefined || data.pubpid === null) {
    pubpid = "";
  } else {
    pubpid = data.pubpid;
  }

  if (data.sex === undefined || data.sex === null) {
    sex = "";
  } else {
    sex = data.sex;
  }

  if (data.u_name === undefined || data.u_name === null) {
    u_name = "";
  } else {
    u_name = data.u_name;
  }

  if (data.p_name === undefined || data.p_name === null) {
    p_name = "";
  } else {
    p_name = data.p_name;
  }

  const date = Date().split(" ");
  // we use a date string to generate our filename.
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];

  doc.setFontSize("12");
  doc.setFont("helvetica", "bold");
  doc.text("Patient :", 15, 15);
  doc.text("Provider :", 15, 22);
  doc.text("Sex :", 155, 15);
  doc.text("Chart ID :", 155, 22);
  doc.text("Created On :", 80, 40);
  doc.line(0, 27, 210, 27);
  doc.setFontSize("18");
  doc.text("VITAL SIGNS HISTORY FOR Test Bill", 50, 35);

  doc.setFont("helvetica", "normal");
  doc.setFontSize("12");

  doc.text(p_name, 37, 15);
  doc.text(u_name, 37, 22);
  doc.text(sex, 176, 15);
  doc.text(pubpid, 176, 22);

  doc.text(`${date[0]} ${date[1]} ${date[2]} ${date[3]} ${date[4]}`, 108, 40);
  doc.setFontSize("14");
  doc.text("System Vitals :", 30, 52);
  doc.setFontSize("12");
  doc.text("Blood Pressure (mmHG)", 30, 59);
  doc.text(`${bps_1} / ${bps_2}`, 90, 59);

  doc.text("Weight (lbs)", 30, 66);
  doc.text(90, 66, `${weight}`);

  doc.text("Height (in)", 30, 73);
  doc.text(90, 73, `${height}`);

  doc.text("pulse (bps)", 30, 79);
  doc.text(90, 79, `${pulse}`);

  doc.text("Temperature (f)", 30, 86);
  doc.text(90, 86, `${temperature}`);

  doc.text("Respiratory Rate (rpm)", 30, 93);
  doc.text(90, 93, `${respiration}`);

  doc.save(`vital_pdf_${data.encounter_date}.pdf`);
}
export default GeneratePDF;
